export const ExperimentsDynamicJSON = [
    // experiment filter
    {
        "id": "main",
        "head": "",
        "name": "main",
        "type": "",
        "fields": [
            {
                "id": "wrapper_5498",
                "head": "",
                "name": "wrapper_2775",
                "type": "",
                "fields": [
                    {
                        "id": "wrapper_521",
                        "head": "",
                        "name": "wrapper_234",
                        "type": "",
                        "fields": [],
                        "control": [
                            {
                                "id": "exp_code",
                                "name": "exp_code",
                                "rows": 0,
                                "type": "text",
                                "value": "",
                                "columns": 0,
                                "disabled": false,
                                "required": false,
                                "classname": "form-control ",
                                "draggable": false,
                                "multiline": false,
                                "labelClass": "form-label",
                                "multiError": false,
                                "placeholder": "",
                                "errorMessage": {
                                    "noData": "This field is required",
                                    "regexFail": ""
                                },
                                "isLabelAfter": false,
                                "labelContent": "Experiment ID",
                                "onDemandError": false,
                                "showInputLabel": true,
                                "applicationError": "",
                                "customValidation": [],
                                "wrapperClassName": "",
                                "regularExpression": "",
                                "requiredErrorMessage": "This field is required",
                                "validationButtonType": "",
                                "isAdditonalInputAfter": true,
                                "additionalInputContent": "",
                                "showAdditionalInputContent": false
                            }
                        ],
                        "classname": "col-6 mb-3",
                        "draggable": false,
                        "wrapperType": "BOX",
                        "tabHeadClass": ""
                    },
                    {
                        "id": "wrapper_205",
                        "head": "",
                        "name": "wrapper_541",
                        "type": "",
                        "fields": [],
                        "control": [
                            {
                                "id": "exp_title",
                                "name": "exp_title",
                                "rows": 0,
                                "type": "text",
                                "value": "",
                                "columns": 0,
                                "disabled": false,
                                "required": false,
                                "classname": "form-control ",
                                "draggable": false,
                                "multiline": false,
                                "labelClass": "form-label",
                                "multiError": false,
                                "placeholder": "",
                                "errorMessage": {
                                    "noData": "This field is required",
                                    "regexFail": ""
                                },
                                "isLabelAfter": false,
                                "labelContent": "Experiment Name",
                                "onDemandError": false,
                                "showInputLabel": true,
                                "applicationError": "",
                                "customValidation": [],
                                "wrapperClassName": "",
                                "regularExpression": "",
                                "requiredErrorMessage": "This field is required",
                                "validationButtonType": "",
                                "isAdditonalInputAfter": true,
                                "additionalInputContent": "",
                                "showAdditionalInputContent": false
                            }
                        ],
                        "classname": "col-6 mb-3",
                        "wrapperType": "BOX",
                        "tabHeadClass": ""
                    },
                    {
                        "id": "wrapper_569",
                        "head": "",
                        "name": "wrapper_426",
                        "type": "",
                        "fields": [],
                        "control": [
                            {
                                "id": "updated_by",
                                "name": "updated_by",
                                "type": "dropdown",
                                "value": "",
                                "search": true,
                                "options": [],
                                "disabled": false,
                                "imageUrl": "",
                                "isStatus": false,
                                "multiple": false,
                                "required": false,
                                "classname": "",
                                "draggable": false,
                                "showLabel": "value",
                                "labelClass": "form-label",
                                "isClearable": false,
                                "apiEndPoints": [
                                    {
                                        "url": "user_management/users_list",
                                        "type": "init",
                                        "method": "get"
                                    }
                                ],
                                "errorMessage": {
                                    "noData": "This field is required"
                                },
                                "isLabelAfter": false,
                                "labelContent": "Updated By",
                                "singleSelect": true,
                                "blankDataList": [
                                    "\"\"",
                                    "undefined",
                                    "null"
                                ],
                                "showInputLabel": true,
                                "connectedArrays": [],
                                "wrapperClassName": "",
                                "validationButtonType": "",
                                "isAdditonalInputAfter": true,
                                "additionalInputContent": "",
                                "showBlankInPlaceholder": true,
                                "showAdditionalInputContent": false
                            }
                        ],
                        "classname": "col-6 mb-3",
                        "wrapperType": "BOX",
                        "tabHeadClass": ""
                    }
                ],
                "control": [],
                "classname": "row",
                "draggable": false,
                "wrapperType": "BOX",
                "tabHeadClass": ""
            }
        ],
        "jsonId": 105,
        "control": [],
        "classname": "",
        "wrapperType": "REGULAR"
    },

    //edit experiment
    {
        "id": "main",
        "head": "",
        "name": "main",
        "type": "",
        "fields": [
            {
                "id": "wrapper_299",
                "head": "",
                "name": "wrapper_584",
                "type": "",
                "fields": [],
                "control": [
                    {
                        "id": "exp_title",
                        "name": "exp_title",
                        "rows": 0,
                        "type": "text",
                        "value": "",
                        "columns": 0,
                        "disabled": true,
                        "required": true,
                        "classname": "form-control ",
                        "draggable": false,
                        "multiline": false,
                        "labelClass": "form-label mandatory",
                        "multiError": false,
                        "placeholder": "",
                        "errorMessage": {
                            "noData": "This field is required",
                            "regexFail": ""
                        },
                        "isLabelAfter": false,
                        "labelContent": "Experiment Title",
                        "onDemandError": false,
                        "showInputLabel": true,
                        "applicationError": "",
                        "customValidation": [],
                        "wrapperClassName": "",
                        "regularExpression": "",
                        "requiredErrorMessage": "This field is required",
                        "validationButtonType": "submit",
                        "isAdditonalInputAfter": true,
                        "additionalInputContent": "",
                        "showAdditionalInputContent": false
                    }
                ],
                "classname": "form-group",
                "draggable": false,
                "wrapperType": "BOX",
                "tabHeadClass": ""
            },
            {
                "id": "wrapper_547",
                "head": "",
                "name": "wrapper_946",
                "type": "",
                "fields": [],
                "control": [
                    {
                        "id": "exp_desc",
                        "name": "exp_desc",
                        "rows": 0,
                        "type": "text",
                        "value": "",
                        "columns": 0,
                        "disabled": false,
                        "required": true,
                        "classname": "form-control textarea",
                        "draggable": false,
                        "multiline": true,
                        "labelClass": "form-label mandatory",
                        "multiError": false,
                        "placeholder": "",
                        "errorMessage": {
                            "noData": "This field is required",
                            "regexFail": ""
                        },
                        "isLabelAfter": false,
                        "labelContent": "Description",
                        "onDemandError": false,
                        "showInputLabel": true,
                        "applicationError": "",
                        "customValidation": [],
                        "wrapperClassName": "",
                        "regularExpression": "",
                        "requiredErrorMessage": "This field is required",
                        "validationButtonType": "submit",
                        "isAdditonalInputAfter": true,
                        "additionalInputContent": "",
                        "showAdditionalInputContent": false
                    }
                ],
                "classname": "form-group",
                "draggable": false,
                "wrapperType": "BOX",
                "tabHeadClass": ""
            }
        ],
        "jsonId": 106,
        "control": [],
        "classname": "",
        "wrapperType": "REGULAR"
    }
]