export const FILLINPURIFIEDssDNA = [
    //project_init
    {
        "id": "main",
        "head": "",
        "name": "main",
        "type": "",
        "fields": [
            {
                "id": "wrapper_790",
                "head": "",
                "name": "wrapper_576",
                "type": "",
                "fields": [
                    {
                        "id": "wrapper_808",
                        "head": "",
                        "name": "wrapper_50",
                        "type": "",
                        "fields": [],
                        "control": [
                            {
                                "id": "project_title",
                                "name": "project_title",
                                "__schema_id": "project_title",
                                "rows": 0,
                                "type": "text",
                                "value": "",
                                "columns": 0,
                                "disabled": false,
                                "required": true,
                                "classname": "form-control ",
                                "draggable": false,
                                "multiline": false,
                                "labelClass": "form-label mandatory",
                                "multiError": false,
                                "placeholder": "",
                                "errorMessage": {
                                    "noData": "This field is required",
                                    "regexFail": ""
                                },
                                "isLabelAfter": false,
                                "labelContent": "Project Title",
                                "onDemandError": false,
                                "showInputLabel": true,
                                "applicationError": "",
                                "customValidation": [],
                                "wrapperClassName": "",
                                "regularExpression": "",
                                "requiredErrorMessage": "This field is required",
                                "validationButtonType": "initial-submit",
                                "isAdditonalInputAfter": true,
                                "additionalInputContent": "",
                                "showAdditionalInputContent": false
                            }
                        ],
                        "classname": "col-12 mb-3",
                        "draggable": false,
                        "wrapperType": "BOX",
                        "tabHeadClass": ""
                    },
                    {
                        "id": "wrapper_654",
                        "head": "",
                        "name": "wrapper_290",
                        "type": "",
                        "fields": [],
                        "control": [
                            {
                                "id": "project_init_by",
                                "name": "project_init_by",
                                "__schema_id": "project_init_by",
                                "type": "dropdown",
                                "value": "",
                                "search": true,
                                "disabled": false,
                                "imageUrl": "",
                                "isStatus": false,
                                "multiple": false,
                                "required": true,
                                "classname": "",
                                "draggable": false,
                                "showLabel": "value",
                                "labelClass": "form-label mandatory",
                                "isClearable": false,
                                "apiEndPoints": [
                                    {
                                        "url": "user_management/users_list",
                                        "type": "init",
                                        "method": "get"
                                    }
                                ],
                                "errorMessage": {
                                    "noData": "This field is required"
                                },
                                "isLabelAfter": false,
                                "labelContent": "Initiated By",
                                "singleSelect": true,
                                "showInputLabel": true,
                                "wrapperClassName": "",
                                "validationButtonType": "initial-submit",
                                "isAdditonalInputAfter": true,
                                "additionalInputContent": "",
                                "showAdditionalInputContent": false
                            }
                        ],
                        "classname": "col-12 mb-3",
                        "draggable": false,
                        "wrapperType": "BOX",
                        "tabHeadClass": ""
                    },
                    {
                        "id": "wrapper_778",
                        "head": "",
                        "name": "wrapper_209",
                        "type": "",
                        "fields": [],
                        "control": [
                            {
                                "id": "project_start_date",
                                "name": "project_start_date",
                                "__schema_id": "project_start_date",
                                "type": "datepicker_v2",
                                "range": false,
                                "timer": false,
                                "value": "",
                                "locale": "",
                                "disabled": false,
                                "multiple": false,
                                "required": true,
                                "classname": "",
                                "draggable": false,
                                "dateFormat": "dd/mm/yyyy",
                                "hourFormat": 24,
                                "labelClass": "form-label mandatory",
                                "multiAmount": 3,
                                "placeholder": "DD-MM-YYYY",
                                "dayClassName": "dd",
                                "errorMessage": {
                                    "noData": "This field is required",
                                    "regexFail": "Date is not valid!!"
                                },
                                "isHostParent": false,
                                "isLabelAfter": false,
                                "labelContent": "Start Date",
                                "dateSeperator": "",
                                "keyboardInput": false,
                                "arrowClassName": "arrow",
                                "fordisabledEnd": "",
                                "inputClassName": "form-control date-input",
                                "monthClassName": "mtn",
                                "showInputLabel": true,
                                "todayClassName": "now",
                                "endDateReadOnly": false,
                                "inputDateFormat": false,
                                "fordisabledStart": "",
                                "wrapperClassName": "",
                                "startDateReadOnly": false,
                                "startDatePlaceHolder": "MM/DD/YYYY",
                                "validationButtonType": "initial-submit",
                                "isAdditonalInputAfter": true,
                                "selectedDateClassName": "selectedDate",
                                "additionalInputContent": "",
                                "showAdditionalInputContent": false
                            }
                        ],
                        "classname": "col-6 mb-3",
                        "draggable": false,
                        "wrapperType": "BOX",
                        "tabHeadClass": ""
                    },
                    {
                        "id": "wrapper_92",
                        "head": "",
                        "name": "wrapper_643",
                        "type": "",
                        "fields": [],
                        "control": [
                            {
                                "id": "project_end_date",
                                "name": "project_end_date",
                                "__schema_id": "project_end_date",
                                "type": "datepicker_v2",
                                "range": false,
                                "timer": false,
                                "value": "",
                                "locale": "",
                                "disabled": false,
                                "multiple": false,
                                "required": false,
                                "classname": "",
                                "draggable": false,
                                "dateFormat": "dd/mm/yyyy",
                                "hourFormat": 24,
                                "labelClass": "form-label",
                                "multiAmount": 3,
                                "placeholder": "DD-MM-YYYY",
                                "dayClassName": "dd",
                                "errorMessage": {
                                    "noData": "This field is required",
                                    "regexFail": "Date is not valid!!"
                                },
                                "isHostParent": false,
                                "isLabelAfter": false,
                                "labelContent": "End Date",
                                "dateSeperator": "",
                                "keyboardInput": false,
                                "arrowClassName": "arrow",
                                "fordisabledEnd": "",
                                "inputClassName": "form-control date-input",
                                "monthClassName": "mtn",
                                "showInputLabel": true,
                                "todayClassName": "now",
                                "endDateReadOnly": false,
                                "inputDateFormat": false,
                                "fordisabledStart": "",
                                "wrapperClassName": "",
                                "startDateReadOnly": false,
                                "startDatePlaceHolder": "MM/DD/YYYY",
                                "validationButtonType": "",
                                "isAdditonalInputAfter": true,
                                "selectedDateClassName": "selectedDate",
                                "additionalInputContent": "",
                                "showAdditionalInputContent": false
                            }
                        ],
                        "classname": "col-6 mb-3",
                        "draggable": false,
                        "wrapperType": "BOX",
                        "tabHeadClass": ""
                    },
                    {
                        "control": [
                            {
                                "type": "text",
                                "name": "status",
                                "id": "status",
                                "value": "",
                                "placeholder": "",
                                "multiline": false,
                                "classname": "form-control ",
                                "regularExpression": "",
                                "disabled": true,
                                "required": false,
                                "errorMessage": {
                                    "noData": "This fields is required",
                                    "regexFail": ""
                                },
                                "validationButtonType": "",
                                "rows": 0,
                                "columns": 0,
                                "onDemandError": false,
                                "customValidation": [],
                                "multiError": false,
                                "applicationError": "",
                                "wrapperClassName": "",
                                "requiredErrorMessage": "This fields is required",
                                "__schema_id": "status",
                                "showInputLabel": false,
                                "labelContent": "text_977",
                                "labelClass": "form-label",
                                "isLabelAfter": false,
                                "showAdditionalInputContent": false,
                                "additionalInputContent": "",
                                "isAdditonalInputAfter": true,
                                "draggable": false
                            },
                            {
                                "type": "text",
                                "name": "experiment_type",
                                "id": "experiment_type",
                                "value": "",
                                "placeholder": "",
                                "multiline": false,
                                "classname": "form-control ",
                                "regularExpression": "",
                                "disabled": true,
                                "required": false,
                                "errorMessage": {
                                    "noData": "This fields is required",
                                    "regexFail": ""
                                },
                                "validationButtonType": "",
                                "rows": 0,
                                "columns": 0,
                                "onDemandError": false,
                                "customValidation": [],
                                "multiError": false,
                                "applicationError": "",
                                "wrapperClassName": "",
                                "requiredErrorMessage": "This fields is required",
                                "__schema_id": "experiment_type",
                                "showInputLabel": false,
                                "labelContent": "text_581",
                                "labelClass": "form-label",
                                "isLabelAfter": false,
                                "showAdditionalInputContent": false,
                                "additionalInputContent": "",
                                "isAdditonalInputAfter": true,
                                "draggable": false
                            }
                        ],
                        "type": "",
                        "name": "wrapper_237",
                        "classname": "col-12 d-none",
                        "wrapperType": "BOX",
                        "id": "wrapper_929",
                        "fields": [],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    }
                ],
                "control": [],
                "classname": "row",
                "draggable": false,
                "wrapperType": "BOX",
                "tabHeadClass": ""
            }
        ],
        "jsonId": 114,
        "control": [],
        "classname": "form-container",
        "wrapperType": "REGULAR",
        "payload_schema": {
            "projectInitDetails": {
                "title": "project_title",
                "init_user": "project_init_by",
                "experiment_type": "experiment_type",
                "start_date": "project_start_date",
                "end_date": "project_end_date",
                "status": "status"
            }
        },
        "validation_type": "initial-submit"
    },
    {
        "control": [],
        "head": "",
        "name": "main",
        "id": "main",
        "wrapperType": "REGULAR",
        "jsonId": 115,
        "fields": [
            {
                "control": [],
                "type": "",
                "name": "wrapper_793",
                "classname": "row mb-4",
                "wrapperType": "BOX",
                "id": "wrapper_682",
                "fields": [
                    {
                        "control": [
                            {
                                "type": "dropdown",
                                "name": "lib_template",
                                "__schema_id": "lib_template",
                                "id": "dropDown_41",
                                "validationButtonType": "",
                                "classname": "",
                                "required": false,
                                "apiEndPoints": [
                                    {
                                        "url": "project/1/instance/search",
                                        "type": "init",
                                        "method": "post",
                                        "payload": {
                                            "filter": {
                                                "instance_title": "",
                                                "instance_status": {
                                                    "id": 5,
                                                    "value": "Project Completed"
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "change",
                                        "method": "get",
                                        "url": "project/{project_id}/instance/{instance_id}/step/{step_id}",
                                        "Mapto": [
                                            {
                                                "id": "",
                                                "urlParameters": "",
                                                "value": "",
                                            }
                                        ]
                                    }
                                ],
                                "options": [],
                                "multiple": false,
                                "isClearable": false,
                                "isStatus": false,
                                "search": true,
                                "singleSelect": true,
                                "showLabel": "project_instance_code",
                                "errorMessage": {
                                    "noData": "This fields is required"
                                },
                                "value": "",
                                "imageUrl": "",
                                "disabled": false,
                                "wrapperClassName": "",
                                "connectedArrays": [],
                                "showBlankInPlaceholder": false,
                                "blankDataList": [],
                                "commonPathOfImage": "",
                                "showInputLabel": true,
                                "labelContent": "Select Library Template Generation Project",
                                "labelClass": "form-label",
                                "isLabelAfter": false,
                                "showAdditionalInputContent": false,
                                "additionalInputContent": "",
                                "isAdditonalInputAfter": true,
                                "draggable": false
                            }
                        ],
                        "type": "",
                        "name": "wrapper_846",
                        "classname": "col-4 ",
                        "wrapperType": "BOX",
                        "id": "wrapper_524",
                        "fields": [],
                        "head": "",
                        "tabHeadClass": ""
                    },
                    {
                        "control": [
                            {
                                "type": "text",
                                "name": "projectTitle",
                                "__schema_id": "projectTitle",
                                "id": "textBox_767",
                                "value": "",
                                "placeholder": "",
                                "multiline": false,
                                "classname": "form-control ",
                                "regularExpression": "",
                                "disabled": true,
                                "required": false,
                                "errorMessage": {
                                    "noData": "This fields is required",
                                    "regexFail": ""
                                },
                                "validationButtonType": "",
                                "rows": 0,
                                "columns": 0,
                                "onDemandError": false,
                                "customValidation": [],
                                "multiError": false,
                                "applicationError": "",
                                "wrapperClassName": "",
                                "requiredErrorMessage": "This fields is required",
                                "showInputLabel": true,
                                "labelContent": "Project Title",
                                "labelClass": "form-label",
                                "isLabelAfter": false,
                                "showAdditionalInputContent": false,
                                "additionalInputContent": "",
                                "isAdditonalInputAfter": true,
                                "draggable": false
                            }
                        ],
                        "type": "",
                        "name": "wrapper_828",
                        "classname": "col-4",
                        "wrapperType": "BOX",
                        "id": "wrapper_68",
                        "fields": [],
                        "head": "",
                        "tabHeadClass": ""
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_277",
                        "classname": "col-4 ",
                        "wrapperType": "BOX",
                        "id": "wrapper_723",
                        "fields": [],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false
            },
            {
                "control": [],
                "type": "",
                "name": "wrapper_983",
                "classname": "accordion accordion-custom",
                "wrapperType": "ACCORDION",
                "id": "wrapper_197",
                "fields": [
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_893",
                        "classname": "project-table scrollable",
                        "wrapperType": "BOX",
                        "id": "wrapper_714",
                        "fields": [
                            {
                                "control": [],
                                "type": "",
                                "name": "phage",
                                "classname": "table  project-ini-table bact-table1",
                                "wrapperType": "TABLE",
                                "id": "wrapper_983",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false,
                                "hideSingleItemDelete": false,
                                "tableFields": {
                                    "showHead": true,
                                    "isVertical": false,
                                    "isIteratable": false,
                                    "addButton": {
                                        "classname": "add-expand",
                                        "name": "",
                                        "value": ""
                                    },
                                    "removeButton": {
                                        "classname": "add-minus",
                                        "name": "",
                                        "value": ""
                                    },
                                    "tableHead": {
                                        "heading": [
                                            {
                                                "value": "",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Name",
                                                "classname": ""
                                            },
                                            {
                                                "value": "ID",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Source",
                                                "classname": ""
                                            },
                                            {
                                                "value": "ANTIBIOTIC RESISTANCE",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Action",
                                                "classname": ""
                                            },
                                            {
                                                "value": "",
                                                "classname": ""
                                            }
                                        ],
                                        "classname": ""
                                    },
                                    "initRecordCount": 1,
                                    "showRecordCount": false,
                                    "recordCounterConfig": {
                                        "classname": "",
                                        "adjValue": "",
                                        "isAdjValueAfter": false
                                    },
                                    "tableRow": {
                                        "name": "",
                                        "classname": "trclass",
                                        "tableCols": [
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "phage",
                                                            "__schema_id": "phage_val",
                                                            "id": "textBox_69",
                                                            "value": "phage",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": true,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_249",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "head": "",
                                                    "name": "box_960",
                                                    "id": "box_760",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "name",
                                                            "__schema_id": "phage_name",
                                                            "id": "textBox_658",
                                                            "value": "M13K07",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_91",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_348",
                                                    "id": "box_383",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "id",
                                                            "__schema_id": "phage_code",
                                                            "id": "textBox_919",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_859",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_266",
                                                    "id": "box_651",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "source",
                                                            "__schema_id": "phage_source",
                                                            "id": "textBox_78",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_713",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_595",
                                                    "id": "box_126",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "antibiotic_resistance",
                                                            "__schema_id": "phage_antibiotic_resistance",
                                                            "id": "textBox_610",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_878",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_789",
                                                    "id": "box_963",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            // {
                                            //     "fields": {
                                            //         "control": [
                                            //             {
                                            //                 "type": "button",
                                            //                 "id": "button_696",
                                            //                 "value": "View Location",
                                            //                 "buttonType": "",
                                            //                 "classname": "info-icon",
                                            //                 "divClass": "",
                                            //                 "controlEndPoints": [],
                                            //                 "disabled": false,
                                            //                 "validationEndPoints": [],
                                            //                 "invalid": false,
                                            //                 "name": "button_211",
                                            //                 "wrapperClassName": "",
                                            //                 "draggable": false
                                            //             }
                                            //         ],
                                            //         "name": "box_989",
                                            //         "id": "box_673",
                                            //         "wrapperType": "BOX",
                                            //         "fields": [],
                                            //         "type": "",
                                            //         "classname": "text-center",
                                            //         "addButton": {
                                            //             "classname": "",
                                            //             "value": ""
                                            //         },
                                            //         "removeButton": {
                                            //             "classname": "",
                                            //             "value": ""
                                            //         },
                                            //         "wrapperClassName": ""
                                            //     },
                                            //     "classname": ""
                                            // },
                                            {
                                                "fields": {
                                                    "control": [],
                                                    "name": "box_947",
                                                    "id": "box_464",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [],
                                                    "name": "box_104",
                                                    "id": "box_772",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            }
                                        ]
                                    }
                                }
                            },
                            {
                                "control": [],
                                "type": "",
                                "name": "bacteria",
                                "classname": "table  project-ini-table bact-table2",
                                "wrapperType": "TABLE",
                                "id": "wrapper_559",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false,
                                "tableFields": {
                                    "showHead": false,
                                    "isVertical": false,
                                    "isIteratable": true,
                                    "addButton": {
                                        "classname": "add-expand",
                                        "name": "",
                                        "value": "<div class='custom-tooltip'><p>Add Row </p></div>"
                                    },
                                    "removeButton": {
                                        "classname": "add-minus",
                                        "name": "",
                                        "value": "<div class='custom-tooltip'><p>Remove Row </p></div>"
                                    },
                                    "tableHead": {
                                        "heading": [
                                            {
                                                "value": "",
                                                "classname": ""
                                            },
                                            {
                                                "value": "",
                                                "classname": ""
                                            },
                                            {
                                                "value": "",
                                                "classname": ""
                                            },
                                            {
                                                "value": "",
                                                "classname": ""
                                            },
                                            {
                                                "value": "",
                                                "classname": ""
                                            },
                                            {
                                                "value": "",
                                                "classname": ""
                                            },
                                            {
                                                "value": "",
                                                "classname": ""
                                            }
                                        ],
                                        "classname": ""
                                    },
                                    "initRecordCount": 1,
                                    "showRecordCount": false,
                                    "recordCounterConfig": {
                                        "classname": "",
                                        "adjValue": "",
                                        "isAdjValueAfter": false
                                    },
                                    "tableRow": {
                                        "name": "",
                                        "classname": "trclass",
                                        "tableCols": [
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "dropdown",
                                                            "name": "inv_category",
                                                            "__schema_id": "inv_category",
                                                            "id": "dropDown_76",
                                                            "validationButtonType": "",
                                                            "classname": "",
                                                            "required": false,
                                                            "apiEndPoints": [
                                                                {
                                                                    "type": "change",
                                                                    "method": "post",
                                                                    "url": "inventory_item",
                                                                    "Mapto": [
                                                                        {
                                                                            "id": "main.wrapper_197.wrapper_714.wrapper_559.${i}.box_820.dropDown_281",
                                                                            "urlParameters": "",
                                                                            "value": "",
                                                                            "payload": {
                                                                                "perma": "bacterial-strains",
                                                                                "user_input": ""
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                                // {
                                                                //     "type": "init",
                                                                //     "method": "post",
                                                                //     "url": "inventory_item/item-type",
                                                                //     "Mapto": [],
                                                                //     "payload": {
                                                                //         "perma": [
                                                                //             "assay-kits",
                                                                //             "cell-culture-media-antibiotics-agar-plates",
                                                                //             "gels-buffers-lab-reagents",
                                                                //             "plasticwares",
                                                                //             "glasswares",
                                                                //             "cell-lines",
                                                                //             "bacterial-strains",
                                                                //             "plasmids"
                                                                //         ]
                                                                //     }
                                                                // }
                                                            ],
                                                            "options": [
                                                                {
                                                                    "id": 7,
                                                                    "inv_item_type": "Bacterial Strains",
                                                                    "perma": "bacterial-strains"
                                                                }
                                                            ],
                                                            "multiple": false,
                                                            "isClearable": false,
                                                            "isStatus": false,
                                                            "search": true,
                                                            "singleSelect": true,
                                                            "showLabel": "inv_item_type",
                                                            "errorMessage": {
                                                                "noData": "This fields is required"
                                                            },
                                                            "value": {
                                                                "id": 7,
                                                                "inv_item_type": "Bacterial Strains",
                                                                "perma": "bacterial-strains"
                                                            },
                                                            "imageUrl": "",
                                                            "disabled": false,
                                                            "wrapperClassName": "",
                                                            "connectedArrays": [],
                                                            "showBlankInPlaceholder": false,
                                                            "blankDataList": [],
                                                            "commonPathOfImage": "",
                                                            "showInputLabel": false,
                                                            "labelContent": "dropdown_175",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "head": "",
                                                    "name": "box_162",
                                                    "id": "box_452",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "dropdown",
                                                            "name": "inv_item",
                                                            "__schema_id": "inv_item",
                                                            "id": "dropDown_281",
                                                            "validationButtonType": "",
                                                            "classname": "",
                                                            "required": false,
                                                            "apiEndPoints": [
                                                                {
                                                                    "type": "change",
                                                                    "method": "get",
                                                                    "url": "inventory_item/${id}",
                                                                    "Mapto": [
                                                                        {
                                                                            "id": "main.wrapper_197.wrapper_714.wrapper_559.${i}.box_691.inv_item_code",
                                                                            "displayKey": "change",
                                                                            "urlParameterValues": {
                                                                                "id": null,
                                                                                "patchProperty": "item_code"
                                                                            }
                                                                        },
                                                                        {
                                                                            "id": "main.wrapper_197.wrapper_714.wrapper_559.${i}.box_691.item_details",
                                                                            "displayKey": "item_details",
                                                                            "urlParameterValues": {
                                                                                "id": null
                                                                            }
                                                                        },
                                                                        {
                                                                            "id": "main.wrapper_197.wrapper_714.wrapper_559.${i}.box_847.bacteria_source",
                                                                            "displayKey": "change",
                                                                            "urlParameterValues": {
                                                                                "id": null,
                                                                                "patchProperty": "source_name"
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ],
                                                            "options": [],
                                                            "multiple": false,
                                                            "isClearable": false,
                                                            "isStatus": false,
                                                            "search": true,
                                                            "singleSelect": true,
                                                            "showLabel": "value",
                                                            "errorMessage": {
                                                                "noData": "This fields is required"
                                                            },
                                                            "value": "",
                                                            "imageUrl": "",
                                                            "disabled": false,
                                                            "wrapperClassName": "",
                                                            "connectedArrays": [],
                                                            "showBlankInPlaceholder": false,
                                                            "blankDataList": [],
                                                            "commonPathOfImage": "",
                                                            "showInputLabel": false,
                                                            "labelContent": "dropdown_507",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        },
                                                        {
                                                            "type": "button",
                                                            "id": "button_161",
                                                            "value": "",
                                                            "buttonType": "",
                                                            "classname": "info d-none",
                                                            "divClass": "",
                                                            "controlEndPoints": [],
                                                            "disabled": false,
                                                            "validationEndPoints": [],
                                                            "invalid": false,
                                                            "name": "button_23",
                                                            "wrapperClassName": "",
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_226",
                                                    "id": "box_820",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "d-block",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "inv_item_id",
                                                            "__schema_id": "inv_item_id",
                                                            "id": "inv_item_id",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_720",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        },
                                                        {
                                                            "type": "text",
                                                            "name": "item_details",
                                                            "__schema_id": "item_details",
                                                            "id": "item_details",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control d-none",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_720",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_962",
                                                    "id": "box_691",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "source",
                                                            "__schema_id": "bacteria_source",
                                                            "id": "bacteria_source",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_720",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_288",
                                                    "id": "box_847",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "bacteria_antibiotic_resistance",
                                                            "__schema_id": "bacteria_antibiotic_resistance",
                                                            "id": "textBox_353",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_134",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_36",
                                                    "id": "box_489",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            // {
                                            //     "fields": {
                                            //         "control": [
                                            //             {
                                            //                 "type": "button",
                                            //                 "id": "button_137",
                                            //                 "value": "View Location",
                                            //                 "buttonType": "storage_info",
                                            //                 "classname": "info-icon",
                                            //                 "divClass": "",
                                            //                 "controlEndPoints": [],
                                            //                 "disabled": false,
                                            //                 "validationEndPoints": [],
                                            //                 "invalid": false,
                                            //                 "name": "button_619",
                                            //                 "wrapperClassName": "",
                                            //                 "draggable": false
                                            //             }
                                            //         ],
                                            //         "name": "box_45",
                                            //         "id": "box_60",
                                            //         "wrapperType": "BOX",
                                            //         "fields": [],
                                            //         "type": "",
                                            //         "classname": "text-center",
                                            //         "addButton": {
                                            //             "classname": "",
                                            //             "value": ""
                                            //         },
                                            //         "removeButton": {
                                            //             "classname": "",
                                            //             "value": ""
                                            //         },
                                            //         "wrapperClassName": ""
                                            //     },
                                            //     "classname": ""
                                            // },
                                            {
                                                "fields": {
                                                    "control": [],
                                                    "name": "box_644",
                                                    "id": "box_477",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            }
                                        ]
                                    }
                                },
                                "addBtnPostion": null,
                                "hideSingleItemDelete": false
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "bacteria-phage",
                            "headerBtnClassName": "",
                            "header": "<span>Bacteria, Phage & Template</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "",
                            "collapseOthers": true
                        }
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_463",
                        "classname": "accordion accordion-flush accordion-custom",
                        "wrapperType": "BOX",
                        "id": "wrapper_903",
                        "fields": [
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_911",
                                "classname": "col-9",
                                "wrapperType": "BOX",
                                "id": "wrapper_501",
                                "fields": [
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "parent_clone",
                                        "classname": "table  project-ini-table",
                                        "wrapperType": "TABLE",
                                        "id": "wrapper_211",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false,
                                        "hideSingleItemDelete": false,
                                        "tableFields": {
                                            "showHead": true,
                                            "isVertical": false,
                                            "isIteratable": false,
                                            "addButton": {
                                                "classname": "btn btn-primary",
                                                "name": "",
                                                "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                            },
                                            "removeButton": {
                                                "classname": "btn btn-danger",
                                                "name": "",
                                                "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                            },
                                            "tableHead": {
                                                "heading": [
                                                    {
                                                        "value": "PROJECT ID",
                                                        "classname": ""
                                                    },
                                                    {
                                                        "value": "SCREENING ID",
                                                        "classname": ""
                                                    },
                                                    {
                                                        "value": "Parent clone ID",
                                                        "classname": ""
                                                    }
                                                ],
                                                "classname": ""
                                            },
                                            "initRecordCount": 1,
                                            "showRecordCount": false,
                                            "recordCounterConfig": {
                                                "classname": "",
                                                "adjValue": "",
                                                "isAdjValueAfter": false
                                            },
                                            "tableRow": {
                                                "name": "",
                                                "classname": "trclass",
                                                "tableCols": [
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "project_id",
                                                                    "__schema_id": "project_id",
                                                                    "id": "textBox_970",
                                                                    "value": "",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": false,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_617",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "head": "",
                                                            "name": "box_324",
                                                            "id": "box_418",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": ""
                                                        },
                                                        "classname": ""
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "screening_id",
                                                                    "__schema_id": "screening_id",
                                                                    "id": "textBox_368",
                                                                    "value": "",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": false,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_411",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "box_653",
                                                            "id": "box_185",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "",
                                                            "addButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "removeButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "wrapperClassName": ""
                                                        },
                                                        "classname": ""
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "parent_clone_id",
                                                                    "__schema_id": "parent_clone_id",
                                                                    "id": "textBox_223",
                                                                    "value": "",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": false,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_524",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "box_984",
                                                            "id": "box_20",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "",
                                                            "addButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "removeButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "wrapperClassName": ""
                                                        },
                                                        "classname": ""
                                                    }
                                                ]
                                            }
                                        }
                                    }
                                ],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false,
                                "isAccordion": true,
                                "accordionConfig": {
                                    "headerClassName": "",
                                    "headerBtnClassName": "",
                                    "header": "",
                                    "collapseDivClassName": "",
                                    "bodyClassName": "",
                                    "collapseOthers": true
                                }
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "",
                            "headerBtnClassName": "",
                            "header": "<span>Parent Clone</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "",
                            "collapseOthers": true
                        }
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_115",
                        "classname": "col-12 scrollable",
                        "wrapperType": "BOX",
                        "id": "wrapper_98",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "dropdown",
                                        "name": "no_of_template",
                                        "__schema_id": "no_of_template",
                                        "id": "dropDown_408",
                                        "validationButtonType": "",
                                        "classname": "",
                                        "required": false,
                                        "apiEndPoints": [],
                                        "options": [
                                            1,
                                            2,
                                            3,
                                            4,
                                            5,
                                            6,
                                            7,
                                            8,
                                            9,
                                            10,
                                            11,
                                            12,
                                            13,
                                            14,
                                            15,
                                            16,
                                            17,
                                            18,
                                            19,
                                            20
                                        ],
                                        "multiple": false,
                                        "isClearable": false,
                                        "isStatus": false,
                                        "search": true,
                                        "singleSelect": true,
                                        "showLabel": "",
                                        "errorMessage": {
                                            "noData": "This fields is required"
                                        },
                                        "value": "1",
                                        "imageUrl": "",
                                        "disabled": false,
                                        "wrapperClassName": "",
                                        "connectedArrays": [],
                                        "showBlankInPlaceholder": false,
                                        "blankDataList": [],
                                        "commonPathOfImage": "",
                                        "showInputLabel": true,
                                        "labelContent": "No: of Templates to be generated",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_624",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_720",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false
                            },
                            {
                                "control": [
                                    {
                                        "type": "label",
                                        "name": "Select CDRs",
                                        "classname": "sub-heading",
                                        "for": "",
                                        "required": false,
                                        "wrapperClassName": "",
                                        "id": "label_503",
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_549",
                                "classname": "col-12",
                                "wrapperType": "BOX",
                                "id": "wrapper_918",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false
                            },
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_841",
                                "classname": "project-table",
                                "wrapperType": "BOX",
                                "id": "wrapper_866",
                                "fields": [
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "template_cdr",
                                        "classname": "table ssdna-table",
                                        "wrapperType": "TABLE",
                                        "id": "wrapper_832",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false,
                                        "hideSingleItemDelete": false,
                                        "tableFields": {
                                            "showHead": true,
                                            "isVertical": false,
                                            "isIteratable": false,
                                            "addButton": {
                                                "classname": "btn btn-primary",
                                                "name": "",
                                                "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                            },
                                            "removeButton": {
                                                "classname": "btn btn-danger",
                                                "name": "",
                                                "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                            },
                                            "tableHead": {
                                                "heading": [
                                                    {
                                                        "value": "Template ssdna ID",
                                                        "classname": ""
                                                    },
                                                    {
                                                        "value": "STOP CODONS",
                                                        "classname": ""
                                                    },
                                                    {
                                                        "value": "Template SSDNA NAME",
                                                        "classname": ""
                                                    },
                                                    {
                                                        "value": "Action",
                                                        "classname": ""
                                                    }
                                                ],
                                                "classname": ""
                                            },
                                            "initRecordCount": 1,
                                            "showRecordCount": false,
                                            "recordCounterConfig": {
                                                "classname": "",
                                                "adjValue": "",
                                                "isAdjValueAfter": false
                                            },
                                            "tableRow": {
                                                "name": "",
                                                "classname": "trclass",
                                                "tableCols": [
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "templatessDna",
                                                                    "__schema_id": "templatessDna",
                                                                    "id": "textBox_229",
                                                                    "value": "",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": true,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_546",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "head": "",
                                                            "name": "box_423",
                                                            "id": "box_64",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": ""
                                                        },
                                                        "classname": ""
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "dropdown",
                                                                    "name": "stop_codons",
                                                                    "__schema_id": "stop_codons",
                                                                    "id": "dropDown_103",
                                                                    "validationButtonType": "",
                                                                    "classname": "",
                                                                    "required": false,
                                                                    "apiEndPoints": [],
                                                                    "options": [
                                                                        "L1",
                                                                        "L2",
                                                                        "L3",
                                                                        "H1",
                                                                        "H2",
                                                                        "H3"
                                                                    ],
                                                                    "multiple": true,
                                                                    "isClearable": true,
                                                                    "isStatus": false,
                                                                    "search": true,
                                                                    "singleSelect": false,
                                                                    "showLabel": "",
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required"
                                                                    },
                                                                    "value": "",
                                                                    "imageUrl": "",
                                                                    "disabled": false,
                                                                    "wrapperClassName": "",
                                                                    "connectedArrays": [],
                                                                    "showBlankInPlaceholder": false,
                                                                    "blankDataList": [],
                                                                    "commonPathOfImage": "",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "dropdown_958",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "box_422",
                                                            "id": "box_476",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "",
                                                            "addButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "removeButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "wrapperClassName": ""
                                                        },
                                                        "classname": ""
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "ssDnaName",
                                                                    "__schema_id": "ssDnaName",
                                                                    "id": "textBox_498",
                                                                    "value": "",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": false,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_978",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                },
                                                                {
                                                                    "type": "text",
                                                                    "name": "seq_file_id",
                                                                    "__schema_id": "seq_file_id",
                                                                    "id": "textBox_498",
                                                                    "value": "",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control d-none",
                                                                    "regularExpression": "",
                                                                    "disabled": false,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_978",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "box_148",
                                                            "id": "box_270",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "",
                                                            "addButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "removeButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "wrapperClassName": ""
                                                        },
                                                        "classname": ""
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "button",
                                                                    "id": "button_12",
                                                                    "value": "<div class=\"custom-tooltip\"><p>Upload Sequence</p></div>",
                                                                    "buttonType": "template_dna_fileupload",
                                                                    "classname": "upload-blue me-3",
                                                                    "divClass": "",
                                                                    "controlEndPoints": [],
                                                                    "disabled": false,
                                                                    "validationEndPoints": [],
                                                                    "invalid": false,
                                                                    "name": "button_45",
                                                                    "wrapperClassName": "",
                                                                    "draggable": false
                                                                },
                                                                {
                                                                    "type": "button",
                                                                    "id": "button_737",
                                                                    "value": "<div class=\"custom-tooltip\"><p>View </p></div>",
                                                                    "buttonType": "template_dna_file_view",
                                                                    "classname": "plasmid-view",
                                                                    "divClass": "",
                                                                    "controlEndPoints": [],
                                                                    "disabled": true,
                                                                    "validationEndPoints": [],
                                                                    "invalid": false,
                                                                    "name": "button_130",
                                                                    "wrapperClassName": "",
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "box_177",
                                                            "id": "box_299",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "action-wrap",
                                                            "addButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "removeButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "wrapperClassName": ""
                                                        },
                                                        "classname": ""
                                                    }
                                                ]
                                            }
                                        }
                                    }
                                ],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "bacteria-phage",
                            "headerBtnClassName": "",
                            "header": "<span>Template ssDNA Sequence</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "",
                            "collapseOthers": true
                        }
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_620",
                        "classname": "col-12",
                        "wrapperType": "BOX",
                        "id": "wrapper_723",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "comment",
                                        "id": "textBox_620",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": true,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "comment",
                                        "showInputLabel": false,
                                        "labelContent": "text_125",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_864",
                                "classname": "col-12",
                                "wrapperType": "BOX",
                                "id": "wrapper_776",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "experiment-note",
                            "headerBtnClassName": "",
                            "header": "<span>Experiment Notes/Comments</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "",
                            "collapseOthers": true
                        }
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false,
                "addBtnPostion": null,
                "isAccordion": true,
                "accordionConfig": {
                    "headerClassName": "",
                    "headerBtnClassName": "",
                    "header": "",
                    "collapseDivClassName": "",
                    "bodyClassName": "",
                    "collapseOthers": true
                }
            }
        ],
        "type": "",
        "classname": "",
        "showClass": true,
        "payload_schema": {
            "bacteriaandphage": {
                "lib_template": "lib_template",
                "bacteria": [
                    {
                        "inv_category": "inv_category",
                        "inv_item": "inv_item",
                        "inv_item_code": "inv_item_id.$d{}",
                        "source": "bacteria_source.$d{}",
                        "antibiotic_resistance": "bacteria_antibiotic_resistance.$d{}"
                    }
                ],
                "phage": [
                    {
                        "phage": "phage_val",
                        "name": "phage_name",
                        "item_code": "phage_code.$d{}",
                        "source": "phage_source.$d{}",
                        "antibiotic_resistance": "phage_antibiotic_resistance.$d{}"
                    }
                ],
                "parent_clone": [
                    {
                        "project_id": "project_id",
                        "screening_id": "screening_id",
                        "parent_clone_id": "parent_clone_id"
                    }
                ],
                "templates": {
                    "no_of_template": "no_of_template",
                    "template_cdr": [
                        {
                            "templatessDna": "templatessDna",
                            "ssDnaName": "ssDnaName",
                            "seq_file_id": "seq_file_id",
                            "stop_codons": "stop_codons"
                        }
                    ]
                },
                "comment": "comment"
            }
        }
    }
]
export const FILLINPURIFIEDssDNASUMMARYJSON = [
    {
        "control": [],
        "head": "",
        "name": "main",
        "id": "main",
        "wrapperType": "REGULAR",
        "fields": [
            {
                "control": [
                    {
                        "type": "label",
                        "name": "<h2 class=\"add-heading heading-without-icon\"><span>Summary</span></h2>",
                        "classname": "w-100",
                        "for": "",
                        "required": false,
                        "wrapperClassName": "",
                        "id": "label_98",
                        "draggable": false
                    }
                ],
                "type": "",
                "name": "wrapper_847",
                "classname": "col-12",
                "wrapperType": "BOX",
                "id": "wrapper_850",
                "fields": [
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_650",
                        "classname": "row mb-3",
                        "wrapperType": "BOX",
                        "id": "wrapper_860",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "proj_type",
                                        "id": "proj_type",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": true,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "proj_type",
                                        "showInputLabel": true,
                                        "labelContent": "Project Type",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_440",
                                "classname": "col-4",
                                "wrapperType": "BOX",
                                "id": "wrapper_855",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            // {
                            //     "control": [
                            //         {
                            //             "type": "text",
                            //             "name": "id",
                            //             "id": "id",
                            //             "value": "",
                            //             "placeholder": "",
                            //             "multiline": false,
                            //             "classname": "form-control ",
                            //             "regularExpression": "",
                            //             "disabled": true,
                            //             "required": false,
                            //             "errorMessage": {
                            //                 "noData": "This fields is required",
                            //                 "regexFail": ""
                            //             },
                            //             "validationButtonType": "",
                            //             "rows": 0,
                            //             "columns": 0,
                            //             "onDemandError": false,
                            //             "customValidation": [],
                            //             "multiError": false,
                            //             "applicationError": "",
                            //             "wrapperClassName": "",
                            //             "requiredErrorMessage": "This fields is required",
                            //             "__schema_id": "id",
                            //             "showInputLabel": true,
                            //             "labelContent": "Project ID",
                            //             "labelClass": "form-label",
                            //             "isLabelAfter": false,
                            //             "showAdditionalInputContent": false,
                            //             "additionalInputContent": "",
                            //             "isAdditonalInputAfter": true,
                            //             "draggable": false
                            //         }
                            //     ],
                            //     "type": "",
                            //     "name": "wrapper_995",
                            //     "classname": "col-2 d-none",
                            //     "wrapperType": "BOX",
                            //     "id": "wrapper_596",
                            //     "fields": [],
                            //     "head": "",
                            //     "tabHeadClass": ""
                            // },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "title",
                                        "id": "title",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": true,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "title",
                                        "showInputLabel": true,
                                        "labelContent": "Project Title",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_457",
                                "classname": "col-4",
                                "wrapperType": "BOX",
                                "id": "wrapper_393",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_195",
                        "classname": "row mb-3",
                        "wrapperType": "BOX",
                        "id": "wrapper_646",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "objective",
                                        "id": "objective",
                                        "value": "Prepare CCC-dsDNA for Electroporation",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": true,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "objective",
                                        "showInputLabel": true,
                                        "labelContent": "Objective",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    },
                                    {
                                        "type": "text",
                                        "name": "proj_code",
                                        "id": "proj_code",
                                        "value": "SIM1001",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control d-none",
                                        "regularExpression": "",
                                        "disabled": true,
                                        "required": true,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "proj_code",
                                        "showInputLabel": false,
                                        "labelContent": "Objective",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_413",
                                "classname": "col-4",
                                "wrapperType": "BOX",
                                "id": "wrapper_270",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_834",
                                "classname": "col-8",
                                "wrapperType": "BOX",
                                "id": "wrapper_697",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    },
                    {
                        "control": [
                            {
                                "type": "text",
                                "name": "comments",
                                "id": "comments",
                                "value": "",
                                "placeholder": "Enter your comments if any",
                                "multiline": true,
                                "classname": "form-control ",
                                "regularExpression": "",
                                "disabled": false,
                                "required": false,
                                "errorMessage": {
                                    "noData": "This fields is required",
                                    "regexFail": ""
                                },
                                "validationButtonType": "",
                                "rows": 0,
                                "columns": 0,
                                "onDemandError": false,
                                "customValidation": [],
                                "multiError": false,
                                "applicationError": "",
                                "wrapperClassName": "",
                                "requiredErrorMessage": "This fields is required",
                                "__schema_id": "comments",
                                "showInputLabel": true,
                                "labelContent": "Experiment Notes/Comments",
                                "labelClass": "form-label",
                                "isLabelAfter": false,
                                "showAdditionalInputContent": false,
                                "additionalInputContent": "",
                                "isAdditonalInputAfter": true,
                                "draggable": false
                            }
                        ],
                        "type": "",
                        "name": "wrapper_269",
                        "classname": "col-12",
                        "wrapperType": "BOX",
                        "id": "wrapper_521",
                        "fields": [],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false
            }
        ],
        "type": "",
        "jsonId": 117,
        "classname": "",
        "showClass": true,
        "payload_schema": {
            "project_summary": {
                "title": "title",
                "proj_type": "proj_type",
                // "proj_id": "id",
                "objective": "objective",
                "comments": "comments"
            },
        },
        "validation_type": "submit",
    }
]
export const LabConsumabesTemplateDynamicJSON = [
    //lab
    {
        "jsonId": 116,
        "control": [],
        "head": "",
        "name": "main",
        "id": "main",
        "wrapperType": "REGULAR",
        "fields": [
            {
                "control": [],
                "type": "",
                "name": "wrapper_217",
                "classname": "project-table",
                "wrapperType": "BOX",
                "id": "wrapper_7",
                "fields": [
                    {
                        "control": [],
                        "type": "",
                        "name": "lab",
                        "classname": "table reagents-table-p2",
                        "wrapperType": "TABLE",
                        "id": "lab_consumables",
                        "fields": [],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "hideSingleItemDelete": false,
                        "tableFields": {
                            "showHead": true,
                            "isVertical": false,
                            "isIteratable": true,
                            "addButton": {
                                "classname": "add-expand",
                                "name": "",
                                "value": "<div class='custom-tooltip'><p>Add Row</p></div>"
                            },
                            "removeButton": {
                                "classname": "add-minus ",
                                "name": "",
                                "value": "<div class='custom-tooltip'><p>Remove Row </p></div>"
                            },
                            "tableHead": {
                                "heading": [
                                    {
                                        "value": "Select from  Inventory",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Name",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Vendor",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Capacity (μL)",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Units  available",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Units  taken",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Storage Location",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Action",
                                        "classname": ""
                                    },
                                    {
                                        "value": "",
                                        "classname": ""
                                    }
                                ],
                                "classname": ""
                            },
                            "initRecordCount": 1,
                            "showRecordCount": false,
                            "recordCounterConfig": {
                                "classname": "",
                                "adjValue": "",
                                "isAdjValueAfter": false
                            },
                            "tableRow": {
                                "name": "",
                                "classname": "trclass",
                                "tableCols": [
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "dropdown",
                                                    "name": "select_inventory",
                                                    "id": "select_inventory",
                                                    "validationButtonType": "",
                                                    "classname": "",
                                                    "required": false,
                                                    "apiEndPoints": [
                                                        {
                                                            "type": "change",
                                                            "method": "post",
                                                            "url": "inventory_item",
                                                            "Mapto": [
                                                                {
                                                                    "id": "main.wrapper_7.lab_consumables.${i}.box_90.inv_item",
                                                                    "urlParameters": "",
                                                                    "value": "",
                                                                    "payload": {
                                                                        "perma": "plasticwares",
                                                                        "user_input": ""
                                                                    },
                                                                    "type": null
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "type": "init",
                                                            "method": "post",
                                                            "url": "inventory_item/item-type",
                                                            "Mapto": [],
                                                            "payload": {
                                                                "perma": [
                                                                    "assay-kits",
                                                                    "cell-culture-media-antibiotics-agar-plates",
                                                                    "gels-buffers-lab-reagents",
                                                                    "plasticwares",
                                                                    "glasswares",
                                                                    "cell-lines",
                                                                    "bacterial-strains",
                                                                    "plasmids"
                                                                ]
                                                            }
                                                        }
                                                    ],
                                                    "options": [],
                                                    "multiple": false,
                                                    "isClearable": false,
                                                    "isStatus": false,
                                                    "search": true,
                                                    "singleSelect": true,
                                                    "showLabel": "inv_item_type",
                                                    "errorMessage": {
                                                        "noData": "This fields is required"
                                                    },
                                                    "value": "",
                                                    "imageUrl": "",
                                                    "disabled": false,
                                                    "wrapperClassName": "",
                                                    "connectedArrays": [],
                                                    "showBlankInPlaceholder": false,
                                                    "blankDataList": [],
                                                    "commonPathOfImage": "",
                                                    "__schema_id": "select_inventory",
                                                    "showInputLabel": false,
                                                    "labelContent": "dropdown_448",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "head": "",
                                            "name": "box_893",
                                            "id": "box_760",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": ""
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "dropdown",
                                                    "name": "inv_item",
                                                    "id": "inv_item",
                                                    "validationButtonType": "",
                                                    "classname": "",
                                                    "required": false,
                                                    "apiEndPoints": [
                                                        {
                                                            "type": "change",
                                                            "method": "get",
                                                            "url": "inventory_item/${id}",
                                                            "Mapto": [
                                                                {
                                                                    "id": "main.wrapper_7.lab_consumables.${i}.box_584.vendor",
                                                                    "displayKey": "change",
                                                                    "urlParameterValues": {
                                                                        "id": null,
                                                                        "patchProperty": "vendor_name"
                                                                    }
                                                                },
                                                                {
                                                                    "id": "main.wrapper_7.lab_consumables.${i}.box_908.capacity",
                                                                    "displayKey": "change",
                                                                    "urlParameterValues": {
                                                                        "id": null,
                                                                        "patchProperty": "item_volume"
                                                                    }
                                                                },
                                                                {
                                                                    "id": "main.wrapper_7.lab_consumables.${i}.box_293.units_available",
                                                                    "displayKey": "change",
                                                                    "urlParameterValues": {
                                                                        "id": null,
                                                                        "patchProperty": "item_quantity"
                                                                    }
                                                                },
                                                                {
                                                                    "id": "main.wrapper_7.lab_consumables.${i}.box_126.item_details",
                                                                    "displayKey": "item_details",
                                                                    "urlParameterValues": {
                                                                        "id": null
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ],
                                                    "options": [],
                                                    "multiple": false,
                                                    "isClearable": false,
                                                    "isStatus": false,
                                                    "search": true,
                                                    "singleSelect": true,
                                                    "showLabel": "value",
                                                    "errorMessage": {
                                                        "noData": "This fields is required"
                                                    },
                                                    "value": "",
                                                    "imageUrl": "",
                                                    "disabled": false,
                                                    "wrapperClassName": "",
                                                    "connectedArrays": [],
                                                    "showBlankInPlaceholder": false,
                                                    "blankDataList": [],
                                                    "commonPathOfImage": "",
                                                    "__schema_id": "inv_item",
                                                    "showInputLabel": false,
                                                    "labelContent": "dropdown_532",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_289",
                                            "id": "box_90",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": "",
                                            "draggable": false
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "vendor",
                                                    "id": "vendor",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": true,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": false,
                                                    "customValidation": [],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "vendor",
                                                    "showInputLabel": false,
                                                    "labelContent": "text_305",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_924",
                                            "id": "box_584",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": ""
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "capacity",
                                                    "id": "capacity",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": true,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": true,
                                                    "customValidation": [
                                                        {
                                                            "regularExpression": "/^\\d+$/",
                                                            "errorMessage": "Please enter a valid number"
                                                        }
                                                    ],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "capacity",
                                                    "showInputLabel": false,
                                                    "labelContent": "text_166",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_12",
                                            "id": "box_908",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": ""
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "units_available",
                                                    "id": "units_available",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": true,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": true,
                                                    "customValidation": [
                                                        {
                                                            "regularExpression": "/^\\d+$/",
                                                            "errorMessage": "Please enter a valid number"
                                                        }
                                                    ],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "units_available",
                                                    "showInputLabel": false,
                                                    "labelContent": "text_159",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_213",
                                            "id": "box_293",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": ""
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "units_taken",
                                                    "id": "units_taken",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": false,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": true,
                                                    "customValidation": [
                                                        {
                                                            "regularExpression": "/^\\d+$/",
                                                            "errorMessage": "Please enter a valid number"
                                                        }
                                                    ],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "units_taken",
                                                    "showInputLabel": false,
                                                    "labelContent": "text_885",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_214",
                                            "id": "box_798",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "action-wrap ",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": ""
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "button",
                                                    "id": "infoicon",
                                                    "value": "View Location",
                                                    "buttonType": "storage_info",
                                                    "classname": "info-icon",
                                                    "divClass": "",
                                                    "controlEndPoints": [],
                                                    "disabled": false,
                                                    "validationEndPoints": [],
                                                    "invalid": false,
                                                    "name": "infoicon",
                                                    "wrapperClassName": "text-nowrap",
                                                    "draggable": false
                                                },
                                                {
                                                    "type": "text",
                                                    "name": "item_details",
                                                    "id": "item_details",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control d-none",
                                                    "regularExpression": "",
                                                    "disabled": false,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": false,
                                                    "customValidation": [],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "item_details",
                                                    "showInputLabel": false,
                                                    "labelContent": "text_995",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_261",
                                            "id": "box_126",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": " ",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": ""
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [],
                                            "name": "box_564",
                                            "id": "box_24",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": ""
                                        },
                                        "classname": ""
                                    }
                                ]
                            }
                        }
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false
            }
        ],
        "type": "",
        "classname": "",
        "showClass": true,
        "payload_schema": {
            "consumables": [
                {
                    "inv_category": "select_inventory",
                    "inv_item": "inv_item",
                    "vendor": "vendor.$d{}",
                    "vol_capacity": "capacity.$t{number}",
                    "no_unit_available": "units_available.$t{number}",
                    "no_unit_taken": "units_taken.$d{}",
                    "storage": "item_details.{{assigned_storage}}"
                }
            ],
            "inventory__": {
                "itm_qty": [
                    {
                        "inv_item_id": "inv_item.{{id}}",
                        "qntity_taken": "units_taken.$d{}"
                    }
                ]
            }
        }
    }
]
export const PURIFIEDssDNAWORKFLOWJSON = [
    {
        "control": [],
        "head": "",
        "name": "main",
        "id": "main",
        "jsonId": 119,
        "wrapperType": "REGULAR",
        "fields": [
            {
                "control": [],
                "type": "",
                "name": "wrapper_524",
                "classname": "col-12",
                "wrapperType": "BOX",
                "id": "wrapper_298",
                "fields": [
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_665",
                        "classname": "row mb-3",
                        "wrapperType": "BOX",
                        "id": "wrapper_463",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "file",
                                        "name": "gel_image_file",
                                        "id": "gel_image_file",
                                        "value": "upload",
                                        "classname": "btn btn-file",
                                        "fileNameShow": true,
                                        "size": 10,
                                        "url": "",
                                        "validationButtonType": "submit",
                                        "wrapperClassName": "",
                                        "fileCount": 5,
                                        "sizeKB": 3000,
                                        "allowMultiple": true,
                                        "formDataType": "blob",
                                        "fileTypes": [
                                            "jpeg", "png", "jpg"
                                        ],
                                        "listClass": "",
                                        "mainClass": "",
                                        "btnDivClass": "",
                                        "outsideFileContainer": true,
                                        "errorMessage": {
                                            "invalidFormat": "Invalid File Format",
                                            "invalidSize": "File Size exceeded",
                                            "classname": ""
                                        },
                                        "__schema_id": "gel_image_file",
                                        "showInputLabel": true,
                                        "labelContent": "Upload Gel Image",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false,
                                        "showDownloadIcon":false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_610",
                                "classname": "col-6",
                                "wrapperType": "BOX",
                                "id": "wrapper_69",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "file",
                                        "name": "absorbance_data_file",
                                        "id": "absorbance_data_file",
                                        "value": "upload",
                                        "classname": "btn btn-file",
                                        "fileNameShow": true,
                                        "size": 10,
                                        "url": "",
                                        "validationButtonType": "submit",
                                        "wrapperClassName": "",
                                        "fileCount": 5,
                                        "sizeKB": 3000,
                                        "allowMultiple": true,
                                        "formDataType": "blob",
                                        "fileTypes": [
                                            "all"
                                        ],
                                        "listClass": "",
                                        "mainClass": "",
                                        "btnDivClass": "",
                                        "outsideFileContainer": true,
                                        "errorMessage": {
                                            "invalidFormat": "Invalid File Format",
                                            "invalidSize": "File Size exceeded",
                                            "classname": ""
                                        },
                                        "__schema_id": "absorbance_data_file",
                                        "showInputLabel": true,
                                        "labelContent": "Upload Absorbance Data",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false,
                                        "showDownloadIcon":false
                                    },
                                    {
                                        "type": "text",
                                        "name": "file_ids",
                                        "id": "file_ids",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control d-none",
                                        "regularExpression": "",
                                        "disabled": true,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "file_ids",
                                        "showInputLabel": false,
                                        "labelContent": "",
                                        "labelClass": "form-label ",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_939",
                                "classname": "col-6",
                                "wrapperType": "BOX",
                                "id": "wrapper_945",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_215",
                        "classname": "row mb-3",
                        "wrapperType": "BOX",
                        "id": "wrapper_598",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "dropdown",
                                        "name": "protocol",
                                        "id": "protocol",
                                        "validationButtonType": "submit",
                                        "classname": "",
                                        "required": true,
                                        "apiEndPoints": [
                                            {
                                                "type": "init",
                                                "method": "get",
                                                "url": "protocol/protocol-list"
                                            },
                                            {
                                                "type": "change",
                                                "method": "get",
                                                "Mapto": [
                                                    {
                                                        "id": "main.wrapper_298.wrapper_598.wrapper_921.protocol_file_id",
                                                        "displayKey": "change",
                                                        "urlParameterValues": {
                                                            "id": null,
                                                            "patchProperty": "protocol_file_id"
                                                        }
                                                    }
                                                ],
                                                "url": "protocol/${id}"
                                            }
                                        ],
                                        "options": [],
                                        "multiple": false,
                                        "isClearable": false,
                                        "isStatus": false,
                                        "search": true,
                                        "singleSelect": true,
                                        "showLabel": "value",
                                        "errorMessage": {
                                            "noData": "This fields is required"
                                        },
                                        "value": "",
                                        "imageUrl": "",
                                        "disabled": false,
                                        "wrapperClassName": "",
                                        "connectedArrays": [],
                                        "showBlankInPlaceholder": false,
                                        "blankDataList": [],
                                        "commonPathOfImage": "",
                                        "__schema_id": "protocol",
                                        "showInputLabel": true,
                                        "labelContent": "Select Protocol",
                                        "labelClass": "form-label mandatory",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    },
                                    {
                                        "type": "button",
                                        "id": "button_324",
                                        "value": "<div class=\"custom-tooltip\"> <p>View</p> </div>",
                                        "buttonType": "view-protocol",
                                        "classname": "plasmid-view icon-align ms-3",
                                        "divClass": "",
                                        "controlEndPoints": [],
                                        "disabled": false,
                                        "validationEndPoints": [],
                                        "invalid": false,
                                        "name": "button_168",
                                        "wrapperClassName": "",
                                        "draggable": false
                                    },
                                    {
                                        "type": "text",
                                        "name": "protocol_file_id",
                                        "id": "protocol_file_id",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control d-none",
                                        "regularExpression": "",
                                        "disabled": true,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "protocol_file_id",
                                        "showInputLabel": false,
                                        "labelContent": "Bacteria Strain",
                                        "labelClass": "form-label mandatory ",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_588",
                                "classname": "col-3 action-wrap ",
                                "wrapperType": "BOX",
                                "id": "wrapper_921",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_416",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_518",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_736",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_931",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_213",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_559",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false
            },
            {
                "control": [],
                "type": "",
                "name": "",
                "classname": "accordion accordion-custom",
                "wrapperType": "ACCORDION",
                "id": "wrapper_235",
                "fields": [
                    {
                        "control": [],
                        "type": "",
                        "name": "",
                        "classname": "row",
                        "wrapperType": "BOX",
                        "id": "wrapper_370",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "dropdown",
                                        "name": "bacterial_strain",
                                        "id": "bacterial_strain",
                                        "validationButtonType": "submit",
                                        "classname": "",
                                        "required": true,
                                        "apiEndPoints": [

                                        ],
                                        "options": [],
                                        "multiple": false,
                                        "isClearable": false,
                                        "isStatus": false,
                                        "search": true,
                                        "singleSelect": true,
                                        "showLabel": "value",
                                        "errorMessage": {
                                            "noData": "This fields is required"
                                        },
                                        "value": "",
                                        "imageUrl": "",
                                        "disabled": false,
                                        "wrapperClassName": "",
                                        "connectedArrays": [],
                                        "showBlankInPlaceholder": false,
                                        "blankDataList": [],
                                        "commonPathOfImage": "",
                                        "__schema_id": "bacterial_strain",
                                        "showInputLabel": true,
                                        "labelContent": "Bacteria Strain",
                                        "labelClass": "form-label mandatory ",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_714",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_118",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "helper_phage",
                                        "id": "helper_phage",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": true,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "submit",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "helper_phage",
                                        "showInputLabel": true,
                                        "labelContent": "Helper Phage",
                                        "labelClass": "form-label mandatory",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_61",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_14",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "dropdown",
                                        "name": "inv_cell_culture",
                                        "id": "inv_cell_culture",
                                        "validationButtonType": "submit",
                                        "classname": "",
                                        "required": true,
                                        "apiEndPoints": [
                                            {
                                                "type": "init",
                                                "method": "post",
                                                "url": "inventory_item",
                                                "payload": {
                                                    "user_input": "",
                                                    "perma": "cell-culture-media"
                                                }
                                            }
                                        ],
                                        "options": [],
                                        "multiple": false,
                                        "isClearable": false,
                                        "isStatus": false,
                                        "search": true,
                                        "singleSelect": true,
                                        "showLabel": "value",
                                        "errorMessage": {
                                            "noData": "This fields is required"
                                        },
                                        "value": "",
                                        "imageUrl": "",
                                        "disabled": false,
                                        "wrapperClassName": "",
                                        "connectedArrays": [],
                                        "showBlankInPlaceholder": false,
                                        "blankDataList": [],
                                        "commonPathOfImage": "",
                                        "__schema_id": "inv_cell_culture",
                                        "showInputLabel": true,
                                        "labelContent": "Culture Media Used",
                                        "labelClass": "form-label mandatory ",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_798",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_737",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "dropdown",
                                        "name": "media_phage_antibiotics",
                                        "id": "media_phage_antibiotics",
                                        "validationButtonType": "submit",
                                        "classname": "",
                                        "required": true,
                                        "apiEndPoints": [],
                                        "options": [
                                            {
                                                "id": 1,
                                                "value": "Carbenicillin"
                                            },
                                            {
                                                "id": 2,
                                                "value": "Chloramphenicol"
                                            },
                                            {
                                                "id": 3,
                                                "value": "Kanamycin"
                                            },
                                            {
                                                "id": 4,
                                                "value": "Ampicillin"
                                            }
                                        ],
                                        "multiple": true,
                                        "isClearable": true,
                                        "isStatus": false,
                                        "search": true,
                                        "singleSelect": false,
                                        "showLabel": "value",
                                        "errorMessage": {
                                            "noData": "This fields is required"
                                        },
                                        "value": [
                                            {
                                                "id": 1,
                                                "value": "Carbenicillin"
                                            },
                                            {
                                                "id": 2,
                                                "value": "Chloramphenicol"
                                            },
                                            {
                                                "id": 3,
                                                "value": "Kanamycin"
                                            }
                                        ],
                                        "imageUrl": "",
                                        "disabled": false,
                                        "wrapperClassName": "",
                                        "connectedArrays": [],
                                        "showBlankInPlaceholder": false,
                                        "blankDataList": [],
                                        "commonPathOfImage": "",
                                        "__schema_id": "media_phage_antibiotics",
                                        "showInputLabel": true,
                                        "labelContent": "Media/Phage Antibiotics",
                                        "labelClass": "form-label mandatory ",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_558",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_794",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_539",
                                "classname": "col-12",
                                "wrapperType": "BOX",
                                "id": "wrapper_848",
                                "fields": [
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_212",
                                        "classname": "row mb-3 mt-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_512",
                                        "fields": [
                                            {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "cp_rpm",
                                                        "id": "cp_rpm",
                                                        "value": "200",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": true,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "submit",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [
                                                            {
                                                                "errorMessage": "Please type a number",
                                                                "regularExpression": "/^\\d+(\\.\\d+)?$/"
                                                            }
                                                        ],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "cp_rpm",
                                                        "showInputLabel": true,
                                                        "labelContent": "RPM",
                                                        "labelClass": "form-label mandatory ",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "type": "",
                                                "name": "wrapper_632",
                                                "classname": "col-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_96",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            },
                                            {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "cp_inc_time",
                                                        "id": "cp_inc_time",
                                                        "value": "20",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": true,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "submit",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [
                                                            {
                                                                "errorMessage": "Please type a number",
                                                                "regularExpression": "/^\\d+(\\.\\d+)?$/"
                                                            }
                                                        ],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "cp_inc_time",
                                                        "showInputLabel": true,
                                                        "labelContent": "Incubation Time (h)",
                                                        "labelClass": "form-label mandatory ",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "type": "",
                                                "name": "wrapper_719",
                                                "classname": "col-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_596",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            },
                                            {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "cp_inc_temp",
                                                        "id": "cp_inc_temp",
                                                        "value": "37",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "submit",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [
                                                            {
                                                                "errorMessage": "Please type a number",
                                                                "regularExpression": "/^\\d+(\\.\\d+)?$/"
                                                            }
                                                        ],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "cp_inc_temp",
                                                        "showInputLabel": true,
                                                        "labelContent": "Incubation Temperature (°C)",
                                                        "labelClass": "form-label mandatory",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "type": "",
                                                "name": "wrapper_251",
                                                "classname": "col-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_267",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            },
                                            {
                                                "control": [],
                                                "type": "",
                                                "name": "wrapper_9",
                                                "classname": "col-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_753",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            }
                                        ],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    }
                                ],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "",
                            "headerBtnClassName": "",
                            "header": "<span>Started Culture Preparation</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "ms-3 me-3",
                            "collapseOthers": true
                        }
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_887",
                        "classname": "row",
                        "wrapperType": "BOX",
                        "id": "wrapper_355",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "snt_vol",
                                        "id": "snt_vol",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": true,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "submit",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [
                                            {
                                                "errorMessage": "Please type a number",
                                                "regularExpression": "/^\\d+(\\.\\d+)?$/"
                                            }
                                        ],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "snt_vol",
                                        "showInputLabel": true,
                                        "labelContent": "Supernatant Vol (ml)",
                                        "labelClass": "form-label mandatory ",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_714",
                                "classname": "col-3 mb-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_79",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "peg_nacl",
                                        "id": "peg_nacl",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": true,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "submit",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [
                                            {
                                                "errorMessage": "Please type a number",
                                                "regularExpression": "/^\\d+(\\.\\d+)?$/"
                                            }
                                        ],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "peg_nacl",
                                        "showInputLabel": true,
                                        "labelContent": "PEG/Nacl (ml)",
                                        "labelClass": "form-label mandatory ",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_452",
                                "classname": "col-3 mb-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_96",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "percipitation",
                                        "id": "percipitation",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": true,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "submit",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [
                                            {
                                                "errorMessage": "Please type a number",
                                                "regularExpression": "/^\\d+(\\.\\d+)?$/"
                                            }
                                        ],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "percipitation",
                                        "showInputLabel": true,
                                        "labelContent": "Precipitation Time (h)",
                                        "labelClass": "form-label mandatory ",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_325",
                                "classname": "col-3 mb-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_581",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "pbs_vol",
                                        "id": "pbs_vol",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": true,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "submit",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [
                                            {
                                                "errorMessage": "Please type a number",
                                                "regularExpression": "/^\\d+(\\.\\d+)?$/"
                                            }
                                        ],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "pbs_vol",
                                        "showInputLabel": true,
                                        "labelContent": "PBS Vol (ml)",
                                        "labelClass": "form-label mandatory ",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_503",
                                "classname": "col-3 mb-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_787",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_57",
                                "classname": "col-12",
                                "wrapperType": "BOX",
                                "id": "wrapper_461",
                                "fields": [
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_786",
                                        "classname": "row",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_20",
                                        "fields": [
                                            {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "centrifuge_time",
                                                        "id": "centrifuge_time",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": true,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "submit",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [
                                                            {
                                                                "errorMessage": "Please type a number",
                                                                "regularExpression": "/^\\d+(\\.\\d+)?$/"
                                                            }
                                                        ],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "centrifuge_time",
                                                        "showInputLabel": true,
                                                        "labelContent": "Centrifuge Time (h)",
                                                        "labelClass": "form-label mandatory ",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "type": "",
                                                "name": "wrapper_543",
                                                "classname": "col-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_930",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            },
                                            {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "cpe_rmp",
                                                        "id": "cpe_rmp",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": true,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "submit",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [
                                                            {
                                                                "errorMessage": "Please type a number",
                                                                "regularExpression": "/^\\d+(\\.\\d+)?$/"
                                                            }
                                                        ],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "cpe_rmp",
                                                        "showInputLabel": true,
                                                        "labelContent": "RPM",
                                                        "labelClass": "form-label mandatory ",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "type": "",
                                                "name": "wrapper_872",
                                                "classname": "col-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_954",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            },
                                            {
                                                "control": [],
                                                "type": "",
                                                "name": "wrapper_34",
                                                "classname": "col-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_279",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            },
                                            {
                                                "control": [],
                                                "type": "",
                                                "name": "wrapper_628",
                                                "classname": "col-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_251",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            }
                                        ],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    }
                                ],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "",
                            "headerBtnClassName": "",
                            "header": "<span>Centrifuge And Phage Extraction</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "ms-3 me-3",
                            "collapseOthers": true
                        }
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_119",
                        "classname": "project-table",
                        "wrapperType": "BOX",
                        "id": "wrapper_441",
                        "fields": [
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_64",
                                "classname": "row mb-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_220",
                                "fields": [
                                    {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "purification_kit",
                                                "id": "purification_kit",
                                                "validationButtonType": "submit",
                                                "classname": "",
                                                "required": true,
                                                "apiEndPoints": [
                                                    {
                                                        "type": "init",
                                                        "method": "post",
                                                        "url": "inventory_item",
                                                        "Mapto": [],
                                                        "payload": {
                                                            "perma": "assay-kits",
                                                            "user_input": ""
                                                        }
                                                    },
                                                ],
                                                "options": [],
                                                "multiple": false,
                                                "isClearable": false,
                                                "isStatus": false,
                                                "search": true,
                                                "singleSelect": true,
                                                "showLabel": "value",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": "",
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "purification_kit",
                                                "showInputLabel": true,
                                                "labelContent": "Purification Kit",
                                                "labelClass": "form-label mandatory ",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_611",
                                        "classname": "col-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_50",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "absorbance",
                                                "id": "absorbance",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": true,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "submit",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "absorbance",
                                                "showInputLabel": true,
                                                "labelContent": "Absorbance (nm)",
                                                "labelClass": "form-label mandatory ",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_595",
                                        "classname": "col-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_196",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_40",
                                        "classname": "col-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_291",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_828",
                                        "classname": "col-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_19",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    }
                                ],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false
                            },
                            {
                                "control": [],
                                "type": "",
                                "name": "pdep_template_info",
                                "classname": "table ",
                                "wrapperType": "TABLE",
                                "id": "wrapper_978",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false,
                                "hideSingleItemDelete": false,
                                "tableFields": {
                                    "showHead": true,
                                    "isVertical": false,
                                    "isIteratable": false,
                                    "addButton": {
                                        "classname": "btn btn-primary",
                                        "name": "",
                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                    },
                                    "removeButton": {
                                        "classname": "btn btn-danger",
                                        "name": "",
                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                    },
                                    "tableHead": {
                                        "heading": [
                                            {
                                                "value": "Template SSDNA",
                                                "classname": "mandatory"
                                            },
                                            {
                                                "value": "Eluent Vol <span class='text-initial'>(μl)</span>",
                                                "classname": "mandatory"
                                            },
                                            {
                                                "value": "SsDNA Conc <span class='text-initial'>(ng/μL)</span>",
                                                "classname": "mandatory"
                                            },
                                            {
                                                "value": "Total Yield <span class='text-initial'>(ng)</span>",
                                                "classname": ""
                                            }
                                        ],
                                        "classname": ""
                                    },
                                    "initRecordCount": 1,
                                    "showRecordCount": false,
                                    "recordCounterConfig": {
                                        "classname": "",
                                        "adjValue": "",
                                        "isAdjValueAfter": false
                                    },
                                    "tableRow": {
                                        "name": "",
                                        "classname": "trclass",
                                        "tableCols": [
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "lib_template",
                                                            "id": "lib_template",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": true,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "submit",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "lib_template",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_990",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "head": "",
                                                    "name": "box_883",
                                                    "id": "box_899",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "elutent_vol",
                                                            "id": "elutent_vol",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": true,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "submit",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [
                                                                {
                                                                    "errorMessage": "Please type a number",
                                                                    "regularExpression": "/^\\d+(\\.\\d+)?$/"
                                                                }
                                                            ],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "elutent_vol",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_753",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false,
                                                            "callBackEvents": ['input'
                                                            ]
                                                        }
                                                    ],
                                                    "name": "box_943",
                                                    "id": "box_761",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "ssdna_conc",
                                                            "id": "ssdna_conc",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": true,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "submit",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [
                                                                {
                                                                    "errorMessage": "Please type a number",
                                                                    "regularExpression": "/^\\d+(\\.\\d+)?$/"
                                                                }
                                                            ],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "ssdna_conc",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_920",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false,
                                                            "callBackEvents": ['input'
                                                            ]
                                                        }
                                                    ],
                                                    "name": "box_356",
                                                    "id": "box_752",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "total_yield",
                                                            "id": "total_yield",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": true,
                                                            "required": true,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "submit",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [
                                                                {
                                                                    "errorMessage": "Please type a number",
                                                                    "regularExpression": "/^\\d+(\\.\\d+)?$/"
                                                                }
                                                            ],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "total_yield",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_50",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_285",
                                                    "id": "box_582",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            }
                                        ]
                                    }
                                }
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "",
                            "headerBtnClassName": "",
                            "header": "<span>Phage DNA Extraction and Purification</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "",
                            "collapseOthers": true
                        }
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_727",
                        "classname": "col-12 scrollable",
                        "wrapperType": "BOX",
                        "id": "wrapper_471",
                        "fields": [
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_210",
                                "classname": "row",
                                "wrapperType": "BOX",
                                "id": "wrapper_357",
                                "fields": [
                                    {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "dna_qc_analysis_using",
                                                "id": "dna_qc_analysis_using",
                                                "validationButtonType": "submit",
                                                "classname": "",
                                                "required": true,
                                                "apiEndPoints": [
                                                    {
                                                        "type": "init",
                                                        "method": "post",
                                                        "url": "inventory_item",
                                                        "Mapto": [],
                                                        "payload": {
                                                            "perma": "instruments",
                                                            "user_input": ""
                                                        }
                                                    },
                                                ],
                                                "options": [],
                                                "multiple": false,
                                                "isClearable": false,
                                                "isStatus": false,
                                                "search": true,
                                                "singleSelect": true,
                                                "showLabel": "value",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": "",
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "dna_qc_analysis_using",
                                                "showInputLabel": true,
                                                "labelContent": "Analysis using",
                                                "labelClass": "form-label mandatory",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            },
                                        ],
                                        "type": "",
                                        "name": "wrapper_435",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_351",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "dna_qc_voltage",
                                                "id": "dna_qc_voltage",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": true,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "submit",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "dna_qc_voltage",
                                                "showInputLabel": true,
                                                "labelContent": "Voltage (V)",
                                                "labelClass": "form-label mandatory ",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_6",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_276",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "dna_qc_run_time",
                                                "id": "dna_qc_run_time",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": true,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "submit",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "dna_qc_run_time",
                                                "showInputLabel": true,
                                                "labelContent": "Run Time (h)",
                                                "labelClass": "form-label mandatory ",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_356",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_847",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_245",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_650",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "dna_qc_template_info",
                                        "classname": "table  ",
                                        "wrapperType": "TABLE",
                                        "id": "wrapper_458",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false,
                                        "addBtnPostion": null,
                                        "hideSingleItemDelete": false,
                                        "tableFields": {
                                            "showHead": true,
                                            "isVertical": false,
                                            "isIteratable": false,
                                            "addButton": {
                                                "classname": "btn btn-primary",
                                                "name": "",
                                                "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                            },
                                            "removeButton": {
                                                "classname": "btn btn-danger",
                                                "name": "",
                                                "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                            },
                                            "tableHead": {
                                                "heading": [
                                                    {
                                                        "value": "Template ssDNA",
                                                        "classname": "col-3 mandatory"
                                                    },
                                                    {
                                                        "value": "QC Status",
                                                        "classname": "col-3 mandatory"
                                                    },
                                                    {
                                                        "value": "Bands Formed",
                                                        "classname": "mandatory"
                                                    }
                                                ],
                                                "classname": ""
                                            },
                                            "initRecordCount": 1,
                                            "showRecordCount": false,
                                            "recordCounterConfig": {
                                                "classname": "",
                                                "adjValue": "",
                                                "isAdjValueAfter": false
                                            },
                                            "tableRow": {
                                                "name": "",
                                                "classname": "trclass",
                                                "tableCols": [
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "dna_qc_lib_template",
                                                                    "id": "dna_qc_lib_template",
                                                                    "value": "",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": false,
                                                                    "required": true,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "submit",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "__schema_id": "dna_qc_lib_template",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_503",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "head": "",
                                                            "name": "box_245",
                                                            "id": "box_681",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": ""
                                                        },
                                                        "classname": ""
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "dropdown",
                                                                    "name": "qc_status",
                                                                    "id": "qc_status",
                                                                    "validationButtonType": "submit",
                                                                    "classname": "",
                                                                    "required": true,
                                                                    "apiEndPoints": [],
                                                                    "options": [
                                                                        {
                                                                            "id": 1,
                                                                            "value": "Pass"
                                                                        },
                                                                        {
                                                                            "id": 2,
                                                                            "value": "Fail"
                                                                        }
                                                                    ],
                                                                    "multiple": false,
                                                                    "isClearable": false,
                                                                    "isStatus": false,
                                                                    "search": true,
                                                                    "singleSelect": true,
                                                                    "showLabel": "value",
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required"
                                                                    },
                                                                    "value": "",
                                                                    "imageUrl": "",
                                                                    "disabled": false,
                                                                    "wrapperClassName": "",
                                                                    "connectedArrays": [],
                                                                    "showBlankInPlaceholder": false,
                                                                    "blankDataList": [],
                                                                    "commonPathOfImage": "",
                                                                    "__schema_id": "qc_status",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "dropdown_675",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "box_341",
                                                            "id": "box_502",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "",
                                                            "addButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "removeButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "wrapperClassName": ""
                                                        },
                                                        "classname": ""
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "dropdown",
                                                                    "name": "bands_formed",
                                                                    "id": "bands_formed",
                                                                    "validationButtonType": "submit",
                                                                    "classname": "",
                                                                    "required": true,
                                                                    "apiEndPoints": [],
                                                                    "options": [
                                                                        {
                                                                            "id": 1,
                                                                            "value": "Predominant single band, but faintbands, lower electrophoretic mobility"
                                                                        },
                                                                        {
                                                                            "id": 2,
                                                                            "value": "No Significant Observations"
                                                                        },
                                                                    ],
                                                                    "multiple": false,
                                                                    "isClearable": false,
                                                                    "isStatus": false,
                                                                    "search": true,
                                                                    "singleSelect": true,
                                                                    "showLabel": "value",
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required"
                                                                    },
                                                                    "value": "",
                                                                    "imageUrl": "",
                                                                    "disabled": false,
                                                                    "wrapperClassName": "",
                                                                    "connectedArrays": [],
                                                                    "showBlankInPlaceholder": false,
                                                                    "blankDataList": [],
                                                                    "commonPathOfImage": "",
                                                                    "__schema_id": "bands_formed",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "dropdown_684",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "box_925",
                                                            "id": "box_77",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "",
                                                            "addButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "removeButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "wrapperClassName": ""
                                                        },
                                                        "classname": ""
                                                    }
                                                ]
                                            }
                                        }
                                    }
                                ],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "",
                            "headerBtnClassName": "",
                            "header": "<span>DNA QC</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "",
                            "collapseOthers": true
                        }
                    },
                    {
                        "control": [
                            {
                                "type": "text",
                                "name": "comments",
                                "id": "comments",
                                "value": "",
                                "placeholder": "",
                                "multiline": true,
                                "classname": "form-control ",
                                "regularExpression": "",
                                "disabled": false,
                                "required": false,
                                "errorMessage": {
                                    "noData": "This fields is required",
                                    "regexFail": ""
                                },
                                "validationButtonType": "submit",
                                "rows": 0,
                                "columns": 0,
                                "onDemandError": false,
                                "customValidation": [],
                                "multiError": false,
                                "applicationError": "",
                                "wrapperClassName": "",
                                "requiredErrorMessage": "This fields is required",
                                "__schema_id": "comments",
                                "showInputLabel": false,
                                "labelContent": "text_161",
                                "labelClass": "form-label",
                                "isLabelAfter": false,
                                "showAdditionalInputContent": false,
                                "additionalInputContent": "",
                                "isAdditonalInputAfter": true,
                                "draggable": false
                            }
                        ],
                        "type": "",
                        "name": "wrapper_188",
                        "classname": "col-12 scrollable",
                        "wrapperType": "BOX",
                        "id": "wrapper_86",
                        "fields": [],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "",
                            "headerBtnClassName": "",
                            "header": "<span>Experiment Notes/Comments</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "",
                            "collapseOthers": true
                        }
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false,
                "isAccordion": true,
                "accordionConfig": {
                    "headerClassName": "",
                    "headerBtnClassName": "",
                    "header": "",
                    "collapseDivClassName": "",
                    "bodyClassName": "",
                    "collapseOthers": true
                }
            }
        ],
        "type": "",
        "classname": "",
        "showClass": true,
        "payload_schema": {
            "general_info": {
                "protocol": "protocol",
                // "file_ids": "file_ids"
            },
            "culture_prep": {
                // "inv_bact_strain": {
                //     "id": 34,
                //     "value": "bacterial_strain"
                // },
                // "helper_phage": {
                //     "id": 2,
                //     "value": "helper_phage"
                // },
                "inv_bact_strain": "bacterial_strain",
                "helper_phage": "helper_phage",
                "inv_cell_culture": "inv_cell_culture",
                "media_phage_antibiotics": "media_phage_antibiotics",
                "cp_rpm": "cp_rpm.$t{number}",
                "cp_inc_time": "cp_inc_time.$t{number}",
                "cp_inc_temp": "cp_inc_temp.$t{number}"
            },
            "centrifuge_phase_ext": {
                "snt_vol": "snt_vol.$t{number}",
                "peg_nacl": "peg_nacl.$t{number}",
                "percipitation": "percipitation.$t{number}",
                "pbs_vol": "pbs_vol.$t{number}",
                "centrifuge_time": "centrifuge_time.$t{number}",
                "cpe_rmp": "cpe_rmp.$t{number}"
            },
            "phage_dna_ext_purify": {
                "purification_kit": "purification_kit",
                "absorbance": "absorbance.$t{number}",
                "pdep_template_info": [
                    {
                        "lib_template": "lib_template",
                        "elutent_vol": "elutent_vol.$t{number}",
                        "ssdna_conc": "ssdna_conc.$t{number}",
                        "total_yield": "total_yield.$t{number}"
                    }
                ]
            },
            "dna_qc": {
                "comments": "comments.$d{}",
                "dna_qc_analysis_using": "dna_qc_analysis_using",
                "dna_qc_voltage": "dna_qc_voltage.$t{number}",
                "dna_qc_run_time": "dna_qc_run_time.$t{number}",
                "dna_qc_template_info": [
                    {
                        "lib_template": "dna_qc_lib_template",
                        "qc_status": "qc_status",
                        "bands_formed": "bands_formed"
                    }
                ]
            }
        },
        "validation_type": "submit"
    }
]
export const heteroduplexDnaSynthesis = [
    {
        "control": [],
        "head": "",
        "name": "main",
        "id": "main",
        "jsonId": 127,
        "wrapperType": "REGULAR",
        "fields": [
            {
                "control": [],
                "type": "",
                "name": "wrapper_524",
                "classname": "col-12",
                "wrapperType": "BOX",
                "id": "wrapper_298",
                "fields": [
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_665",
                        "classname": "row mb-3",
                        "wrapperType": "BOX",
                        "id": "wrapper_463",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "file",
                                        "name": "gel_image_file",
                                        "id": "upload-gel-Image",
                                        "value": "upload",
                                        "classname": "btn btn-file",
                                        "fileNameShow": true,
                                        "size": 10,
                                        "url": "",
                                        "validationButtonType": "",
                                        "wrapperClassName": "",
                                        "fileCount": 5,
                                        "sizeKB": 3000,
                                        "allowMultiple": true,
                                        "formDataType": "blob",
                                        "fileTypes": ["all"],
                                        "listClass": "",
                                        "mainClass": "",
                                        "btnDivClass": "",
                                        "outsideFileContainer": true,
                                        "errorMessage": {
                                            "invalidFormat": "",
                                            "invalidSize": "",
                                            "classname": ""
                                        },
                                        "__schema_id": "",
                                        "showInputLabel": true,
                                        "labelContent": "Upload Gel Image",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false,
                                        "showDownloadIcon":false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_610",
                                "classname": "col-6",
                                "wrapperType": "BOX",
                                "id": "wrapper_69",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "file",
                                        "name": "absorbance_data_file",
                                        "id": "upload-absorbance-data",
                                        "value": "upload",
                                        "classname": "btn btn-file",
                                        "fileNameShow": false,
                                        "size": 10,
                                        "url": "",
                                        "validationButtonType": "",
                                        "wrapperClassName": "",
                                        "fileCount": 5,
                                        "sizeKB": 3000,
                                        "allowMultiple": true,
                                        "formDataType": "blob",
                                        "fileTypes": ["ab1","pdf","txt","xlsx", "xlsm", "ods", "doc", "docx", "dot", "dotm", "dotx"],
                                        "listClass": "",
                                        "mainClass": "",
                                        "btnDivClass": "",
                                        "outsideFileContainer": true,
                                        "errorMessage": {
                                            "invalidFormat": "",
                                            "invalidSize": "",
                                            "classname": ""
                                        },
                                        "__schema_id": "",
                                        "showInputLabel": true,
                                        "labelContent": "Upload Absorbance Data",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false,
                                        "showDownloadIcon":false

                                    }
                                ],
                                "type": "",
                                "name": "wrapper_939",
                                "classname": "col-6",
                                "wrapperType": "BOX",
                                "id": "wrapper_945",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_215",
                        "classname": "row mb-4",
                        "wrapperType": "BOX",
                        "id": "wrapper_598",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "dropdown",
                                        "name": "protocol_id",
                                        "id": "select-protocol",
                                        "validationButtonType": "",
                                        "classname": "",
                                        "required": false,
                                        "apiEndPoints": [
                                            {
                                                "type": "init",
                                                "method": "get",
                                                "url": "protocol/protocol-list"
                                            },
                                            {
                                                "type": "change",
                                                "method": "get",
                                                "Mapto": [
                                                    {
                                                        "id": "main.wrapper_298.wrapper_598.wrapper_921.protocol_file_id_1",
                                                        "displayKey": "change",
                                                        "urlParameterValues": {
                                                            "id": null,
                                                            "patchProperty": "protocol_file_id"
                                                        }
                                                    }
                                                ],
                                                "url": "protocol/${id}"
                                            }
                                        ],
                                        "options": [],
                                        "multiple": false,
                                        "isClearable": false,
                                        "isStatus": false,
                                        "search": true,
                                        "singleSelect": true,
                                        "showLabel": "value",
                                        "errorMessage": {
                                            "noData": "This fields is required"
                                        },
                                        "value": "",
                                        "imageUrl": "",
                                        "disabled": false,
                                        "wrapperClassName": "",
                                        "connectedArrays": [],
                                        "showBlankInPlaceholder": false,
                                        "blankDataList": [],
                                        "commonPathOfImage": "",
                                        "__schema_id": "protocol_id",
                                        "showInputLabel": true,
                                        "labelContent": "Select Protocol",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    },
                                    {
                                        "type": "text",
                                        "name": "protocol_file_id",
                                        "id": "protocol_file_id_1",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control d-none",
                                        "regularExpression": "",
                                        "disabled": true,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "protocol_file_id",
                                        "showInputLabel": false,
                                        "labelContent": "Bacteria Strain",
                                        "labelClass": "form-label mandatory ",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    },
                                    {
                                        "type": "button",
                                        "id": "button_324",
                                        "value": "<div class=\"custom-tooltip\"> <p>View</p> </div>",
                                        "buttonType": "view-protocol",
                                        "classname": "plasmid-view icon-align ms-3",
                                        "divClass": "",
                                        "controlEndPoints": [],
                                        "disabled": false,
                                        "validationEndPoints": [],
                                        "invalid": false,
                                        "name": "button_168",
                                        "wrapperClassName": "",
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_588",
                                "classname": "col-3 action-wrap ",
                                "wrapperType": "BOX",
                                "id": "wrapper_921",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_416",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_518",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_736",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_931",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_213",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_559",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false
            },
            {
                "control": [],
                "type": "",
                "name": "",
                "classname": "accordion accordion-custom custom-table",
                "wrapperType": "ACCORDION",
                "id": "wrapper_235",
                "fields": [
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_727",
                        "classname": "project-table scrollable",
                        "wrapperType": "BOX",
                        "id": "wrapper_471",
                        "fields": [
                            {
                                "control": [],
                                "type": "",
                                "name": "mutagenic",
                                "classname": "table ssdna-table table-top-align",
                                "wrapperType": "TABLE",
                                "id": "wrapper_936",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false,
                                "hideSingleItemDelete": false,
                                "tableFields": {
                                    "showHead": true,
                                    "isVertical": false,
                                    "isIteratable": false,
                                    "addButton": {
                                        "classname": "btn btn-primary",
                                        "name": "",
                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                    },
                                    "removeButton": {
                                        "classname": "btn btn-danger",
                                        "name": "",
                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                    },
                                    "tableHead": {
                                        "heading": [
                                            {
                                                "value": "Template ssDNA",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Stop Codons",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Template ssdna Names",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Select the CDRs to be randomized",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Action",
                                                "classname": ""
                                            }
                                        ],
                                        "classname": ""
                                    },
                                    "initRecordCount": 1,
                                    "showRecordCount": false,
                                    "recordCounterConfig": {
                                        "classname": "",
                                        "adjValue": "",
                                        "isAdjValueAfter": false
                                    },
                                    "tableRow": {
                                        "name": "",
                                        "classname": "trclass",
                                        "tableCols": [
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "template_ssdna",
                                                            "id": "template-ssdna",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": true,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "lib_template",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_277",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "head": "",
                                                    "name": "box_975",
                                                    "id": "box_801",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "stop_codons",
                                                            "id": "stop-codons",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": true,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "stop_codons",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_323",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "stop_codons",
                                                    "id": "box_736",
                                                    "wrapperType": "ITERATE",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "repeat-box",
                                                    "addButton": {
                                                        "classname": "btn",
                                                        "name": "",
                                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                                    },
                                                    "removeButton": {
                                                        "classname": "btn",
                                                        "name": "",
                                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                                    },
                                                    "wrapperClassName": "",
                                                    "ButtonPosition": "top",
                                                    "controlButtonClass": "d-none",
                                                    "hideSingleItemDelete": false,
                                                    "addBtnPostion": null
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "template_ssdna_names",
                                                            "id": "template-ssdna-names",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": true,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_340",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_6",
                                                    "id": "box_464",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "dropdown",
                                                            "name": "cdrs_to_random",
                                                            "id": "select-the-cdrs",
                                                            "validationButtonType": "",
                                                            "classname": "",
                                                            "required": false,
                                                            "apiEndPoints": [],
                                                            "options": [
                                                                "L1",
                                                                "L2",
                                                                "L3",
                                                                "H1",
                                                                "H2",
                                                                "H3"
                                                            ],
                                                            "multiple": false,
                                                            "isClearable": false,
                                                            "isStatus": false,
                                                            "search": true,
                                                            "singleSelect": true,
                                                            "showLabel": "",
                                                            "errorMessage": {
                                                                "noData": "This fields is required"
                                                            },
                                                            "value": "",
                                                            "imageUrl": "",
                                                            "disabled": false,
                                                            "wrapperClassName": "",
                                                            "connectedArrays": [],
                                                            "showBlankInPlaceholder": false,
                                                            "blankDataList": [],
                                                            "commonPathOfImage": "",
                                                            "__schema_id": "cdrs_to_random",
                                                            "showInputLabel": false,
                                                            "labelContent": "dropdown_945",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "cdrs_to_random",
                                                    "id": "box_769",
                                                    "wrapperType": "ITERATE",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "repeat-box",
                                                    "addButton": {
                                                        "classname": "btn",
                                                        "name": "",
                                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                                    },
                                                    "removeButton": {
                                                        "classname": "btn",
                                                        "name": "",
                                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                                    },
                                                    "wrapperClassName": "",
                                                    "ButtonPosition": "top",
                                                    "controlButtonClass": "d-none",
                                                    "hideSingleItemDelete": false,
                                                    "addBtnPostion": null
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "button",
                                                            "id": "button_580",
                                                            "value": "<div class=\"custom-tooltip\"><p>Upload Sequence</p></div>",
                                                            "buttonType": "mutagenic_file_upload",
                                                            "classname": "upload-blue me-3",
                                                            "divClass": "",
                                                            "controlEndPoints": [],
                                                            "disabled": false,
                                                            "validationEndPoints": [],
                                                            "invalid": false,
                                                            "name": "button_624",
                                                            "wrapperClassName": "",
                                                            "draggable": false,
                                                            "callBackEvents": [],
                                                            "controlPoints": []
                                                        },
                                                        {
                                                            "type": "button",
                                                            "id": "button_730",
                                                            "value": "<div class=\"custom-tooltip\"><p>View </p></div>",
                                                            "buttonType": "mutagenic_file_view",
                                                            "classname": "plasmid-view",
                                                            "divClass": "",
                                                            "controlEndPoints": [],
                                                            "disabled": false,
                                                            "validationEndPoints": [],
                                                            "invalid": false,
                                                            "name": "button_464",
                                                            "wrapperClassName": "",
                                                            "draggable": false
                                                        },
                                                        {
                                                            "type": "text",
                                                            "name": "mosu_file_ids",
                                                            "__schema_id": "mosu_file_ids",
                                                            "id": "textBox_498",
                                                            "value": '',
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control d-none",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_978",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "mosu_file_ids",
                                                    "id": "box_439",
                                                    "wrapperType": "ITERATE",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "action-wrap repeat-box-action",
                                                    "addButton": {
                                                        "classname": "btn",
                                                        "name": "",
                                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                                    },
                                                    "removeButton": {
                                                        "classname": "btn",
                                                        "name": "",
                                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                                    },
                                                    "wrapperClassName": "",
                                                    "ButtonPosition": "top",
                                                    "controlButtonClass": "d-none",
                                                    "hideSingleItemDelete": false,
                                                    "addBtnPostion": null
                                                },
                                                "classname": ""
                                            }
                                        ]
                                    }
                                }
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "",
                            "headerBtnClassName": "",
                            "header": "<span>Mutagenic Oligonucleotide Sequence Upload</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "",
                            "collapseOthers": true
                        }
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_70",
                        "classname": "col-12",
                        "wrapperType": "BOX",
                        "id": "wrapper_679",
                        "fields": [
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_70",
                                "classname": "row mb-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_677",
                                "fields": [
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "mutagenic_oligo_amount",
                                                "id": "Amount of-mutagenic",
                                                "value": "600",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "mutagenic_oligo_amount",
                                                "showInputLabel": true,
                                                "labelContent": "Amount of Mutagenic Oligonucleotide (ng)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_141",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_623",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "tm_buffer_10x",
                                                "id": "tm-buffer",
                                                "value": "2.0",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "10x_tm_buffer",
                                                "showInputLabel": true,
                                                "labelContent": "10X TM buffer (μL)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_986",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_257",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "atp_10mm",
                                                "id": "atp-buffer",
                                                "value": "2.0",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "10mm_atp",
                                                "showInputLabel": true,
                                                "labelContent": "10mm ATP (μL)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_90",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_407",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "dtt_100mm",
                                                "id": "dtt-buffer",
                                                "value": "1.0",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "100mm_dtt",
                                                "showInputLabel": true,
                                                "labelContent": "100mM DTT (μL)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_245",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_302",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_347",
                                        "classname": "col-12",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_929",
                                        "fields": [
                                            {
                                                "control": [],
                                                "type": "",
                                                "name": "wrapper_663",
                                                "classname": "row",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_111",
                                                "fields": [
                                                    {
                                                        "control": [
                                                            {
                                                                "type": "text",
                                                                "name": "pnk_enzyme_10",
                                                                "id": "pnk-enzyme",
                                                                "value": "20",
                                                                "placeholder": "",
                                                                "multiline": false,
                                                                "classname": "form-control ",
                                                                "regularExpression": "",
                                                                "disabled": false,
                                                                "required": false,
                                                                "errorMessage": {
                                                                    "noData": "This fields is required",
                                                                    "regexFail": ""
                                                                },
                                                                "validationButtonType": "",
                                                                "rows": 0,
                                                                "columns": 0,
                                                                "onDemandError": false,
                                                                "customValidation": [],
                                                                "multiError": false,
                                                                "applicationError": "",
                                                                "wrapperClassName": "",
                                                                "requiredErrorMessage": "This fields is required",
                                                                "__schema_id": "10_pnk_enzyme",
                                                                "showInputLabel": true,
                                                                "labelContent": "10 (U/μL) PNK Enzyme (units)",
                                                                "labelClass": "form-label",
                                                                "isLabelAfter": false,
                                                                "showAdditionalInputContent": false,
                                                                "additionalInputContent": "",
                                                                "isAdditonalInputAfter": true,
                                                                "draggable": false
                                                            }
                                                        ],
                                                        "type": "",
                                                        "name": "wrapper_152",
                                                        "classname": "col-3",
                                                        "wrapperType": "BOX",
                                                        "id": "wrapper_253",
                                                        "fields": [],
                                                        "head": "",
                                                        "tabHeadClass": ""
                                                    },
                                                    {
                                                        "control": [
                                                            {
                                                                "type": "text",
                                                                "name": "phos_inc_time",
                                                                "id": "incubation-time",
                                                                "value": "",
                                                                "placeholder": "",
                                                                "multiline": false,
                                                                "classname": "form-control ",
                                                                "regularExpression": "",
                                                                "disabled": false,
                                                                "required": false,
                                                                "errorMessage": {
                                                                    "noData": "This fields is required",
                                                                    "regexFail": ""
                                                                },
                                                                "validationButtonType": "",
                                                                "rows": 0,
                                                                "columns": 0,
                                                                "onDemandError": false,
                                                                "customValidation": [],
                                                                "multiError": false,
                                                                "applicationError": "",
                                                                "wrapperClassName": "",
                                                                "requiredErrorMessage": "This fields is required",
                                                                "__schema_id": "phos_inc_time",
                                                                "showInputLabel": true,
                                                                "labelContent": "Incubation Time (h)",
                                                                "labelClass": "form-label",
                                                                "isLabelAfter": false,
                                                                "showAdditionalInputContent": false,
                                                                "additionalInputContent": "",
                                                                "isAdditonalInputAfter": true,
                                                                "draggable": false
                                                            }
                                                        ],
                                                        "type": "",
                                                        "name": "wrapper_10",
                                                        "classname": "col-3",
                                                        "wrapperType": "BOX",
                                                        "id": "wrapper_7",
                                                        "fields": [],
                                                        "head": "",
                                                        "tabHeadClass": ""
                                                    },
                                                    {
                                                        "control": [
                                                            {
                                                                "type": "text",
                                                                "name": "phos_inc_temp",
                                                                "id": "incubation-temperature",
                                                                "value": "",
                                                                "placeholder": "",
                                                                "multiline": false,
                                                                "classname": "form-control ",
                                                                "regularExpression": "",
                                                                "disabled": false,
                                                                "required": false,
                                                                "errorMessage": {
                                                                    "noData": "This fields is required",
                                                                    "regexFail": ""
                                                                },
                                                                "validationButtonType": "",
                                                                "rows": 0,
                                                                "columns": 0,
                                                                "onDemandError": false,
                                                                "customValidation": [],
                                                                "multiError": false,
                                                                "applicationError": "",
                                                                "wrapperClassName": "",
                                                                "requiredErrorMessage": "This fields is required",
                                                                "__schema_id": "phos_inc_temp",
                                                                "showInputLabel": true,
                                                                "labelContent": "Incubation Temperature (°C)",
                                                                "labelClass": "form-label",
                                                                "isLabelAfter": false,
                                                                "showAdditionalInputContent": false,
                                                                "additionalInputContent": "",
                                                                "isAdditonalInputAfter": true,
                                                                "draggable": false
                                                            }
                                                        ],
                                                        "type": "",
                                                        "name": "wrapper_170",
                                                        "classname": "col-3",
                                                        "wrapperType": "BOX",
                                                        "id": "wrapper_918",
                                                        "fields": [],
                                                        "head": "",
                                                        "tabHeadClass": ""
                                                    },
                                                    {
                                                        "control": [],
                                                        "type": "",
                                                        "name": "wrapper_241",
                                                        "classname": "col-3",
                                                        "wrapperType": "BOX",
                                                        "id": "wrapper_354",
                                                        "fields": [],
                                                        "head": "",
                                                        "tabHeadClass": ""
                                                    }
                                                ],
                                                "head": "",
                                                "tabHeadClass": "",
                                                "draggable": false
                                            }
                                        ],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    }
                                ],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "",
                            "headerBtnClassName": "",
                            "header": "<span>Phosphorylation</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "",
                            "collapseOthers": true
                        }
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_759",
                        "classname": "project-table scrollable",
                        "wrapperType": "BOX",
                        "id": "wrapper_572",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "label",
                                        "name": "label_787",
                                        "classname": "",
                                        "for": "",
                                        "required": false,
                                        "wrapperClassName": "",
                                        "id": "label_277",
                                        "draggable": false
                                    },
                                    {
                                        "type": "label",
                                        "name": "label_213",
                                        "classname": "",
                                        "for": "",
                                        "required": false,
                                        "wrapperClassName": "",
                                        "id": "label_624",
                                        "draggable": false
                                    },
                                    {
                                        "type": "label",
                                        "name": "label_420",
                                        "classname": "",
                                        "for": "",
                                        "required": false,
                                        "wrapperClassName": "",
                                        "id": "label_684",
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "annealing_temp",
                                "classname": "table annealing-table",
                                "wrapperType": "TABLE",
                                "id": "wrapper_176",
                                "fields": [
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_174",
                                        "classname": "col-12 scrollable",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_340",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_799",
                                        "classname": "col-12",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_766",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    }
                                ],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false,
                                "hideSingleItemDelete": false,
                                "tableFields": {
                                    "showHead": true,
                                    "isVertical": false,
                                    "isIteratable": false,
                                    "addButton": {
                                        "classname": "btn btn-primary",
                                        "name": "",
                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                    },
                                    "removeButton": {
                                        "classname": "btn btn-danger",
                                        "name": "",
                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                    },
                                    "tableHead": {
                                        "heading": [
                                            {
                                                "value": "Temperature (°C)",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Incubation Time (mins)",
                                                "classname": ""
                                            }
                                        ],
                                        "classname": ""
                                    },
                                    "initRecordCount": 1,
                                    "showRecordCount": false,
                                    "recordCounterConfig": {
                                        "classname": "",
                                        "adjValue": "",
                                        "isAdjValueAfter": false
                                    },
                                    "tableRow": {
                                        "name": "",
                                        "classname": "trclass",
                                        "tableCols": [
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "temperature",
                                                            "id": "temperature",
                                                            "value": "90",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "annealing_temperature",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_649",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "head": "",
                                                    "name": "box_429",
                                                    "id": "box_868",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": ""
                                                },
                                                "classname": "col-4"
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "incubation_time",
                                                            "id": "incubation-time",
                                                            "value": "3",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "annealing_incubation_time",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_698",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_392",
                                                    "id": "box_421",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": "col-8"
                                            }
                                        ]
                                    }
                                }
                            },
                            {
                                "control": [],
                                "type": "",
                                "name": "anealing_templates",
                                "classname": "col-12 mb-3",
                                "wrapperType": "ITERATE",
                                "id": "anealing_templates",
                                "fields": [
                                    {
                                        "control": [
                                            {
                                                "type": "label",
                                                "name": "Template ${i}",
                                                "classname": "mb-4 template-head",
                                                "for": "",
                                                "required": false,
                                                "wrapperClassName": "",
                                                "id": "label_572",
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_828",
                                        "classname": "col-12",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_768",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_497",
                                        "classname": "col-12",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_226",
                                        "fields": [
                                            {
                                                "control": [],
                                                "type": "",
                                                "name": "wrapper_652",
                                                "classname": "row mb-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_959",
                                                "fields": [
                                                    {
                                                        "control": [
                                                            {
                                                                "type": "text",
                                                                "name": "lib_template",
                                                                "id": "template-ssdna-id",
                                                                "value": "",
                                                                "placeholder": "",
                                                                "multiline": false,
                                                                "classname": "form-control ",
                                                                "regularExpression": "",
                                                                "disabled": true,
                                                                "required": false,
                                                                "errorMessage": {
                                                                    "noData": "This fields is required",
                                                                    "regexFail": ""
                                                                },
                                                                "validationButtonType": "",
                                                                "rows": 0,
                                                                "columns": 0,
                                                                "onDemandError": false,
                                                                "customValidation": [],
                                                                "multiError": false,
                                                                "applicationError": "",
                                                                "wrapperClassName": "",
                                                                "requiredErrorMessage": "This fields is required",
                                                                "__schema_id": "annealing_lib_template",
                                                                "showInputLabel": true,
                                                                "labelContent": "Template ssDNA ID",
                                                                "labelClass": "form-label",
                                                                "isLabelAfter": false,
                                                                "showAdditionalInputContent": false,
                                                                "additionalInputContent": "",
                                                                "isAdditonalInputAfter": true,
                                                                "draggable": false
                                                            }
                                                        ],
                                                        "type": "",
                                                        "name": "wrapper_415",
                                                        "classname": "col-3",
                                                        "wrapperType": "BOX",
                                                        "id": "wrapper_179",
                                                        "fields": [],
                                                        "head": "",
                                                        "tabHeadClass": ""
                                                    },
                                                    {
                                                        "control": [
                                                            {
                                                                "type": "text",
                                                                "name": "conc_temp_ssdna",
                                                                "id": "concentration-of-template-ssdna-t1_calcVol",
                                                                "value": "",
                                                                "callBackEvents": ['input'
                                                                ],
                                                                "placeholder": "",
                                                                "multiline": false,
                                                                "classname": "form-control ",
                                                                "regularExpression": "",
                                                                "disabled": false,
                                                                "required": false,
                                                                "errorMessage": {
                                                                    "noData": "This fields is required",
                                                                    "regexFail": ""
                                                                },
                                                                "validationButtonType": "",
                                                                "rows": 0,
                                                                "columns": 0,
                                                                "onDemandError": false,
                                                                "customValidation": [],
                                                                "multiError": false,
                                                                "applicationError": "",
                                                                "wrapperClassName": "",
                                                                "requiredErrorMessage": "This fields is required",
                                                                "__schema_id": "annealing_conc_temp_ssdna",
                                                                "showInputLabel": true,
                                                                "labelContent": "Concentration of template ssDNA (ng/μL)",
                                                                "labelClass": "form-label",
                                                                "isLabelAfter": false,
                                                                "showAdditionalInputContent": false,
                                                                "additionalInputContent": "",
                                                                "isAdditonalInputAfter": true,
                                                                "draggable": false
                                                            }
                                                        ],
                                                        "type": "",
                                                        "name": "wrapper_689",
                                                        "classname": "col-3",
                                                        "wrapperType": "BOX",
                                                        "id": "wrapper_179",
                                                        "fields": [],
                                                        "head": "",
                                                        "tabHeadClass": ""
                                                    },
                                                    {
                                                        "control": [
                                                            {
                                                                "type": "text",
                                                                "name": "vol_temp_ssdna",
                                                                "id": "Volume-of-template-ssdna-t1",
                                                                "value": "",
                                                                "placeholder": "",
                                                                "multiline": false,
                                                                "classname": "form-control ",
                                                                "regularExpression": "",
                                                                "disabled": true,
                                                                "required": false,
                                                                "errorMessage": {
                                                                    "noData": "This fields is required",
                                                                    "regexFail": ""
                                                                },
                                                                "validationButtonType": "",
                                                                "rows": 0,
                                                                "columns": 0,
                                                                "onDemandError": false,
                                                                "customValidation": [],
                                                                "multiError": false,
                                                                "applicationError": "",
                                                                "wrapperClassName": "",
                                                                "requiredErrorMessage": "This fields is required",
                                                                "__schema_id": "",
                                                                "showInputLabel": true,
                                                                "labelContent": "Volume of template ssDNA (μL)",
                                                                "labelClass": "form-label",
                                                                "isLabelAfter": false,
                                                                "showAdditionalInputContent": false,
                                                                "additionalInputContent": "",
                                                                "isAdditonalInputAfter": true,
                                                                "draggable": false
                                                            }
                                                        ],
                                                        "type": "",
                                                        "name": "wrapper_435",
                                                        "classname": "col-3",
                                                        "wrapperType": "BOX",
                                                        "id": "wrapper_422",
                                                        "fields": [],
                                                        "head": "",
                                                        "tabHeadClass": ""
                                                    },
                                                    {
                                                        "control": [
                                                            {
                                                                "type": "text",
                                                                "name": "total_temp_ssdna",
                                                                "id": "total-templates-ssdna-t1_calcVol",
                                                                "value": "20",
                                                                "callBackEvents": ['input'
                                                                ],
                                                                "placeholder": "",
                                                                "multiline": false,
                                                                "classname": "form-control ",
                                                                "regularExpression": "",
                                                                "disabled": false,
                                                                "required": false,
                                                                "errorMessage": {
                                                                    "noData": "This fields is required",
                                                                    "regexFail": ""
                                                                },
                                                                "validationButtonType": "",
                                                                "rows": 0,
                                                                "columns": 0,
                                                                "onDemandError": false,
                                                                "customValidation": [],
                                                                "multiError": false,
                                                                "applicationError": "",
                                                                "wrapperClassName": "",
                                                                "requiredErrorMessage": "This fields is required",
                                                                "__schema_id": "annealing_total_temp_ssdna",
                                                                "showInputLabel": true,
                                                                "labelContent": "Total Templates ssDNA (μg) ",
                                                                "labelClass": "form-label",
                                                                "isLabelAfter": false,
                                                                "showAdditionalInputContent": false,
                                                                "additionalInputContent": "",
                                                                "isAdditonalInputAfter": true,
                                                                "draggable": false
                                                            }
                                                        ],
                                                        "type": "",
                                                        "name": "wrapper_979",
                                                        "classname": "col-3",
                                                        "wrapperType": "BOX",
                                                        "id": "wrapper_759",
                                                        "fields": [],
                                                        "head": "",
                                                        "tabHeadClass": ""
                                                    }
                                                ],
                                                "head": "",
                                                "tabHeadClass": "",
                                                "draggable": false
                                            }
                                        ],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "template_oligos",
                                        "classname": "table annealing-table-2 table-top-align",
                                        "wrapperType": "TABLE",
                                        "id": "wrapper_802",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false,
                                        "hideSingleItemDelete": false,
                                        "tableFields": {
                                            "showHead": true,
                                            "isVertical": false,
                                            "isIteratable": false,
                                            "addButton": {
                                                "classname": "btn btn-primary",
                                                "name": "",
                                                "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                            },
                                            "removeButton": {
                                                "classname": "btn btn-danger",
                                                "name": "",
                                                "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                            },
                                            "tableHead": {
                                                "heading": [
                                                    {
                                                        "value": "No: of Oligos ",
                                                        "classname": ""
                                                    },
                                                    {
                                                        "value": "CDRs",
                                                        "classname": ""
                                                    },
                                                    {
                                                        "value": "Volume of oligos <span class='text-initial'>(μL)</sapn>",
                                                        "classname": ""
                                                    },
                                                    {
                                                        "value": "10X TM <span class='text-initial'>(μL)</span>",
                                                        "classname": ""
                                                    },
                                                    {
                                                        "value": "H2O <span class='text-initial'>(μL)</span>",
                                                        "classname": ""
                                                    },
                                                    {
                                                        "value": "Total Volume <span class='text-initial'>(μL)</span>",
                                                        "classname": ""
                                                    }
                                                ],
                                                "classname": ""
                                            },
                                            "initRecordCount": 1,
                                            "showRecordCount": false,
                                            "recordCounterConfig": {
                                                "classname": "",
                                                "adjValue": "",
                                                "isAdjValueAfter": false
                                            },
                                            "tableRow": {
                                                "name": "",
                                                "classname": "trclass",
                                                "tableCols": [
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "no_of_oligos",
                                                                    "id": "no-of-oligos-t1",
                                                                    "value": "1",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": true,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "__schema_id": "annealing_no_of_oligos",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_470",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "head": "",
                                                            "name": "box_910",
                                                            "id": "box_141",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": ""
                                                        },
                                                        "classname": "col-2"
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "cdrs",
                                                                    "id": "cdrs-t1",
                                                                    "value": "",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": true,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "__schema_id": "oligos_details_cdrs",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_744",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "cdrs",
                                                            "id": "box_663",
                                                            "wrapperType": "ITERATE",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "repeat-box",
                                                            "addButton": {
                                                                "classname": "btn",
                                                                "name": "",
                                                                "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                                            },
                                                            "removeButton": {
                                                                "classname": "btn",
                                                                "name": "",
                                                                "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                                            },
                                                            "wrapperClassName": "",
                                                            "ButtonPosition": "top",
                                                            "controlButtonClass": "d-none",
                                                            "hideSingleItemDelete": false,
                                                            "addBtnPostion": null
                                                        },
                                                        "classname": "col-2"
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "vol_oligos",
                                                                    "id": "textBox_volum_calcH2oVolOligos",
                                                                    "value": "20",
                                                                    "callBackEvents": ['input'
                                                                    ],
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": false,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "__schema_id": "oligos_details_vol_oligos",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_769",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "vol_oligos",
                                                            "id": "box_666",
                                                            "wrapperType": "ITERATE",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "repeat-box",
                                                            "addButton": {
                                                                "classname": "btn",
                                                                "name": "",
                                                                "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                                            },
                                                            "removeButton": {
                                                                "classname": "btn",
                                                                "name": "",
                                                                "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                                            },
                                                            "wrapperClassName": "",
                                                            "ButtonPosition": "top",
                                                            "controlButtonClass": "d-none",
                                                            "hideSingleItemDelete": false,
                                                            "addBtnPostion": null
                                                        },
                                                        "classname": "col-2"
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "x_tm10",
                                                                    "id": "textBox_10x-m_calcH2o",
                                                                    "value": "25",
                                                                    "callBackEvents": ['input'
                                                                    ],
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": false,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "__schema_id": "annealing_10x_tm",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_592",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "box_144",
                                                            "id": "box_573",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "",
                                                            "addButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "removeButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "wrapperClassName": ""
                                                        },
                                                        "classname": "col-2"
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "h20",
                                                                    "id": "textBox_h2o",
                                                                    "value": "0.1",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": true,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "__schema_id": "annealing_h20",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_939",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "box_414",
                                                            "id": "box_645",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "",
                                                            "addButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "removeButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "wrapperClassName": ""
                                                        },
                                                        "classname": "col-2"
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "total_vol",
                                                                    "id": "total-volume_633_calcH2o",
                                                                    "value": "250",
                                                                    "callBackEvents": ['input'],
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": false,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "__schema_id": "annealing_total_vol",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_169",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "box_781",
                                                            "id": "box_887",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "",
                                                            "addButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "removeButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "wrapperClassName": ""
                                                        },
                                                        "classname": "col-2"
                                                    }
                                                ]
                                            }
                                        }
                                    }
                                ],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false,
                                "hideSingleItemDelete": false,
                                "ButtonPosition": "top",
                                "addButton": {
                                    "classname": "btn",
                                    "name": "",
                                    "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                },
                                "removeButton": {
                                    "classname": "btn",
                                    "name": "",
                                    "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                },
                                "controlButtonClass": "d-none",
                                "addBtnPostion": null,
                                "maxRecordCount": 2
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "",
                            "headerBtnClassName": "",
                            "header": "<span>Annealing</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "",
                            "collapseOthers": true
                        }
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_608",
                        "classname": "col-12 scrollable",
                        "wrapperType": "BOX",
                        "id": "wrapper_26",
                        "fields": [
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_549",
                                "classname": "row mb-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_44",
                                "fields": [
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "escsdna_inc_time",
                                                "id": "incubation-time",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "escsdna_inc_time",
                                                "showInputLabel": true,
                                                "labelContent": "Incubation Time (h)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_284",
                                        "classname": "col-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_660",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "escsdna_inc_temp",
                                                "id": "incubation-temperature",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "escsdna_inc_temp",
                                                "showInputLabel": true,
                                                "labelContent": "Incubation Temperature (°C)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_741",
                                        "classname": "col-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_526",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_223",
                                        "classname": "col-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_987",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_772",
                                        "classname": "col-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_576",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    }
                                ],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false
                            },
                            {
                                "control": [],
                                "type": "",
                                "name": "enzymatic",
                                "classname": "table enzymatic-table mb-0 table-top-align",
                                "wrapperType": "TABLE",
                                "id": "wrapper_253",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false,
                                "hideSingleItemDelete": false,
                                "tableFields": {
                                    "showHead": true,
                                    "isVertical": false,
                                    "isIteratable": false,
                                    "addButton": {
                                        "classname": "btn btn-primary",
                                        "name": "",
                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                    },
                                    "removeButton": {
                                        "classname": "btn btn-danger",
                                        "name": "",
                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                    },
                                    "tableHead": {
                                        "heading": [
                                            {
                                                "value": "Template ssdna ID",
                                                "classname": ""
                                            },
                                            {
                                                "value": "CDRs",
                                                "classname": ""
                                            },
                                            {
                                                "value": "dNTPs <span class='text-initial'>(μL)</span>",
                                                "classname": ""
                                            },
                                            {
                                                "value": "ATP <span class='text-initial'>(μL)</span>",
                                                "classname": ""
                                            },
                                            {
                                                "value": "DTT <span class='text-initial'>(μL)</span>",
                                                "classname": ""
                                            },
                                            {
                                                "value": "T7 Polymerase <span class='text-initial'>(μL)</span>",
                                                "classname": ""
                                            },
                                            {
                                                "value": "T4 Ligase <span class='text-initial'>(μL)</span>",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Total Volume <span class='text-initial'>(μL)</span>",
                                                "classname": ""
                                            }
                                        ],
                                        "classname": ""
                                    },
                                    "initRecordCount": 1,
                                    "showRecordCount": false,
                                    "recordCounterConfig": {
                                        "classname": "",
                                        "adjValue": "",
                                        "isAdjValueAfter": false
                                    },
                                    "tableRow": {
                                        "name": "",
                                        "classname": "trclass",
                                        "tableCols": [
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "template_id",
                                                            "id": "template-ssdna-id",
                                                            "value": "template_id",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": true,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "enzymatic_template_id",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_975",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "head": "",
                                                    "name": "box_133",
                                                    "id": "box_763",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": ""
                                                },
                                                "classname": "col-3"
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "cdrs",
                                                            "id": "cdrs-t",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": true,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "enzymatic_cdrs",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_975",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "cdrs",
                                                    "id": "box_506",
                                                    "wrapperType": "ITERATE",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "repeat-box",
                                                    "addButton": {
                                                        "classname": "btn",
                                                        "name": "",
                                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                                    },
                                                    "removeButton": {
                                                        "classname": "btn",
                                                        "name": "",
                                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                                    },
                                                    "wrapperClassName": "",
                                                    "ButtonPosition": "top",
                                                    "controlButtonClass": "d-none",
                                                    "hideSingleItemDelete": false,
                                                    "addBtnPostion": null
                                                },
                                                "classname": "col-2"
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "dntps",
                                                            "id": "dntps_calcEnzymaticVol",
                                                            "value": "10",
                                                            "callBackEvents": ['input'],
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "enzymatic_dntps",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_20",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_385",
                                                    "id": "box_247",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": "col-1"
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "atp",
                                                            "id": "atp-d2_calcEnzymaticVol",
                                                            "value": "10",
                                                            "callBackEvents": ['input'],
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "enzymatic_atp",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_352",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_382",
                                                    "id": "box_656",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": "col-1"
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "dtt",
                                                            "id": "dtt-f2_calcEnzymaticVol",
                                                            "value": "15",
                                                            "callBackEvents": ['input'],
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "enzymatic_dtt",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_993",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_846",
                                                    "id": "box_163",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": "col-1"
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "t7_polymerase",
                                                            "id": "polymerase_calcEnzymaticVol",
                                                            "value": "1",
                                                            "callBackEvents": ['input'],
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "enzymatic_t7_polymerase",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_77",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_310",
                                                    "id": "box_633",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": "col-1"
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "t4_ligase",
                                                            "id": "t4-ligase_calcEnzymaticVol",
                                                            "value": "3",
                                                            "callBackEvents": ['input'],
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "enzymatic_t4_ligase",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_32",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_665",
                                                    "id": "box_529",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": "col-1"
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "total_vol",
                                                            "id": "total-volume",
                                                            "value": "1",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": true,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "enzymatic_total_vol",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_451",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_903",
                                                    "id": "box_297",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": "col-2"
                                            }
                                        ]
                                    }
                                }
                            },
                            {
                                "control": [],
                                "type": "",
                                "name": "master_mix_sum",
                                "classname": "table enzymatic-table-2",
                                "wrapperType": "TABLE",
                                "id": "wrapper_317",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false,
                                "hideSingleItemDelete": false,
                                "tableFields": {
                                    "showHead": false,
                                    "isVertical": false,
                                    "isIteratable": false,
                                    "addButton": {
                                        "classname": "btn btn-primary",
                                        "name": "",
                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                    },
                                    "removeButton": {
                                        "classname": "btn btn-danger",
                                        "name": "",
                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                    },
                                    "tableHead": {
                                        "heading": [
                                            {
                                                "value": "T_Head-1",
                                                "classname": ""
                                            },
                                            {
                                                "value": "T_Head_2",
                                                "classname": ""
                                            }
                                        ],
                                        "classname": ""
                                    },
                                    "initRecordCount": 1,
                                    "showRecordCount": false,
                                    "recordCounterConfig": {
                                        "classname": "",
                                        "adjValue": "",
                                        "isAdjValueAfter": false
                                    },
                                    "tableRow": {
                                        "name": "",
                                        "classname": "trclass",
                                        "tableCols": [
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "label",
                                                            "name": "Master Mix Sum",
                                                            "classname": "",
                                                            "for": "",
                                                            "required": false,
                                                            "wrapperClassName": "",
                                                            "id": "label_872",
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "head": "",
                                                    "name": "box_857",
                                                    "id": "box_283",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "master-sum-label"
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "master_mix_sum",
                                                            "id": "textBox_573",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": true,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "master_mix_sum",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_814",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_798",
                                                    "id": "box_416",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            }
                                        ]
                                    }
                                }
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "",
                            "headerBtnClassName": "",
                            "header": "<span>Enzymatic Synthesis of CCC-dsDNA</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "",
                            "collapseOthers": true
                        }
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_740",
                        "classname": "project-table scrollable",
                        "wrapperType": "BOX",
                        "id": "wrapper_789",
                        "fields": [
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_318",
                                "classname": "row mb-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_129",
                                "fields": [
                                    {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "purification_kit",
                                                "id": "purification-kit",
                                                "validationButtonType": "",
                                                "classname": "",
                                                "required": false,
                                                "apiEndPoints": [
                                                    {
                                                        "type": "init",
                                                        "method": "post",
                                                        "url": "inventory_item",
                                                        "payload": {
                                                            "user_input": "",
                                                            "perma": "assay-kits"
                                                        }
                                                    }
                                                ],
                                                "options": [],
                                                "multiple": false,
                                                "isClearable": false,
                                                "isStatus": false,
                                                "search": true,
                                                "singleSelect": true,
                                                "showLabel": "value",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": "",
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "purification_kit",
                                                "showInputLabel": true,
                                                "labelContent": "Purification Kit",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_870",
                                        "classname": "col-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_503",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "absorbance",
                                                "id": "absorbance",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "absorbance",
                                                "showInputLabel": true,
                                                "labelContent": "Absorbance (nm)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_972",
                                        "classname": "col-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_395",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_111",
                                        "classname": "col-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_353",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_671",
                                        "classname": "col-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_766",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    }
                                ],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false
                            },
                            {
                                "control": [],
                                "type": "",
                                "name": "dnaExtraction",
                                "classname": "table dna-extraction-table table-top-align",
                                "wrapperType": "TABLE",
                                "id": "wrapper_756",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false,
                                "activeClass": "",
                                "isIteratable": true,
                                "hideSingleItemDelete": false,
                                "tableFields": {
                                    "showHead": true,
                                    "isVertical": false,
                                    "isIteratable": false,
                                    "addButton": {
                                        "classname": "btn btn-primary",
                                        "name": "",
                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                    },
                                    "removeButton": {
                                        "classname": "btn btn-danger",
                                        "name": "",
                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                    },
                                    "tableHead": {
                                        "heading": [
                                            {
                                                "value": "Template ssdna ID",
                                                "classname": ""
                                            },
                                            {
                                                "value": "CDRs",
                                                "classname": ""
                                            },
                                            {
                                                "value": "CCC-dsDNA Name",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Eluent Volume <span class='text-initial'>(μL)</span>",
                                                "classname": ""
                                            },
                                            {
                                                "value": "DNA Conc <span class='text-initial'>(ng/μL)</span>",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Total Yield <span class='text-initial'>(ng)</span>",
                                                "classname": ""
                                            }
                                        ],
                                        "classname": ""
                                    },
                                    "initRecordCount": 1,
                                    "showRecordCount": false,
                                    "recordCounterConfig": {
                                        "classname": "",
                                        "adjValue": "",
                                        "isAdjValueAfter": false
                                    },
                                    "tableRow": {
                                        "name": "",
                                        "classname": "trclass",
                                        "tableCols": [
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "lib_template",
                                                            "id": "template-ssdna-id",
                                                            "value": "template_id",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": true,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "dna_ext_purify_lib_template",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_488",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "head": "",
                                                    "name": "box_563",
                                                    "id": "box_462",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "cdrs",
                                                            "id": "dropDown_330",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": true,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "dna_ext_purify_cdrs",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_488",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "template_cdrs",
                                                    "id": "box_7691",
                                                    "wrapperType": "ITERATE",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "repeat-box",
                                                    "addButton": {
                                                        "classname": "btn",
                                                        "name": "",
                                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                                    },
                                                    "removeButton": {
                                                        "classname": "btn",
                                                        "name": "",
                                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                                    },
                                                    "wrapperClassName": "",
                                                    "ButtonPosition": "top",
                                                    "controlButtonClass": "d-none",
                                                    "hideSingleItemDelete": false,
                                                    "addBtnPostion": null
                                                },
                                                "classname": ""
                                            },
                                            // {
                                            //     "fields": {
                                            //         "control": [
                                            //             // {
                                            //             //     "type": "dropdown",
                                            //             //     "name": "cdrs",
                                            //             //     "id": "dropDown_330",
                                            //             //     "validationButtonType": "",
                                            //             //     "classname": "",
                                            //             //     "required": false,
                                            //             //     "apiEndPoints": [],
                                            //             //     "options": [],
                                            //             //     "multiple": true,
                                            //             //     "isClearable": false,
                                            //             //     "isStatus": false,
                                            //             //     "search": false,
                                            //             //     "singleSelect": true,
                                            //             //     "showLabel": "",
                                            //             //     "errorMessage": {
                                            //             //         "noData": "This fields is required"
                                            //             //     },
                                            //             //     "value": "cdr1",
                                            //             //     "imageUrl": "",
                                            //             //     "disabled": true,
                                            //             //     "wrapperClassName": "",
                                            //             //     "connectedArrays": [],
                                            //             //     "showBlankInPlaceholder": false,
                                            //             //     "blankDataList": [],
                                            //             //     "commonPathOfImage": "",
                                            //             //     "__schema_id": "cdrs",
                                            //             //     "showInputLabel": false,
                                            //             //     "labelContent": "dropdown_533",
                                            //             //     "labelClass": "form-label",
                                            //             //     "isLabelAfter": false,
                                            //             //     "showAdditionalInputContent": false,
                                            //             //     "additionalInputContent": "",
                                            //             //     "isAdditonalInputAfter": true,
                                            //             //     "draggable": false
                                            //             // }

                                            //         ],
                                            //         "name": "box_283",
                                            //         "id": "box_354",
                                            //         "wrapperType": "BOX",
                                            //         "fields": [],
                                            //         "type": "",
                                            //         "classname": "",
                                            //         "addButton": {
                                            //             "classname": "",
                                            //             "value": ""
                                            //         },
                                            //         "removeButton": {
                                            //             "classname": "",
                                            //             "value": ""
                                            //         },
                                            //         "wrapperClassName": ""
                                            //     },
                                            //     "classname": ""
                                            // },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "ccc_dsdna_name",
                                                            "id": "ccc-dsdna-name",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_258",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_266",
                                                    "id": "box_838",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "eluent_vol",
                                                            "id": "eluent-volume_calcDNATotalYield",
                                                            "value": "0.1",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "dna_ext_purify_eluent_vol",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_282",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false,
                                                            "callBackEvents": ['input'
                                                            ]
                                                        }
                                                    ],
                                                    "name": "box_611",
                                                    "id": "box_318",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "dna_conc",
                                                            "id": "dna-conc_calcDNATotalYield",
                                                            "value": "0.1",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "dna_ext_purify_dna_conc",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_655",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false,
                                                            "callBackEvents": ['input'
                                                            ]
                                                        }
                                                    ],
                                                    "name": "box_972",
                                                    "id": "box_3",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "total_yield",
                                                            "id": "total-yield",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": true,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "dna_ext_purify_total_yield",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_612",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_579",
                                                    "id": "box_179",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            }
                                        ]
                                    }
                                }
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "",
                            "headerBtnClassName": "",
                            "header": "<span>DNA Extraction & Purification</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "",
                            "collapseOthers": true
                        }
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_141",
                        "classname": "project-table acrollable",
                        "wrapperType": "BOX",
                        "id": "wrapper_340",
                        "fields": [
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_315",
                                "classname": "row mb-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_516",
                                "fields": [
                                    {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "analysis_using",
                                                "id": "analysis-using",
                                                "validationButtonType": "",
                                                "classname": "",
                                                "required": false,
                                                "apiEndPoints": [
                                                    {
                                                        "type": "init",
                                                        "method": "post",
                                                        "url": "inventory_item",
                                                        "payload": {
                                                            "user_input": "",
                                                            "sub_type_id": 0,
                                                            "perma": "instruments"
                                                        }
                                                    }
                                                ],
                                                "options": [],
                                                "multiple": false,
                                                "isClearable": false,
                                                "isStatus": false,
                                                "search": true,
                                                "singleSelect": true,
                                                "showLabel": "value",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": "",
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "dna_qc_analysis_using",
                                                "showInputLabel": true,
                                                "labelContent": "Analysis Using",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_42",
                                        "classname": "col-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_84",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "no_of_controls",
                                                "id": "number-of-controls",
                                                "validationButtonType": "",
                                                "classname": "",
                                                "required": false,
                                                "apiEndPoints": [],
                                                "options": [],
                                                "multiple": false,
                                                "isClearable": false,
                                                "isStatus": false,
                                                "search": true,
                                                "singleSelect": true,
                                                "showLabel": "",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": "",
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "dna_qc_no_of_controls",
                                                "showInputLabel": true,
                                                "labelContent": "Number Of Controls",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_94",
                                        "classname": "col-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_564",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "ssdna_controls",
                                                "id": "select-the-ssdna-control_qcSsDnaControl",
                                                "validationButtonType": "",
                                                "classname": "",
                                                "required": false,
                                                "apiEndPoints": [],
                                                "options": [],
                                                "multiple": true,
                                                "isClearable": true,
                                                "isStatus": false,
                                                "search": true,
                                                "singleSelect": false,
                                                "showLabel": "ssDnaName",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": '',
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "dna_qc_ssdna_controls",
                                                "showInputLabel": true,
                                                "labelContent": "Select the ssDNA Control",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_962",
                                        "classname": "col-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_211",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_410",
                                        "classname": "col-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_581",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    }
                                ],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false
                            },
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_688",
                                "classname": "col-12",
                                "wrapperType": "BOX",
                                "id": "wrapper_235",
                                "fields": [
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_507",
                                        "classname": "row mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_659",
                                        "fields": [
                                            {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "dna_qc_voltage",
                                                        "id": "voltage-t4",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "dna_qc_voltage",
                                                        "showInputLabel": true,
                                                        "labelContent": "Voltage (V)",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "type": "",
                                                "name": "wrapper_420",
                                                "classname": "col-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_899",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            },
                                            {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "dna_qc_runtime",
                                                        "id": "run-time-t5",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "dna_qc_runtime",
                                                        "showInputLabel": true,
                                                        "labelContent": "Run Time (h)",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "type": "",
                                                "name": "wrapper_776",
                                                "classname": "col-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_909",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            },
                                            {
                                                "control": [],
                                                "type": "",
                                                "name": "wrapper_121",
                                                "classname": "col-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_627",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            },
                                            {
                                                "control": [],
                                                "type": "",
                                                "name": "wrapper_228",
                                                "classname": "col-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_841",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            }
                                        ],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    }
                                ],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false
                            },
                            {
                                "control": [],
                                "type": "",
                                "name": "dna_qc_templateInfo",
                                "classname": "table  dna-qc-table",
                                "wrapperType": "TABLE",
                                "id": "wrapper_264",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false,
                                "hideSingleItemDelete": false,
                                "tableFields": {
                                    "showHead": true,
                                    "isVertical": false,
                                    "isIteratable": false,
                                    "addButton": {
                                        "classname": "btn btn-primary",
                                        "name": "",
                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                    },
                                    "removeButton": {
                                        "classname": "btn btn-danger",
                                        "name": "",
                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                    },
                                    "tableHead": {
                                        "heading": [
                                            {
                                                "value": "CCC-dsDNA Sample",
                                                "classname": ""
                                            },
                                            {
                                                "value": "control ssDna",
                                                "classname": ""
                                            },
                                            {
                                                "value": "QC Status",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Bands Formed",
                                                "classname": ""
                                            }
                                        ],
                                        "classname": ""
                                    },
                                    "initRecordCount": 1,
                                    "showRecordCount": false,
                                    "recordCounterConfig": {
                                        "classname": "",
                                        "adjValue": "",
                                        "isAdjValueAfter": false
                                    },
                                    "tableRow": {
                                        "name": "",
                                        "classname": "trclass",
                                        "tableCols": [
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "ccc_dsdna_sample",
                                                            "id": "ccc-dsdna-sample",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": true,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "dna_qc_ccc_dsdna_sample",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_992",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "head": "",
                                                    "name": "box_512",
                                                    "id": "box_305",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "template_id",
                                                            "id": "ccc-dsdna-name",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": true,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "dna_qc_template_id",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_8",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_714",
                                                    "id": "box_824",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "dropdown",
                                                            "name": "qc_status",
                                                            "id": "qc-status",
                                                            "validationButtonType": "",
                                                            "classname": "",
                                                            "required": false,
                                                            "apiEndPoints": [],
                                                            "options": [
                                                                {
                                                                    "id": 1,
                                                                    "value": "Pass"
                                                                },
                                                                {
                                                                    "id": 2,
                                                                    "value": "Fail"
                                                                }
                                                            ],
                                                            "multiple": true,
                                                            "isClearable": false,
                                                            "isStatus": false,
                                                            "search": true,
                                                            "singleSelect": true,
                                                            "showLabel": "value",
                                                            "errorMessage": {
                                                                "noData": "This fields is required"
                                                            },
                                                            "value": "",
                                                            "imageUrl": "",
                                                            "disabled": false,
                                                            "wrapperClassName": "",
                                                            "connectedArrays": [],
                                                            "showBlankInPlaceholder": false,
                                                            "blankDataList": [],
                                                            "commonPathOfImage": "",
                                                            "__schema_id": "dna_qc_qc_status",
                                                            "showInputLabel": false,
                                                            "labelContent": "dropdown_163",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_92",
                                                    "id": "box_442",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "dropdown",
                                                            "name": "bands_formed",
                                                            "id": "bands-formed",
                                                            "validationButtonType": "",
                                                            "classname": "",
                                                            "required": false,
                                                            "apiEndPoints": [],
                                                            "options": [
                                                                "Product band with heigher electrophoretic mobility",
                                                                "Extended band with lower electrophoretic mobility",
                                                                "No Significant Observations"
                                                            ],
                                                            "multiple": true,
                                                            "isClearable": false,
                                                            "isStatus": false,
                                                            "search": true,
                                                            "singleSelect": true,
                                                            "showLabel": "",
                                                            "errorMessage": {
                                                                "noData": "This fields is required"
                                                            },
                                                            "value": "",
                                                            "imageUrl": "",
                                                            "disabled": false,
                                                            "wrapperClassName": "",
                                                            "connectedArrays": [],
                                                            "showBlankInPlaceholder": false,
                                                            "blankDataList": [],
                                                            "commonPathOfImage": "",
                                                            "__schema_id": "dna_qc_bands_formed",
                                                            "showInputLabel": false,
                                                            "labelContent": "dropdown_250",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_924",
                                                    "id": "box_967",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            }
                                        ]
                                    }
                                }
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "",
                            "headerBtnClassName": "",
                            "header": "<span>DNA QC</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "",
                            "collapseOthers": true
                        }
                    },
                    {
                        "control": [
                            {
                                "type": "text",
                                "name": "comments",
                                "id": "textBox_70",
                                "value": "",
                                "placeholder": "",
                                "multiline": true,
                                "classname": "form-control ",
                                "regularExpression": "",
                                "disabled": false,
                                "required": false,
                                "errorMessage": {
                                    "noData": "This fields is required",
                                    "regexFail": ""
                                },
                                "validationButtonType": "",
                                "rows": 0,
                                "columns": 0,
                                "onDemandError": false,
                                "customValidation": [],
                                "multiError": false,
                                "applicationError": "",
                                "wrapperClassName": "",
                                "requiredErrorMessage": "This fields is required",
                                "__schema_id": "comments",
                                "showInputLabel": false,
                                "labelContent": "text_884",
                                "labelClass": "form-label",
                                "isLabelAfter": false,
                                "showAdditionalInputContent": false,
                                "additionalInputContent": "",
                                "isAdditonalInputAfter": true,
                                "draggable": false
                            }
                        ],
                        "type": "",
                        "name": "wrapper_4",
                        "classname": "col-12",
                        "wrapperType": "BOX",
                        "id": "wrapper_120",
                        "fields": [],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "",
                            "headerBtnClassName": "",
                            "header": "<span>Experiment Notes/Comments</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "",
                            "collapseOthers": true
                        }
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false,
                "isAccordion": false
            }
        ],
        "type": "",
        "classname": "",
        "showClass": true,
        "payload_schema": {
            "enzymatic_synthesis_Purification": {
                "protocol_id": "protocol_id"
            },
            "mutagenic_oligonucleotide_seq": [
                {
                    "lib_template": "lib_template",
                    "lib_template_info": [
                        {
                            "stop_codon_id": "stop_codons",
                            "cdrs_to_random": "cdrs_to_random",
                            "mosu_file_ids": "mosu_file_ids"
                        }
                    ]
                }
            ],
            "phosphorylation": {
                "mutagenic_oligo_amount": "mutagenic_oligo_amount",
                "10x_tm_buffer": "10x_tm_buffer",
                "10mm_atp": "10mm_atp",
                "100mm_dtt": "100mm_dtt",
                "10_pnk_enzyme": "10_pnk_enzyme",
                "phos_inc_time": "phos_inc_time",
                "phos_inc_temp": "phos_inc_temp"
            },
            "annealing": {
                "annealing_temp_info": [
                    {
                        "temperature": "annealing_temperature",
                        "incubation_time": "annealing_incubation_time"
                    }
                ],
                "lib_template_info": [
                    {
                        "lib_template": "annealing_lib_template",
                        "conc_temp_ssdna": "annealing_conc_temp_ssdna",
                        "total_temp_ssdna": "annealing_total_temp_ssdna",
                        "no_of_oligos": "annealing_no_of_oligos",
                        "oligos_details": [
                            {
                                "cdrs": "oligos_details_cdrs",
                                "vol_oligos": "oligos_details_vol_oligos"
                            }
                        ],
                        "10x_tm": "annealing_10x_tm",
                        "total_vol": "annealing_total_vol",
                        "h20": "annealing_h20"
                    }
                ]
            },
            "enzymatic_synthesis_dna": {
                "escsdna_inc_time": "escsdna_inc_time",
                "escsdna_inc_temp": "escsdna_inc_time",
                "lib_template_info": [
                    {
                        "template_id": "enzymatic_template_id",
                        "cdrs": "enzymatic_cdrs",
                        "dntps": "enzymatic_dntps",
                        "atp": "enzymatic_atp",
                        "dtt": "enzymatic_dtt",
                        "t7_polymerase": "enzymatic_t7_polymerase",
                        "t4_ligase": "enzymatic_t4_ligase",
                        "total_vol": "enzymatic_total_vol"
                    }
                ]
            },
            "dna_ext_purify": {
                "purification_kit": "purification_kit",
                "absorbance": "absorbance",
                "lib_template_info": [
                    {
                        "lib_template": "dna_ext_purify_lib_template",
                        "cdrs": "dna_ext_purify_cdrs",
                        "eluent_vol": "dna_ext_purify_eluent_vol",
                        "dna_conc": "dna_ext_purify_dna_conc",
                        "total_yield": "dna_ext_purify_total_yield"
                    }
                ]
            },
            "dna_qc": {
                "analysis_using": "dna_qc_analysis_using",
                "no_of_controls": "dna_qc_no_of_controls",
                "ssdna_controls": "dna_qc_ssdna_controls",
                "dna_qc_voltage": "dna_qc_voltage",
                "dna_qc_runtime": "dna_qc_runtime",
                "lib_template_info": [
                    {
                        "template_id": "dna_qc_template_id",
                        "ccc_dsdna_sample": "dna_qc_ccc_dsdna_sample",
                        "ssdna_controls": "dna_qc_ssdna_controls",
                        "qc_status": "dna_qc_qc_status",
                        "bands_formed": "dna_qc_bands_formed"
                    }
                ]
            },
            "comments": "comments",
            "file_ids": "file_ids"
        }
    },
    // Template file upload
    {
        jsonId: 126,
        control: [],
        head: '',
        name: 'main',
        id: 'main',
        wrapperType: 'REGULAR',
        fields: [
            {
                control: [
                    {
                        type: 'dropdown',
                        showBlankInPlaceholder: true,
                        blankDataList: ['""', 'undefined', 'null'
                        ],
                        name: 'file_type',
                        id: 'file_type',
                        validationButtonType: 'upload',
                        classname: '',
                        required: true,
                        apiEndPoints: [],
                        options: [
                            {
                                key: 'protocol',
                                value: 'Protocol',
                            },
                        ],
                        multiple: false,
                        isClearable: false,
                        isStatus: false,
                        search: true,
                        singleSelect: true,
                        showLabel: 'value',
                        errorMessage: {
                            noData: 'This field is required'
                        },
                        value: {
                            key: 'Sequence File',
                            value: 'Sequence File',
                        },
                        imageUrl: '',
                        disabled: true,
                        wrapperClassName: '',
                        showInputLabel: true,
                        labelContent: 'Select File Type',
                        labelClass: 'form-label',
                        isLabelAfter: false,
                        showAdditionalInputContent: false,
                        additionalInputContent: '',
                        isAdditonalInputAfter: true,
                        draggable: false,
                    },
                ],
                type: '',
                name: 'wrapper_801',
                classname: 'col-12 mb-3 d-none',
                wrapperType: 'BOX',
                id: 'wrapper_766',
                fields: [],
                head: '',
                tabHeadClass: '',
                draggable: false,
            },
            {
                control: [
                    {
                        type: 'file',
                        name: 'file',
                        id: 'file',
                        value: 'upload',
                        classname: 'btn btn-file',
                        fileNameShow: true,
                        size: 10,
                        url: '',
                        functionName: '',
                        wrapperClassName: '',
                        fileCount: 1,
                        sizeKB: '50000',
                        allowMultiple: false,
                        required: true,
                        validationButtonType: 'upload',
                        formDataType: 'blob',
                        errorMsg: {
                            invalidFormat: 'Invalid File Format', invalidSize: 'File Size exceeded'
                        },
                        fileTypes: ['fasta', 'fas', 'fa', 'fna', 'ffn', 'faa', 'mpfa', 'frn', 'ab1', 'txt'
                        ],
                        listClass: '',
                        mainClass: '',
                        btnDivClass: '',
                        showInputLabel: true,
                        labelContent: 'Drag and drop files here or browse files  ',
                        labelClass: 'form-label',
                        requiredErrorMessage: 'This field is required',
                        isLabelAfter: false,
                        showAdditionalInputContent: false,
                        additionalInputContent: '',
                        isAdditonalInputAfter: true,
                        draggable: false,
                        showViewIcon: false,
                        showDownloadIcon: false,
                    },
                ],
                type: '',
                name: 'wrapper_379',
                classname: 'col-12 mb-3 drag-and-drop',
                wrapperType: 'BOX',
                id: 'wrapper_461',
                fields: [],
                head: '',
                tabHeadClass: '',
                draggable: false,
            },
        ],
        type: '',
        classname: '',
    },
]