export const diseaseProgramDynamicJson = [
  {
    jsonId: 143,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_983',
        classname: 'accordion accordion-custom',
        wrapperType: 'ACCORDION',
        id: 'wrapper_197',
        fields: [
          {
            control: [],
            type: '',
            name: 'strainCulture',
            classname: 'accordion accordion-flush accordion-custom',
            wrapperType: 'BOX',
            id: 'strainCulture',
            fields: [
              {
                control: [],
                type: '',
                name: 'wrapper_203',
                classname: 'row',
                wrapperType: 'BOX',
                id: 'wrapper_67',
                fields: [
                  {
                    control: [
                      {
                        type: 'text',
                        name: 'projectTitle',
                        id: 'projectTitle',
                        value: '',
                        placeholder: '',
                        multiline: false,
                        classname: 'form-control ',
                        regularExpression: '',
                        disabled: false,
                        required: true,
                        errorMessage: { noData: 'This fields is required', regexFail: '' },
                        validationButtonType: 'disease_submit',
                        rows: 0,
                        columns: 0,
                        onDemandError: false,
                        customValidation: [],
                        multiError: false,
                        applicationError: '',
                        wrapperClassName: '',
                        requiredErrorMessage: 'This fields is required',
                        __schema_id: '',
                        showInputLabel: true,
                        labelContent: 'Project Title ',
                        labelClass: 'form-label mandatory',
                        isLabelAfter: false,
                        showAdditionalInputContent: false,
                        additionalInputContent: '',
                        isAdditonalInputAfter: true,
                        draggable: false,
                      },
                    ],
                    type: '',
                    name: 'wrapper_300',
                    classname: 'col-9 mb-3',
                    wrapperType: 'BOX',
                    id: 'wrapper_293',
                    fields: [],
                    head: '',
                    tabHeadClass: '',
                  },
                  {
                    control: [
                      {
                        type: 'dropdown',
                        name: 'initiatedBy',
                        id: 'initiatedBy',
                        validationButtonType: 'disease_submit',
                        classname: '',
                        required: false,
                        apiEndPoints: [
                          {
                            url: 'user_management/users_list',
                            type: 'init',
                            method: 'get',
                          },
                        ],
                        options: [],
                        multiple: false,
                        isClearable: false,
                        isStatus: false,
                        search: false,
                        singleSelect: true,
                        showLabel: 'value',
                        errorMessage: { noData: 'This fields is required' },
                        value: '',
                        imageUrl: '',
                        disabled: false,
                        wrapperClassName: '',
                        connectedArrays: [],
                        showBlankInPlaceholder: false,
                        blankDataList: [],
                        commonPathOfImage: '',
                        __schema_id: '',
                        showInputLabel: true,
                        labelContent: 'Initiated By ',
                        labelClass: 'form-label mandatory',
                        isLabelAfter: false,
                        showAdditionalInputContent: false,
                        additionalInputContent: '',
                        isAdditonalInputAfter: true,
                        draggable: false,
                      },
                    ],
                    type: '',
                    name: 'wrapper_553',
                    classname: 'col-3 mb-3',
                    wrapperType: 'BOX',
                    id: 'wrapper_850',
                    fields: [],
                    head: '',
                    tabHeadClass: '',
                  },
                  {
                    control: [
                      {
                        type: 'dropdown',
                        name: 'target',
                        id: 'target',
                        validationButtonType: 'disease_submit',
                        classname: '',
                        required: true,
                        apiEndPoints: [
                          {
                            type: 'init',
                            method: 'post',
                            url: 'masterdata',
                            payload: {
                              data_types: ['target'],
                              inv_type_perma: '',
                            },
                          },
                        ],
                        options: [],
                        multiple: true,
                        isClearable: true,
                        isStatus: false,
                        search: false,
                        singleSelect: false,
                        showLabel: 'value',
                        errorMessage: { noData: 'This fields is required' },
                        value: '',
                        imageUrl: '',
                        disabled: false,
                        wrapperClassName: '',
                        connectedArrays: [],
                        showBlankInPlaceholder: false,
                        blankDataList: [],
                        commonPathOfImage: '',
                        __schema_id: '',
                        showInputLabel: true,
                        labelContent: 'Target ',
                        labelClass: 'form-label mandatory',
                        isLabelAfter: false,
                        showAdditionalInputContent: false,
                        additionalInputContent: '',
                        isAdditonalInputAfter: true,
                        draggable: false,
                      },
                    ],
                    type: '',
                    name: 'wrapper_685',
                    classname: 'col-3 mb-3',
                    wrapperType: 'BOX',
                    id: 'wrapper_27',
                    fields: [],
                    head: '',
                    tabHeadClass: '',
                  },
                  {
                    control: [
                      {
                        type: 'datepicker_v2',
                        name: 'StartDate',
                        id: 'StartDate',
                        validationButtonType: 'disease_submit',
                        range: false,
                        value: '',
                        multiple: false,
                        multiAmount: 3,
                        keyboardInput: false,
                        startDateReadOnly: false,
                        endDateReadOnly: false,
                        fordisabledStart: '',
                        fordisabledEnd: '',
                        timer: false,
                        hourFormat: 24,
                        placeholder: 'DD/MM/YYYY',
                        startDatePlaceHolder: 'DD/MM/YYYY',
                        inputClassName: 'form-control date-input',
                        selectedDateClassName: 'selectedDate',
                        arrowClassName: 'arrow',
                        dayClassName: 'dd',
                        todayClassName: 'now',
                        monthClassName: 'mtn',
                        regularExpression: '^(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[0-2])/([0-9]{4})$',
                        required: true,
                        classname: '',
                        errorMessage: { noData: 'This fields is required', regexFail: 'Date is not valid!!' },
                        dateFormat: 'dd/mm/yyyy',
                        disabled: false,
                        wrapperClassName: '',
                        locale: '',
                        dateSeperator: '',
                        inputDateFormat: false,
                        isHostParent: false,
                        __schema_id: '',
                        showInputLabel: true,
                        labelContent: 'Start Date ',
                        labelClass: 'form-label mandatory',
                        isLabelAfter: false,
                        showAdditionalInputContent: false,
                        additionalInputContent: '',
                        isAdditonalInputAfter: true,
                        draggable: false,
                      },
                    ],
                    type: '',
                    name: 'wrapper_142',
                    classname: 'col-3 mb-3',
                    wrapperType: 'BOX',
                    id: 'wrapper_639',
                    fields: [],
                    head: '',
                    tabHeadClass: '',
                    draggable: false,
                  },
                  {
                    control: [
                      {
                        type: 'datepicker_v2',
                        name: 'EndDate',
                        id: 'EndDate',
                        validationButtonType: 'disease_submit',
                        range: false,
                        value: '',
                        multiple: false,
                        multiAmount: 3,
                        keyboardInput: false,
                        startDateReadOnly: false,
                        endDateReadOnly: false,
                        fordisabledStart: '',
                        fordisabledEnd: '',
                        timer: false,
                        hourFormat: 24,
                        placeholder: 'DD/MM/YYYY',
                        startDatePlaceHolder: 'DD/MM/YYYY',
                        inputClassName: 'form-control date-input',
                        selectedDateClassName: 'selectedDate',
                        arrowClassName: 'arrow',
                        dayClassName: 'dd',
                        todayClassName: 'now',
                        monthClassName: 'mtn',
                        regularExpression: '^(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[0-2])/([0-9]{4})$',
                        required: false,
                        classname: '',
                        errorMessage: { noData: 'This fields is required', regexFail: 'Date is not valid!!' },
                        dateFormat: 'dd/mm/yyyy',
                        disabled: false,
                        wrapperClassName: '',
                        locale: '',
                        dateSeperator: '',
                        inputDateFormat: false,
                        isHostParent: false,
                        __schema_id: '',
                        showInputLabel: true,
                        labelContent: 'End Date',
                        labelClass: 'form-label ',
                        isLabelAfter: false,
                        showAdditionalInputContent: false,
                        additionalInputContent: '',
                        isAdditonalInputAfter: true,
                        draggable: false,
                      },
                    ],
                    type: '',
                    name: 'wrapper_951',
                    classname: 'col-3 mb-3',
                    wrapperType: 'BOX',
                    id: 'wrapper_787',
                    fields: [],
                    head: '',
                    tabHeadClass: '',
                    draggable: false,
                  },
                ],
                head: '',
                tabHeadClass: '',
                draggable: false,
              },
            ],
            head: '',
            tabHeadClass: '',
            draggable: false,
            isAccordion: true,
            accordionConfig: {
              headerClassName: '',
              headerBtnClassName: '',
              header: '<span>Project Details</span>',
              collapseDivClassName: '',
              bodyClassName: 'row',
              collapseOthers: true,
            },
          },
          {
            control: [],
            type: '',
            name: 'wrapper_115',
            classname: 'col-12 project-table study-allocation-table',
            wrapperType: 'BOX',
            id: 'wrapper_98',
            fields: [
              {
                control: [
                  {
                    type: 'button',
                    id: 'button_220',
                    value: 'button',
                    buttonType: '',
                    classname: 'btn btn-primary',
                    divClass: '',
                    controlEndPoints: [],
                    disabled: false,
                    validationEndPoints: [],
                    invalid: false,
                    name: 'button_700',
                    wrapperClassName: '',
                    draggable: false,
                  },
                ],
                type: '',
                name: 'wrapper_290',
                classname: 'table project-ini-table',
                wrapperType: 'TABLE',
                id: 'wrapper_226',
                fields: [],
                head: '',
                tabHeadClass: '',
                draggable: false,
                hideSingleItemDelete: false,
                tableFields: {
                  showHead: true,
                  isVertical: false,
                  isIteratable: true,
                  addButton: {
                    classname: 'add-expand',
                    name: '',
                    value: '',
                  },
                  removeButton: {
                    classname: 'add-minus',
                    name: '',
                    value: '',
                  },
                  tableHead: {
                    heading: [
                      { value: 'Select the study', classname: 'mandatory' },
                      { value: 'Select the Experiments ', classname: 'mandatory' },
                      { value: 'ACTION', classname: '' },
                      {
                        value: '',
                        classname: '',
                      },
                    ],
                    classname: '',
                  },
                  initRecordCount: 1,
                  showRecordCount: false,
                  recordCounterConfig: { classname: '', adjValue: '', isAdjValueAfter: false },
                  tableRow: {
                    name: '',
                    classname: 'trclass',
                    tableCols: [
                      {
                        fields: {
                          control: [
                            {
                              type: 'dropdown',
                              name: 'studySelect',
                              id: 'studySelect',
                              validationButtonType: 'disease_submit',
                              classname: '',
                              required: true,
                              apiEndPoints: [
                                {
                                  type: 'init',
                                  method: 'get',
                                  url: 'studies?from=1&size=25',
                                },
                                {
                                  type: 'change',
                                  method: 'get',
                                  Mapto: [
                                    {
                                      id: 'main.wrapper_197.wrapper_98.wrapper_226.${i}.box_210.selectExperiment',
                                      displayKey: null,
                                      urlParameterValues: { id: null },
                                    },
                                  ],
                                  url: 'studies/',
                                },
                              ],
                              options: [],
                              multiple: false,
                              isClearable: false,
                              isStatus: false,
                              search: false,
                              singleSelect: true,
                              showLabel: 'study_code',
                              errorMessage: { noData: 'This fields is required' },
                              value: '',
                              imageUrl: '',
                              disabled: false,
                              wrapperClassName: '',
                              connectedArrays: [],
                              showBlankInPlaceholder: false,
                              blankDataList: [],
                              commonPathOfImage: '',
                              __schema_id: '',
                              showInputLabel: true,
                              labelContent: '',
                              labelClass: 'form-label',
                              isLabelAfter: false,
                              showAdditionalInputContent: false,
                              additionalInputContent: '',
                              isAdditonalInputAfter: true,
                              draggable: false,
                            },
                          ],
                          head: '',
                          name: 'box_794',
                          id: 'box_514',
                          wrapperType: 'BOX',
                          fields: [],
                          type: '',
                          classname: '',
                        },
                        classname: '',
                      },
                      {
                        fields: {
                          control: [
                            {
                              type: 'dropdown',
                              name: 'selectExperiment',
                              id: 'selectExperiment',
                              validationButtonType: 'disease_submit',
                              classname: '',
                              required: true,
                              apiEndPoints: [],
                              options: [],
                              multiple: true,

                              isClearable: true,
                              isStatus: false,
                              search: false,
                              singleSelect: false,
                              showLabel: 'exp_title',
                              errorMessage: { noData: 'This fields is required' },
                              value: '',
                              imageUrl: '',
                              disabled: false,
                              wrapperClassName: '',
                              connectedArrays: [],
                              showBlankInPlaceholder: false,
                              blankDataList: [],
                              commonPathOfImage: '',
                              __schema_id: '',
                              showInputLabel: true,
                              labelContent: '',
                              labelClass: 'form-label ',
                              isLabelAfter: false,
                              showAdditionalInputContent: false,
                              additionalInputContent: '',
                              isAdditonalInputAfter: true,
                              draggable: false,
                            },
                          ],
                          name: 'box_778',
                          id: 'box_210',
                          wrapperType: 'BOX',
                          fields: [],
                          type: '',
                          classname: '',
                          addButton: { classname: '', value: '' },
                          removeButton: { classname: '', value: '' },
                          wrapperClassName: '',
                        },
                        classname: '',
                      },
                      {
                        fields: {
                          control: [
                            // {
                            //   type: 'button',
                            //   id: 'button_530',
                            //   value: '<div class="custom-tooltip"><p>View </p></div>',
                            //   buttonType: '',
                            //   classname: 'view',
                            //   divClass: '',
                            //   controlEndPoints: [],
                            //   disabled: false,
                            //   validationEndPoints: [],
                            //   invalid: false,
                            //   name: 'button_563',
                            //   wrapperClassName: 'actions',
                            //   draggable: false,
                            // },
                          ],
                          name: 'box_869',
                          id: 'box_724',
                          wrapperType: 'BOX',
                          fields: [],
                          type: '',
                          classname: '',
                          addButton: { classname: '', value: '' },
                          removeButton: { classname: '', value: '' },
                          wrapperClassName: '',
                        },
                        classname: '',
                      },
                    ],
                  },
                },
              },
            ],
            head: '',
            tabHeadClass: '',
            draggable: false,
            isAccordion: true,
            accordionConfig: {
              headerClassName: 'plague-formation',
              headerBtnClassName: '',
              header: '<span>Study Allocation</span>',
              collapseDivClassName: '',
              bodyClassName: 'row',
              collapseOthers: true,
            },
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
        addBtnPostion: null,
        isAccordion: true,
        accordionConfig: {
          headerClassName: '',
          headerBtnClassName: '',
          header: '',
          collapseDivClassName: '',
          bodyClassName: '',
          collapseOthers: true,
        },
      },
    ],
    type: '',
    classname: 'form-wrapper',
    showClass: true,
  },

  // {
  //   control: [],
  //   jsonId: 144,
  //   head: '',
  //   name: 'main',
  //   id: 'main',
  //   wrapperType: 'REGULAR',
  //   fields: [
  //     {
  //       control: [],
  //       type: '',
  //       name: 'wrapper_184',
  //       classname: 'row',
  //       wrapperType: 'BOX',
  //       id: 'wrapper_502',
  //       fields: [
  //         {
  //           control: [
  //             {
  //               type: 'label',
  //               name: '<span>Protocol Information</span>',
  //               classname: 'add-heading heading-without-icon  w-100',
  //               for: '',
  //               required: false,
  //               wrapperClassName: '',
  //               id: 'label_203',
  //               draggable: false,
  //             },
  //           ],
  //           type: '',
  //           name: 'wrapper_702',
  //           classname: 'col-12 mb-3',
  //           wrapperType: 'BOX',
  //           id: 'wrapper_162',
  //           fields: [],
  //           head: '',
  //           tabHeadClass: '',
  //         },
  //         {
  //           control: [],
  //           type: '',
  //           name: 'wrapper_609',
  //           classname: 'tab-wrapper d-block mt-0 form-wrapper',
  //           wrapperType: 'TAB',
  //           id: 'wrapper_761',
  //           "fields": [
  //             {
  //               "control": [],
  //               "type": "",
  //               "name": "Antibody Production & Purification Study",
  //               "classname": "nav-link ",
  //               "wrapperType": "BOX",
  //               "id": "wrapper_660",
  //               "fields": [
  //                 {
  //                   "control": [],
  //                   "type": "",
  //                   "name": "wrapper_694",
  //                   "classname": "tab-content",
  //                   "wrapperType": "BOX",
  //                   "id": "wrapper_988",
  //                   "fields": [
  //                     {
  //                       "control": [],
  //                       "type": "",
  //                       "name": "wrapper_716",
  //                       "classname": "col-12 project-table",
  //                       "wrapperType": "BOX",
  //                       "id": "wrapper_421",
  //                       "fields": [
  //                         {
  //                           "control": [
  //                             {
  //                               "type": "label",
  //                               "name": "<span>Experiments</span>",
  //                               "classname": "add-heading heading-without-icon w-100",
  //                               "for": "",
  //                               "required": false,
  //                               "wrapperClassName": "",
  //                               "id": "label_165",
  //                               "draggable": false
  //                             }
  //                           ],
  //                           "type": "",
  //                           "name": "wrapper_272",
  //                           "classname": "",
  //                           "wrapperType": "BOX",
  //                           "id": "wrapper_804",
  //                           "fields": [],
  //                           "head": "",
  //                           "tabHeadClass": "",
  //                           "draggable": false
  //                         },
  //                         {
  //                           "control": [
  //                             {
  //                               "type": "text",
  //                               "name": "textBox_919",
  //                               "id": "textBox_12",
  //                               "value": "",
  //                               "placeholder": "",
  //                               "multiline": false,
  //                               "classname": "form-control ",
  //                               "regularExpression": "",
  //                               "disabled": false,
  //                               "required": false,
  //                               "errorMessage": {
  //                                 "noData": "This fields is required",
  //                                 "regexFail": ""
  //                               },
  //                               "validationButtonType": "",
  //                               "rows": 0,
  //                               "columns": 0,
  //                               "onDemandError": false,
  //                               "customValidation": [],
  //                               "multiError": false,
  //                               "applicationError": "",
  //                               "wrapperClassName": "",
  //                               "requiredErrorMessage": "This fields is required",
  //                               "__schema_id": "",
  //                               "showInputLabel": true,
  //                               "labelContent": "text_429",
  //                               "labelClass": "form-label",
  //                               "isLabelAfter": false,
  //                               "showAdditionalInputContent": false,
  //                               "additionalInputContent": "",
  //                               "isAdditonalInputAfter": true,
  //                               "draggable": false
  //                             }
  //                           ],
  //                           "type": "",
  //                           "name": "wrapper_707",
  //                           "classname": " table  ",
  //                           "wrapperType": "TABLE",
  //                           "id": "wrapper_112",
  //                           "fields": [],
  //                           "head": "",
  //                           "tabHeadClass": "",
  //                           "draggable": false,
  //                           "hideSingleItemDelete": false,
  //                           "tableFields": {
  //                             "showHead": true,
  //                             "isVertical": false,
  //                             "isIteratable": false,
  //                             "addButton": {
  //                               "classname": "btn btn-primary",
  //                               "name": "",
  //                               "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
  //                             },
  //                             "removeButton": {
  //                               "classname": "btn btn-danger",
  //                               "name": "",
  //                               "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
  //                             },
  //                             "tableHead": {
  //                               "heading": [
  //                                 {
  //                                   "value": "Select Experiment",
  //                                   "classname": ""
  //                                 },
  //                                 {
  //                                   "value": "Experiment Protocol",
  //                                   "classname": ""
  //                                 },
  //                                 {
  //                                   "value": "Proposed By",
  //                                   "classname": ""
  //                                 },
  //                                 {
  //                                   "value": "Version",
  //                                   "classname": ""
  //                                 }
  //                               ],
  //                               "classname": ""
  //                             },
  //                             "initRecordCount": 1,
  //                             "showRecordCount": false,
  //                             "recordCounterConfig": {
  //                               "classname": "",
  //                               "adjValue": "",
  //                               "isAdjValueAfter": false
  //                             },
  //                             "tableRow": {
  //                               "name": "",
  //                               "classname": "trclass",
  //                               "tableCols": [
  //                                 {
  //                                   "fields": {
  //                                     "control": [
  //                                       {
  //                                         "type": "dropdown",
  //                                         "name": "dropDown_575",
  //                                         "id": "dropDown_987",
  //                                         "validationButtonType": "",
  //                                         "classname": "",
  //                                         "required": false,
  //                                         "apiEndPoints": [],
  //                                         "options": [
  //                                           "Vo1_BS Creation"
  //                                         ],
  //                                         "multiple": false,
  //                                         "isClearable": false,
  //                                         "isStatus": false,
  //                                         "search": false,
  //                                         "singleSelect": true,
  //                                         "showLabel": "",
  //                                         "errorMessage": {
  //                                           "noData": "This fields is required"
  //                                         },
  //                                         "value": "",
  //                                         "imageUrl": "",
  //                                         "disabled": false,
  //                                         "wrapperClassName": "",
  //                                         "connectedArrays": [],
  //                                         "showBlankInPlaceholder": false,
  //                                         "blankDataList": [],
  //                                         "commonPathOfImage": "",
  //                                         "__schema_id": "",
  //                                         "showInputLabel": false,
  //                                         "labelContent": "",
  //                                         "labelClass": "form-label",
  //                                         "isLabelAfter": false,
  //                                         "showAdditionalInputContent": false,
  //                                         "additionalInputContent": "",
  //                                         "isAdditonalInputAfter": true,
  //                                         "draggable": false
  //                                       }
  //                                     ],
  //                                     "head": "",
  //                                     "name": "box_852",
  //                                     "id": "box_541",
  //                                     "wrapperType": "BOX",
  //                                     "fields": [],
  //                                     "type": "",
  //                                     "classname": ""
  //                                   },
  //                                   "classname": ""
  //                                 },
  //                                 {
  //                                   "fields": {
  //                                     "control": [
  //                                       {
  //                                         "type": "dropdown",
  //                                         "name": "dropDown_576",
  //                                         "id": "dropDown_988",
  //                                         "validationButtonType": "",
  //                                         "classname": "",
  //                                         "required": false,
  //                                         "apiEndPoints": [],
  //                                         "options": [
  //                                           "Vo1_BS Creation"
  //                                         ],
  //                                         "multiple": false,
  //                                         "isClearable": false,
  //                                         "isStatus": false,
  //                                         "search": false,
  //                                         "singleSelect": true,
  //                                         "showLabel": "",
  //                                         "errorMessage": {
  //                                           "noData": "This fields is required"
  //                                         },
  //                                         "value": "",
  //                                         "imageUrl": "",
  //                                         "disabled": false,
  //                                         "wrapperClassName": "",
  //                                         "connectedArrays": [],
  //                                         "showBlankInPlaceholder": false,
  //                                         "blankDataList": [],
  //                                         "commonPathOfImage": "",
  //                                         "__schema_id": "",
  //                                         "showInputLabel": false,
  //                                         "labelContent": "",
  //                                         "labelClass": "form-label",
  //                                         "isLabelAfter": false,
  //                                         "showAdditionalInputContent": false,
  //                                         "additionalInputContent": "",
  //                                         "isAdditonalInputAfter": true,
  //                                         "draggable": false
  //                                       }
  //                                     ],
  //                                     "head": "",
  //                                     "name": "box_853",
  //                                     "id": "box_542",
  //                                     "wrapperType": "BOX",
  //                                     "fields": [],
  //                                     "type": "",
  //                                     "classname": ""
  //                                   },
  //                                   "classname": ""
  //                                 },
  //                                 {
  //                                   "fields": {
  //                                     "control": [
  //                                       {
  //                                         "type": "dropdown",
  //                                         "name": "dropDown_96",
  //                                         "id": "dropDown_249",
  //                                         "validationButtonType": "",
  //                                         "classname": "",
  //                                         "required": false,
  //                                         "apiEndPoints": [],
  //                                         "options": [
  //                                           "Username"
  //                                         ],
  //                                         "multiple": false,
  //                                         "isClearable": false,
  //                                         "isStatus": false,
  //                                         "search": false,
  //                                         "singleSelect": true,
  //                                         "showLabel": "",
  //                                         "errorMessage": {
  //                                           "noData": "This fields is required"
  //                                         },
  //                                         "value": "",
  //                                         "imageUrl": "",
  //                                         "disabled": true,
  //                                         "wrapperClassName": "",
  //                                         "connectedArrays": [],
  //                                         "showBlankInPlaceholder": false,
  //                                         "blankDataList": [],
  //                                         "commonPathOfImage": "",
  //                                         "__schema_id": "",
  //                                         "showInputLabel": false,
  //                                         "labelContent": "",
  //                                         "labelClass": "form-label",
  //                                         "isLabelAfter": false,
  //                                         "showAdditionalInputContent": false,
  //                                         "additionalInputContent": "",
  //                                         "isAdditonalInputAfter": true,
  //                                         "draggable": false
  //                                       }
  //                                     ],
  //                                     "name": "box_121",
  //                                     "id": "box_9",
  //                                     "wrapperType": "BOX",
  //                                     "fields": [],
  //                                     "type": "",
  //                                     "classname": "",
  //                                     "addButton": {
  //                                       "classname": "",
  //                                       "value": ""
  //                                     },
  //                                     "removeButton": {
  //                                       "classname": "",
  //                                       "value": ""
  //                                     },
  //                                     "wrapperClassName": ""
  //                                   },
  //                                   "classname": ""
  //                                 },
  //                                 {
  //                                   "fields": {
  //                                     "control": [
  //                                       {
  //                                         "type": "text",
  //                                         "name": "textBox_103",
  //                                         "id": "textBox_878",
  //                                         "value": "v2.0",
  //                                         "placeholder": "",
  //                                         "multiline": false,
  //                                         "classname": "form-control ",
  //                                         "regularExpression": "",
  //                                         "disabled": true,
  //                                         "required": false,
  //                                         "errorMessage": {
  //                                           "noData": "This fields is required",
  //                                           "regexFail": ""
  //                                         },
  //                                         "validationButtonType": "",
  //                                         "rows": 0,
  //                                         "columns": 0,
  //                                         "onDemandError": false,
  //                                         "customValidation": [],
  //                                         "multiError": false,
  //                                         "applicationError": "",
  //                                         "wrapperClassName": "",
  //                                         "requiredErrorMessage": "This fields is required",
  //                                         "__schema_id": "",
  //                                         "showInputLabel": false,
  //                                         "labelContent": "",
  //                                         "labelClass": "form-label",
  //                                         "isLabelAfter": false,
  //                                         "showAdditionalInputContent": false,
  //                                         "additionalInputContent": "",
  //                                         "isAdditonalInputAfter": true,
  //                                         "draggable": false
  //                                       }
  //                                     ],
  //                                     "name": "box_243",
  //                                     "id": "box_691",
  //                                     "wrapperType": "BOX",
  //                                     "fields": [],
  //                                     "type": "",
  //                                     "classname": "",
  //                                     "addButton": {
  //                                       "classname": "",
  //                                       "value": ""
  //                                     },
  //                                     "removeButton": {
  //                                       "classname": "",
  //                                       "value": ""
  //                                     },
  //                                     "wrapperClassName": ""
  //                                   },
  //                                   "classname": ""
  //                                 }
  //                               ]
  //                             },

  //                           }
  //                         }
  //                       ],
  //                       "head": "",
  //                       "tabHeadClass": "",
  //                       "draggable": false
  //                     },
  //                     {
  //                       "control": [
  //                         {
  //                           "type": "text",
  //                           "name": "Comment",
  //                           "id": "Comment",
  //                           "value": "",
  //                           "placeholder": "",
  //                           "multiline": true,
  //                           "classname": "form-control ",
  //                           "regularExpression": "",
  //                           "disabled": false,
  //                           "required": false,
  //                           "errorMessage": {
  //                             "noData": "This fields is required",
  //                             "regexFail": ""
  //                           },
  //                           "validationButtonType": "",
  //                           "rows": 0,
  //                           "columns": 0,
  //                           "onDemandError": false,
  //                           "customValidation": [],
  //                           "multiError": false,
  //                           "applicationError": "",
  //                           "wrapperClassName": "",
  //                           "requiredErrorMessage": "This fields is required",
  //                           "__schema_id": "",
  //                           "showInputLabel": true,
  //                           "labelContent": "Comment",
  //                           "labelClass": "form-label",
  //                           "isLabelAfter": false,
  //                           "showAdditionalInputContent": false,
  //                           "additionalInputContent": "",
  //                           "isAdditonalInputAfter": true,
  //                           "draggable": false
  //                         }
  //                       ],
  //                       "type": "",
  //                       "name": "wrapper_648",
  //                       "classname": "col-12 mb-3",
  //                       "wrapperType": "BOX",
  //                       "id": "wrapper_637",
  //                       "fields": [],
  //                       "head": "",
  //                       "tabHeadClass": "",
  //                       "draggable": false
  //                     }
  //                   ],
  //                   "head": "",
  //                   "tabHeadClass": "",
  //                   "draggable": false
  //                 }
  //               ],
  //               "head": ""
  //             },
  //             {
  //               "control": [],
  //               "type": "",
  //               "name": "Phenotypic Cell Based Study",
  //               "classname": "nav-link ",
  //               "wrapperType": "BOX",
  //               "id": "wrapper_303",
  //               "fields": [
  //                 {
  //                   "control": [],
  //                   "type": "",
  //                   "name": "wrapper_242",
  //                   "classname": "tab-content",
  //                   "wrapperType": "BOX",
  //                   "id": "wrapper_498",
  //                   "fields": [
  //                     {
  //                       "control": [],
  //                       "type": "",
  //                       "name": "wrapper_716",
  //                       "classname": "col-12 project-table",
  //                       "wrapperType": "BOX",
  //                       "id": "wrapper_421",
  //                       "fields": [
  //                         {
  //                           "control": [
  //                             {
  //                               "type": "label",
  //                               "name": "<span>Experiments</span>",
  //                               "classname": "add-heading heading-without-icon w-100",
  //                               "for": "",
  //                               "required": false,
  //                               "wrapperClassName": "",
  //                               "id": "label_165",
  //                               "draggable": false
  //                             }
  //                           ],
  //                           "type": "",
  //                           "name": "wrapper_272",
  //                           "classname": "",
  //                           "wrapperType": "BOX",
  //                           "id": "wrapper_804",
  //                           "fields": [],
  //                           "head": "",
  //                           "tabHeadClass": "",
  //                           "draggable": false
  //                         },
  //                         {
  //                           "control": [
  //                             {
  //                               "type": "text",
  //                               "name": "textBox_919",
  //                               "id": "textBox_12",
  //                               "value": "",
  //                               "placeholder": "",
  //                               "multiline": false,
  //                               "classname": "form-control ",
  //                               "regularExpression": "",
  //                               "disabled": false,
  //                               "required": false,
  //                               "errorMessage": {
  //                                 "noData": "This fields is required",
  //                                 "regexFail": ""
  //                               },
  //                               "validationButtonType": "",
  //                               "rows": 0,
  //                               "columns": 0,
  //                               "onDemandError": false,
  //                               "customValidation": [],
  //                               "multiError": false,
  //                               "applicationError": "",
  //                               "wrapperClassName": "",
  //                               "requiredErrorMessage": "This fields is required",
  //                               "__schema_id": "",
  //                               "showInputLabel": true,
  //                               "labelContent": "text_429",
  //                               "labelClass": "form-label",
  //                               "isLabelAfter": false,
  //                               "showAdditionalInputContent": false,
  //                               "additionalInputContent": "",
  //                               "isAdditonalInputAfter": true,
  //                               "draggable": false
  //                             }
  //                           ],
  //                           "type": "",
  //                           "name": "wrapper_707",
  //                           "classname": " table  ",
  //                           "wrapperType": "TABLE",
  //                           "id": "wrapper_112",
  //                           "fields": [],
  //                           "head": "",
  //                           "tabHeadClass": "",
  //                           "draggable": false,
  //                           "hideSingleItemDelete": false,
  //                           "tableFields": {
  //                             "showHead": true,
  //                             "isVertical": false,
  //                             "isIteratable": false,
  //                             "addButton": {
  //                               "classname": "btn btn-primary",
  //                               "name": "",
  //                               "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
  //                             },
  //                             "removeButton": {
  //                               "classname": "btn btn-danger",
  //                               "name": "",
  //                               "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
  //                             },
  //                             "tableHead": {
  //                               "heading": [
  //                                 {
  //                                   "value": "Select Experiment",
  //                                   "classname": ""
  //                                 },
  //                                 {
  //                                   "value": "Experiment Protocol",
  //                                   "classname": ""
  //                                 },
  //                                 {
  //                                   "value": "Proposed By",
  //                                   "classname": ""
  //                                 },
  //                                 {
  //                                   "value": "Version",
  //                                   "classname": ""
  //                                 }
  //                               ],
  //                               "classname": ""
  //                             },
  //                             "initRecordCount": 1,
  //                             "showRecordCount": false,
  //                             "recordCounterConfig": {
  //                               "classname": "",
  //                               "adjValue": "",
  //                               "isAdjValueAfter": false
  //                             },
  //                             "tableRow": {
  //                               "name": "",
  //                               "classname": "trclass",
  //                               "tableCols": [
  //                                 {
  //                                   "fields": {
  //                                     "control": [
  //                                       {
  //                                         "type": "dropdown",
  //                                         "name": "dropDown_575",
  //                                         "id": "dropDown_987",
  //                                         "validationButtonType": "",
  //                                         "classname": "",
  //                                         "required": false,
  //                                         "apiEndPoints": [],
  //                                         "options": [
  //                                           "Vo1_BS Creation"
  //                                         ],
  //                                         "multiple": false,
  //                                         "isClearable": false,
  //                                         "isStatus": false,
  //                                         "search": false,
  //                                         "singleSelect": true,
  //                                         "showLabel": "",
  //                                         "errorMessage": {
  //                                           "noData": "This fields is required"
  //                                         },
  //                                         "value": "",
  //                                         "imageUrl": "",
  //                                         "disabled": false,
  //                                         "wrapperClassName": "",
  //                                         "connectedArrays": [],
  //                                         "showBlankInPlaceholder": false,
  //                                         "blankDataList": [],
  //                                         "commonPathOfImage": "",
  //                                         "__schema_id": "",
  //                                         "showInputLabel": false,
  //                                         "labelContent": "",
  //                                         "labelClass": "form-label",
  //                                         "isLabelAfter": false,
  //                                         "showAdditionalInputContent": false,
  //                                         "additionalInputContent": "",
  //                                         "isAdditonalInputAfter": true,
  //                                         "draggable": false
  //                                       }
  //                                     ],
  //                                     "head": "",
  //                                     "name": "box_852",
  //                                     "id": "box_541",
  //                                     "wrapperType": "BOX",
  //                                     "fields": [],
  //                                     "type": "",
  //                                     "classname": ""
  //                                   },
  //                                   "classname": ""
  //                                 },
  //                                 {
  //                                   "fields": {
  //                                     "control": [
  //                                       {
  //                                         "type": "dropdown",
  //                                         "name": "dropDown_576",
  //                                         "id": "dropDown_988",
  //                                         "validationButtonType": "",
  //                                         "classname": "",
  //                                         "required": false,
  //                                         "apiEndPoints": [],
  //                                         "options": [
  //                                           "Vo1_BS Creation"
  //                                         ],
  //                                         "multiple": false,
  //                                         "isClearable": false,
  //                                         "isStatus": false,
  //                                         "search": false,
  //                                         "singleSelect": true,
  //                                         "showLabel": "",
  //                                         "errorMessage": {
  //                                           "noData": "This fields is required"
  //                                         },
  //                                         "value": "",
  //                                         "imageUrl": "",
  //                                         "disabled": false,
  //                                         "wrapperClassName": "",
  //                                         "connectedArrays": [],
  //                                         "showBlankInPlaceholder": false,
  //                                         "blankDataList": [],
  //                                         "commonPathOfImage": "",
  //                                         "__schema_id": "",
  //                                         "showInputLabel": false,
  //                                         "labelContent": "",
  //                                         "labelClass": "form-label",
  //                                         "isLabelAfter": false,
  //                                         "showAdditionalInputContent": false,
  //                                         "additionalInputContent": "",
  //                                         "isAdditonalInputAfter": true,
  //                                         "draggable": false
  //                                       }
  //                                     ],
  //                                     "head": "",
  //                                     "name": "box_853",
  //                                     "id": "box_542",
  //                                     "wrapperType": "BOX",
  //                                     "fields": [],
  //                                     "type": "",
  //                                     "classname": ""
  //                                   },
  //                                   "classname": ""
  //                                 },
  //                                 {
  //                                   "fields": {
  //                                     "control": [
  //                                       {
  //                                         "type": "dropdown",
  //                                         "name": "dropDown_96",
  //                                         "id": "dropDown_249",
  //                                         "validationButtonType": "",
  //                                         "classname": "",
  //                                         "required": false,
  //                                         "apiEndPoints": [],
  //                                         "options": [
  //                                           "Username"
  //                                         ],
  //                                         "multiple": false,
  //                                         "isClearable": false,
  //                                         "isStatus": false,
  //                                         "search": false,
  //                                         "singleSelect": true,
  //                                         "showLabel": "",
  //                                         "errorMessage": {
  //                                           "noData": "This fields is required"
  //                                         },
  //                                         "value": "",
  //                                         "imageUrl": "",
  //                                         "disabled": true,
  //                                         "wrapperClassName": "",
  //                                         "connectedArrays": [],
  //                                         "showBlankInPlaceholder": false,
  //                                         "blankDataList": [],
  //                                         "commonPathOfImage": "",
  //                                         "__schema_id": "",
  //                                         "showInputLabel": false,
  //                                         "labelContent": "",
  //                                         "labelClass": "form-label",
  //                                         "isLabelAfter": false,
  //                                         "showAdditionalInputContent": false,
  //                                         "additionalInputContent": "",
  //                                         "isAdditonalInputAfter": true,
  //                                         "draggable": false
  //                                       }
  //                                     ],
  //                                     "name": "box_121",
  //                                     "id": "box_9",
  //                                     "wrapperType": "BOX",
  //                                     "fields": [],
  //                                     "type": "",
  //                                     "classname": "",
  //                                     "addButton": {
  //                                       "classname": "",
  //                                       "value": ""
  //                                     },
  //                                     "removeButton": {
  //                                       "classname": "",
  //                                       "value": ""
  //                                     },
  //                                     "wrapperClassName": ""
  //                                   },
  //                                   "classname": ""
  //                                 },
  //                                 {
  //                                   "fields": {
  //                                     "control": [
  //                                       {
  //                                         "type": "text",
  //                                         "name": "textBox_103",
  //                                         "id": "textBox_878",
  //                                         "value": "v2.0",
  //                                         "placeholder": "",
  //                                         "multiline": false,
  //                                         "classname": "form-control ",
  //                                         "regularExpression": "",
  //                                         "disabled": true,
  //                                         "required": false,
  //                                         "errorMessage": {
  //                                           "noData": "This fields is required",
  //                                           "regexFail": ""
  //                                         },
  //                                         "validationButtonType": "",
  //                                         "rows": 0,
  //                                         "columns": 0,
  //                                         "onDemandError": false,
  //                                         "customValidation": [],
  //                                         "multiError": false,
  //                                         "applicationError": "",
  //                                         "wrapperClassName": "",
  //                                         "requiredErrorMessage": "This fields is required",
  //                                         "__schema_id": "",
  //                                         "showInputLabel": false,
  //                                         "labelContent": "",
  //                                         "labelClass": "form-label",
  //                                         "isLabelAfter": false,
  //                                         "showAdditionalInputContent": false,
  //                                         "additionalInputContent": "",
  //                                         "isAdditonalInputAfter": true,
  //                                         "draggable": false
  //                                       }
  //                                     ],
  //                                     "name": "box_243",
  //                                     "id": "box_691",
  //                                     "wrapperType": "BOX",
  //                                     "fields": [],
  //                                     "type": "",
  //                                     "classname": "",
  //                                     "addButton": {
  //                                       "classname": "",
  //                                       "value": ""
  //                                     },
  //                                     "removeButton": {
  //                                       "classname": "",
  //                                       "value": ""
  //                                     },
  //                                     "wrapperClassName": ""
  //                                   },
  //                                   "classname": ""
  //                                 }
  //                               ]
  //                             },

  //                           }
  //                         }
  //                       ],
  //                       "head": "",
  //                       "tabHeadClass": "",
  //                       "draggable": false
  //                     },
  //                     {
  //                       "control": [
  //                         {
  //                           "type": "text",
  //                           "name": "Comment",
  //                           "id": "Comment",
  //                           "value": "",
  //                           "placeholder": "",
  //                           "multiline": true,
  //                           "classname": "form-control ",
  //                           "regularExpression": "",
  //                           "disabled": false,
  //                           "required": false,
  //                           "errorMessage": {
  //                             "noData": "This fields is required",
  //                             "regexFail": ""
  //                           },
  //                           "validationButtonType": "",
  //                           "rows": 0,
  //                           "columns": 0,
  //                           "onDemandError": false,
  //                           "customValidation": [],
  //                           "multiError": false,
  //                           "applicationError": "",
  //                           "wrapperClassName": "",
  //                           "requiredErrorMessage": "This fields is required",
  //                           "__schema_id": "",
  //                           "showInputLabel": true,
  //                           "labelContent": "Comment",
  //                           "labelClass": "form-label",
  //                           "isLabelAfter": false,
  //                           "showAdditionalInputContent": false,
  //                           "additionalInputContent": "",
  //                           "isAdditonalInputAfter": true,
  //                           "draggable": false
  //                         }
  //                       ],
  //                       "type": "",
  //                       "name": "wrapper_648",
  //                       "classname": "col-12 mb-3",
  //                       "wrapperType": "BOX",
  //                       "id": "wrapper_637",
  //                       "fields": [],
  //                       "head": "",
  //                       "tabHeadClass": "",
  //                       "draggable": false
  //                     }
  //                   ],
  //                   "head": ""
  //                 }
  //               ],
  //               "head": ""
  //             }
  //           ],
  //           head: '',
  //           tabHeadClass: ' nav nav-tabs',
  //           draggable: false,
  //           activeClass: 'active',
  //         },
  //       ],
  //       head: '',
  //       tabHeadClass: '',
  //       draggable: false,
  //     },
  //   ],
  //   type: '',
  //   classname: '',
  // },
  {
    jsonId: 144,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_716',
        classname: 'col-12 project-table',
        wrapperType: 'BOX',
        id: 'wrapper_421',
        fields: [
          {
            control: [
              {
                type: 'label',
                name: '<span>Experiments</span>',
                classname: 'add-heading heading-without-icon w-100',
                for: '',
                required: false,
                wrapperClassName: '',
                id: 'label_165',
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_272',
            classname: '',
            wrapperType: 'BOX',
            id: 'wrapper_804',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'textBox_919',
                id: 'textBox_12',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This fields is required', regexFail: '' },
                validationButtonType: 'disease_submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This fields is required',
                __schema_id: '',
                showInputLabel: true,
                labelContent: 'text_429',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_707',
            classname: ' table  ',
            wrapperType: 'TABLE',
            id: 'wrapper_112',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            hideSingleItemDelete: false,
            tableFields: {
              showHead: true,
              isVertical: false,
              isIteratable: false,
              addButton: {
                classname: 'btn btn-primary',
                name: '',
                value:
                  '<?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n<circle cx="12" cy="12" r="9" stroke="#222222"/>\n<path d="M12 15L12 9" stroke="#222222" stroke-linecap="square"/>\n<path d="M15 12L9 12" stroke="#222222" stroke-linecap="square"/>\n</svg>',
              },
              removeButton: {
                classname: 'btn btn-danger',
                name: '',
                value:
                  '<?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path d="M4 7H20" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n<path d="M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n<path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n</svg>',
              },
              tableHead: {
                heading: [
                  { value: 'Lab Experiment', classname: '' },
                  { value: 'Protocol Name', classname: '' },
                  { value: 'Proposed By', classname: '' },
                  { value: 'version', classname: '' },
                ],
                classname: '',
              },
              initRecordCount: 1,
              showRecordCount: false,
              recordCounterConfig: { classname: '', adjValue: '', isAdjValueAfter: false },
              tableRow: {
                name: '',
                classname: 'trclass',
                tableCols: [
                  {
                    fields: {
                      control: [
                        {
                          type: 'dropdown',
                          name: 'lab_exp',
                          id: 'lab_exp',
                          validationButtonType: '',
                          classname: '',
                          required: false,
                          apiEndPoints: [
                            {
                              type: 'change',
                              method: 'get',
                              Mapto: [
                                {
                                  id: 'main.wrapper_421.wrapper_112.${i}.box_542.protocol_name',
                                },
                              ],
                              url: 'protocol/protocol-study-list',
                            },
                          ],
                          options: [],
                          multiple: false,
                          isClearable: false,
                          isStatus: false,
                          search: false,
                          singleSelect: true,
                          showLabel: 'value',
                          errorMessage: { noData: 'This fields is required' },
                          value: '',
                          imageUrl: '',
                          disabled: true,
                          wrapperClassName: '',
                          connectedArrays: [],
                          showBlankInPlaceholder: false,
                          blankDataList: [],
                          commonPathOfImage: '',
                          __schema_id: '',
                          showInputLabel: false,
                          labelContent: '',
                          labelClass: 'form-label',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                      ],
                      head: '',
                      name: 'box_852',
                      id: 'box_541',
                      wrapperType: 'BOX',
                      fields: [],
                      type: '',
                      classname: '',
                    },
                    classname: '',
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'dropdown',
                          name: 'protocol_name',
                          id: 'protocol_name',
                          validationButtonType: 'protocolPayload',
                          classname: '',
                          required: true,
                          apiEndPoints: [
                            {
                              "type": "change",
                              "method": "get",
                              "Mapto": [
                                {
                                  "id": "main.wrapper_421.wrapper_112.${i}.box_9.initiatedBy",
                                  "displayKey": "change",
                                  "urlParameterValues": {
                                    "id": null,
                                    "patchProperty": "protocol_proposed_by"
                                  }
                                },
                                {
                                  "id": "main.wrapper_421.wrapper_112.${i}.box_691.version",
                                  "displayKey": "change",
                                  "urlParameterValues": {
                                    "id": null,
                                    "patchProperty": "protocol_ver"
                                  }
                                }
                              ],
                              url: 'protocol/protocol-study-list',
                            }
                          ],
                          options: [],
                          multiple: false,
                          isClearable: false,
                          isStatus: false,
                          search: false,
                          singleSelect: true,
                          showLabel: 'value',
                          errorMessage: { noData: 'This fields is required' },
                          value: '',
                          imageUrl: '',
                          disabled: false,
                          wrapperClassName: '',
                          connectedArrays: [],
                          showBlankInPlaceholder: false,
                          blankDataList: [],
                          commonPathOfImage: '',
                          __schema_id: '',
                          showInputLabel: false,
                          labelContent: '',
                          labelClass: 'form-label',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                      ],
                      head: '',
                      name: 'box_853',
                      id: 'box_542',
                      wrapperType: 'BOX',
                      fields: [],
                      type: '',
                      classname: '',
                    },
                    classname: '',
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'dropdown',
                          name: 'initiatedBy',
                          id: 'initiatedBy',
                          validationButtonType: 'protocolPayload',
                          classname: '',
                          required: false,
                          apiEndPoints: [
                          ],
                          options: [],
                          multiple: false,
                          isClearable: false,
                          isStatus: false,
                          search: false,
                          singleSelect: true,
                          showLabel: 'value',
                          errorMessage: { noData: 'This fields is required' },
                          value: '',
                          imageUrl: '',
                          disabled: true,
                          wrapperClassName: '',
                          connectedArrays: [],
                          showBlankInPlaceholder: false,
                          blankDataList: [],
                          commonPathOfImage: '',
                          __schema_id: '',
                          showInputLabel: false,
                          labelContent: '',
                          labelClass: 'form-label',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                      ],
                      name: 'box_121',
                      id: 'box_9',
                      wrapperType: 'BOX',
                      fields: [],
                      type: '',
                      classname: '',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'text',
                          name: 'version',
                          id: 'version',
                          value: '',
                          placeholder: '',
                          multiline: false,
                          classname: 'form-control ',
                          regularExpression: '',
                          disabled: true,
                          required: false,
                          errorMessage: { noData: 'This fields is required', regexFail: '' },
                          validationButtonType: '',
                          rows: 0,
                          columns: 0,
                          onDemandError: false,
                          customValidation: [],
                          multiError: false,
                          applicationError: '',
                          wrapperClassName: '',
                          requiredErrorMessage: 'This fields is required',
                          __schema_id: '',
                          showInputLabel: false,
                          labelContent: '',
                          labelClass: 'form-label',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                      ],
                      name: 'box_243',
                      id: 'box_691',
                      wrapperType: 'BOX',
                      fields: [],
                      type: '',
                      classname: '',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                ],
              },
            },
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'text',
            name: 'Comment',
            id: 'Comment',
            value: '',
            placeholder: '',
            multiline: true,
            classname: 'form-control ',
            regularExpression: '',
            disabled: false,
            required: false,
            errorMessage: { noData: 'This fields is required', regexFail: '' },
            validationButtonType: '',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            requiredErrorMessage: 'This fields is required',
            __schema_id: '',
            showInputLabel: true,
            labelContent: 'Comment',
            labelClass: 'form-label',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_648',
        classname: 'col-12 mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_637',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: 'form-wrapper',
  },
  {
    control: [],
    type: '',
    jsonId: 165,
    name: 'wrapper_73',
    classname: 'project-table',
    wrapperType: 'BOX',
    id: 'wrapper_364',
    fields: [
      {
        control: [],
        type: '',
        name: 'plasmidtable',
        classname: 'table project-ini-table',
        wrapperType: 'TABLE',
        id: 'plasmidtable',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
        tableFields: {
          showHead: true,
          isVertical: false,
          isIteratable: true,
          addButton: {
            classname: 'd-none',
            name: '',
            value: '',
          },
          removeButton: {
            classname: 'd-none',
            name: '',
            value: '',
          },
          tableHead: {
            heading: [
              { value: 'PLASMID TYPE', classname: '' },
              { value: 'PLASMID', classname: '' },
              { value: 'PLASMID ID', classname: '' },
              { value: 'SOURCE', classname: '' },
              { value: 'ANTIBIOTIC RESISTANCE', classname: '' },
              { value: 'STORAGE LOCATION', classname: '' },
              { value: 'ACTIONS', classname: '' },
              { value: '', classname: '' },
            ],
            classname: '',
          },
          initRecordCount: 1,
          showRecordCount: false,
          recordCounterConfig: { classname: '', adjValue: '', isAdjValueAfter: false },
          tableRow: {
            name: '',
            classname: 'trclass',
            tableCols: [
              {
                fields: {
                  control: [
                    {
                      type: 'dropdown',
                      name: 'plasmid_type',
                      id: 'plasmid_type',
                      validationButtonType: 'plasmid-submit',
                      classname: '',
                      required: true,
                      apiEndPoints: [
                        {
                          type: 'init',
                          method: 'post',
                          url: 'inventory-plasmids/inventory-sub-types',
                          payload: {
                            perma: ['plasmids'],
                          },
                        },
                        {
                          type: 'change',
                          method: 'get',
                          Mapto: [
                            {
                              id: 'wrapper_364.plasmidtable.${i}.Group_Details.plasmid',
                              displayKey: 'value',
                              urlParameterValues: { id: null },
                            },
                          ],
                          url: 'inventory-plasmids/plasmids-list/${id}',
                        },
                      ],
                      options: [],
                      multiple: false,
                      isClearable: false,
                      isStatus: false,
                      search: true,
                      singleSelect: true,
                      showLabel: 'value',
                      errorMessage: { noData: 'This field is required' },
                      value: '',
                      imageUrl: '',
                      disabled: false,
                      wrapperClassName: '',
                      connectedArrays: [],
                      showBlankInPlaceholder: false,
                      blankDataList: [],
                      showInputLabel: true,
                      labelContent: '',
                      labelClass: 'form-label d-none',
                      isLabelAfter: false,
                      showAdditionalInputContent: false,
                      additionalInputContent: '',
                      isAdditonalInputAfter: true,
                      draggable: false,
                    },
                  ],
                  head: '',
                  name: 'box_734',
                  id: 'box_582',
                  wrapperType: 'BOX',
                  fields: [],
                  type: '',
                  classname: '',
                },
                classname: '',
              },

              {
                fields: {
                  control: [
                    {
                      required: true,
                      validationButtonType: 'plasmid-submit',
                      type: 'dropdown',
                      name: 'plasmid',
                      id: 'plasmid',
                      classname: '',
                      apiEndPoints: [
                        {
                          type: 'change',
                          method: 'get',
                          isGet: true,
                          Mapto: [
                            {
                              id: 'wrapper_364.plasmidtable.${i}.box_716.plasmid_id',
                              displayKey: '',
                              urlParameterValues: { id: null, patchProperty: 'plasmids_id' },
                            },
                            {
                              id: 'wrapper_364.plasmidtable.${i}.box_716.source',
                              displayKey: '',
                              urlParameterValues: { id: null, patchProperty: 'source' },
                            },
                            {
                              id: 'wrapper_364.plasmidtable.${i}.box_716.antibiotic_resistence',
                              displayKey: '',
                              urlParameterValues: { id: null, patchProperty: 'bacterial_resistance' },
                            },
                            {
                              id: 'wrapper_364.plasmidtable.${i}.box_716.plasmid_info',
                              displayKey: '',
                              urlParameterValues: { id: null, patchProperty: 'plasmid_info' },
                            },
                          ],
                          url: 'inventory-plasmids/${id}',
                        },
                      ],
                      options: [],
                      multiple: false,
                      isClearable: true,
                      isStatus: false,
                      search: true,
                      singleSelect: true,
                      showLabel: 'value',
                      disabled: false,
                      wrapperClassName: '',
                      connectedArrays: [],
                      showBlankInPlaceholder: false,
                      blankDataList: [],
                      showInputLabel: true,
                      labelContent: '',
                      labelClass: 'form-label d-none',
                      isLabelAfter: false,
                      showAdditionalInputContent: false,
                      additionalInputContent: '',
                      isAdditonalInputAfter: true,
                      draggable: false,
                      errorMessage: {
                        noData: 'This field is required',
                      },
                    },
                    {
                      type: 'button',
                      id: 'infoicon',
                      value: '',
                      buttonType: 'plasmid_info',
                      classname: 'info',
                      divClass: '',
                      controlEndPoints: [],
                      disabled: false,
                      validationEndPoints: [],
                      invalid: false,
                      name: 'infoicon',
                      wrapperClassName: '',
                      draggable: false,
                    },
                  ],
                  name: 'groupDetails',
                  classname: ['action-wrap'],
                  id: 'Group_Details',
                  wrapperType: 'BOX',
                  fields: [],
                },
              },
              {
                fields: {
                  control: [
                    {
                      type: 'text',
                      name: 'plasmid_id',
                      id: 'plasmid_id',
                      value: '-',
                      placeholder: '',
                      multiline: false,
                      classname: 'form-control ',
                      regularExpression: '',
                      disabled: true,
                      required: false,
                      errorMessage: {
                        noData: 'This field is required',
                        regexFail: '',
                      },
                      validationButtonType: '',
                      rows: 0,
                      columns: 0,
                      onDemandError: false,
                      customValidation: [],
                      multiError: false,
                      applicationError: '',
                      wrapperClassName: '',
                      requiredErrorMessage: 'This field is required',
                      showInputLabel: true,
                      labelContent: '',
                      labelClass: 'form-label',
                      isLabelAfter: false,
                      showAdditionalInputContent: false,
                      additionalInputContent: '',
                      isAdditonalInputAfter: true,
                      draggable: false,
                    },
                    {
                      type: 'text',
                      name: 'plasmid_info',
                      id: 'plasmid_info',
                      value: '',
                      placeholder: '',
                      multiline: false,
                      classname: 'd-none',
                      regularExpression: '',
                      disabled: true,
                      required: false,
                      errorMessage: {
                        noData: 'This field is required',
                        regexFail: '',
                      },
                      validationButtonType: '',
                      rows: 0,
                      columns: 0,
                      onDemandError: false,
                      customValidation: [],
                      multiError: false,
                      applicationError: '',
                      wrapperClassName: '',
                      requiredErrorMessage: 'This field is required',
                      showInputLabel: true,
                      labelContent: '',
                      labelClass: 'd-none',
                      isLabelAfter: false,
                      showAdditionalInputContent: false,
                      additionalInputContent: '',
                      isAdditonalInputAfter: true,
                      draggable: false,
                    },
                  ],

                  name: 'box_644',
                  id: 'box_716',
                  wrapperType: 'BOX',
                  fields: [],
                  classname: '',
                  addButton: { classname: '', value: '' },
                  removeButton: { classname: '', value: '' },
                  wrapperClassName: '',
                },
                classname: '',
              },

              {
                fields: {
                  control: [
                    {
                      type: 'text',
                      name: 'source',
                      id: 'source',
                      value: '',
                      placeholder: '',
                      multiline: false,
                      classname: 'form-control ',
                      regularExpression: '',
                      disabled: true,
                      required: false,
                      errorMessage: {
                        noData: 'This field is required',
                        regexFail: '',
                      },
                      validationButtonType: '',
                      rows: 0,
                      columns: 0,
                      onDemandError: false,
                      customValidation: [],
                      multiError: false,
                      applicationError: '',
                      wrapperClassName: '',
                      requiredErrorMessage: 'This field is required',
                      showInputLabel: true,
                      labelContent: '',
                      labelClass: 'form-label',
                      isLabelAfter: false,
                      showAdditionalInputContent: false,
                      additionalInputContent: '',
                      isAdditonalInputAfter: true,
                      draggable: false,
                    },
                  ],
                  name: 'box_644',
                  id: 'box_716',
                  wrapperType: 'BOX',
                  fields: [],
                  classname: '',
                  addButton: { classname: '', value: '' },
                  removeButton: { classname: '', value: '' },
                  wrapperClassName: '',
                },
                classname: '',
              },
              {
                fields: {
                  control: [
                    {
                      type: 'text',
                      name: 'antibiotic_resistence',
                      id: 'antibiotic_resistence',
                      value: '-',
                      placeholder: '',
                      multiline: false,
                      classname: 'form-control ',
                      regularExpression: '',
                      disabled: true,
                      required: false,
                      errorMessage: {
                        noData: 'This field is required',
                        regexFail: '',
                      },
                      validationButtonType: '',
                      rows: 0,
                      columns: 0,
                      onDemandError: false,
                      customValidation: [],
                      multiError: false,
                      applicationError: '',
                      wrapperClassName: '',
                      requiredErrorMessage: 'This field is required',
                      showInputLabel: true,
                      labelContent: '',
                      labelClass: 'form-label',
                      isLabelAfter: false,
                      showAdditionalInputContent: false,
                      additionalInputContent: '',
                      isAdditonalInputAfter: true,
                      draggable: false,
                    },
                  ],
                  name: 'box_644',
                  id: 'box_716',
                  wrapperType: 'BOX',
                  fields: [],
                  classname: '',
                  addButton: { classname: '', value: '' },
                  removeButton: { classname: '', value: '' },
                  wrapperClassName: '',
                },
                classname: '',
              },
              {
                fields: {
                  control: [
                    {
                      type: 'button',
                      id: 'infoicon',
                      value: 'View Location',
                      buttonType: 'storage_info',
                      classname: 'info-icon',
                      divClass: '',
                      controlEndPoints: [],
                      disabled: false,
                      validationEndPoints: [],
                      invalid: false,
                      name: 'infoicon',
                      wrapperClassName: '',
                      draggable: false,
                    },
                  ],
                  name: 'box_644',
                  id: 'box_716',
                  wrapperType: 'BOX',
                  fields: [],
                  classname: '',
                  addButton: { classname: '', value: '' },
                  removeButton: { classname: '', value: '' },
                  wrapperClassName: '',
                },
                classname: '',
              },
              {
                fields: {
                  control: [
                    {
                      type: 'button',
                      id: 'plasmidmap',
                      value: '<div class="custom-tooltip"><p>View Plasmid Map</p></div>',
                      buttonType: 'plasmid_map',
                      classname: 'plasmid-view',
                      divClass: '',
                      controlEndPoints: [],
                      disabled: false,
                      validationEndPoints: [],
                      invalid: false,
                      name: 'plasmidmap',
                      wrapperClassName: '',
                      draggable: false,
                    },
                  ],
                  name: 'box_985',
                  id: 'box_89',
                  wrapperType: 'BOX',
                  fields: [],
                  type: '',
                  classname: 'actions',
                  addButton: { classname: '', value: '<div class="custom-tooltip"><p>Add Row</div>' },
                  removeButton: { classname: '', value: '<div class="custom-tooltip"><p>Remove Row</div>' },
                  wrapperClassName: '',
                },
                classname: '',
              },
            ],
          },
        },
      },
    ],
    head: '',
    tabHeadClass: '',
    draggable: false,
  },


  //filter


  {
    jsonId: 169,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_576',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_790',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'project_title',
                id: 'project_title',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'initial-submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Project Title',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_50',
            classname: 'col-12 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_808',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'project_id',
                id: 'project_id',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'initial-submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Project Id',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_290',
            classname: 'col-12 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_654',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'project_start_date_from',
                id: 'project_start_date_from',
                validationButtonType: 'initial-submit',
                range: false,
                value: '',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: '',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input mb-3',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                required: false,
                classname: '',
                errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-US',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'Start Date From',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
              {
                type: 'datepicker_v2',
                name: 'project_start_date_to',
                id: 'project_start_date_to',
                validationButtonType: 'initial-submit',
                range: false,
                value: '',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: '',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                required: false,
                classname: '',
                errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-US',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'Start Date To',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_209',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_778',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'project_end_date_from',
                id: 'project_end_date_from',
                validationButtonType: '',
                range: false,
                value: '',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: '',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input mb-3',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                required: false,
                classname: '',
                errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-US',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'End Date From',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
              {
                type: 'datepicker_v2',
                name: 'project_end_date_to',
                id: 'project_end_date_to',
                validationButtonType: '',
                range: false,
                value: '',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: '',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                required: false,
                classname: '',
                errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-US',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'End Date',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_643',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_92',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'dropdown',
                name: 'status',
                id: 'status',
                validationButtonType: '',
                classname: 'status-dropdown',
                required: false,
                apiEndPoints: [],
                options: [
                  {
                    id: 1,
                    value: 'Project Initiated',
                    className: 'project-initiated',
                  },
                  {
                    id: 2,
                    value: 'Project workflow Inprogress',
                    className: 'project-progress',
                  },
                  {
                    id: 3,
                    value: 'Project Completed',
                    className: 'project-completed',
                  },
                  {
                    id: 4,
                    value: 'Deleted',
                    className: 'deleted-status',
                  },
                  {
                    id: 5,
                    value: 'Project End',
                    className: 'project-end',
                  },
                ],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: false,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Status',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_97',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_513',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
];
