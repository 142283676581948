import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InventoryDynamicJSON } from './dynamic-json/inventory-dynamic-jsons';
import { VendorDynamicJSON } from './dynamic-json/vendors-dynamic-json';
import { UsermgtDynamicJSON } from './dynamic-json/user-mgt-dynamic-jsons';
import { ProtocolDynamicJSON } from './dynamic-json/protocol-dynamic-jsons';
import { ProjectpepJSON } from './dynamic-json/project-pep-dynamic-jsons';
import { ProjectmspDynamicJSON } from './dynamic-json/project-msp-dynamic-jsons';
import { ProjectbspDynamicJSON } from './dynamic-json/project-bsp-dynamic-jsons';
import { ProjectappDynamicJSON } from './dynamic-json/project-app-dynamic-jsons';
import { ProductDynamicJSON } from './dynamic-json/products-dynamic-jsons';
import { LoginDynamicJSON } from './dynamic-json/login-dynamic-jsons';
import { studyDynamicJson } from './dynamic-json/study-json';
import { ExperimentsDynamicJSON } from './dynamic-json/experiments-dynamic-jsons';
import { LIBRARYTEMPLATEDYNAMICJSON, LIBRARYTEMPLATESUMMARY, BacteriaPhageTemplateDynamicJSON} from './dynamic-json/template-generation-jsons';
import { FILLINPURIFIEDssDNA, LabConsumabesTemplateDynamicJSON,FILLINPURIFIEDssDNASUMMARYJSON, PURIFIEDssDNAWORKFLOWJSON, heteroduplexDnaSynthesis } from './dynamic-json/fill-in-purified-ssDNA-json';
import {  WORKFLOWBACTERIALSTRAINGROWTH, WORKFLOWTELECTROCOMPETENTSTRAINGLYCEROL } from './dynamic-json/preparation-of- electrocompetent-strain-dynamic-json';
import { SELECTION_ATIGEN_SPEC_ANTOBODY_JSON } from './dynamic-json/selection-of-antigen-spec-antibodies-jsons';
import { STRAIN_ELECTROPORATION, STRAIN_ELECTROPORATION_WORKFLOW } from './dynamic-json/strain-electroporation-and-phage-propagation-dynamic-jsons';
import { ELCTROCOMPETENTLABCONSUMABLES, ELECTROCOMPETENTBACTERIAPHAGE, ELECTROCOMPETENTINITIATION, ELECTROCOMPETENTSUMMARY } from './dynamic-json/preparation-of- electrocompetent-strain-dynamic-json';
import { diseaseProgramDynamicJson } from './dynamic-json/disease-program-json';
import { ANALYSIS_OF_PHAGE_CLONES, APCE_WORKFLOW } from './dynamic-json/analysis-of-phage-clones-by-ELISA-jsons';
import { DNA_SEQUENCING } from './dynamic-json/dna-sequencing-json';
export const sharedAngularModules = [CommonModule, ReactiveFormsModule, FormsModule];
export const sharedJson = [
  VendorDynamicJSON,
  UsermgtDynamicJSON,
  ProtocolDynamicJSON,
  ProjectpepJSON,
  ProjectmspDynamicJSON,
  ProjectbspDynamicJSON,
  ProjectappDynamicJSON,
  ProductDynamicJSON,
  LoginDynamicJSON,
  InventoryDynamicJSON,
  studyDynamicJson,
  ExperimentsDynamicJSON,
  LIBRARYTEMPLATEDYNAMICJSON,
  LIBRARYTEMPLATESUMMARY,
  BacteriaPhageTemplateDynamicJSON,
  FILLINPURIFIEDssDNA,
  FILLINPURIFIEDssDNASUMMARYJSON,
  LabConsumabesTemplateDynamicJSON,
  FILLINPURIFIEDssDNASUMMARYJSON,
  PURIFIEDssDNAWORKFLOWJSON,
  ELECTROCOMPETENTINITIATION,
  ELCTROCOMPETENTLABCONSUMABLES,
  ELECTROCOMPETENTBACTERIAPHAGE,
  heteroduplexDnaSynthesis,
  ELECTROCOMPETENTSUMMARY,
  SELECTION_ATIGEN_SPEC_ANTOBODY_JSON,
  WORKFLOWBACTERIALSTRAINGROWTH,
  WORKFLOWTELECTROCOMPETENTSTRAINGLYCEROL,
  STRAIN_ELECTROPORATION,
  STRAIN_ELECTROPORATION_WORKFLOW,
  diseaseProgramDynamicJson,
  ANALYSIS_OF_PHAGE_CLONES,
  DNA_SEQUENCING,
  APCE_WORKFLOW
];
