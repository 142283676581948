export const APP_CONSTANTS = {
  INVENTORY: {
    BIOLOGICAL_SAMPLE: {
      PLASMIDS: {
        NEW: 'ADD NEW PLASMID',
        MASTER_NO_DATA_DESCRIPTION: 'No Plasmid Master Data Available',
        INVENTORY_NO_DATA_DESCRIPTION: 'No Inventory Data',
        ADDINVENTORY: 'ADD NEW INVENTORY',
        VIEW_NO_MAP_DESCRIPTION: 'No Plasmid Map Available',
        NO_VIEW_ATTACHMENTS: 'No Attachments Available',
      },
      BACTERIAL_STRAIN: {
        NEW: 'ADD NEW BACTERIAL STRAIN',
        MASTER_NO_DATA_DESCRIPTION: 'No Bacterial Strain Master Data Available',
        INVENTORY_NO_DATA_DESCRIPTION: 'No Inventory Data',
        ADDINVENTORY: 'ADD NEW INVENTORY',
      },
      CELL_LINES: {
        NEW: 'ADD NEW CELL LINE',
        MASTER_NO_DATA_DESCRIPTION: 'No Cell Lines Master Data Available',
        INVENTORY_NO_DATA_DESCRIPTION: 'No Inventory Data',
        ADDINVENTORY: 'ADD NEW INVENTORY',
      },
    },
    LAB_EQUIPMENTS: {
      REFRIGERATOR: {
        NEW: 'ADD NEW REFRIGERATOR',
        MASTER_NO_DATA_DESCRIPTION: 'No Refrigerator Master Data Available',
        INVENTORY_NO_DATA_DESCRIPTION: 'No Inventory Data',
        ADDINVENTORY: 'ADD NEW INVENTORY',
      },
      FREEZER: {
        NEW: 'ADD NEW FREEZER',
        MASTER_NO_DATA_DESCRIPTION: 'No Freezer Master Data Available',
        INVENTORY_NO_DATA_DESCRIPTION: 'No Inventory Data',
        ADDINVENTORY: 'ADD NEW INVENTORY',
      },
      SHELVES: {
        NEW: 'ADD NEW SHELF',
        MASTER_NO_DATA_DESCRIPTION: 'No Shelves Master List Data Available',
        INVENTORY_NO_DATA_DESCRIPTION: 'No Inventory Data',
        ADDINVENTORY: 'ADD NEW INVENTORY',
      },
      STORAGE_CABINETS: {
        NEW: 'ADD NEW STORAGE CABINET',
        MASTER_NO_DATA_DESCRIPTION: 'No Storage Cabinet Master List Data Available',
        INVENTORY_NO_DATA_DESCRIPTION: 'No Inventory Data',
        ADDINVENTORY: 'ADD NEW INVENTORY',
      },
    },
    LAB_CONSUMABLES: {
      ASSAY_KITS: {
        NEW: 'ADD NEW ASSAY KIT',
        MASTER_NO_DATA_DESCRIPTION: 'No Assay kit Master Data Available',
        INVENTORY_NO_DATA_DESCRIPTION: 'No Inventory Data',
        ADDINVENTORY: 'ADD NEW INVENTORY',
      },

      CELL_CULTURE_REAGENTS: {
        ITEM: {
          NEW: 'ADD NEW ITEM',
          MASTER_NO_DATA_DESCRIPTION: 'No  Master List Data Available',
          INVENTORY_NO_DATA_DESCRIPTION: 'No Inventory Data',
          ADDINVENTORY: 'ADD NEW INVENTORY',
        },
      },
      LAB_WARES: {
        PLASTICWARES: {
          NEW: 'ADD NEW PLASTICWARE',
          MASTER_NO_DATA_DESCRIPTION: 'No Plasticware Master List Data Available',
          INVENTORY_NO_DATA_DESCRIPTION: 'No Inventory Data',
          ADDINVENTORY: 'ADD NEW INVENTORY',
        },
        GLASSWARE: {
          NEW: 'ADD NEW GLASSWARE',
          MASTER_NO_DATA_DESCRIPTION: 'No Glassware Master List Data Available',
          INVENTORY_NO_DATA_DESCRIPTION: 'No Inventory Data',
          ADDINVENTORY: 'ADD NEW INVENTORY',
        },
      },
    },
    INSTRUMENTS: {
      NEW: 'ADD NEW INSTRUMENT',
      MASTER_NO_DATA_DESCRIPTION: 'No Instrument Master List Data Available',
      INVENTORY_NO_DATA_DESCRIPTION: 'No Inventory Data',
      ADDINVENTORY: 'ADD NEW INVENTORY',
    },
    PROTOCOL: {
      NEW: 'ADD NEW PROTOCOL',
      MASTER_NO_DATA_DESCRIPTION: 'No Protocol Master List Data Available',
      INVENTORY_NO_DATA_DESCRIPTION: 'No Inventory Data',
      ADDINVENTORY: 'ADD NEW INVENTORY',
    },
    VENDOR: {
      NEW: 'ADD NEW vendor',
      MASTER_NO_DATA_DESCRIPTION: 'No vendor Master List Data Available',
    },
    IDS: {
      EMPTY: {
        id: null,
        value: '',
      },
      IN_HOUSE: {
        id: 1,
        value: 'In House',
      },
      COMMERCIAL: {
        id: 2,
        value: 'Commercial',
      },
      COLLABORATOR: {
        id: 3,
        value: 'Collaborator',
      },
      IN_HOUSE_EXPAND: {
        id: 4,
        value: 'In House Expanded',
      },
      IN_HOUSE_CREATE: {
        id: 5,
        value: 'In House Created',
      },
      CELL_CULTURE_MEDIA: {
        id: 21,
        value: 'Cell Culture Media',
      },
      ANTIBIOTICS: {
        id: 22,
        value: 'Antibiotics',
      },
      AGAR_PLATES: {
        id: 23,
        value: 'Agar Plates',
      },
      GELS: {
        id: 24,
        value: 'Gels',
      },
      BUFFERS: {
        id: 25,
        value: 'Buffers',
      },
      LAB_REAGENT: {
        id: 26,
        value: 'Lab Reagents',
      },
    },
    PERMA: {
      ASSAY_KITS: 'assay-kits',
      AGAR_PLATES: 'agar-plates',
      CELL_CULTURE_MEDIA: 'cell-culture-media',
      ANTIBIOTICS: 'antibiotics',
      GELS: 'gels',
      BUFFERS: 'buffers',
      LAB_REAGENTS: 'lab-reagents',
      PLASTICWARES: 'plasticwares',
      GLASSWARES: 'glasswares',
      CCMAA: 'cell-culture-media-antibiotics-agar-plates',
      GBLR: 'gels-buffers-lab-reagents',
    },
    HISTORY: {
      LIST_TYPES: {
        PLASMID_MASTER: 'Plasmid_master',
        PLASMID_INVENTORY: 'Plasmid_inventory',
        BACTERIAL_STRAIN_MASTER: 'Bacterialstrain_master',
        BACTERIAL_STRAIN_INVENTORY: 'Bacterialstrain_inventory',
        CELL_LINES_MASTER: 'Celllines_master',
        CELL_LINES_INVENTORY: 'Celllines_inventory',
        REFRIGERATOR_MASTER: 'Refrigerator_master',
        REFRIGERATOR_INVENTORY: 'Refrigerator_inventory',
        FREEZER_MASTER: 'Freezer_master',
        FREEZER_INVENTORY: 'Freezer_inventory',
        STORAGE_CABINET_MASTER: 'storage-cabinet_master',
        STORAGE_CABINET_INV: 'storage-cabinet_inventory',
        SHELVES_MASTER: 'shelves_master',
        SHELVES_INV: 'shelves_inventory',
        ASSAY_KITS_MASTER: 'assay_kits_master',
        CCM_AB_AK_MASTER: 'cellculture_media_master',
        G_B_LR_MASTER: 'gels_buffers_master',
        PLASTICWARE_MASTER: 'plasticwares_master',
        GLASSWARE_MASTER: 'glasswares_master',
        ASSAY_KITS_INVENTORY: 'assay-kits_inventory',
        CCMAA_INVENTORY: 'cell-culture-media_inventory',
        GBLR_INVENTORY: 'gels_inventory',
        PLASTIC_WARES_INVENTORY: 'plasticwares_inventory',
        GLASS_WARES_INVENTORY: 'glasswares_inventory',
        INSTRUMENT_INVENTORY: 'instruments_inventory',
        INSTRUMENT_MASTER: 'instruments_master',
        PROTOCOLS: 'protocol',
        PROJECTS: 'projects',
        VENDOR: 'vendor',
        AGAR_PLATES: 'agar-plates_inventory',
        CELL_CULTURE_MEDIA: 'cell-culture-media_inventory',
        ANTIBIOTICS: 'antibiotics_inventory',
        GELS: 'gels_inventory',
        BUFFERS: 'buffers_inventory',
        LAB_REAGENTS: 'lab-reagents_inventory',
        EXPERIMENT: 'Experiments',
        STUDY: 'study',
        PROJECT_INSTANCE:'project-instance',
        DISEASE: 'Diseases',
      },
      ACTION_TYPES: {
        ADD: 1,
        EDIT: 2,
        DELETE: 3,
      },
      NO_DATA: 'No History Data Available',
    },
    DELETE_WARNING_MESSAGE:
      'Some of the selected items is linked to an entry in inventory and hence cannot be deleted. Do you wish to proceed with deleting the rest?',
    DELETE_ALL_LINKED_MESSAGE:
      'All of the selected items are linked to an entry in inventory and hence cannot be deleted.',
    DELETE_SINGLE_LINKED_MESSAGE: 'This item is linked to an entry in inventory and hence cannot be deleted.',
    INVENTORY_DELETE_WARNING_MESSAGE:
      'Some selected items are linked to projects and cannot be deleted. Do you wish to proceed with deleting the rest?',
    INVENTORY_DELETE_ALL_LINKED_MESSAGE:
      'All selected items are linked to projects and hence cannot be deleted.',
    INVENTORY_DELETE_SINGLE_LINKED_MESSAGE: 'This item is linked to projects and hence cannot be deleted.',
    STORAGE_DELETE_WARNING_MESSAGE:
      'Some selected items are linked to project(s) or inventory(s) and cannot be deleted. Do you wish to proceed with deleting the rest?',
    STORAGE_DELETE_ALL_LINKED_MESSAGE:
      'All selected items are linked to project(s) or inventory(s) and hence cannot be deleted.',
    STORAGE_DELETE_SINGLE_LINKED_MESSAGE: 'This item is linked to project(s) or inventory(s) and hence cannot be deleted.',
  },
  ASSIGNSTORAGE: {
    INVALIDTUBECOUNT: 'Assigned units should not exceed the total units!',
    NOUNITASSIGNED: 'Please assign a storage before saving !',
    CONFIRMGRID: 'Please save the storage before submitting !',
    NODATA: 'No storage is assigned',
    NOUNITS: 'Please choose total units before assigning stoarge !',
    ALLDISCARDED: 'All storage are discarded !',
    DISCARDED_STR: 'This storage is discarded !',
    NOT_SELECTED_OR_DISCARDED_STR: 'This storage is either discarded or not selected !',
    DELETEUNWANTED:'Selected storage is over the required limit. Please delete unnecessary storage items.'
  },
  CRYOBOX: {
    MAXSELECTIONEXEEDS: 'Maximum selection exceeds !',
    EMPTYMAXSELECTION: 'Please select total number of units !',

    ALLUNITSASSINGED: 'All units are assigned !',
    EMPTYWARNING: {
      PLAMSIDINVENTORY: 'Please select total number of tubes !',
      BACTERIALSTRAIN: 'Please select total number of tubes !',
      CELLLINES: 'Please select total number of vials !',
      ASSAYKITS: 'Please select total number of units !',
    },
    VIAL_COLOR: ['green', 'blue', 'yellow', 'red', 'orange', 'pink', 'rose', 'cyan', 'violet', 'purple'],
    ASSIGNMULTIPLE: {
      ALLUNITASSIGNED: 'All units of selected item are assigned !',
      NOSTOARGE: 'No storage is defined for selected item !',
    },
  },
  PRODUCT: {
    PRODUCT_TYPE: {
      AB: '3', //Anti Body
      GS: 1, //Glycerol stock
      EP: '2', //Antibody Production and Purification
      MSP: '4', // Mammalian Cell Lines Stock Preparation
    },
  },
  PROJECTS: {
    NO_DATA: 'No Project Data Available',
    HISTORY: {
      LIST_TYPES: {
        PROJECTS: 'projects',
      },
      NO_DATA: 'No History Log Available',
    },
    PROJECT_TYPES: {
      BSP: 1, // Bacterial Stock Preparation
      PEP: 2, // Plasmid Extraction and Purification
      APP: 3, // Antibody Production and Purification
      MSP: 4, // Mammalian Cell Lines Stock Preparation,
      DNA: 11, // DNA SEQ,
      ELISA: 10, // Analysis of Binding Clones by ELISA",
    },
    SIMISCO_PROJECT_TYPES: {
      LTG: 1, // Library Template Generation
      PURIFIEDssDNA: 7,// Fill-in of purified ssDNA to generate CCC-dsDNA,
      ECS: 8, // Preparation of Electrocompetent Strains,
      SEP: 9, // Strain Electroporation and Phage Propagation,
      SASA: 10, // Selection of Antigen Specific Antibodies using Immobilized Antigen
      APCE: 11, //Analysis of phage clones by ELISA
      DNASEQ: 12 //DNA Sequencing

    },
    TUBE_TYPES: [
      { id: 1, value: 'Cryovials' },
      { id: 2, value: 'Screw Cap Tube' },
    ],
    WORFLOW_HTML_TEMPLATE:{
      PURIFICATION_SS_DNA:"purification_of_ssDNA_summary",
      BACTERIA_GROWTH:"bacterial growth summary",
      SELECTION_ANTIGEN_ANTIBODIES:'antigen specific antibodies summary',
      DNA_SEQUENCING:'pcr_template_dna_preparation_summary'
    }
  },
  LIB_TEMP_EXP_PROJECT: {
    NO_DATA: 'No Experiment Data Available',
    HISTORY: {
      LIST_TYPES: {
        EXPERIMENTS: 'project_instance',
      },
      NO_DATA: 'No History Log Available',
    },
    LIB_TEMP_EXP_PROJECT_TYPES: {

    },
  },

  FILE_TYPES: {
    PROTOCOL: {
      FILE_TYPE: 'protocol',
      FILE_NAME: 'Protocol',
    },
    AMC: {
      FILE_TYPE: 'amc',
      FILE_NAME: 'AMC Contract',
    },
    CMC: {
      FILE_TYPE: 'cmc',
      FILE_NAME: 'CMC Contract',
    },
    CERTIFICATE: {
      FILE_TYPE: 'certificate',
      FILE_NAME: 'IQ/OQ/PQ Certificate',
    },
    INSTRUMENTS: {
      FILE_TYPE: 'instrument',
      FILE_NAME: 'Instrument',
    },
  },
  SETTINGS: {
    USER: {
      NEW: 'ADD NEW USER',
      NO_DATA_DESCRIPTION: 'No User Data Available',
    },
  },
  EXPERIMENTS: {
      NEW: 'ADD NEW EXPERIMENT',
      NO_DATA_DESCRIPTION: 'No Experiment Data Available',
  },
  STUDIES: {
    NEW: 'ADD NEW STUDY',
    NO_DATA_DESCRIPTION: 'No Study Data Available',
    SELECT_EXP:"Please select some experiments to continue"
},
DISEASE_PROGRAM:{
  NEW: 'ADD NEW DISEASE PROGRAM',
  NO_DATA_DESCRIPTION: 'No Data Available',
},

  DELETEITEM: 'Please ensure before deletion',
  DATEFORMAT: 'dd/MM/yyyy',
  DATENOTAPPLICABLE: 'No Expiry',
  CLIENT_ID:'__client_id',

  MASTERDATATYPES: [
    'bact_rest',
    'bio_level',
    'collab',
    'box_type',
    'invent_source',
    'mam_rest',
    'samp_form',
    'st_temp',
    'vendor',
    'project-type-list',
    'protein',
    'role',
  ],
  MANUS_BIO:{
    EXPERIMENT_STEPS:{
      EXPERIMENT_INTITIATION:0,
      INSTRUMENT_PLATE:1,
      COMPOUNDS:2,
      SAMPLES:3,
      STANDARDS:4,
      PLATE_MAP:5
    },
    AREA_CONC:{
      AREA_CONC_CALCULATION:0,
      CV:1,
      DATA_SUMMARY:2,
      ITSD_QUALITY_CHECK:3,
      SUMMARY:4
    }
  }
};
export const DATEPICKER = {
  SINGLEDATEPICKER: {
    type: 'datepicker_v2',
    name: 'single_datePicker',
    id: 'single_datePicker',
    validationButtonType: '',
    range: false,
    value: '',
    multiple: false,
    multiAmount: 3,
    keyboardInput: false,
    startDateReadOnly: false,
    endDateReadOnly: false,
    fordisabledStart: '',
    fordisabledEnd: '',
    timer: false,
    hourFormat: 24,
    placeholder: 'DD-MM-YYYY',
    startDatePlaceHolder: 'MM/DD/YYYY',
    inputClassName: 'form-control date-input',
    selectedDateClassName: 'selectedDate',
    arrowClassName: 'arrow',
    dayClassName: 'dd',
    todayClassName: 'now',
    monthClassName: 'mtn',
    // regularExpression:
    //   '/^(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}(s+tos+(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}|(,s*(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4})*)?$/',
    // required: false,
    classname: '',
    errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
    dateFormat: 'dd/mm/yyyy',
    disabled: false,
    wrapperClassName: '',
    locale: '',
    dateSeperator: '',
    inputDateFormat: false,
    isHostParent: false,
    showInputLabel: true,
    labelContent: '',
    labelClass: 'form-label',
    isLabelAfter: false,
    showAdditionalInputContent: false,
    additionalInputContent: '',
    isAdditonalInputAfter: true,
    errorClass: 'errorMessage',
  },
};

export const SOURCE_VALIDATIONS = {
  PLASMIDINVENTORY: {
    1: {
      REQUIRED: ['in_house_id'],
    },
    2: {
      REQUIRED: ['vendor', 'catalogue_no', 'product_url'],
    },
    3: {
      REQUIRED: ['collaborator', 'collaborator_source_id'],
    },
  },
  CELLLINEINVENTORY: {
    4: {
      REQUIRED: ['in_house_id'],
    },

    // 5: {
    //   REQUIRED: ['in_house_id'],
    // },

    2: {
      REQUIRED: ['vendor', 'catalogue_no', 'product_url'],
    },

    3: {
      REQUIRED: ['collaborator', 'collaborator_source_id'],
    },
  },
};

const options: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};
export const CURRENTDATE = new Date().toLocaleDateString('en-GB', options);

export const DEFAULT_PAYLOAD_VALUES:any = {
  PLASMID_INVENTORY: {
    "in_house_id": "",
    "collaborator_source_id": "",
    "product_url": "",
    "catalogue_no": "",
    "description": "",
    "collaborator": {
      "id": 0,
      "value": ""
    },
    "format": {
      "id": null,
      "value": ""
    },
    "mammalian_resistance": {
      "id": 0,
      "value": ""
    },
    "bacterial_resistance": {
      "id": 0,
      "value": ""
    },
    "vendor": {
      "id": null,
      "value": ""
    },
  },
  BACTERIAL_STRAIN_INVENTORY: {
    "in_house_id": "",
    "collaborator_source_id": "",
    "product_url": "",
    "catalogue_no": "",
    "description": "",
    "collaborator": {
      "id": null,
      "value": ""
    },
    "vendor": {
      "id": null,
      "value": ""
    },
    "format": {
      "id": null,
      "value": ""
    },
  },
  CELL_LINE_INVENTORY: {
    "in_house_id": "",
    "collaborator_source_id": "",
    "catalogue_no": "",
    "product_url": "",
    "viability": null,
    "description": "",
    "collaborator": {
      "id": null,
      "value": ""
    },
    "vendor": {
      "id": null,
      "value": ""
    },
    "mammalian_resistance": {
      "id": null,
      "value": ""
    },
    "format": {
      "id": null,
      "value": ""
    },
  },
  ASSAY_KITS_INVENTORY: {
    "inv_item_desc": "",
    "vendor": {
      "id": null,
      "value": ""
    }
  },
  CELL_CULTURE_INVENTORY: {
    "inhouse_id": "",
    "shelf_life_months": null,
    "inv_item_desc": "",
    "form_other_type": {
      "id": null,
      "value": ""
    },
    "vendor": {
      "id": null,
      "value": ""
    },
    "no_of_boxes": null,
  },
  G_BL_R_INVENTORY: {
    "no_of_boxes": null,
    "inv_item_desc": "",
    "vendor": {
      "id": null,
      "value": ""
    },
    "form_other_type": {
      "id": null,
      "value": ""
    },
    "inv_item_volume": null,
  },
  GLASSWARE_INVENTORY:{
    "inv_item_volume": null,
    "vendor": {
        "id": null,
        "value": ""
    },
    "storage_temp": {
        "id": null,
        "value": ""
    },
    "inv_item_desc": "Dec"
  },
  PLASTICWARE_INVENTORY:{
    "inv_item_volume": null,
    "vendor": {
        "id": null,
        "value": ""
    },
    "storage_temp": {
        "id": null,
        "value": ""
    },
    "inv_item_desc": "Dec"
  }
}

