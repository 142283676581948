const projectInit={
    "id": "main",
    "head": "",
    "name": "main",
    "type": "",
    "jsonId": 159,
    "fields": [
        {
            "id": "wrapper_790",
            "head": "",
            "name": "wrapper_576",
            "type": "",
            "fields": [
                {
                    "id": "wrapper_808",
                    "head": "",
                    "name": "wrapper_50",
                    "type": "",
                    "fields": [],
                    "control": [
                        {
                            "id": "project_title",
                            "name": "project_title",
                            "__schema_id": "project_title",
                            "rows": 0,
                            "type": "text",
                            "value": "",
                            "columns": 0,
                            "disabled": false,
                            "required": true,
                            "classname": "form-control ",
                            "draggable": false,
                            "multiline": false,
                            "labelClass": "form-label mandatory",
                            "multiError": false,
                            "placeholder": "",
                            "errorMessage": {
                                "noData": "This field is required",
                                "regexFail": ""
                            },
                            "isLabelAfter": false,
                            "labelContent": "Project Title",
                            "onDemandError": false,
                            "showInputLabel": true,
                            "applicationError": "",
                            "customValidation": [],
                            "wrapperClassName": "",
                            "regularExpression": "",
                            "requiredErrorMessage": "This field is required",
                            "validationButtonType": "initial-submit",
                            "isAdditonalInputAfter": true,
                            "additionalInputContent": "",
                            "showAdditionalInputContent": false
                        }
                    ],
                    "classname": "col-12 mb-3",
                    "draggable": false,
                    "wrapperType": "BOX",
                    "tabHeadClass": ""
                },
                {
                    "id": "wrapper_654",
                    "head": "",
                    "name": "wrapper_290",
                    "type": "",
                    "fields": [],
                    "control": [
                        {
                            "id": "project_init_by",
                            "name": "project_init_by",
                            "__schema_id": "project_init_by",
                            "type": "dropdown",
                            "value": "",
                            "search": true,
                            "disabled": false,
                            "imageUrl": "",
                            "isStatus": false,
                            "multiple": false,
                            "required": true,
                            "classname": "",
                            "draggable": false,
                            "showLabel": "value",
                            "labelClass": "form-label mandatory",
                            "isClearable": false,
                            "apiEndPoints": [
                                {
                                    "url": "user_management/users_list",
                                    "type": "init",
                                    "method": "get"
                                }
                            ],
                            "errorMessage": {
                                "noData": "This field is required"
                            },
                            "isLabelAfter": false,
                            "labelContent": "Initiated By",
                            "singleSelect": true,
                            "showInputLabel": true,
                            "wrapperClassName": "",
                            "validationButtonType": "initial-submit",
                            "isAdditonalInputAfter": true,
                            "additionalInputContent": "",
                            "showAdditionalInputContent": false
                        }
                    ],
                    "classname": "col-12 mb-3",
                    "draggable": false,
                    "wrapperType": "BOX",
                    "tabHeadClass": ""
                },
                {
                    "id": "wrapper_778",
                    "head": "",
                    "name": "wrapper_209",
                    "type": "",
                    "fields": [],
                    "control": [
                        {
                            "id": "project_start_date",
                            "name": "project_start_date",
                            "__schema_id": "project_start_date",
                            "type": "datepicker_v2",
                            "range": false,
                            "timer": false,
                            "value": "",
                            "locale": "",
                            "disabled": false,
                            "multiple": false,
                            "required": true,
                            "classname": "",
                            "draggable": false,
                            "dateFormat": "dd/mm/yyyy",
                            "hourFormat": 24,
                            "labelClass": "form-label mandatory",
                            "multiAmount": 3,
                            "placeholder": "DD-MM-YYYY",
                            "dayClassName": "dd",
                            "errorMessage": {
                                "noData": "This field is required",
                                "regexFail": "Date is not valid!!"
                            },
                            "isHostParent": false,
                            "isLabelAfter": false,
                            "labelContent": "Start Date",
                            "dateSeperator": "",
                            "keyboardInput": false,
                            "arrowClassName": "arrow",
                            "fordisabledEnd": "",
                            "inputClassName": "form-control date-input",
                            "monthClassName": "mtn",
                            "showInputLabel": true,
                            "todayClassName": "now",
                            "endDateReadOnly": false,
                            "inputDateFormat": false,
                            "fordisabledStart": "",
                            "wrapperClassName": "",
                            "startDateReadOnly": false,
                            "startDatePlaceHolder": "MM/DD/YYYY",
                            "validationButtonType": "initial-submit",
                            "isAdditonalInputAfter": true,
                            "selectedDateClassName": "selectedDate",
                            "additionalInputContent": "",
                            "showAdditionalInputContent": false
                        }
                    ],
                    "classname": "col-6 mb-3",
                    "draggable": false,
                    "wrapperType": "BOX",
                    "tabHeadClass": ""
                },
                {
                    "id": "wrapper_92",
                    "head": "",
                    "name": "wrapper_643",
                    "type": "",
                    "fields": [],
                    "control": [
                        {
                            "id": "project_end_date",
                            "name": "project_end_date",
                            "__schema_id": "project_end_date",
                            "type": "datepicker_v2",
                            "range": false,
                            "timer": false,
                            "value": "",
                            "locale": "",
                            "disabled": false,
                            "multiple": false,
                            "required": false,
                            "classname": "",
                            "draggable": false,
                            "dateFormat": "dd/mm/yyyy",
                            "hourFormat": 24,
                            "labelClass": "form-label",
                            "multiAmount": 3,
                            "placeholder": "DD-MM-YYYY",
                            "dayClassName": "dd",
                            "errorMessage": {
                                "noData": "This field is required",
                                "regexFail": "Date is not valid!!"
                            },
                            "isHostParent": false,
                            "isLabelAfter": false,
                            "labelContent": "End Date",
                            "dateSeperator": "",
                            "keyboardInput": false,
                            "arrowClassName": "arrow",
                            "fordisabledEnd": "",
                            "inputClassName": "form-control date-input",
                            "monthClassName": "mtn",
                            "showInputLabel": true,
                            "todayClassName": "now",
                            "endDateReadOnly": false,
                            "inputDateFormat": false,
                            "fordisabledStart": "",
                            "wrapperClassName": "",
                            "startDateReadOnly": false,
                            "startDatePlaceHolder": "MM/DD/YYYY",
                            "validationButtonType": "",
                            "isAdditonalInputAfter": true,
                            "selectedDateClassName": "selectedDate",
                            "additionalInputContent": "",
                            "showAdditionalInputContent": false
                        }
                    ],
                    "classname": "col-6 mb-3",
                    "draggable": false,
                    "wrapperType": "BOX",
                    "tabHeadClass": ""
                },
                {
                    "control": [
                        {
                            "type": "text",
                            "name": "status",
                            "id": "status",
                            "value": "",
                            "placeholder": "",
                            "multiline": false,
                            "classname": "form-control ",
                            "regularExpression": "",
                            "disabled": true,
                            "required": false,
                            "errorMessage": {
                                "noData": "This fields is required",
                                "regexFail": ""
                            },
                            "validationButtonType": "",
                            "rows": 0,
                            "columns": 0,
                            "onDemandError": false,
                            "customValidation": [],
                            "multiError": false,
                            "applicationError": "",
                            "wrapperClassName": "",
                            "requiredErrorMessage": "This fields is required",
                            "__schema_id": "status",
                            "showInputLabel": false,
                            "labelContent": "text_977",
                            "labelClass": "form-label",
                            "isLabelAfter": false,
                            "showAdditionalInputContent": false,
                            "additionalInputContent": "",
                            "isAdditonalInputAfter": true,
                            "draggable": false
                        },
                        {
                            "type": "text",
                            "name": "experiment_type",
                            "id": "experiment_type",
                            "value": "",
                            "placeholder": "",
                            "multiline": false,
                            "classname": "form-control ",
                            "regularExpression": "",
                            "disabled": true,
                            "required": false,
                            "errorMessage": {
                                "noData": "This fields is required",
                                "regexFail": ""
                            },
                            "validationButtonType": "",
                            "rows": 0,
                            "columns": 0,
                            "onDemandError": false,
                            "customValidation": [],
                            "multiError": false,
                            "applicationError": "",
                            "wrapperClassName": "",
                            "requiredErrorMessage": "This fields is required",
                            "__schema_id": "experiment_type",
                            "showInputLabel": false,
                            "labelContent": "text_581",
                            "labelClass": "form-label",
                            "isLabelAfter": false,
                            "showAdditionalInputContent": false,
                            "additionalInputContent": "",
                            "isAdditonalInputAfter": true,
                            "draggable": false
                        }
                    ],
                    "type": "",
                    "name": "wrapper_237",
                    "classname": "col-12 d-none",
                    "wrapperType": "BOX",
                    "id": "wrapper_929",
                    "fields": [],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false
                }
            ],
            "control": [],
            "classname": "row",
            "draggable": false,
            "wrapperType": "BOX",
            "tabHeadClass": ""
        }
    ],
    "control": [],
    "classname": "form-container",
    "wrapperType": "REGULAR",
    "payload_schema": {
        "projectInitDetails": {
            "title": "project_title",
            "init_user": "project_init_by",
            "experiment_type": "experiment_type",
            "start_date": "project_start_date",
            "end_date": "project_end_date",
            "status": "status"
        }
    },
    "validation_type": "initial-submit",
}
//clone selection
const cloneSelection={
    "control": [],
    "jsonId": 160,
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
        {
            "control": [],
            "type": "",
            "name": "wrapper_63",
            "classname": "col-12 project-table",
            "wrapperType": "BOX",
            "id": "wrapper_742",
            "fields": [
                {
                    "control": [
                        {
                            "type": "text",
                            "name": "textBox_889",
                            "id": "textBox_156",
                            "value": "",
                            "placeholder": "",
                            "multiline": false,
                            "classname": "form-control ",
                            "regularExpression": "",
                            "disabled": false,
                            "required": false,
                            "errorMessage": {
                                "noData": "This fields is required",
                                "regexFail": ""
                            },
                            "validationButtonType": "",
                            "rows": 0,
                            "columns": 0,
                            "onDemandError": false,
                            "customValidation": [],
                            "multiError": false,
                            "applicationError": "",
                            "wrapperClassName": "",
                            "requiredErrorMessage": "This fields is required",
                            "__schema_id": "",
                            "showInputLabel": true,
                            "labelContent": "text_723",
                            "labelClass": "form-label",
                            "isLabelAfter": false,
                            "showAdditionalInputContent": false,
                            "additionalInputContent": "",
                            "isAdditonalInputAfter": true,
                            "draggable": false
                        }
                    ],
                    "type": "",
                    "name": "clone_selection",
                    "classname": "table clone-selection-pr-7",
                    "wrapperType": "TABLE",
                    "id": "wrapper_274",
                    "fields": [],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false,
                    "hideSingleItemDelete": false,
                    "tableFields": {
                        "showHead": true,
                        "isVertical": false,
                        "isIteratable": false,
                        "addButton": {
                            "classname": "btn btn-primary",
                            "name": "",
                            "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                        },
                        "removeButton": {
                            "classname": "btn btn-danger",
                            "name": "",
                            "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                        },
                        "tableHead": {
                            "heading": [
                                {
                                    "value": "PROJECT ID",
                                    "classname": ""
                                },
                                {
                                    "value": "Screening ID",
                                    "classname": ""
                                },
                                {
                                    "value": "Rounds",
                                    "classname": ""
                                },
                                {
                                    "value": "Clone PLATE ID",
                                    "classname": ""
                                },
                                {
                                    "value": "Storage Location",
                                    "classname": ""
                                },
                                {
                                    "value": "Action",
                                    "classname": ""
                                }
                            ],
                            "classname": ""
                        },
                        "initRecordCount": 1,
                        "showRecordCount": false,
                        "recordCounterConfig": {
                            "classname": "",
                            "adjValue": "",
                            "isAdjValueAfter": false
                        },
                        "tableRow": {
                            "name": "",
                            "classname": "trclass",
                            "tableCols": [
                                {
                                    "fields": {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "project_id",
                                                "id": "project_id",
                                                "validationButtonType": "",
                                                "classname": "",
                                                "required": false,
                                                "apiEndPoints": [
                                                    {
                                                        "type": "init",
                                                        "method": "post",
                                                        "url": "project/10/instance/search",
                                                        "payload": {
                                                            "filter": {
                                                                "instance_title": "",
                                                                "instance_status": {
                                                                    "id": 5,
                                                                    "value": ""
                                                                }
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "type": "change",
                                                        "method": "get",
                                                        "url": "project/10/instance/{project_instance_id}/flow/{flow_instance_id}",
                                                        "Mapto": [
                                                            {
                                                                "id": "main.wrapper_742.wrapper_274.0.box_501.dropDown_728",
                                                                "displayKey": "change",
                                                                "urlParameterValues": {},
                                                                "type": null
                                                            }
                                                        ]
                                                    }
                                                ],
                                                "options": [],
                                                "multiple": false,
                                                "isClearable": false,
                                                "isStatus": false,
                                                "search": true,
                                                "singleSelect": true,
                                                "showLabel": "project_instance_code",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": "",
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "project_id",
                                                "showInputLabel": false,
                                                "labelContent": "dropdown_533",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "head": "",
                                        "name": "box_350",
                                        "id": "box_459",
                                        "wrapperType": "BOX",
                                        "fields": [],
                                        "type": "",
                                        "classname": ""
                                    },
                                    "classname": ""
                                },
                                {
                                    "fields": {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "screening_id",
                                                "id": "screening_id",
                                                "validationButtonType": "",
                                                "classname": "",
                                                "required": false,
                                                "apiEndPoints": [],
                                                "options": [],
                                                "multiple": false,
                                                "isClearable": false,
                                                "isStatus": false,
                                                "search": true,
                                                "singleSelect": true,
                                                "showLabel": "",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": "",
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "screening_id",
                                                "showInputLabel": false,
                                                "labelContent": "dropdown_393",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "name": "box_214",
                                        "id": "box_501",
                                        "wrapperType": "BOX",
                                        "fields": [],
                                        "type": "",
                                        "classname": "",
                                        "addButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "removeButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "wrapperClassName": ""
                                    },
                                    "classname": ""
                                },
                                {
                                    "fields": {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "rounds",
                                                "id": "rounds",
                                                "validationButtonType": "",
                                                "classname": "",
                                                "required": false,
                                                "apiEndPoints": [],
                                                "options": [],
                                                "multiple": true,
                                                "isClearable": true,
                                                "isStatus": false,
                                                "search": true,
                                                "singleSelect": false,
                                                "showLabel": "clone_rounds",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": "",
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "rounds",
                                                "showInputLabel": false,
                                                "labelContent": "dropdown_593",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "name": "box_265",
                                        "id": "box_254",
                                        "wrapperType": "BOX",
                                        "fields": [],
                                        "type": "",
                                        "classname": "",
                                        "wrapperClassName": "",
                                        "addBtnPostion": null
                                    },
                                    "classname": ""
                                },
                                {
                                    "fields": {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "clone_plate_id",
                                                "id": "clone_plate_id",
                                                "validationButtonType": "",
                                                "classname": "",
                                                "required": false,
                                                "apiEndPoints": [],
                                                "options": [],
                                                "multiple": true,
                                                "isClearable": true,
                                                "isStatus": false,
                                                "search": true,
                                                "singleSelect": false,
                                                "showLabel": "clone_plate_id",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": "",
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "clone_plate_id",
                                                "showInputLabel": false,
                                                "labelContent": "dropdown_356",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "name": "box_628",
                                        "id": "box_101",
                                        "wrapperType": "BOX",
                                        "fields": [],
                                        "type": "",
                                        "classname": "",
                                        "wrapperClassName": "",
                                        "addBtnPostion": null
                                    },
                                    "classname": ""
                                },
                                {
                                    "fields": {
                                        "control": [
                                            {
                                                "type": "button",
                                                "id": "infoicon",
                                                "value": "View Location",
                                                "buttonType": "plate_storage",
                                                "classname": "info-icon",
                                                "divClass": "",
                                                "controlEndPoints": [],
                                                "disabled": false,
                                                "validationEndPoints": [],
                                                "invalid": false,
                                                "name": "infoicon",
                                                "wrapperClassName": "",
                                                "draggable": false
                                            }
                                        ],
                                        "name": "box_59",
                                        "id": "box_42",
                                        "wrapperType": "BOX",
                                        "fields": [],
                                        "type": "",
                                        "classname": "",
                                        "wrapperClassName": "",
                                        "addBtnPostion": null
                                    },
                                    "classname": ""
                                },
                                {
                                    "fields": {
                                        "control": [
                                            {
                                                "type": "button",
                                                "id": "button_62",
                                                "value": "<div class='custom-tooltip small-tooltip'><p>View</p></div>",
                                                "buttonType": "view_plates",
                                                "classname": "plasmid-view",
                                                "divClass": "",
                                                "controlEndPoints": [],
                                                "disabled": false,
                                                "validationEndPoints": [],
                                                "invalid": false,
                                                "name": "view_plates",
                                                "wrapperClassName": "",
                                                "draggable": false
                                            }
                                        ],
                                        "name": "box_985",
                                        "id": "box_441",
                                        "wrapperType": "BOX",
                                        "fields": [],
                                        "type": "",
                                        "classname": "text-center",
                                        "wrapperClassName": "",
                                        "addBtnPostion": null
                                    },
                                    "classname": ""
                                }
                            ]
                        }
                    }
                }
            ],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
        }
    ],
    "type": "",
    "classname": "",
    "payload_schema": {
        "cloneSelection": [
            {
                "project_id": "project_id",
                "screening_id": "screening_id",
                "rounds": "rounds",
                "clone_plate_id": "clone_plate_id"
            }
        ]
    }
}
//lab
const labConsumbales= {
    "jsonId": 161,
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
        {
            "control": [],
            "type": "",
            "name": "wrapper_217",
            "classname": "project-table",
            "wrapperType": "BOX",
            "id": "wrapper_7",
            "fields": [
                {
                    "control": [],
                    "type": "",
                    "name": "lab",
                    "classname": "table reagents-table-p2",
                    "wrapperType": "TABLE",
                    "id": "lab_consumables",
                    "fields": [],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false,
                    "hideSingleItemDelete": false,
                    "tableFields": {
                        "showHead": true,
                        "isVertical": false,
                        "isIteratable": true,
                        "addButton": {
                            "classname": "add-expand",
                            "name": "",
                            "value": "<div class='custom-tooltip'><p>Add Row</p></div>"
                        },
                        "removeButton": {
                            "classname": "add-minus ",
                            "name": "",
                            "value": "<div class='custom-tooltip'><p>Remove Row </p></div>"
                        },
                        "tableHead": {
                            "heading": [
                                {
                                    "value": "Select from  Inventory",
                                    "classname": ""
                                },
                                {
                                    "value": "Name",
                                    "classname": ""
                                },
                                {
                                    "value": "Vendor",
                                    "classname": ""
                                },
                                {
                                    "value": "Quantity/Tube (ml)",
                                    "classname": ""
                                },
                                {
                                    "value": "Units  available",
                                    "classname": ""
                                },
                                {
                                    "value": "Units  taken",
                                    "classname": ""
                                },
                                {
                                    "value": "Storage Location",
                                    "classname": ""
                                },
                                {
                                    "value": "Action",
                                    "classname": ""
                                },
                                {
                                    "value": "",
                                    "classname": ""
                                }
                            ],
                            "classname": ""
                        },
                        "initRecordCount": 1,
                        "showRecordCount": false,
                        "recordCounterConfig": {
                            "classname": "",
                            "adjValue": "",
                            "isAdjValueAfter": false
                        },
                        "tableRow": {
                            "name": "",
                            "classname": "trclass",
                            "tableCols": [
                                {
                                    "fields": {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "select_inventory",
                                                "id": "select_inventory",
                                                "validationButtonType": "",
                                                "classname": "",
                                                "required": false,
                                                "apiEndPoints": [
                                                    {
                                                        "type": "change",
                                                        "method": "post",
                                                        "url": "inventory_item",
                                                        "Mapto": [
                                                            {
                                                                "id": "main.wrapper_7.lab_consumables.${i}.box_90.inv_item",
                                                                "urlParameters": "",
                                                                "value": "",
                                                                "payload": {
                                                                    "perma": "plasticwares",
                                                                    "user_input": ""
                                                                },
                                                                "type": null
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        "type": "init",
                                                        "method": "post",
                                                        "url": "inventory_item/item-type",
                                                        "Mapto": [],
                                                        "payload": {
                                                            "perma": [
                                                                "assay-kits",
                                                                "cell-culture-media-antibiotics-agar-plates",
                                                                "gels-buffers-lab-reagents",
                                                                "plasticwares",
                                                                "glasswares",
                                                                "cell-lines",
                                                                "bacterial-strains",
                                                                "plasmids"
                                                            ]
                                                        }
                                                    }
                                                ],
                                                "options": [],
                                                "multiple": false,
                                                "isClearable": false,
                                                "isStatus": false,
                                                "search": true,
                                                "singleSelect": true,
                                                "showLabel": "inv_item_type",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": "",
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "select_inventory",
                                                "showInputLabel": false,
                                                "labelContent": "dropdown_448",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "head": "",
                                        "name": "box_893",
                                        "id": "box_760",
                                        "wrapperType": "BOX",
                                        "fields": [],
                                        "type": "",
                                        "classname": ""
                                    },
                                    "classname": ""
                                },
                                {
                                    "fields": {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "inv_item",
                                                "id": "inv_item",
                                                "validationButtonType": "",
                                                "classname": "",
                                                "required": false,
                                                "apiEndPoints": [
                                                    {
                                                        "type": "change",
                                                        "method": "get",
                                                        "url": "inventory_item/${id}",
                                                        "Mapto": [
                                                            {
                                                                "id": "main.wrapper_7.lab_consumables.${i}.box_584.vendor",
                                                                "displayKey": "change",
                                                                "urlParameterValues": {
                                                                    "id": null,
                                                                    "patchProperty": "vendor_name"
                                                                }
                                                            },
                                                            {
                                                                "id": "main.wrapper_7.lab_consumables.${i}.box_908.capacity",
                                                                "displayKey": "change",
                                                                "urlParameterValues": {
                                                                    "id": null,
                                                                    "patchProperty": "item_volume"
                                                                }
                                                            },
                                                            {
                                                                "id": "main.wrapper_7.lab_consumables.${i}.box_293.units_available",
                                                                "displayKey": "change",
                                                                "urlParameterValues": {
                                                                    "id": null,
                                                                    "patchProperty": "item_quantity"
                                                                }
                                                            },
                                                            {
                                                                "id": "main.wrapper_7.lab_consumables.${i}.box_126.item_details",
                                                                "displayKey": "item_details",
                                                                "urlParameterValues": {
                                                                    "id": null
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ],
                                                "options": [],
                                                "multiple": false,
                                                "isClearable": false,
                                                "isStatus": false,
                                                "search": true,
                                                "singleSelect": true,
                                                "showLabel": "value",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": "",
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "inv_item",
                                                "showInputLabel": false,
                                                "labelContent": "dropdown_532",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "name": "box_289",
                                        "id": "box_90",
                                        "wrapperType": "BOX",
                                        "fields": [],
                                        "type": "",
                                        "classname": "",
                                        "addButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "removeButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "wrapperClassName": "",
                                        "draggable": false
                                    },
                                    "classname": ""
                                },
                                {
                                    "fields": {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "vendor",
                                                "id": "vendor",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": true,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "vendor",
                                                "showInputLabel": false,
                                                "labelContent": "text_305",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "name": "box_924",
                                        "id": "box_584",
                                        "wrapperType": "BOX",
                                        "fields": [],
                                        "type": "",
                                        "classname": "",
                                        "addButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "removeButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "wrapperClassName": ""
                                    },
                                    "classname": ""
                                },
                                {
                                    "fields": {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "capacity",
                                                "id": "capacity",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": true,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": true,
                                                "customValidation": [
                                                    {
                                                        "regularExpression": "/^\\d+$/",
                                                        "errorMessage": "Please enter a valid number"
                                                    }
                                                ],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "capacity",
                                                "showInputLabel": false,
                                                "labelContent": "text_166",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "name": "box_12",
                                        "id": "box_908",
                                        "wrapperType": "BOX",
                                        "fields": [],
                                        "type": "",
                                        "classname": "",
                                        "addButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "removeButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "wrapperClassName": ""
                                    },
                                    "classname": ""
                                },
                                {
                                    "fields": {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "units_available",
                                                "id": "units_available",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": true,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": true,
                                                "customValidation": [
                                                    {
                                                        "regularExpression": "/^\\d+$/",
                                                        "errorMessage": "Please enter a valid number"
                                                    }
                                                ],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "units_available",
                                                "showInputLabel": false,
                                                "labelContent": "text_159",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "name": "box_213",
                                        "id": "box_293",
                                        "wrapperType": "BOX",
                                        "fields": [],
                                        "type": "",
                                        "classname": "",
                                        "addButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "removeButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "wrapperClassName": ""
                                    },
                                    "classname": ""
                                },
                                {
                                    "fields": {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "units_taken",
                                                "id": "units_taken",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": true,
                                                "customValidation": [
                                                    {
                                                        "regularExpression": "/^\\d+$/",
                                                        "errorMessage": "Please enter a valid number"
                                                    }
                                                ],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "units_taken",
                                                "showInputLabel": false,
                                                "labelContent": "text_885",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "name": "box_214",
                                        "id": "box_798",
                                        "wrapperType": "BOX",
                                        "fields": [],
                                        "type": "",
                                        "classname": "action-wrap ",
                                        "addButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "removeButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "wrapperClassName": ""
                                    },
                                    "classname": ""
                                },
                                {
                                    "fields": {
                                        "control": [
                                            {
                                                "type": "button",
                                                "id": "infoicon",
                                                "value": "View Location",
                                                "buttonType": "storage_info",
                                                "classname": "info-icon",
                                                "divClass": "",
                                                "controlEndPoints": [],
                                                "disabled": false,
                                                "validationEndPoints": [],
                                                "invalid": false,
                                                "name": "infoicon",
                                                "wrapperClassName": "text-nowrap",
                                                "draggable": false
                                            },
                                            {
                                                "type": "text",
                                                "name": "item_details",
                                                "id": "item_details",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control d-none",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "item_details",
                                                "showInputLabel": false,
                                                "labelContent": "text_995",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "name": "box_261",
                                        "id": "box_126",
                                        "wrapperType": "BOX",
                                        "fields": [],
                                        "type": "",
                                        "classname": " ",
                                        "addButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "removeButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "wrapperClassName": ""
                                    },
                                    "classname": ""
                                },
                                {
                                    "fields": {
                                        "control": [],
                                        "name": "box_564",
                                        "id": "box_24",
                                        "wrapperType": "BOX",
                                        "fields": [],
                                        "type": "",
                                        "classname": "",
                                        "addButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "removeButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "wrapperClassName": ""
                                    },
                                    "classname": ""
                                }
                            ]
                        }
                    }
                }
            ],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
        }
    ],
    "type": "",
    "classname": "",
    "showClass": true,
    "payload_schema": {
        "consumables": [
            {
                "inv_category": "select_inventory",
                "inv_item": "inv_item",
                "vendor": "vendor.$d{}",
                "vol_capacity": "capacity.$t{number}",
                "no_unit_available": "units_available.$t{number}",
                "no_unit_taken": "units_taken.$d{}",
                "storage": "item_details.{{assigned_storage}}"
            }
        ],
        "inventory__": {
            "itm_qty": [
                {
                    "inv_item_id": "inv_item.{{id}}",
                    "qntity_taken": "units_taken.$d{}"
                }
            ]
        }
    }
}
 //summary
const summary=  
{
    "control": [],
    "jsonId": 162,
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
        {
            "control": [
                {
                    "type": "label",
                    "name": "<h2 class=\"add-heading heading-without-icon\"><span>Summary</span></h2>",
                    "classname": "w-100",
                    "for": "",
                    "required": false,
                    "wrapperClassName": "",
                    "id": "label_98",
                    "draggable": false
                }
            ],
            "type": "",
            "name": "wrapper_847",
            "classname": "col-12",
            "wrapperType": "BOX",
            "id": "wrapper_850",
            "fields": [
                {
                    "control": [],
                    "type": "",
                    "name": "wrapper_650",
                    "classname": "row mb-3",
                    "wrapperType": "BOX",
                    "id": "wrapper_860",
                    "fields": [
                        {
                            "control": [
                                {
                                    "type": "text",
                                    "name": "proj_type",
                                    "id": "proj_type",
                                    "value": "",
                                    "placeholder": "",
                                    "multiline": false,
                                    "classname": "form-control ",
                                    "regularExpression": "",
                                    "disabled": true,
                                    "required": false,
                                    "errorMessage": {
                                        "noData": "This fields is required",
                                        "regexFail": ""
                                    },
                                    "validationButtonType": "",
                                    "rows": 0,
                                    "columns": 0,
                                    "onDemandError": false,
                                    "customValidation": [],
                                    "multiError": false,
                                    "applicationError": "",
                                    "wrapperClassName": "",
                                    "requiredErrorMessage": "This fields is required",
                                    "__schema_id": "proj_type",
                                    "showInputLabel": true,
                                    "labelContent": "Project Type",
                                    "labelClass": "form-label",
                                    "isLabelAfter": false,
                                    "showAdditionalInputContent": false,
                                    "additionalInputContent": "",
                                    "isAdditonalInputAfter": true,
                                    "draggable": false
                                }
                            ],
                            "type": "",
                            "name": "wrapper_440",
                            "classname": "col-4",
                            "wrapperType": "BOX",
                            "id": "wrapper_855",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": ""
                        },
                        {
                            "control": [
                                {
                                    "type": "text",
                                    "name": "title",
                                    "id": "title",
                                    "value": "",
                                    "placeholder": "",
                                    "multiline": false,
                                    "classname": "form-control ",
                                    "regularExpression": "",
                                    "disabled": true,
                                    "required": false,
                                    "errorMessage": {
                                        "noData": "This fields is required",
                                        "regexFail": ""
                                    },
                                    "validationButtonType": "",
                                    "rows": 0,
                                    "columns": 0,
                                    "onDemandError": false,
                                    "customValidation": [],
                                    "multiError": false,
                                    "applicationError": "",
                                    "wrapperClassName": "",
                                    "requiredErrorMessage": "This fields is required",
                                    "__schema_id": "title",
                                    "showInputLabel": true,
                                    "labelContent": "Project Title",
                                    "labelClass": "form-label",
                                    "isLabelAfter": false,
                                    "showAdditionalInputContent": false,
                                    "additionalInputContent": "",
                                    "isAdditonalInputAfter": true,
                                    "draggable": false
                                }
                            ],
                            "type": "",
                            "name": "wrapper_457",
                            "classname": "col-4",
                            "wrapperType": "BOX",
                            "id": "wrapper_393",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": ""
                        }
                    ],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false
                },
                {
                    "control": [],
                    "type": "",
                    "name": "wrapper_195",
                    "classname": "row mb-3",
                    "wrapperType": "BOX",
                    "id": "wrapper_646",
                    "fields": [
                        {
                            "control": [
                                {
                                    "type": "text",
                                    "name": "objective",
                                    "id": "objective",
                                    "value": "",
                                    "placeholder": "",
                                    "multiline": false,
                                    "classname": "form-control ",
                                    "regularExpression": "",
                                    "disabled": false,
                                    "required": true,
                                    "errorMessage": {
                                        "noData": "This fields is required",
                                        "regexFail": ""
                                    },
                                    "validationButtonType": "",
                                    "rows": 0,
                                    "columns": 0,
                                    "onDemandError": false,
                                    "customValidation": [],
                                    "multiError": false,
                                    "applicationError": "",
                                    "wrapperClassName": "",
                                    "requiredErrorMessage": "This fields is required",
                                    "__schema_id": "objective",
                                    "showInputLabel": true,
                                    "labelContent": "Objective",
                                    "labelClass": "form-label",
                                    "isLabelAfter": false,
                                    "showAdditionalInputContent": false,
                                    "additionalInputContent": "",
                                    "isAdditonalInputAfter": true,
                                    "draggable": false
                                },
                                {
                                    "type": "text",
                                    "name": "proj_code",
                                    "id": "proj_code",
                                    "value": "SIM1001",
                                    "placeholder": "",
                                    "multiline": false,
                                    "classname": "form-control d-none",
                                    "regularExpression": "",
                                    "disabled": true,
                                    "required": true,
                                    "errorMessage": {
                                        "noData": "This fields is required",
                                        "regexFail": ""
                                    },
                                    "validationButtonType": "",
                                    "rows": 0,
                                    "columns": 0,
                                    "onDemandError": false,
                                    "customValidation": [],
                                    "multiError": false,
                                    "applicationError": "",
                                    "wrapperClassName": "",
                                    "requiredErrorMessage": "This fields is required",
                                    "__schema_id": "proj_code",
                                    "showInputLabel": false,
                                    "labelContent": "Objective",
                                    "labelClass": "form-label",
                                    "isLabelAfter": false,
                                    "showAdditionalInputContent": false,
                                    "additionalInputContent": "",
                                    "isAdditonalInputAfter": true,
                                    "draggable": false
                                }
                            ],
                            "type": "",
                            "name": "wrapper_413",
                            "classname": "col-12",
                            "wrapperType": "BOX",
                            "id": "wrapper_270",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": ""
                        },
                        {
                            "control": [],
                            "type": "",
                            "name": "wrapper_834",
                            "classname": "col-8",
                            "wrapperType": "BOX",
                            "id": "wrapper_697",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": ""
                        }
                    ],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false
                },
                {
                    "control": [
                        {
                            "type": "text",
                            "name": "comments",
                            "id": "comments",
                            "value": "",
                            "placeholder": "Enter your comments if any",
                            "multiline": true,
                            "classname": "form-control ",
                            "regularExpression": "",
                            "disabled": false,
                            "required": false,
                            "errorMessage": {
                                "noData": "This fields is required",
                                "regexFail": ""
                            },
                            "validationButtonType": "",
                            "rows": 0,
                            "columns": 0,
                            "onDemandError": false,
                            "customValidation": [],
                            "multiError": false,
                            "applicationError": "",
                            "wrapperClassName": "",
                            "requiredErrorMessage": "This fields is required",
                            "__schema_id": "comments",
                            "showInputLabel": true,
                            "labelContent": "Experiment Notes/Comments",
                            "labelClass": "form-label",
                            "isLabelAfter": false,
                            "showAdditionalInputContent": false,
                            "additionalInputContent": "",
                            "isAdditonalInputAfter": true,
                            "draggable": false
                        }
                    ],
                    "type": "",
                    "name": "wrapper_269",
                    "classname": "col-12",
                    "wrapperType": "BOX",
                    "id": "wrapper_521",
                    "fields": [],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false
                }
            ],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
        }
    ],
    "type": "",
    "classname": "",
    "showClass": true,
    "payload_schema": {
        "project_summary": {
            "title": "title",
            "proj_type": "proj_type",
            "objective": "objective",
            "comments": "comments"
        }
    },
    "validation_type": "submit"
}

// pcr_template_dna_preparation workflow
const dnaPreparationSequencing={
    "control": [],
    "jsonId": 163,
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
        {
            "control": [],
            "type": "",
            "name": "wrapper_156",
            "classname": "row",
            "wrapperType": "BOX",
            "id": "wrapper_880",
            "fields": [
                {
                    "control": [
                        {
                            "type": "dropdown",
                            "name": "protocol",
                            "id": "protocol",
                            "validationButtonType": "",
                            "classname": "mb-3",
                            "required": false,
                            "apiEndPoints": [
                                {
                                    "type": "init",
                                    "method": "get",
                                    "url": "protocol/protocol-list",
                                    "Mapto": []
                                },
                                {
                                    "type": "change",
                                    "method": "get",
                                    "url": "protocol/${id}",
                                    "Mapto": [
                                        {
                                            "id": "main.wrapper_880.wrapper_90.protocol_file_id",
                                            "displayKey": "change",
                                            "urlParameterValues": {
                                                "id": null,
                                                "patchProperty": "protocol_file_id"
                                            },
                                            "type": null
                                        }
                                    ]
                                }
                            ],
                            "options": [],
                            "multiple": false,
                            "isClearable": false,
                            "isStatus": false,
                            "search": false,
                            "singleSelect": true,
                            "showLabel": "value",
                            "errorMessage": {
                                "noData": "This fields is required"
                            },
                            "value": "",
                            "imageUrl": "",
                            "disabled": false,
                            "wrapperClassName": "",
                            "connectedArrays": [],
                            "showBlankInPlaceholder": false,
                            "blankDataList": [],
                            "commonPathOfImage": "",
                            "__schema_id": "protocol",
                            "showInputLabel": true,
                            "labelContent": "Select Protocol",
                            "labelClass": "form-label",
                            "isLabelAfter": false,
                            "showAdditionalInputContent": false,
                            "additionalInputContent": "",
                            "isAdditonalInputAfter": true,
                            "draggable": false
                        },
                        {
                            "type": "button",
                            "id": "view_protocol_btn",
                            "value": "<div class=\"custom-tooltip\"><p>View </p></div>",
                            "buttonType": "view-protocol",
                            "classname": "view icon-align ms-3",
                            "divClass": "",
                            "controlEndPoints": [],
                            "disabled": false,
                            "validationEndPoints": [],
                            "invalid": false,
                            "name": "view_protocol_btn",
                            "wrapperClassName": "actions position-relative ",
                            "draggable": false
                        },
                        {
                            "type": "text",
                            "name": "protocol_file_id",
                            "id": "protocol_file_id",
                            "value": "",
                            "placeholder": "",
                            "multiline": false,
                            "classname": "form-control d-none",
                            "regularExpression": "",
                            "disabled": false,
                            "required": false,
                            "errorMessage": {
                                "noData": "This fields is required",
                                "regexFail": ""
                            },
                            "validationButtonType": "",
                            "rows": 0,
                            "columns": 0,
                            "onDemandError": false,
                            "customValidation": [],
                            "multiError": false,
                            "applicationError": "",
                            "wrapperClassName": "",
                            "requiredErrorMessage": "This fields is required",
                            "__schema_id": "protocol_file_id",
                            "showInputLabel": false,
                            "labelContent": "text_398",
                            "labelClass": "form-label",
                            "isLabelAfter": false,
                            "showAdditionalInputContent": false,
                            "additionalInputContent": "",
                            "isAdditonalInputAfter": true,
                            "draggable": false
                        }
                    ],
                    "type": "",
                    "name": "wrapper_652",
                    "classname": "col-3 mb-3 action-wrap",
                    "wrapperType": "BOX",
                    "id": "wrapper_90",
                    "fields": [],
                    "head": "",
                    "tabHeadClass": ""
                },
                {
                    "control": [
                        {
                            "type": "text",
                            "name": "gel_image_file_id",
                            "id": "gel_image_file_id",
                            "value": "",
                            "placeholder": "",
                            "multiline": false,
                            "classname": "form-control d-none",
                            "regularExpression": "",
                            "disabled": false,
                            "required": false,
                            "errorMessage": {
                                "noData": "This fields is required",
                                "regexFail": ""
                            },
                            "validationButtonType": "",
                            "rows": 0,
                            "columns": 0,
                            "onDemandError": false,
                            "customValidation": [],
                            "multiError": false,
                            "applicationError": "",
                            "wrapperClassName": "",
                            "requiredErrorMessage": "This fields is required",
                            "__schema_id": "gel_image_file_id",
                            "showInputLabel": false,
                            "labelContent": "text_398",
                            "labelClass": "form-label",
                            "isLabelAfter": false,
                            "showAdditionalInputContent": false,
                            "additionalInputContent": "",
                            "isAdditonalInputAfter": true,
                            "draggable": false
                        }
                    ],
                    "type": "",
                    "name": "wrapper_386",
                    "classname": "col-5",
                    "wrapperType": "BOX",
                    "id": "wrapper_768",
                    "fields": [],
                    "head": "",
                    "tabHeadClass": ""
                },
                {
                    "control": [
                        {
                            "type": "button",
                            "id": "button_780",
                            "value": "Upload Gel Image",
                            "buttonType": "dna_sequence_gel_image",
                            "classname": "btn btn-outline-primary mt-3 upload-btn",
                            "divClass": "",
                            "controlEndPoints": [],
                            "disabled": false,
                            "validationEndPoints": [],
                            "invalid": false,
                            "name": "button_664",
                            "wrapperClassName": "",
                            "draggable": false
                        },
                        {
                            "type": "button",
                            "id": "button_780",
                            "value": "Upload CDR Sequence",
                            "buttonType": "dna_sequence_cdr_file",
                            "classname": "btn btn-outline-primary ms-1 mt-3 upload-btn",
                            "divClass": "",
                            "controlEndPoints": [],
                            "disabled": false,
                            "validationEndPoints": [],
                            "invalid": false,
                            "name": "button_664",
                            "wrapperClassName": "",
                            "draggable": false
                        },
                    ],
                    "type": "",
                    "name": "wrapper_282",
                    "classname": "col-4 d-flex justify-content-end",
                    "wrapperType": "BOX",
                    "id": "wrapper_861",
                    "fields": [],
                    "head": "",
                    "tabHeadClass": ""
                }
            ],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
        },
        {
            "control": [],
            "type": "",
            "name": "wrapper_547",
            "classname": "accordion accordion-custom",
            "wrapperType": "ACCORDION",
            "id": "wrapper_120",
            "fields": [
                {
                    "control": [
                        {
                            "type": "label",
                            "name": "<h2>Selected Clone Details</h2>",
                            "classname": "sub-heading mt-3",
                            "for": "",
                            "required": false,
                            "wrapperClassName": "",
                            "id": "label_360",
                            "draggable": false
                        }
                    ],
                    "type": "",
                    "name": "wrapper_240",
                    "classname": "col-12",
                    "wrapperType": "BOX",
                    "id": "wrapper_456",
                    "fields": [
                        {
                            "control": [],
                            "type": "",
                            "name": "clone_info",
                            "classname": "table clone-info-table-pr-7",
                            "wrapperType": "TABLE",
                            "id": "wrapper_97",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": "",
                            "draggable": false,
                            "hideSingleItemDelete": false,
                            "tableFields": {
                                "showHead": true,
                                "isVertical": false,
                                "isIteratable": false,
                                "addButton": {
                                    "classname": "btn btn-primary",
                                    "name": "",
                                    "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                },
                                "removeButton": {
                                    "classname": "btn btn-danger",
                                    "name": "",
                                    "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                },
                                "tableHead": {
                                    "heading": [
                                        {
                                            "value": "Project ID",
                                            "classname": ""
                                        },
                                        {
                                            "value": "Screening ID",
                                            "classname": ""
                                        },
                                        {
                                            "value": "Phage Clone Plate ID",
                                            "classname": ""
                                        },
                                        {
                                            "value": "Clones selected for  Sequencing",
                                            "classname": ""
                                        },
                                        {
                                            "value": "Action",
                                            "classname": ""
                                        }
                                    ],
                                    "classname": ""
                                },
                                "initRecordCount": 1,
                                "showRecordCount": false,
                                "recordCounterConfig": {
                                    "classname": "",
                                    "adjValue": "",
                                    "isAdjValueAfter": false
                                },
                                "tableRow": {
                                    "name": "",
                                    "classname": "trclass",
                                    "tableCols": [
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "project_id",
                                                        "id": "project_id",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": true,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "project_id",
                                                        "showInputLabel": false,
                                                        "labelContent": "text_759",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "head": "",
                                                "name": "box_876",
                                                "id": "box_894",
                                                "wrapperType": "BOX",
                                                "fields": [],
                                                "type": "",
                                                "classname": ""
                                            },
                                            "classname": ""
                                        },
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "screening_id",
                                                        "id": "screening_id",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": true,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "screening_id",
                                                        "showInputLabel": false,
                                                        "labelContent": "text_508",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "name": "box_139",
                                                "id": "box_440",
                                                "wrapperType": "BOX",
                                                "fields": [],
                                                "type": "",
                                                "classname": "",
                                                "addButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "removeButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "wrapperClassName": ""
                                            },
                                            "classname": ""
                                        },
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "dropdown",
                                                        "name": "clone_plate_id",
                                                        "id": "clone_plate_id",
                                                        "validationButtonType": "",
                                                        "classname": "",
                                                        "required": false,
                                                        "apiEndPoints": [],
                                                        "options": [],
                                                        "multiple": true,
                                                        "isClearable": true,
                                                        "isStatus": false,
                                                        "search": false,
                                                        "singleSelect": false,
                                                        "showLabel": "clone_plate_id",
                                                        "errorMessage": {
                                                            "noData": "This fields is required"
                                                        },
                                                        "value": "",
                                                        "imageUrl": "",
                                                        "disabled": false,
                                                        "wrapperClassName": "",
                                                        "connectedArrays": [],
                                                        "showBlankInPlaceholder": false,
                                                        "blankDataList": [],
                                                        "commonPathOfImage": "",
                                                        "__schema_id": "clone_plate_id",
                                                        "showInputLabel": false,
                                                        "labelContent": "dropdown_10",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "name": "box_854",
                                                "id": "box_413",
                                                "wrapperType": "BOX",
                                                "fields": [],
                                                "type": "",
                                                "classname": "",
                                                "addButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "removeButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "wrapperClassName": ""
                                            },
                                            "classname": ""
                                        },
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "clone_selected",
                                                        "id": "clone_selected",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": true,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "clone_selected",
                                                        "showInputLabel": false,
                                                        "labelContent": "text_469",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "name": "box_429",
                                                "id": "box_911",
                                                "wrapperType": "BOX",
                                                "fields": [],
                                                "type": "",
                                                "classname": "",
                                                "addButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "removeButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "wrapperClassName": ""
                                            },
                                            "classname": ""
                                        },
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "button",
                                                        "id": "button_155",
                                                        "value": "<div class=\"custom-tooltip\"><p>View Plate Map</p></div>",
                                                        "buttonType": "view_plates",
                                                        "classname": "plasmid-view",
                                                        "divClass": "",
                                                        "controlEndPoints": [],
                                                        "disabled": false,
                                                        "validationEndPoints": [],
                                                        "invalid": false,
                                                        "name": "button_730",
                                                        "wrapperClassName": "",
                                                        "draggable": false
                                                    }
                                                ],
                                                "name": "box_105",
                                                "id": "box_148",
                                                "wrapperType": "BOX",
                                                "fields": [],
                                                "type": "",
                                                "classname": "",
                                                "addButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "removeButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "wrapperClassName": ""
                                            },
                                            "classname": ""
                                        }
                                    ]
                                }
                            }
                        }
                    ],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false,
                    "isAccordion": true,
                    "accordionConfig": {
                        "headerClassName": "",
                        "headerBtnClassName": "",
                        "header": "<span>Clone Info</span>",
                        "collapseDivClassName": "",
                        "bodyClassName": "",
                        "collapseOthers": true
                    }
                },
                {
                    "control": [
                        {
                            "type": "label",
                            "name": "<h2>Primer Details</h2>",
                            "classname": "sub-heading mt-3",
                            "for": "",
                            "required": false,
                            "wrapperClassName": "",
                            "id": "label_360",
                            "draggable": false
                        }
                    ],
                    "type": "",
                    "name": "pcr_info",
                    "classname": "col-12",
                    "wrapperType": "BOX",
                    "id": "pcr_info",
                    "fields": [
                        {
                            "control": [],
                            "type": "",
                            "name": "pcr_primer_details",
                            "classname": "table primer-details-table",
                            "wrapperType": "TABLE",
                            "id": "pcr_primer_details",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": "",
                            "draggable": false,
                            "hideSingleItemDelete": false,
                            "tableFields": {
                                "showHead": true,
                                "isVertical": false,
                                "isIteratable": false,
                                "addButton": {
                                    "classname": "btn btn-primary",
                                    "name": "",
                                    "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                },
                                "removeButton": {
                                    "classname": "btn btn-danger",
                                    "name": "",
                                    "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                },
                                "tableHead": {
                                    "heading": [
                                        {
                                            "value": "forward Primer Name",
                                            "classname": ""
                                        },
                                        {
                                            "value": "Reverse Primer Name",
                                            "classname": ""
                                        }
                                    ],
                                    "classname": ""
                                },
                                "initRecordCount": 1,
                                "showRecordCount": false,
                                "recordCounterConfig": {
                                    "classname": "",
                                    "adjValue": "",
                                    "isAdjValueAfter": false
                                },
                                "tableRow": {
                                    "name": "",
                                    "classname": "trclass",
                                    "tableCols": [
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "forward_primer_name",
                                                        "id": "forward_primer_name",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "forward_primer_name",
                                                        "showInputLabel": false,
                                                        "labelContent": "text_463",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    },
                                                    {
                                                        "type": "button",
                                                        "id": "button_887",
                                                        "value": "<div class=\"custom-tooltip big-tooltip\"><p>Upload forward primer </p></div>",
                                                        "buttonType": "upload_forward_primer",
                                                        "classname": "upload-blue mt-2 ms-2",
                                                        "divClass": "",
                                                        "controlEndPoints": [],
                                                        "disabled": false,
                                                        "validationEndPoints": [],
                                                        "invalid": false,
                                                        "name": "button_234",
                                                        "wrapperClassName": "",
                                                        "draggable": false
                                                    },
                                                    {
                                                        "type": "button",
                                                        "id": "button_6234",
                                                        "value": "<div class='custom-tooltip small-tooltip'><p>View</p></div>",
                                                        "buttonType": "view_forward_file",
                                                        "classname": "plasmid-view mt-2 ms-2",
                                                        "divClass": "",
                                                        "controlEndPoints": [],
                                                        "disabled": false,
                                                        "validationEndPoints": [],
                                                        "invalid": false,
                                                        "name": "view_plates",
                                                        "wrapperClassName": "",
                                                        "draggable": false
                                                    }
                                                ],
                                                "head": "",
                                                "name": "box_252",
                                                "id": "box_304",
                                                "wrapperType": "BOX",
                                                "fields": [],
                                                "type": "",
                                                "classname": "action-wrap"
                                            },
                                            "classname": ""
                                        },
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "reverse_primer_name",
                                                        "id": "reverse_primer_name",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "reverse_primer_name",
                                                        "showInputLabel": false,
                                                        "labelContent": "text_366",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    },
                                                    {
                                                        "type": "button",
                                                        "id": "button_653",
                                                        "value": "<div class=\"custom-tooltip big-tooltip\"><p>Upload reverse primer </p></div>",
                                                        "buttonType": "upload_reverse_primer",
                                                        "classname": "upload-blue  mt-2 ms-2",
                                                        "divClass": "",
                                                        "controlEndPoints": [],
                                                        "disabled": false,
                                                        "validationEndPoints": [],
                                                        "invalid": false,
                                                        "name": "button_641",
                                                        "wrapperClassName": "",
                                                        "draggable": false
                                                    },
                                                    {
                                                        "type": "button",
                                                        "id": "button_6234",
                                                        "value": "<div class='custom-tooltip small-tooltip'><p>View</p></div>",
                                                        "buttonType": "view_reverse_file",
                                                        "classname": "plasmid-view mt-2 ms-2",
                                                        "divClass": "",
                                                        "controlEndPoints": [],
                                                        "disabled": false,
                                                        "validationEndPoints": [],
                                                        "invalid": false,
                                                        "name": "view_plates",
                                                        "wrapperClassName": "",
                                                        "draggable": false
                                                    }
                                                ],
                                                "name": "box_161",
                                                "id": "box_156",
                                                "wrapperType": "BOX",
                                                "fields": [],
                                                "type": "",
                                                "classname": "action-wrap",
                                                "addButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "removeButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "wrapperClassName": ""
                                            },
                                            "classname": ""
                                        },
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "reverse_primer_file_id",
                                                        "id": "reverse_primer_file_id",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control d-none",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "reverse_primer_file_id",
                                                        "showInputLabel": false,
                                                        "labelContent": "text_366",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    },
                                                    {
                                                        "type": "text",
                                                        "name": "forward_primer_file_id",
                                                        "id": "forward_primer_file_id",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control d-none",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "forward_primer_file_id",
                                                        "showInputLabel": false,
                                                        "labelContent": "text_463",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    },
                                                ],
                                                "name": "box_16166",
                                                "id": "box_156333",
                                                "wrapperType": "BOX",
                                                "fields": [],
                                                "type": "",
                                                "classname": "remove",
                                                "addButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "removeButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "wrapperClassName": ""
                                            },
                                            "classname": "d-none"
                                        }
                                    ]
                                }
                            }
                        },
                        {
                            "control": [
                                {
                                    "type": "label",
                                    "name": "<h2>PCR Mix</h2>",
                                    "classname": "sub-heading mt-3",
                                    "for": "",
                                    "required": false,
                                    "wrapperClassName": "",
                                    "id": "label_76",
                                    "draggable": false
                                }
                            ],
                            "type": "",
                            "name": "wrapper_682",
                            "classname": "col-12",
                            "wrapperType": "BOX",
                            "id": "wrapper_260",
                            "fields": [
                                {
                                    "control": [],
                                    "type": "",
                                    "name": "wrapper_916",
                                    "classname": "table-responsive",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_724",
                                    "fields": [
                                        {
                                            "control": [
                                                {
                                                    "type": "label",
                                                    "name": "PCR Mix",
                                                    "classname": "sub-heading",
                                                    "for": "",
                                                    "required": false,
                                                    "wrapperClassName": "",
                                                    "id": "label_505",
                                                    "draggable": false
                                                }
                                            ],
                                            "type": "",
                                            "name": "pcr_mix",
                                            "classname": "table pcr-info-table-pr-7 mb-0",
                                            "wrapperType": "TABLE",
                                            "id": "pcr_mix",
                                            "fields": [],
                                            "head": "",
                                            "tabHeadClass": "",
                                            "draggable": false,
                                            "hideSingleItemDelete": false,
                                            "tableFields": {
                                                "showHead": true,
                                                "isVertical": false,
                                                "isIteratable": false,
                                                "addButton": {
                                                    "classname": "btn btn-primary",
                                                    "name": "",
                                                    "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                                },
                                                "removeButton": {
                                                    "classname": "btn btn-danger",
                                                    "name": "",
                                                    "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                                },
                                                "tableHead": {
                                                    "heading": [
                                                        {
                                                            "value": "Plate ID",
                                                            "classname": ""
                                                        },
                                                        {
                                                            "value": "No of Clones",
                                                            "classname": ""
                                                        },
                                                        {
                                                            "value": "total no of clones",
                                                            "classname": ""
                                                        },
                                                        {
                                                            "value": "MM Vol Per Clone <span class=\"text-initial\">(µl)</span>",
                                                            "classname": ""
                                                        },
                                                        {
                                                            "value": "Total MM Vol  Required <span class=\"text-initial\">(µl)</span>",
                                                            "classname": ""
                                                        },
                                                        {
                                                            "value": "Phage Template  Vol <span class=\"text-initial\">(µl)</span>",
                                                            "classname": ""
                                                        },
                                                        {
                                                            "value": "PCR Kit",
                                                            "classname": ""
                                                        },
                                                        {
                                                            "value": "DNA Polymerase",
                                                            "classname": ""
                                                        },
                                                        {
                                                            "value": "PCR Buffer <span class=\"text-initial\">(µl)</span>",
                                                            "classname": ""
                                                        },
                                                        {
                                                            "value": "<span class=\"text-initial\">d</span>NT P<span class=\"text-initial\">s (µl)</span>",
                                                            "classname": ""
                                                        },
                                                        {
                                                            "value": "PCR Primer  Forward <span class=\"text-initial\">(µl)</span>",
                                                            "classname": ""
                                                        },
                                                        {
                                                            "value": "PCR Primer Reverse <span class=\"text-initial\">(µl)</span>",
                                                            "classname": ""
                                                        },
                                                        {
                                                            "value": "Polymerase <span class=\"text-initial\">(µl)</span>",
                                                            "classname": ""
                                                        },
                                                        {
                                                            "value": "DMSO <span class=\"text-initial\">(µl)</span>",
                                                            "classname": ""
                                                        },
                                                        {
                                                            "value": "DI H<sub>₂</sub>O <span class=\"text-initial\">(µl)</span>",
                                                            "classname": ""
                                                        }
                                                    ],
                                                    "classname": ""
                                                },
                                                "initRecordCount": 1,
                                                "showRecordCount": false,
                                                "recordCounterConfig": {
                                                    "classname": "",
                                                    "adjValue": "",
                                                    "isAdjValueAfter": false
                                                },
                                                "tableRow": {
                                                    "name": "",
                                                    "classname": "trclass",
                                                    "tableCols": [
                                                        {
                                                            "fields": {
                                                                "control": [
                                                                    {
                                                                        "type": "text",
                                                                        "name": "pcr_plate_id",
                                                                        "id": "pcr_plate_id",
                                                                        "value": "",
                                                                        "placeholder": "",
                                                                        "multiline": false,
                                                                        "classname": "form-control mb-1",
                                                                        "regularExpression": "",
                                                                        "disabled": true,
                                                                        "required": false,
                                                                        "errorMessage": {
                                                                            "noData": "This fields is required",
                                                                            "regexFail": ""
                                                                        },
                                                                        "validationButtonType": "",
                                                                        "rows": 0,
                                                                        "columns": 0,
                                                                        "onDemandError": false,
                                                                        "customValidation": [],
                                                                        "multiError": false,
                                                                        "applicationError": "",
                                                                        "wrapperClassName": "",
                                                                        "requiredErrorMessage": "This fields is required",
                                                                        "__schema_id": "pcr_plate_id",
                                                                        "showInputLabel": false,
                                                                        "labelContent": "text_838",
                                                                        "labelClass": "form-label",
                                                                        "isLabelAfter": false,
                                                                        "showAdditionalInputContent": false,
                                                                        "additionalInputContent": "",
                                                                        "isAdditonalInputAfter": true,
                                                                        "draggable": false
                                                                    }
                                                                ],
                                                                "head": "",
                                                                "name": "pcr_mix_plate",
                                                                "id": "pcr_mix_plate",
                                                                "wrapperType": "ITERATE",
                                                                "fields": [],
                                                                "type": "",
                                                                "classname": "",
                                                                "ButtonPosition": "top",
                                                                "addButton": {
                                                                    "classname": "d-none",
                                                                    "name": "",
                                                                    "value": ""
                                                                },
                                                                "removeButton": {
                                                                    "classname": "d-none",
                                                                    "name": "",
                                                                    "value": ""
                                                                },
                                                                "controlButtonClass": "",
                                                                "hideSingleItemDelete": false,
                                                                "addBtnPostion": null
                                                            },
                                                            "classname": ""
                                                        },
                                                        {
                                                            "fields": {
                                                                "control": [
                                                                    {
                                                                        "type": "text",
                                                                        "name": "no_of_clones",
                                                                        "id": "no_of_clones",
                                                                        "value": "",
                                                                        "placeholder": "",
                                                                        "multiline": false,
                                                                        "classname": "form-control mb-1",
                                                                        "regularExpression": "",
                                                                        "disabled": true,
                                                                        "required": false,
                                                                        "errorMessage": {
                                                                            "noData": "This fields is required",
                                                                            "regexFail": ""
                                                                        },
                                                                        "validationButtonType": "",
                                                                        "rows": 0,
                                                                        "columns": 0,
                                                                        "onDemandError": false,
                                                                        "customValidation": [],
                                                                        "multiError": false,
                                                                        "applicationError": "",
                                                                        "wrapperClassName": "",
                                                                        "requiredErrorMessage": "This fields is required",
                                                                        "__schema_id": "no_of_clones",
                                                                        "showInputLabel": false,
                                                                        "labelContent": "text_895",
                                                                        "labelClass": "form-label",
                                                                        "isLabelAfter": false,
                                                                        "showAdditionalInputContent": false,
                                                                        "additionalInputContent": "",
                                                                        "isAdditonalInputAfter": true,
                                                                        "draggable": false
                                                                    }
                                                                ],
                                                                "name": "pcr_mix_clones",
                                                                "id": "pcr_mix_clones",
                                                                "wrapperType": "ITERATE",
                                                                "fields": [],
                                                                "type": "",
                                                                "classname": "",
                                                                "addButton": {
                                                                    "classname": "d-none",
                                                                    "name": "",
                                                                    "value": ""
                                                                },
                                                                "removeButton": {
                                                                    "classname": "d-none",
                                                                    "name": "",
                                                                    "value": ""
                                                                },
                                                                "wrapperClassName": "",
                                                                "ButtonPosition": "top",
                                                                "controlButtonClass": "",
                                                                "hideSingleItemDelete": false,
                                                                "addBtnPostion": null
                                                            },
                                                            "classname": ""
                                                        },
                                                        {
                                                            "fields": {
                                                                "control": [
                                                                    {
                                                                        "type": "text",
                                                                        "name": "total_no_of_clones",
                                                                        "id": "total_no_of_clones",
                                                                        "value": "",
                                                                        "placeholder": "",
                                                                        "multiline": false,
                                                                        "classname": "form-control ",
                                                                        "regularExpression": "",
                                                                        "disabled": true,
                                                                        "required": false,
                                                                        "errorMessage": {
                                                                            "noData": "This fields is required",
                                                                            "regexFail": ""
                                                                        },
                                                                        "validationButtonType": "",
                                                                        "rows": 0,
                                                                        "columns": 0,
                                                                        "onDemandError": false,
                                                                        "customValidation": [],
                                                                        "multiError": false,
                                                                        "applicationError": "",
                                                                        "wrapperClassName": "",
                                                                        "requiredErrorMessage": "This fields is required",
                                                                        "__schema_id": "total_no_of_clones",
                                                                        "showInputLabel": false,
                                                                        "labelContent": "text_546",
                                                                        "labelClass": "form-label",
                                                                        "isLabelAfter": false,
                                                                        "showAdditionalInputContent": false,
                                                                        "additionalInputContent": "",
                                                                        "isAdditonalInputAfter": true,
                                                                        "draggable": false
                                                                    }
                                                                ],
                                                                "name": "box_118",
                                                                "id": "box_377",
                                                                "wrapperType": "BOX",
                                                                "fields": [],
                                                                "type": "",
                                                                "classname": "",
                                                                "addButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "removeButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "wrapperClassName": ""
                                                            },
                                                            "classname": ""
                                                        },
                                                        {
                                                            "fields": {
                                                                "control": [
                                                                    {
                                                                        "type": "text",
                                                                        "name": "vol_per_clones",
                                                                        "id": "vol_per_clones",
                                                                        "value": 20,
                                                                        "placeholder": "",
                                                                        "multiline": false,
                                                                        "classname": "form-control ",
                                                                        "regularExpression": "",
                                                                        "disabled": false,
                                                                        "required": false,
                                                                        "errorMessage": {
                                                                            "noData": "This fields is required",
                                                                            "regexFail": ""
                                                                        },
                                                                        "validationButtonType": "",
                                                                        "rows": 0,
                                                                        "columns": 0,
                                                                        "onDemandError": false,
                                                                        "customValidation": [
                                                                            {
                                                                                "errorMessage": "Please type number",
                                                                                "regularExpression": "/^(0\\.[1-9]+|[1-9]\\d*(\\.\\d+)?)$/"
                                                                            }
                                                                        ],
                                                                        "multiError": false,
                                                                        "applicationError": "",
                                                                        "wrapperClassName": "",
                                                                        "requiredErrorMessage": "This fields is required",
                                                                        "__schema_id": "vol_per_clones",
                                                                        "showInputLabel": false,
                                                                        "labelContent": "text_604",
                                                                        "labelClass": "form-label",
                                                                        "isLabelAfter": false,
                                                                        "showAdditionalInputContent": false,
                                                                        "additionalInputContent": "",
                                                                        "isAdditonalInputAfter": true,
                                                                        "draggable": false
                                                                    }
                                                                ],
                                                                "name": "box_261",
                                                                "id": "box_34",
                                                                "wrapperType": "BOX",
                                                                "fields": [],
                                                                "type": "",
                                                                "classname": "",
                                                                "addButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "removeButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "wrapperClassName": ""
                                                            },
                                                            "classname": ""
                                                        },
                                                        {
                                                            "fields": {
                                                                "control": [
                                                                    {
                                                                        "type": "text",
                                                                        "name": "total_mm_vol_required",
                                                                        "id": "total_mm_vol_required",
                                                                        "value": "",
                                                                        "placeholder": "",
                                                                        "multiline": false,
                                                                        "classname": "form-control ",
                                                                        "regularExpression": "",
                                                                        "disabled": true,
                                                                        "required": false,
                                                                        "errorMessage": {
                                                                            "noData": "This fields is required",
                                                                            "regexFail": ""
                                                                        },
                                                                        "validationButtonType": "",
                                                                        "rows": 0,
                                                                        "columns": 0,
                                                                        "onDemandError": false,
                                                                        "customValidation": [],
                                                                        "multiError": false,
                                                                        "applicationError": "",
                                                                        "wrapperClassName": "",
                                                                        "requiredErrorMessage": "This fields is required",
                                                                        "__schema_id": "total_mm_vol_required",
                                                                        "showInputLabel": false,
                                                                        "labelContent": "text_884",
                                                                        "labelClass": "form-label",
                                                                        "isLabelAfter": false,
                                                                        "showAdditionalInputContent": false,
                                                                        "additionalInputContent": "",
                                                                        "isAdditonalInputAfter": true,
                                                                        "draggable": false
                                                                    }
                                                                ],
                                                                "name": "box_17",
                                                                "id": "box_120",
                                                                "wrapperType": "BOX",
                                                                "fields": [],
                                                                "type": "",
                                                                "classname": "",
                                                                "addButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "removeButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "wrapperClassName": ""
                                                            },
                                                            "classname": ""
                                                        },
                                                        {
                                                            "fields": {
                                                                "control": [
                                                                    {
                                                                        "type": "text",
                                                                        "name": "phage_temp_vol",
                                                                        "id": "phage_temp_vol",
                                                                        "value": 1,
                                                                        "placeholder": "",
                                                                        "multiline": false,
                                                                        "classname": "form-control ",
                                                                        "regularExpression": "",
                                                                        "disabled": false,
                                                                        "required": false,
                                                                        "errorMessage": {
                                                                            "noData": "This fields is required",
                                                                            "regexFail": ""
                                                                        },
                                                                        "validationButtonType": "",
                                                                        "rows": 0,
                                                                        "columns": 0,
                                                                        "onDemandError": false,
                                                                        "customValidation": [
                                                                            {
                                                                                "errorMessage": "Please type a non zero numberr",
                                                                                "regularExpression": "/^(0\\.[1-9]+|[1-9]\\d*(\\.\\d+)?)$/"
                                                                            }
                                                                        ],
                                                                        "multiError": false,
                                                                        "applicationError": "",
                                                                        "wrapperClassName": "",
                                                                        "requiredErrorMessage": "This fields is required",
                                                                        "__schema_id": "phage_temp_vol",
                                                                        "showInputLabel": false,
                                                                        "labelContent": "text_421",
                                                                        "labelClass": "form-label",
                                                                        "isLabelAfter": false,
                                                                        "showAdditionalInputContent": false,
                                                                        "additionalInputContent": "",
                                                                        "isAdditonalInputAfter": true,
                                                                        "draggable": false
                                                                    }
                                                                ],
                                                                "name": "box_705",
                                                                "id": "box_616",
                                                                "wrapperType": "BOX",
                                                                "fields": [],
                                                                "type": "",
                                                                "classname": "",
                                                                "addButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "removeButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "wrapperClassName": ""
                                                            },
                                                            "classname": ""
                                                        },
                                                        {
                                                            "fields": {
                                                                "control": [
                                                                    {
                                                                        "type": "dropdown",
                                                                        "name": "pcr_kit",
                                                                        "id": "pcr_kit",
                                                                        "validationButtonType": "",
                                                                        "classname": "",
                                                                        "required": false,
                                                                        "apiEndPoints": [
                                                                            {
                                                                                "type": "init",
                                                                                "method": "post",
                                                                                "url": "inventory_item",
                                                                                "Mapto": [],
                                                                                "payload": {
                                                                                    "perma": "assay-kits",
                                                                                    "user_input": ""
                                                                                }
                                                                            }
                                                                        ],
                                                                        "options": [],
                                                                        "multiple": false,
                                                                        "isClearable": false,
                                                                        "isStatus": false,
                                                                        "search": true,
                                                                        "singleSelect": true,
                                                                        "showLabel": "value",
                                                                        "errorMessage": {
                                                                            "noData": "This fields is required"
                                                                        },
                                                                        "value": "",
                                                                        "imageUrl": "",
                                                                        "disabled": false,
                                                                        "wrapperClassName": "",
                                                                        "connectedArrays": [],
                                                                        "showBlankInPlaceholder": false,
                                                                        "blankDataList": [],
                                                                        "commonPathOfImage": "",
                                                                        "__schema_id": "pcr_kit",
                                                                        "showInputLabel": false,
                                                                        "labelContent": "dropdown_604",
                                                                        "labelClass": "form-label",
                                                                        "isLabelAfter": false,
                                                                        "showAdditionalInputContent": false,
                                                                        "additionalInputContent": "",
                                                                        "isAdditonalInputAfter": true,
                                                                        "draggable": false
                                                                    }
                                                                ],
                                                                "name": "box_674",
                                                                "id": "box_332",
                                                                "wrapperType": "BOX",
                                                                "fields": [],
                                                                "type": "",
                                                                "classname": "",
                                                                "addButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "removeButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "wrapperClassName": ""
                                                            },
                                                            "classname": ""
                                                        },
                                                        {
                                                            "fields": {
                                                                "control": [
                                                                    {
                                                                        "type": "dropdown",
                                                                        "name": "dna_polymerase",
                                                                        "id": "dna_polymerase",
                                                                        "validationButtonType": "",
                                                                        "classname": "",
                                                                        "required": false,
                                                                        "apiEndPoints": [],
                                                                        "options": [
                                                                            {
                                                                                "id": 1,
                                                                                "value": "Taq Polymerase",
                                                                                "pcr_buffer": 200
                                                                            },
                                                                            {
                                                                                "id": 2,
                                                                                "value": "Phusion Polymerase",
                                                                                "pcr_buffer": 400
                                                                            }
                                                                        ],
                                                                        "multiple": false,
                                                                        "isClearable": false,
                                                                        "isStatus": false,
                                                                        "search": false,
                                                                        "singleSelect": true,
                                                                        "showLabel": "value",
                                                                        "errorMessage": {
                                                                            "noData": "This fields is required"
                                                                        },
                                                                        "value": "",
                                                                        "imageUrl": "",
                                                                        "disabled": false,
                                                                        "wrapperClassName": "",
                                                                        "connectedArrays": [],
                                                                        "showBlankInPlaceholder": false,
                                                                        "blankDataList": [],
                                                                        "commonPathOfImage": "",
                                                                        "__schema_id": "dna_polymerase",
                                                                        "showInputLabel": false,
                                                                        "labelContent": "dropdown_229",
                                                                        "labelClass": "form-label",
                                                                        "isLabelAfter": false,
                                                                        "showAdditionalInputContent": false,
                                                                        "additionalInputContent": "",
                                                                        "isAdditonalInputAfter": true,
                                                                        "draggable": false
                                                                    }
                                                                ],
                                                                "name": "box_395",
                                                                "id": "box_588",
                                                                "wrapperType": "BOX",
                                                                "fields": [],
                                                                "type": "",
                                                                "classname": "",
                                                                "addButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "removeButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "wrapperClassName": ""
                                                            },
                                                            "classname": ""
                                                        },
                                                        {
                                                            "fields": {
                                                                "control": [
                                                                    {
                                                                        "type": "text",
                                                                        "name": "pcr_buffer",
                                                                        "id": "pcr_buffer_pcrMixChange",
                                                                        "value": "",
                                                                        "placeholder": "",
                                                                        "multiline": false,
                                                                        "classname": "form-control ",
                                                                        "regularExpression": "",
                                                                        "disabled": false,
                                                                        "required": false,
                                                                        "errorMessage": {
                                                                            "noData": "This fields is required",
                                                                            "regexFail": ""
                                                                        },
                                                                        "validationButtonType": "",
                                                                        "rows": 0,
                                                                        "columns": 0,
                                                                        "onDemandError": false,
                                                                        "customValidation": [],
                                                                        "multiError": false,
                                                                        "applicationError": "",
                                                                        "wrapperClassName": "",
                                                                        "requiredErrorMessage": "This fields is required",
                                                                        "__schema_id": "pcr_buffer",
                                                                        "showInputLabel": false,
                                                                        "labelContent": "text_512",
                                                                        "labelClass": "form-label",
                                                                        "isLabelAfter": false,
                                                                        "showAdditionalInputContent": false,
                                                                        "additionalInputContent": "",
                                                                        "isAdditonalInputAfter": true,
                                                                        "draggable": false,
                                                                        "callBackEvents": [
                                                                            "input"
                                                                        ]
                                                                    }
                                                                ],
                                                                "name": "box_230",
                                                                "id": "box_661",
                                                                "wrapperType": "BOX",
                                                                "fields": [],
                                                                "type": "",
                                                                "classname": "",
                                                                "addButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "removeButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "wrapperClassName": ""
                                                            },
                                                            "classname": ""
                                                        },
                                                        {
                                                            "fields": {
                                                                "control": [
                                                                    {
                                                                        "type": "text",
                                                                        "name": "pcr_dntps",
                                                                        "id": "pcr_dntps_pcrMixChange",
                                                                        "value": 40,
                                                                        "placeholder": "",
                                                                        "multiline": false,
                                                                        "classname": "form-control ",
                                                                        "regularExpression": "",
                                                                        "disabled": false,
                                                                        "required": false,
                                                                        "errorMessage": {
                                                                            "noData": "This fields is required",
                                                                            "regexFail": ""
                                                                        },
                                                                        "validationButtonType": "",
                                                                        "rows": 0,
                                                                        "columns": 0,
                                                                        "onDemandError": false,
                                                                        "customValidation": [],
                                                                        "multiError": false,
                                                                        "applicationError": "",
                                                                        "wrapperClassName": "",
                                                                        "requiredErrorMessage": "This fields is required",
                                                                        "__schema_id": "pcr_dntps",
                                                                        "showInputLabel": false,
                                                                        "labelContent": "text_516",
                                                                        "labelClass": "form-label",
                                                                        "isLabelAfter": false,
                                                                        "showAdditionalInputContent": false,
                                                                        "additionalInputContent": "",
                                                                        "isAdditonalInputAfter": true,
                                                                        "draggable": false,
                                                                        "callBackEvents": [
                                                                            "input"
                                                                        ]
                                                                    }
                                                                ],
                                                                "name": "box_153",
                                                                "id": "box_891",
                                                                "wrapperType": "BOX",
                                                                "fields": [],
                                                                "type": "",
                                                                "classname": "",
                                                                "addButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "removeButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "wrapperClassName": ""
                                                            },
                                                            "classname": ""
                                                        },
                                                        {
                                                            "fields": {
                                                                "control": [
                                                                    {
                                                                        "type": "text",
                                                                        "name": "pcr_primer_forward",
                                                                        "id": "pcr_primer_forward_pcrMixChange",
                                                                        "value": 20,
                                                                        "placeholder": "",
                                                                        "multiline": false,
                                                                        "classname": "form-control ",
                                                                        "regularExpression": "",
                                                                        "disabled": false,
                                                                        "required": false,
                                                                        "errorMessage": {
                                                                            "noData": "This fields is required",
                                                                            "regexFail": ""
                                                                        },
                                                                        "validationButtonType": "",
                                                                        "rows": 0,
                                                                        "columns": 0,
                                                                        "onDemandError": false,
                                                                        "customValidation": [],
                                                                        "multiError": false,
                                                                        "applicationError": "",
                                                                        "wrapperClassName": "",
                                                                        "requiredErrorMessage": "This fields is required",
                                                                        "__schema_id": "pcr_primer_forward",
                                                                        "showInputLabel": false,
                                                                        "labelContent": "text_993",
                                                                        "labelClass": "form-label",
                                                                        "isLabelAfter": false,
                                                                        "showAdditionalInputContent": false,
                                                                        "additionalInputContent": "",
                                                                        "isAdditonalInputAfter": true,
                                                                        "draggable": false,
                                                                        "callBackEvents": [
                                                                            "input"
                                                                        ]
                                                                    }
                                                                ],
                                                                "name": "box_433",
                                                                "id": "box_76",
                                                                "wrapperType": "BOX",
                                                                "fields": [],
                                                                "type": "",
                                                                "classname": "",
                                                                "addButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "removeButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "wrapperClassName": ""
                                                            },
                                                            "classname": ""
                                                        },
                                                        {
                                                            "fields": {
                                                                "control": [
                                                                    {
                                                                        "type": "text",
                                                                        "name": "pcr_primer_reverse",
                                                                        "id": "pcr_primer_reverse_pcrMixChange",
                                                                        "value": 20,
                                                                        "placeholder": "",
                                                                        "multiline": false,
                                                                        "classname": "form-control ",
                                                                        "regularExpression": "",
                                                                        "disabled": false,
                                                                        "required": false,
                                                                        "errorMessage": {
                                                                            "noData": "This fields is required",
                                                                            "regexFail": ""
                                                                        },
                                                                        "validationButtonType": "",
                                                                        "rows": 0,
                                                                        "columns": 0,
                                                                        "onDemandError": false,
                                                                        "customValidation": [],
                                                                        "multiError": false,
                                                                        "applicationError": "",
                                                                        "wrapperClassName": "",
                                                                        "requiredErrorMessage": "This fields is required",
                                                                        "__schema_id": "pcr_primer_reverse",
                                                                        "showInputLabel": false,
                                                                        "labelContent": "text_451",
                                                                        "labelClass": "form-label",
                                                                        "isLabelAfter": false,
                                                                        "showAdditionalInputContent": false,
                                                                        "additionalInputContent": "",
                                                                        "isAdditonalInputAfter": true,
                                                                        "draggable": false,
                                                                        "callBackEvents": [
                                                                            "input"
                                                                        ]
                                                                    }
                                                                ],
                                                                "name": "box_680",
                                                                "id": "box_175",
                                                                "wrapperType": "BOX",
                                                                "fields": [],
                                                                "type": "",
                                                                "classname": "",
                                                                "addButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "removeButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "wrapperClassName": ""
                                                            },
                                                            "classname": ""
                                                        },
                                                        {
                                                            "fields": {
                                                                "control": [
                                                                    {
                                                                        "type": "text",
                                                                        "name": "pcr_polymerase",
                                                                        "id": "pcr_polymerase_pcrMixChange",
                                                                        "value": 6,
                                                                        "placeholder": "",
                                                                        "multiline": false,
                                                                        "classname": "form-control ",
                                                                        "regularExpression": "",
                                                                        "disabled": false,
                                                                        "required": false,
                                                                        "errorMessage": {
                                                                            "noData": "This fields is required",
                                                                            "regexFail": ""
                                                                        },
                                                                        "validationButtonType": "",
                                                                        "rows": 0,
                                                                        "columns": 0,
                                                                        "onDemandError": false,
                                                                        "customValidation": [],
                                                                        "multiError": false,
                                                                        "applicationError": "",
                                                                        "wrapperClassName": "",
                                                                        "requiredErrorMessage": "This fields is required",
                                                                        "__schema_id": "pcr_polymerase",
                                                                        "showInputLabel": false,
                                                                        "labelContent": "text_666",
                                                                        "labelClass": "form-label",
                                                                        "isLabelAfter": false,
                                                                        "showAdditionalInputContent": false,
                                                                        "additionalInputContent": "",
                                                                        "isAdditonalInputAfter": true,
                                                                        "draggable": false,
                                                                        "callBackEvents": [
                                                                            "input"
                                                                        ]
                                                                    }
                                                                ],
                                                                "name": "box_466",
                                                                "id": "box_37",
                                                                "wrapperType": "BOX",
                                                                "fields": [],
                                                                "type": "",
                                                                "classname": "",
                                                                "addButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "removeButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "wrapperClassName": ""
                                                            },
                                                            "classname": ""
                                                        },
                                                        {
                                                            "fields": {
                                                                "control": [
                                                                    {
                                                                        "type": "text",
                                                                        "name": "pcr_dmso",
                                                                        "id": "pcr_dmso_pcrMixChange",
                                                                        "value": 7.5,
                                                                        "placeholder": "",
                                                                        "multiline": false,
                                                                        "classname": "form-control ",
                                                                        "regularExpression": "",
                                                                        "disabled": false,
                                                                        "required": false,
                                                                        "errorMessage": {
                                                                            "noData": "This fields is required",
                                                                            "regexFail": ""
                                                                        },
                                                                        "validationButtonType": "",
                                                                        "rows": 0,
                                                                        "columns": 0,
                                                                        "onDemandError": false,
                                                                        "customValidation": [],
                                                                        "multiError": false,
                                                                        "applicationError": "",
                                                                        "wrapperClassName": "",
                                                                        "requiredErrorMessage": "This fields is required",
                                                                        "__schema_id": "pcr_dmso",
                                                                        "showInputLabel": false,
                                                                        "labelContent": "text_184",
                                                                        "labelClass": "form-label",
                                                                        "isLabelAfter": false,
                                                                        "showAdditionalInputContent": false,
                                                                        "additionalInputContent": "",
                                                                        "isAdditonalInputAfter": true,
                                                                        "draggable": false,
                                                                        "callBackEvents": [
                                                                            "input"
                                                                        ]
                                                                    }
                                                                ],
                                                                "name": "box_146",
                                                                "id": "box_760",
                                                                "wrapperType": "BOX",
                                                                "fields": [],
                                                                "type": "",
                                                                "classname": "",
                                                                "addButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "removeButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "wrapperClassName": ""
                                                            },
                                                            "classname": ""
                                                        },
                                                        {
                                                            "fields": {
                                                                "control": [
                                                                    {
                                                                        "type": "text",
                                                                        "name": "pcr_di_h2o",
                                                                        "id": "pcr_di_h2o_pcrMixChange",
                                                                        "value": "",
                                                                        "placeholder": "",
                                                                        "multiline": false,
                                                                        "classname": "form-control ",
                                                                        "regularExpression": "",
                                                                        "disabled": false,
                                                                        "required": false,
                                                                        "errorMessage": {
                                                                            "noData": "This fields is required",
                                                                            "regexFail": ""
                                                                        },
                                                                        "validationButtonType": "",
                                                                        "rows": 0,
                                                                        "columns": 0,
                                                                        "onDemandError": false,
                                                                        "customValidation": [],
                                                                        "multiError": false,
                                                                        "applicationError": "",
                                                                        "wrapperClassName": "",
                                                                        "requiredErrorMessage": "This fields is required",
                                                                        "__schema_id": "pcr_di_h2o",
                                                                        "showInputLabel": false,
                                                                        "labelContent": "text_706",
                                                                        "labelClass": "form-label",
                                                                        "isLabelAfter": false,
                                                                        "showAdditionalInputContent": false,
                                                                        "additionalInputContent": "",
                                                                        "isAdditonalInputAfter": true,
                                                                        "draggable": false,
                                                                        "callBackEvents": [
                                                                            "input"
                                                                        ]
                                                                    }
                                                                ],
                                                                "name": "box_5",
                                                                "id": "box_163",
                                                                "wrapperType": "BOX",
                                                                "fields": [],
                                                                "type": "",
                                                                "classname": "",
                                                                "addButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "removeButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "wrapperClassName": ""
                                                            },
                                                            "classname": ""
                                                        }
                                                    ]
                                                }
                                            }
                                        },
                                        {
                                            "control": [],
                                            "type": "",
                                            "name": "pcr_master_total",
                                            "classname": "table pcr-info-table-pr-7  total-value-table",
                                            "wrapperType": "TABLE",
                                            "id": "pcr_master_total",
                                            "fields": [],
                                            "head": "",
                                            "tabHeadClass": "",
                                            "draggable": false,
                                            "hideSingleItemDelete": false,
                                            "tableFields": {
                                                "showHead": false,
                                                "isVertical": false,
                                                "isIteratable": false,
                                                "addButton": {
                                                    "classname": "btn btn-primary",
                                                    "name": "",
                                                    "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                                },
                                                "removeButton": {
                                                    "classname": "btn btn-danger",
                                                    "name": "",
                                                    "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                                },
                                                "tableHead": {
                                                    "heading": [
                                                        {
                                                            "value": "T_Head-1",
                                                            "classname": ""
                                                        },
                                                        {
                                                            "value": "T_Head_2",
                                                            "classname": ""
                                                        },
                                                        {
                                                            "value": "T_Head_3",
                                                            "classname": ""
                                                        },
                                                        {
                                                            "value": "T_Head_4",
                                                            "classname": ""
                                                        },
                                                        {
                                                            "value": "T_Head_5",
                                                            "classname": ""
                                                        },
                                                        {
                                                            "value": "T_Head_6",
                                                            "classname": ""
                                                        },
                                                        {
                                                            "value": "T_Head_7",
                                                            "classname": ""
                                                        },
                                                        {
                                                            "value": "T_Head_8",
                                                            "classname": ""
                                                        },
                                                        {
                                                            "value": "T_Head_9",
                                                            "classname": ""
                                                        }
                                                    ],
                                                    "classname": ""
                                                },
                                                "initRecordCount": 1,
                                                "showRecordCount": false,
                                                "recordCounterConfig": {
                                                    "classname": "",
                                                    "adjValue": "",
                                                    "isAdjValueAfter": false
                                                },
                                                "tableRow": {
                                                    "name": "",
                                                    "classname": "trclass",
                                                    "tableCols": [
                                                        {
                                                            "fields": {
                                                                "control": [],
                                                                "head": "",
                                                                "name": "box_337",
                                                                "id": "box_56",
                                                                "wrapperType": "BOX",
                                                                "fields": [],
                                                                "type": "",
                                                                "classname": ""
                                                            },
                                                            "classname": ""
                                                        },
                                                        {
                                                            "fields": {
                                                                "control": [],
                                                                "name": "box_199",
                                                                "id": "box_419",
                                                                "wrapperType": "BOX",
                                                                "fields": [],
                                                                "type": "",
                                                                "classname": "",
                                                                "addButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "removeButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "wrapperClassName": ""
                                                            },
                                                            "classname": ""
                                                        },
                                                        {
                                                            "fields": {
                                                                "control": [],
                                                                "name": "box_995",
                                                                "id": "box_190",
                                                                "wrapperType": "BOX",
                                                                "fields": [],
                                                                "type": "",
                                                                "classname": "",
                                                                "addButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "removeButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "wrapperClassName": ""
                                                            },
                                                            "classname": ""
                                                        },
                                                        {
                                                            "fields": {
                                                                "control": [],
                                                                "name": "box_864",
                                                                "id": "box_946",
                                                                "wrapperType": "BOX",
                                                                "fields": [],
                                                                "type": "",
                                                                "classname": "",
                                                                "addButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "removeButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "wrapperClassName": ""
                                                            },
                                                            "classname": ""
                                                        },
                                                        {
                                                            "fields": {
                                                                "control": [],
                                                                "name": "box_452",
                                                                "id": "box_80",
                                                                "wrapperType": "BOX",
                                                                "fields": [],
                                                                "type": "",
                                                                "classname": "",
                                                                "addButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "removeButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "wrapperClassName": ""
                                                            },
                                                            "classname": ""
                                                        },
                                                        {
                                                            "fields": {
                                                                "control": [],
                                                                "name": "box_921",
                                                                "id": "box_819",
                                                                "wrapperType": "BOX",
                                                                "fields": [],
                                                                "type": "",
                                                                "classname": "",
                                                                "addButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "removeButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "wrapperClassName": ""
                                                            },
                                                            "classname": ""
                                                        },
                                                        {
                                                            "fields": {
                                                                "control": [],
                                                                "name": "box_762",
                                                                "id": "box_880",
                                                                "wrapperType": "BOX",
                                                                "fields": [],
                                                                "type": "",
                                                                "classname": "",
                                                                "addButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "removeButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "wrapperClassName": ""
                                                            },
                                                            "classname": ""
                                                        },
                                                        {
                                                            "fields": {
                                                                "control": [],
                                                                "name": "box_902",
                                                                "id": "box_393",
                                                                "wrapperType": "BOX",
                                                                "fields": [],
                                                                "type": "",
                                                                "classname": "",
                                                                "addButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "removeButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "wrapperClassName": ""
                                                            },
                                                            "classname": ""
                                                        },
                                                        {
                                                            "fields": {
                                                                "control": [
                                                                    {
                                                                        "type": "label",
                                                                        "name": "Total Master Mix Vol Prepared <span class=\"text-initial\">(µl)</span>",
                                                                        "classname": "",
                                                                        "for": "",
                                                                        "required": false,
                                                                        "wrapperClassName": "",
                                                                        "id": "label_608",
                                                                        "draggable": false
                                                                    },
                                                                    {
                                                                        "type": "text",
                                                                        "name": "total_master_mix",
                                                                        "id": "total_master_mix",
                                                                        "value": "",
                                                                        "placeholder": "",
                                                                        "multiline": false,
                                                                        "classname": "form-control ",
                                                                        "regularExpression": "",
                                                                        "disabled": true,
                                                                        "required": false,
                                                                        "errorMessage": {
                                                                            "noData": "This fields is required",
                                                                            "regexFail": ""
                                                                        },
                                                                        "validationButtonType": "",
                                                                        "rows": 0,
                                                                        "columns": 0,
                                                                        "onDemandError": false,
                                                                        "customValidation": [],
                                                                        "multiError": false,
                                                                        "applicationError": "",
                                                                        "wrapperClassName": "",
                                                                        "requiredErrorMessage": "This fields is required",
                                                                        "__schema_id": "total_master_mix",
                                                                        "showInputLabel": false,
                                                                        "labelContent": "text_859",
                                                                        "labelClass": "form-label",
                                                                        "isLabelAfter": false,
                                                                        "showAdditionalInputContent": false,
                                                                        "additionalInputContent": "",
                                                                        "isAdditonalInputAfter": true,
                                                                        "draggable": false
                                                                    }
                                                                ],
                                                                "name": "box_982",
                                                                "id": "box_406",
                                                                "wrapperType": "BOX",
                                                                "fields": [],
                                                                "type": "",
                                                                "classname": "d-flex justify-content-between align-items-center total-value-col",
                                                                "addButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "removeButton": {
                                                                    "classname": "",
                                                                    "value": ""
                                                                },
                                                                "wrapperClassName": ""
                                                            },
                                                            "classname": "w-auto "
                                                        }
                                                    ]
                                                }
                                            }
                                        }
                                    ],
                                    "head": "",
                                    "tabHeadClass": "",
                                    "draggable": false
                                }
                            ],
                            "head": "",
                            "tabHeadClass": "",
                            "draggable": false
                        },
                        {
                            "control": [
                                {
                                    "type": "label",
                                    "name": "<h2>Program Setup</h2>",
                                    "classname": "sub-heading mt-3",
                                    "for": "",
                                    "required": false,
                                    "wrapperClassName": "",
                                    "id": "label_851",
                                    "draggable": false
                                }
                            ],
                            "type": "",
                            "name": "wrapper_401",
                            "classname": "col-12",
                            "wrapperType": "BOX",
                            "id": "wrapper_270",
                            "fields": [
                                {
                                    "control": [],
                                    "type": "",
                                    "name": "program_setup",
                                    "classname": "table pro-setup-table-pr7",
                                    "wrapperType": "TABLE",
                                    "id": "program_setup",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": "",
                                    "draggable": false,
                                    "hideSingleItemDelete": false,
                                    "tableFields": {
                                        "showHead": true,
                                        "isVertical": false,
                                        "isIteratable": false,
                                        "addButton": {
                                            "classname": "btn btn-primary",
                                            "name": "",
                                            "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                        },
                                        "removeButton": {
                                            "classname": "btn btn-danger",
                                            "name": "",
                                            "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                        },
                                        "tableHead": {
                                            "heading": [
                                                {
                                                    "value": "Select the program",
                                                    "classname": ""
                                                },
                                                {
                                                    "value": "Run Time (min)",
                                                    "classname": ""
                                                },
                                                {
                                                    "value": "No of Cycles",
                                                    "classname": ""
                                                }
                                            ],
                                            "classname": ""
                                        },
                                        "initRecordCount": 1,
                                        "showRecordCount": false,
                                        "recordCounterConfig": {
                                            "classname": "",
                                            "adjValue": "",
                                            "isAdjValueAfter": false
                                        },
                                        "tableRow": {
                                            "name": "",
                                            "classname": "trclass",
                                            "tableCols": [
                                                {
                                                    "fields": {
                                                        "control": [
                                                            {
                                                                "type": "dropdown",
                                                                "name": "select_program",
                                                                "id": "select_program",
                                                                "validationButtonType": "",
                                                                "classname": "",
                                                                "required": false,
                                                                "apiEndPoints": [],
                                                                "options": [
                                                                    {
                                                                        "id": 1,
                                                                        "value": "Whole Gene (VH+VL)"
                                                                    },
                                                                    {
                                                                        "id": 2,
                                                                        "value": "Half Gene (VH)"
                                                                    },
                                                                    {
                                                                        "id": 3,
                                                                        "value": "Half Gene (VL)"
                                                                    }
                                                                ],
                                                                "multiple": false,
                                                                "isClearable": false,
                                                                "isStatus": false,
                                                                "search": false,
                                                                "singleSelect": true,
                                                                "showLabel": "value",
                                                                "errorMessage": {
                                                                    "noData": "This fields is required"
                                                                },
                                                                "value": "",
                                                                "imageUrl": "",
                                                                "disabled": false,
                                                                "wrapperClassName": "",
                                                                "connectedArrays": [],
                                                                "showBlankInPlaceholder": false,
                                                                "blankDataList": [],
                                                                "commonPathOfImage": "",
                                                                "__schema_id": "select_program",
                                                                "showInputLabel": false,
                                                                "labelContent": "dropdown_332",
                                                                "labelClass": "form-label",
                                                                "isLabelAfter": false,
                                                                "showAdditionalInputContent": false,
                                                                "additionalInputContent": "",
                                                                "isAdditonalInputAfter": true,
                                                                "draggable": false
                                                            }
                                                        ],
                                                        "head": "",
                                                        "name": "box_864",
                                                        "id": "box_100",
                                                        "wrapperType": "BOX",
                                                        "fields": [],
                                                        "type": "",
                                                        "classname": ""
                                                    },
                                                    "classname": ""
                                                },
                                                {
                                                    "fields": {
                                                        "control": [
                                                            {
                                                                "type": "text",
                                                                "name": "run_time",
                                                                "id": "run_time",
                                                                "value": "",
                                                                "placeholder": "",
                                                                "multiline": false,
                                                                "classname": "form-control ",
                                                                "regularExpression": "",
                                                                "disabled": false,
                                                                "required": false,
                                                                "errorMessage": {
                                                                    "noData": "This fields is required",
                                                                    "regexFail": ""
                                                                },
                                                                "validationButtonType": "",
                                                                "rows": 0,
                                                                "columns": 0,
                                                                "onDemandError": false,
                                                                "customValidation": [],
                                                                "multiError": false,
                                                                "applicationError": "",
                                                                "wrapperClassName": "",
                                                                "requiredErrorMessage": "This fields is required",
                                                                "__schema_id": "run_time",
                                                                "showInputLabel": false,
                                                                "labelContent": "text_981",
                                                                "labelClass": "form-label",
                                                                "isLabelAfter": false,
                                                                "showAdditionalInputContent": false,
                                                                "additionalInputContent": "",
                                                                "isAdditonalInputAfter": true,
                                                                "draggable": false
                                                            }
                                                        ],
                                                        "name": "box_806",
                                                        "id": "box_373",
                                                        "wrapperType": "BOX",
                                                        "fields": [],
                                                        "type": "",
                                                        "classname": "",
                                                        "addButton": {
                                                            "classname": "",
                                                            "value": ""
                                                        },
                                                        "removeButton": {
                                                            "classname": "",
                                                            "value": ""
                                                        },
                                                        "wrapperClassName": ""
                                                    },
                                                    "classname": ""
                                                },
                                                {
                                                    "fields": {
                                                        "control": [
                                                            {
                                                                "type": "text",
                                                                "name": "no_of_cycles",
                                                                "id": "no_of_cycles",
                                                                "value": "",
                                                                "placeholder": "",
                                                                "multiline": false,
                                                                "classname": "form-control ",
                                                                "regularExpression": "",
                                                                "disabled": false,
                                                                "required": false,
                                                                "errorMessage": {
                                                                    "noData": "This fields is required",
                                                                    "regexFail": ""
                                                                },
                                                                "validationButtonType": "",
                                                                "rows": 0,
                                                                "columns": 0,
                                                                "onDemandError": false,
                                                                "customValidation": [],
                                                                "multiError": false,
                                                                "applicationError": "",
                                                                "wrapperClassName": "",
                                                                "requiredErrorMessage": "This fields is required",
                                                                "__schema_id": "no_of_cycles",
                                                                "showInputLabel": false,
                                                                "labelContent": "text_185",
                                                                "labelClass": "form-label",
                                                                "isLabelAfter": false,
                                                                "showAdditionalInputContent": false,
                                                                "additionalInputContent": "",
                                                                "isAdditonalInputAfter": true,
                                                                "draggable": false
                                                            }
                                                        ],
                                                        "name": "box_217",
                                                        "id": "box_783",
                                                        "wrapperType": "BOX",
                                                        "fields": [],
                                                        "type": "",
                                                        "classname": "",
                                                        "addButton": {
                                                            "classname": "",
                                                            "value": ""
                                                        },
                                                        "removeButton": {
                                                            "classname": "",
                                                            "value": ""
                                                        },
                                                        "wrapperClassName": ""
                                                    },
                                                    "classname": ""
                                                }
                                            ]
                                        }
                                    }
                                }
                            ],
                            "head": "",
                            "tabHeadClass": "",
                            "draggable": false
                        }
                    ],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false,
                    "isAccordion": true,
                    "accordionConfig": {
                        "headerClassName": "",
                        "headerBtnClassName": "",
                        "header": "<span>PCR Information</span>",
                        "collapseDivClassName": "",
                        "bodyClassName": "",
                        "collapseOthers": true
                    }
                },
                {
                    "control": [],
                    "type": "",
                    "name": "wrapper_161",
                    "classname": "col-12",
                    "wrapperType": "BOX",
                    "id": "wrapper_243",
                    "fields": [
                        {
                            "control": [],
                            "type": "",
                            "name": "electrophoresis_info",
                            "classname": "table electrophoresis-table-pr-7",
                            "wrapperType": "TABLE",
                            "id": "electrophoresis_info",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": "",
                            "draggable": false,
                            "hideSingleItemDelete": false,
                            "tableFields": {
                                "showHead": true,
                                "isVertical": false,
                                "isIteratable": false,
                                "addButton": {
                                    "classname": "btn btn-primary",
                                    "name": "",
                                    "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                },
                                "removeButton": {
                                    "classname": "btn btn-danger",
                                    "name": "",
                                    "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                },
                                "tableHead": {
                                    "heading": [
                                        {
                                            "value": "Analysis Using",
                                            "classname": ""
                                        },
                                        {
                                            "value": "Voltage (V)",
                                            "classname": ""
                                        },
                                        {
                                            "value": "Run Time <span class=\"text-initial\">(min)</span>",
                                            "classname": ""
                                        },
                                        {
                                            "value": "plate ID",
                                            "classname": ""
                                        },
                                        {
                                            "value": "Gel %",
                                            "classname": ""
                                        },
                                        {
                                            "value": "Bands Formed",
                                            "classname": ""
                                        }
                                    ],
                                    "classname": ""
                                },
                                "initRecordCount": 1,
                                "showRecordCount": false,
                                "recordCounterConfig": {
                                    "classname": "",
                                    "adjValue": "",
                                    "isAdjValueAfter": false
                                },
                                "tableRow": {
                                    "name": "",
                                    "classname": "trclass",
                                    "tableCols": [
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "dropdown",
                                                        "name": "analysis_using",
                                                        "id": "analysis_using",
                                                        "validationButtonType": "",
                                                        "classname": "",
                                                        "required": false,
                                                        "apiEndPoints": [
                                                            {
                                                                "type": "init",
                                                                "method": "post",
                                                                "url": "inventory_item",
                                                                "Mapto": [],
                                                                "payload": {
                                                                    "perma": "instruments",
                                                                    "user_input": ""
                                                                }
                                                            }
                                                        ],
                                                        "options": [],
                                                        "multiple": false,
                                                        "isClearable": false,
                                                        "isStatus": false,
                                                        "search": false,
                                                        "singleSelect": true,
                                                        "showLabel": "value",
                                                        "errorMessage": {
                                                            "noData": "This fields is required"
                                                        },
                                                        "value": "",
                                                        "imageUrl": "",
                                                        "disabled": false,
                                                        "wrapperClassName": "",
                                                        "connectedArrays": [],
                                                        "showBlankInPlaceholder": false,
                                                        "blankDataList": [],
                                                        "commonPathOfImage": "",
                                                        "__schema_id": "analysis_using",
                                                        "showInputLabel": false,
                                                        "labelContent": "dropdown_111",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "head": "",
                                                "name": "box_419",
                                                "id": "box_774",
                                                "wrapperType": "BOX",
                                                "fields": [],
                                                "type": "",
                                                "classname": ""
                                            },
                                            "classname": ""
                                        },
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "voltage",
                                                        "id": "voltage",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "voltage",
                                                        "showInputLabel": false,
                                                        "labelContent": "text_864",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "name": "box_398",
                                                "id": "box_68",
                                                "wrapperType": "BOX",
                                                "fields": [],
                                                "type": "",
                                                "classname": "",
                                                "addButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "removeButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "wrapperClassName": ""
                                            },
                                            "classname": ""
                                        },
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "electro_run_time",
                                                        "id": "electro_run_time",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "electro_run_time",
                                                        "showInputLabel": false,
                                                        "labelContent": "text_270",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "name": "box_750",
                                                "id": "box_872",
                                                "wrapperType": "BOX",
                                                "fields": [],
                                                "type": "",
                                                "classname": "",
                                                "addButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "removeButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "wrapperClassName": ""
                                            },
                                            "classname": ""
                                        },
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "electro_plate_id",
                                                        "id": "electro_plate_id",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control mb-1",
                                                        "regularExpression": "",
                                                        "disabled": true,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "electro_plate_id",
                                                        "showInputLabel": false,
                                                        "labelContent": "text_777",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "name": "electrophoresis_plates",
                                                "id": "electrophoresis_plates",
                                                "wrapperType": "ITERATE",
                                                "fields": [],
                                                "type": "",
                                                "classname": "",
                                                "addButton": {
                                                    "classname": "d-none",
                                                    "name": "",
                                                    "value": ""
                                                },
                                                "removeButton": {
                                                    "classname": "d-none",
                                                    "name": "",
                                                    "value": ""
                                                },
                                                "wrapperClassName": "",
                                                "ButtonPosition": "top",
                                                "controlButtonClass": "",
                                                "hideSingleItemDelete": false,
                                                "addBtnPostion": null
                                            },
                                            "classname": ""
                                        },
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "electro_gel",
                                                        "id": "electro_gel",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control mb-1",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "electro_gel",
                                                        "showInputLabel": false,
                                                        "labelContent": "text_897",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "name": "electrophoresis_gel",
                                                "id": "electrophoresis_gel",
                                                "wrapperType": "ITERATE",
                                                "fields": [],
                                                "type": "",
                                                "classname": "",
                                                "addButton": {
                                                    "classname": "d-none",
                                                    "name": "",
                                                    "value": ""
                                                },
                                                "removeButton": {
                                                    "classname": "d-none",
                                                    "name": "",
                                                    "value": ""
                                                },
                                                "wrapperClassName": "",
                                                "ButtonPosition": "top",
                                                "controlButtonClass": "",
                                                "hideSingleItemDelete": false,
                                                "addBtnPostion": null
                                            },
                                            "classname": ""
                                        },
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "dropdown",
                                                        "name": "electro_band_formed",
                                                        "id": "electro_band_formed",
                                                        "validationButtonType": "",
                                                        "classname": "",
                                                        "required": false,
                                                        "apiEndPoints": [],
                                                        "options": [
                                                            {
                                                                "id": 1,
                                                                "value": "Single Band 1400 bp"
                                                            },
                                                            {
                                                                "id": 2,
                                                                "value": "Single Band 700 bp"
                                                            },
                                                            {
                                                                "id": 3,
                                                                "value": "No Significant Observations"
                                                            }
                                                        ],
                                                        "multiple": false,
                                                        "isClearable": false,
                                                        "isStatus": false,
                                                        "search": false,
                                                        "singleSelect": true,
                                                        "showLabel": "value",
                                                        "errorMessage": {
                                                            "noData": "This fields is required"
                                                        },
                                                        "value": "",
                                                        "imageUrl": "",
                                                        "disabled": false,
                                                        "wrapperClassName": "mb-1",
                                                        "connectedArrays": [],
                                                        "showBlankInPlaceholder": false,
                                                        "blankDataList": [],
                                                        "commonPathOfImage": "",
                                                        "__schema_id": "electro_band_formed",
                                                        "showInputLabel": false,
                                                        "labelContent": "dropdown_498",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "name": "electrophoresis_bands",
                                                "id": "electrophoresis_bands",
                                                "wrapperType": "ITERATE",
                                                "fields": [],
                                                "type": "",
                                                "classname": "",
                                                "addButton": {
                                                    "classname": "d-none",
                                                    "name": "",
                                                    "value": ""
                                                },
                                                "removeButton": {
                                                    "classname": "d-none",
                                                    "name": "",
                                                    "value": ""
                                                },
                                                "wrapperClassName": "",
                                                "ButtonPosition": "top",
                                                "controlButtonClass": "",
                                                "hideSingleItemDelete": false,
                                                "addBtnPostion": null
                                            },
                                            "classname": ""
                                        }
                                    ]
                                }
                            }
                        }
                    ],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false,
                    "isAccordion": true,
                    "accordionConfig": {
                        "headerClassName": "",
                        "headerBtnClassName": "",
                        "header": "<span>Electrophoresis</span>",
                        "collapseDivClassName": "",
                        "bodyClassName": "",
                        "collapseOthers": true
                    }
                },
                {
                    "control": [],
                    "type": "",
                    "name": "wrapper_553",
                    "classname": "col-12",
                    "wrapperType": "BOX",
                    "id": "wrapper_855",
                    "fields": [
                        {
                            "control": [],
                            "type": "",
                            "name": "wrapper_724",
                            "classname": "table-responsive",
                            "wrapperType": "BOX",
                            "id": "wrapper_103",
                            "fields": [
                                {
                                    "control": [],
                                    "type": "",
                                    "name": "cleanup_template_info",
                                    "classname": "table cleanup-template-table-pr-7",
                                    "wrapperType": "TABLE",
                                    "id": "cleanup_template_info",
                                    "fields": [
                                        {
                                            "control": [],
                                            "type": "",
                                            "name": "wrapper_535",
                                            "classname": "col-12",
                                            "wrapperType": "BOX",
                                            "id": "wrapper_523",
                                            "fields": [],
                                            "head": "",
                                            "tabHeadClass": "",
                                            "draggable": false
                                        }
                                    ],
                                    "head": "",
                                    "tabHeadClass": "",
                                    "draggable": false,
                                    "hideSingleItemDelete": false,
                                    "tableFields": {
                                        "showHead": true,
                                        "isVertical": false,
                                        "isIteratable": false,
                                        "addButton": {
                                            "classname": "btn btn-primary",
                                            "name": "",
                                            "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                        },
                                        "removeButton": {
                                            "classname": "btn btn-danger",
                                            "name": "",
                                            "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                        },
                                        "tableHead": {
                                            "heading": [
                                                {
                                                    "value": "Plate ID",
                                                    "classname": ""
                                                },
                                                {
                                                    "value": "No of Clones",
                                                    "classname": ""
                                                },
                                                {
                                                    "value": "Total no of clones",
                                                    "classname": ""
                                                },
                                                {
                                                    "value": "Cleanup Vol per  Reaction <span class=\"text-initial\">(µl)</span>",
                                                    "classname": ""
                                                },
                                                {
                                                    "value": "Total Cleanup Mix  Required Vol <span class=\"text-initial\">(µL)</span>",
                                                    "classname": ""
                                                },
                                                {
                                                    "value": "Cleanup Mix  Prepared <span class=\"text-initial\">(µL)</span>",
                                                    "classname": ""
                                                },
                                                {
                                                    "value": "Exonuclease <span class=\"text-initial\">(µl)</span>",
                                                    "classname": ""
                                                },
                                                {
                                                    "value": "SAP Vol <span class=\"text-initial\">(µl)</span>",
                                                    "classname": ""
                                                },
                                                {
                                                    "value": "DI H<sub>₂</sub>O <span class=\"text-initial\">(µl)</span>",
                                                    "classname": ""
                                                },
                                                {
                                                    "value": "PCR PDT Vol <span class=\"text-initial\">(µl)</span>",
                                                    "classname": ""
                                                },
                                            ],
                                            "classname": ""
                                        },
                                        "initRecordCount": 1,
                                        "showRecordCount": false,
                                        "recordCounterConfig": {
                                            "classname": "",
                                            "adjValue": "",
                                            "isAdjValueAfter": false
                                        },
                                        "tableRow": {
                                            "name": "",
                                            "classname": "trclass",
                                            "tableCols": [
                                                {
                                                    "fields": {
                                                        "control": [
                                                            {
                                                                "type": "text",
                                                                "name": "cleanup_plate_id",
                                                                "id": "cleanup_plate_id",
                                                                "value": "",
                                                                "placeholder": "",
                                                                "multiline": false,
                                                                "classname": "form-control mb-1",
                                                                "regularExpression": "",
                                                                "disabled": true,
                                                                "required": false,
                                                                "errorMessage": {
                                                                    "noData": "This fields is required",
                                                                    "regexFail": ""
                                                                },
                                                                "validationButtonType": "",
                                                                "rows": 0,
                                                                "columns": 0,
                                                                "onDemandError": false,
                                                                "customValidation": [],
                                                                "multiError": false,
                                                                "applicationError": "",
                                                                "wrapperClassName": "",
                                                                "requiredErrorMessage": "This fields is required",
                                                                "__schema_id": "cleanup_plate_id",
                                                                "showInputLabel": false,
                                                                "labelContent": "text_915",
                                                                "labelClass": "form-label",
                                                                "isLabelAfter": false,
                                                                "showAdditionalInputContent": false,
                                                                "additionalInputContent": "",
                                                                "isAdditonalInputAfter": true,
                                                                "draggable": false
                                                            }
                                                        ],
                                                        "head": "",
                                                        "name": "cleanup_plates",
                                                        "id": "cleanup_plates",
                                                        "wrapperType": "ITERATE",
                                                        "fields": [],
                                                        "type": "",
                                                        "classname": "",
                                                        "ButtonPosition": "top",
                                                        "addButton": {
                                                            "classname": "btn",
                                                            "name": "",
                                                            "value": ""
                                                        },
                                                        "removeButton": {
                                                            "classname": "btn",
                                                            "name": "",
                                                            "value": ""
                                                        },
                                                        "controlButtonClass": "d-none",
                                                        "hideSingleItemDelete": false,
                                                        "addBtnPostion": null
                                                    },
                                                    "classname": ""
                                                },
                                                {
                                                    "fields": {
                                                        "control": [
                                                            {
                                                                "type": "text",
                                                                "name": "cleanup_no_of_clones",
                                                                "id": "cleanup_no_of_clones",
                                                                "value": "",
                                                                "placeholder": "",
                                                                "multiline": false,
                                                                "classname": "form-control mb-1",
                                                                "regularExpression": "",
                                                                "disabled": true,
                                                                "required": false,
                                                                "errorMessage": {
                                                                    "noData": "This fields is required",
                                                                    "regexFail": ""
                                                                },
                                                                "validationButtonType": "",
                                                                "rows": 0,
                                                                "columns": 0,
                                                                "onDemandError": false,
                                                                "customValidation": [],
                                                                "multiError": false,
                                                                "applicationError": "",
                                                                "wrapperClassName": "",
                                                                "requiredErrorMessage": "This fields is required",
                                                                "__schema_id": "cleanup_no_of_clones",
                                                                "showInputLabel": false,
                                                                "labelContent": "text_63",
                                                                "labelClass": "form-label",
                                                                "isLabelAfter": false,
                                                                "showAdditionalInputContent": false,
                                                                "additionalInputContent": "",
                                                                "isAdditonalInputAfter": true,
                                                                "draggable": false
                                                            }
                                                        ],
                                                        "name": "cleanup_clones",
                                                        "id": "cleanup_clones",
                                                        "wrapperType": "ITERATE",
                                                        "fields": [],
                                                        "type": "",
                                                        "classname": "",
                                                        "addButton": {
                                                            "classname": "btn",
                                                            "name": "",
                                                            "value": ""
                                                        },
                                                        "removeButton": {
                                                            "classname": "btn",
                                                            "name": "",
                                                            "value": ""
                                                        },
                                                        "wrapperClassName": "",
                                                        "ButtonPosition": "top",
                                                        "controlButtonClass": "d-none",
                                                        "hideSingleItemDelete": false,
                                                        "addBtnPostion": null
                                                    },
                                                    "classname": ""
                                                },
                                                {
                                                    "fields": {
                                                        "control": [
                                                            {
                                                                "type": "text",
                                                                "name": "cleanup_total_no_of_clones",
                                                                "id": "cleanup_total_no_of_clones",
                                                                "value": "",
                                                                "placeholder": "",
                                                                "multiline": false,
                                                                "classname": "form-control ",
                                                                "regularExpression": "",
                                                                "disabled": false,
                                                                "required": false,
                                                                "errorMessage": {
                                                                    "noData": "This fields is required",
                                                                    "regexFail": ""
                                                                },
                                                                "validationButtonType": "",
                                                                "rows": 0,
                                                                "columns": 0,
                                                                "onDemandError": false,
                                                                "customValidation": [],
                                                                "multiError": false,
                                                                "applicationError": "",
                                                                "wrapperClassName": "",
                                                                "requiredErrorMessage": "This fields is required",
                                                                "__schema_id": "cleanup_total_no_of_clones",
                                                                "showInputLabel": false,
                                                                "labelContent": "text_937",
                                                                "labelClass": "form-label",
                                                                "isLabelAfter": false,
                                                                "showAdditionalInputContent": false,
                                                                "additionalInputContent": "",
                                                                "isAdditonalInputAfter": true,
                                                                "draggable": false
                                                            }
                                                        ],
                                                        "name": "box_854",
                                                        "id": "box_780",
                                                        "wrapperType": "BOX",
                                                        "fields": [],
                                                        "type": "",
                                                        "classname": "",
                                                        "addButton": {
                                                            "classname": "",
                                                            "value": ""
                                                        },
                                                        "removeButton": {
                                                            "classname": "",
                                                            "value": ""
                                                        },
                                                        "wrapperClassName": ""
                                                    },
                                                    "classname": ""
                                                },
                                                {
                                                    "fields": {
                                                        "control": [
                                                            {
                                                                "type": "text",
                                                                "name": "cleanup_vol_per_reaction",
                                                                "id": "cleanup_vol_per_reaction",
                                                                "value": 2,
                                                                "placeholder": "",
                                                                "multiline": false,
                                                                "classname": "form-control ",
                                                                "regularExpression": "",
                                                                "disabled": false,
                                                                "required": false,
                                                                "errorMessage": {
                                                                    "noData": "This fields is required",
                                                                    "regexFail": ""
                                                                },
                                                                "validationButtonType": "",
                                                                "rows": 0,
                                                                "columns": 0,
                                                                "onDemandError": false,
                                                                "customValidation": [],
                                                                "multiError": false,
                                                                "applicationError": "",
                                                                "wrapperClassName": "",
                                                                "requiredErrorMessage": "This fields is required",
                                                                "__schema_id": "cleanup_vol_per_reaction",
                                                                "showInputLabel": false,
                                                                "labelContent": "text_616",
                                                                "labelClass": "form-label",
                                                                "isLabelAfter": false,
                                                                "showAdditionalInputContent": false,
                                                                "additionalInputContent": "",
                                                                "isAdditonalInputAfter": true,
                                                                "draggable": false
                                                            }
                                                        ],
                                                        "name": "box_558",
                                                        "id": "box_925",
                                                        "wrapperType": "BOX",
                                                        "fields": [],
                                                        "type": "",
                                                        "classname": "",
                                                        "addButton": {
                                                            "classname": "",
                                                            "value": ""
                                                        },
                                                        "removeButton": {
                                                            "classname": "",
                                                            "value": ""
                                                        },
                                                        "wrapperClassName": ""
                                                    },
                                                    "classname": ""
                                                },
                                                {
                                                    "fields": {
                                                        "control": [
                                                            {
                                                                "type": "text",
                                                                "name": "cleanup_mix_required",
                                                                "id": "cleanup_mix_required",
                                                                "value": "",
                                                                "placeholder": "",
                                                                "multiline": false,
                                                                "classname": "form-control ",
                                                                "regularExpression": "",
                                                                "disabled": true,
                                                                "required": false,
                                                                "errorMessage": {
                                                                    "noData": "This fields is required",
                                                                    "regexFail": ""
                                                                },
                                                                "validationButtonType": "",
                                                                "rows": 0,
                                                                "columns": 0,
                                                                "onDemandError": false,
                                                                "customValidation": [],
                                                                "multiError": false,
                                                                "applicationError": "",
                                                                "wrapperClassName": "",
                                                                "requiredErrorMessage": "This fields is required",
                                                                "__schema_id": "cleanup_mix_required",
                                                                "showInputLabel": false,
                                                                "labelContent": "text_557",
                                                                "labelClass": "form-label",
                                                                "isLabelAfter": false,
                                                                "showAdditionalInputContent": false,
                                                                "additionalInputContent": "",
                                                                "isAdditonalInputAfter": true,
                                                                "draggable": false
                                                            }
                                                        ],
                                                        "name": "box_830",
                                                        "id": "box_630",
                                                        "wrapperType": "BOX",
                                                        "fields": [],
                                                        "type": "",
                                                        "classname": "",
                                                        "addButton": {
                                                            "classname": "",
                                                            "value": ""
                                                        },
                                                        "removeButton": {
                                                            "classname": "",
                                                            "value": ""
                                                        },
                                                        "wrapperClassName": ""
                                                    },
                                                    "classname": ""
                                                },
                                                {
                                                    "fields": {
                                                        "control": [
                                                            {
                                                                "type": "text",
                                                                "name": "cleanup_mix_prepared",
                                                                "id": "cleanup_mix_prepared",
                                                                "value": 200,
                                                                "placeholder": "",
                                                                "multiline": false,
                                                                "classname": "form-control ",
                                                                "regularExpression": "",
                                                                "disabled": false,
                                                                "required": false,
                                                                "errorMessage": {
                                                                    "noData": "This fields is required",
                                                                    "regexFail": ""
                                                                },
                                                                "validationButtonType": "",
                                                                "rows": 0,
                                                                "columns": 0,
                                                                "onDemandError": false,
                                                                "customValidation": [],
                                                                "multiError": false,
                                                                "applicationError": "",
                                                                "wrapperClassName": "",
                                                                "requiredErrorMessage": "This fields is required",
                                                                "__schema_id": "cleanup_mix_prepared",
                                                                "showInputLabel": false,
                                                                "labelContent": "text_239",
                                                                "labelClass": "form-label",
                                                                "isLabelAfter": false,
                                                                "showAdditionalInputContent": false,
                                                                "additionalInputContent": "",
                                                                "isAdditonalInputAfter": true,
                                                                "draggable": false
                                                            }
                                                        ],
                                                        "name": "box_733",
                                                        "id": "box_159",
                                                        "wrapperType": "BOX",
                                                        "fields": [],
                                                        "type": "",
                                                        "classname": "",
                                                        "addButton": {
                                                            "classname": "",
                                                            "value": ""
                                                        },
                                                        "removeButton": {
                                                            "classname": "",
                                                            "value": ""
                                                        },
                                                        "wrapperClassName": ""
                                                    },
                                                    "classname": ""
                                                },
                                                {
                                                    "fields": {
                                                        "control": [
                                                            {
                                                                "type": "text",
                                                                "name": "cleanup_exonuclease",
                                                                "id": "cleanup_exonuclease",
                                                                "value": 0.2,
                                                                "placeholder": "",
                                                                "multiline": false,
                                                                "classname": "form-control ",
                                                                "regularExpression": "",
                                                                "disabled": false,
                                                                "required": false,
                                                                "errorMessage": {
                                                                    "noData": "This fields is required",
                                                                    "regexFail": ""
                                                                },
                                                                "validationButtonType": "",
                                                                "rows": 0,
                                                                "columns": 0,
                                                                "onDemandError": false,
                                                                "customValidation": [],
                                                                "multiError": false,
                                                                "applicationError": "",
                                                                "wrapperClassName": "",
                                                                "requiredErrorMessage": "This fields is required",
                                                                "__schema_id": "cleanup_exonuclease",
                                                                "showInputLabel": false,
                                                                "labelContent": "text_933",
                                                                "labelClass": "form-label",
                                                                "isLabelAfter": false,
                                                                "showAdditionalInputContent": false,
                                                                "additionalInputContent": "",
                                                                "isAdditonalInputAfter": true,
                                                                "draggable": false
                                                            }
                                                        ],
                                                        "name": "box_652",
                                                        "id": "box_684",
                                                        "wrapperType": "BOX",
                                                        "fields": [],
                                                        "type": "",
                                                        "classname": "",
                                                        "addButton": {
                                                            "classname": "",
                                                            "value": ""
                                                        },
                                                        "removeButton": {
                                                            "classname": "",
                                                            "value": ""
                                                        },
                                                        "wrapperClassName": ""
                                                    },
                                                    "classname": ""
                                                },
                                                {
                                                    "fields": {
                                                        "control": [
                                                            {
                                                                "type": "text",
                                                                "name": "cleanup_sap_vol",
                                                                "id": "cleanup_sap_vol",
                                                                "value": 0.2,
                                                                "placeholder": "",
                                                                "multiline": false,
                                                                "classname": "form-control ",
                                                                "regularExpression": "",
                                                                "disabled": false,
                                                                "required": false,
                                                                "errorMessage": {
                                                                    "noData": "This fields is required",
                                                                    "regexFail": ""
                                                                },
                                                                "validationButtonType": "",
                                                                "rows": 0,
                                                                "columns": 0,
                                                                "onDemandError": false,
                                                                "customValidation": [],
                                                                "multiError": false,
                                                                "applicationError": "",
                                                                "wrapperClassName": "",
                                                                "requiredErrorMessage": "This fields is required",
                                                                "__schema_id": "cleanup_sap_vol",
                                                                "showInputLabel": false,
                                                                "labelContent": "text_358",
                                                                "labelClass": "form-label",
                                                                "isLabelAfter": false,
                                                                "showAdditionalInputContent": false,
                                                                "additionalInputContent": "",
                                                                "isAdditonalInputAfter": true,
                                                                "draggable": false
                                                            }
                                                        ],
                                                        "name": "box_477",
                                                        "id": "box_233",
                                                        "wrapperType": "BOX",
                                                        "fields": [],
                                                        "type": "",
                                                        "classname": "",
                                                        "addButton": {
                                                            "classname": "",
                                                            "value": ""
                                                        },
                                                        "removeButton": {
                                                            "classname": "",
                                                            "value": ""
                                                        },
                                                        "wrapperClassName": ""
                                                    },
                                                    "classname": ""
                                                },
                                                {
                                                    "fields": {
                                                        "control": [
                                                            {
                                                                "type": "text",
                                                                "name": "cleanup_di_h2o",
                                                                "id": "cleanup_di_h2o",
                                                                "value": 200,
                                                                "placeholder": "",
                                                                "multiline": false,
                                                                "classname": "form-control ",
                                                                "regularExpression": "",
                                                                "disabled": false,
                                                                "required": false,
                                                                "errorMessage": {
                                                                    "noData": "This fields is required",
                                                                    "regexFail": ""
                                                                },
                                                                "validationButtonType": "",
                                                                "rows": 0,
                                                                "columns": 0,
                                                                "onDemandError": false,
                                                                "customValidation": [],
                                                                "multiError": false,
                                                                "applicationError": "",
                                                                "wrapperClassName": "",
                                                                "requiredErrorMessage": "This fields is required",
                                                                "__schema_id": "cleanup_di_h2o",
                                                                "showInputLabel": false,
                                                                "labelContent": "text_898",
                                                                "labelClass": "form-label",
                                                                "isLabelAfter": false,
                                                                "showAdditionalInputContent": false,
                                                                "additionalInputContent": "",
                                                                "isAdditonalInputAfter": true,
                                                                "draggable": false
                                                            }
                                                        ],
                                                        "name": "box_66",
                                                        "id": "box_992",
                                                        "wrapperType": "BOX",
                                                        "fields": [],
                                                        "type": "",
                                                        "classname": "",
                                                        "addButton": {
                                                            "classname": "",
                                                            "value": ""
                                                        },
                                                        "removeButton": {
                                                            "classname": "",
                                                            "value": ""
                                                        },
                                                        "wrapperClassName": ""
                                                    },
                                                    "classname": ""
                                                },
                                                {
                                                    "fields": {
                                                        "control": [
                                                            {
                                                                "type": "text",
                                                                "name": "cleanup_pcr_pdt_vol",
                                                                "id": "cleanup_pcr_pdt_vol",
                                                                "value": 5,
                                                                "placeholder": "",
                                                                "multiline": false,
                                                                "classname": "form-control ",
                                                                "regularExpression": "",
                                                                "disabled": false,
                                                                "required": false,
                                                                "errorMessage": {
                                                                    "noData": "This fields is required",
                                                                    "regexFail": ""
                                                                },
                                                                "validationButtonType": "",
                                                                "rows": 0,
                                                                "columns": 0,
                                                                "onDemandError": false,
                                                                "customValidation": [],
                                                                "multiError": false,
                                                                "applicationError": "",
                                                                "wrapperClassName": "",
                                                                "requiredErrorMessage": "This fields is required",
                                                                "__schema_id": "cleanup_pcr_pdt_vol",
                                                                "showInputLabel": false,
                                                                "labelContent": "text_758",
                                                                "labelClass": "form-label",
                                                                "isLabelAfter": false,
                                                                "showAdditionalInputContent": false,
                                                                "additionalInputContent": "",
                                                                "isAdditonalInputAfter": true,
                                                                "draggable": false
                                                            }
                                                        ],
                                                        "name": "box_610",
                                                        "id": "box_355",
                                                        "wrapperType": "BOX",
                                                        "fields": [],
                                                        "type": "",
                                                        "classname": "",
                                                        "addButton": {
                                                            "classname": "",
                                                            "value": ""
                                                        },
                                                        "removeButton": {
                                                            "classname": "",
                                                            "value": ""
                                                        },
                                                        "wrapperClassName": ""
                                                    },
                                                    "classname": ""
                                                }
                                            ]
                                        }
                                    }
                                }
                            ],
                            "head": "",
                            "tabHeadClass": "",
                            "draggable": false
                        },
                        {
                            "control": [
                                {
                                    "type": "label",
                                    "name": "<h2>Cleaning Incubation Setup</h2>",
                                    "classname": "sub-heading mt-3",
                                    "for": "",
                                    "required": false,
                                    "wrapperClassName": "",
                                    "id": "label_446",
                                    "draggable": false
                                }
                            ],
                            "type": "",
                            "name": "wrapper_230",
                            "classname": "col-12",
                            "wrapperType": "BOX",
                            "id": "wrapper_693",
                            "fields": [
                                {
                                    "control": [],
                                    "type": "",
                                    "name": "cleanup_inc_setup_info",
                                    "classname": "table cleaning-incubation-table-pr-7",
                                    "wrapperType": "TABLE",
                                    "id": "wrapper_752",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": "",
                                    "draggable": false,
                                    "hideSingleItemDelete": false,
                                    "tableFields": {
                                        "showHead": true,
                                        "isVertical": false,
                                        "isIteratable": false,
                                        "addButton": {
                                            "classname": "btn btn-primary",
                                            "name": "",
                                            "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                        },
                                        "removeButton": {
                                            "classname": "btn btn-danger",
                                            "name": "",
                                            "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                        },
                                        "tableHead": {
                                            "heading": [
                                                {
                                                    "value": "Temperature (°C)",
                                                    "classname": ""
                                                },
                                                {
                                                    "value": "Incubation time (min)",
                                                    "classname": ""
                                                }
                                            ],
                                            "classname": ""
                                        },
                                        "initRecordCount": 1,
                                        "showRecordCount": false,
                                        "recordCounterConfig": {
                                            "classname": "",
                                            "adjValue": "",
                                            "isAdjValueAfter": false
                                        },
                                        "tableRow": {
                                            "name": "",
                                            "classname": "trclass",
                                            "tableCols": [
                                                {
                                                    "fields": {
                                                        "control": [
                                                            {
                                                                "type": "text",
                                                                "name": "cleanup_inc_temp",
                                                                "id": "cleanup_inc_temp",
                                                                "value": "",
                                                                "placeholder": "",
                                                                "multiline": false,
                                                                "classname": "form-control ",
                                                                "regularExpression": "",
                                                                "disabled": false,
                                                                "required": false,
                                                                "errorMessage": {
                                                                    "noData": "This fields is required",
                                                                    "regexFail": ""
                                                                },
                                                                "validationButtonType": "",
                                                                "rows": 0,
                                                                "columns": 0,
                                                                "onDemandError": false,
                                                                "customValidation": [],
                                                                "multiError": false,
                                                                "applicationError": "",
                                                                "wrapperClassName": "",
                                                                "requiredErrorMessage": "This fields is required",
                                                                "__schema_id": "cleanup_inc_temp",
                                                                "showInputLabel": false,
                                                                "labelContent": "text_46",
                                                                "labelClass": "form-label",
                                                                "isLabelAfter": false,
                                                                "showAdditionalInputContent": false,
                                                                "additionalInputContent": "",
                                                                "isAdditonalInputAfter": true,
                                                                "draggable": false
                                                            }
                                                        ],
                                                        "head": "",
                                                        "name": "box_9",
                                                        "id": "box_42",
                                                        "wrapperType": "BOX",
                                                        "fields": [],
                                                        "type": "",
                                                        "classname": ""
                                                    },
                                                    "classname": ""
                                                },
                                                {
                                                    "fields": {
                                                        "control": [
                                                            {
                                                                "type": "text",
                                                                "name": "cleanup_inc_time",
                                                                "id": "cleanup_inc_time",
                                                                "value": "",
                                                                "placeholder": "",
                                                                "multiline": false,
                                                                "classname": "form-control ",
                                                                "regularExpression": "",
                                                                "disabled": false,
                                                                "required": false,
                                                                "errorMessage": {
                                                                    "noData": "This fields is required",
                                                                    "regexFail": ""
                                                                },
                                                                "validationButtonType": "",
                                                                "rows": 0,
                                                                "columns": 0,
                                                                "onDemandError": false,
                                                                "customValidation": [],
                                                                "multiError": false,
                                                                "applicationError": "",
                                                                "wrapperClassName": "",
                                                                "requiredErrorMessage": "This fields is required",
                                                                "__schema_id": "cleanup_inc_time",
                                                                "showInputLabel": false,
                                                                "labelContent": "text_297",
                                                                "labelClass": "form-label",
                                                                "isLabelAfter": false,
                                                                "showAdditionalInputContent": false,
                                                                "additionalInputContent": "",
                                                                "isAdditonalInputAfter": true,
                                                                "draggable": false
                                                            }
                                                        ],
                                                        "name": "box_760",
                                                        "id": "box_288",
                                                        "wrapperType": "BOX",
                                                        "fields": [],
                                                        "type": "",
                                                        "classname": "",
                                                        "addButton": {
                                                            "classname": "",
                                                            "value": ""
                                                        },
                                                        "removeButton": {
                                                            "classname": "",
                                                            "value": ""
                                                        },
                                                        "wrapperClassName": ""
                                                    },
                                                    "classname": ""
                                                }
                                            ]
                                        }
                                    }
                                }
                            ],
                            "head": "",
                            "tabHeadClass": "",
                            "draggable": false
                        }
                    ],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false,
                    "isAccordion": true,
                    "accordionConfig": {
                        "headerClassName": "",
                        "headerBtnClassName": "",
                        "header": "<span>Cleanup & Template Preparation</span>",
                        "collapseDivClassName": "",
                        "bodyClassName": "",
                        "collapseOthers": true
                    }
                },
            ],
            "head": "",
            "tabHeadClass": "",
            "draggable": false,
            "isAccordion": true,
            "accordionConfig": {
                "headerClassName": "",
                "headerBtnClassName": "",
                "header": "",
                "collapseDivClassName": "",
                "bodyClassName": "",
                "collapseOthers": true
            }
        }
    ],
    "type": "",
    "classname": "",
    "showClass": true,
    "payload_schema": {
        "pcr_template_sequence": {
            "cleanup_inc_setup_info": [
                {
                    "cleanup_inc_time": "cleanup_inc_time.$t{number}",
                    "cleanup_inc_temp": "cleanup_inc_temp.$t{number}"
                }
            ],
            "cleanup_template_info": [
                {
                    "cleanup_pcr_pdt_vol": "cleanup_pcr_pdt_vol.$t{number}",
                    "cleanup_di_h2o": "cleanup_di_h2o.$t{number}",
                    "cleanup_sap_vol": "cleanup_sap_vol.$t{number}",
                    "cleanup_exonuclease":"cleanup_exonuclease.$t{number}",
                    "cleanup_mix_prepared": "cleanup_mix_prepared.$t{number}",
                    "cleanup_mix_required": "cleanup_mix_required.$t{number}",
                    "cleanup_vol_per_reaction": "cleanup_vol_per_reaction.$t{number}",
                    "cleanup_total_no_of_clones": "cleanup_total_no_of_clones.$t{number}",
                    "cleanup_plates": [
                        {
                            "cleanup_plate_id": "cleanup_plate_id.$d{}"
                        }
                    ],
                    "cleanup_clones": [
                        {
                            "cleanup_no_of_clones": "cleanup_no_of_clones.$t{number}"
                        }
                    ]
                }
            ],
            "electrophoresis_info": [
                {
                    "electro_run_time": "electro_run_time.$t{number}",
                    "voltage": "voltage.$t{number}",
                    "analysis_using": "analysis_using",
                    "electrophoresis_plates": [
                        {
                            "electro_plate_id": "electro_plate_id.$d{}"
                        }
                    ],
                    "electrophoresis_gel": [
                        {
                            "electro_gel": "electro_gel.$t{number}"
                        }
                    ],
                    "electrophoresis_bands": [
                        {
                            "electro_band_formed": "electro_band_formed"
                        }
                    ]
                }
            ],
            "program_setup": [
                {
                    "no_of_cycles": "no_of_cycles.$t{number}",
                    "run_time": "run_time.$t{number}",
                    "select_program": "select_program"
                }
            ],
            "pcr_mix": [
                {
                    "pcr_di_h2o": "pcr_di_h2o.$t{number}",
                    "pcr_dmso": "pcr_dmso.$t{number}",
                    "pcr_polymerase": "pcr_polymerase.$t{number}",
                    "pcr_primer_reverse": "pcr_primer_reverse.$t{number}",
                    "pcr_primer_forward": "pcr_primer_forward.$t{number}",
                    "pcr_dntps": "pcr_dntps.$t{number}",
                    "pcr_buffer": "pcr_buffer.$t{number}",
                    "dna_polymerase": "dna_polymerase",
                    "pcr_kit": "pcr_kit",
                    "phage_temp_vol": "phage_temp_vol.$t{number}",
                    "total_mm_vol_required": "total_mm_vol_required.$t{number}",
                    "vol_per_clones": "vol_per_clones.$t{number}",
                    "total_no_of_clones": "total_no_of_clones.$t{number}",
                    "pcr_mix_plate": [
                        {
                            "pcr_plate_id": "pcr_plate_id.$d{}"
                        } 
                    ],
                    "pcr_mix_clones": [
                        {
                            "no_of_clones": "no_of_clones.$t{number}"
                        }
                    ]
                }
            ],
            "pcr_master_total": [
                {
                    "total_master_mix": "total_master_mix.$t{number}"
                }
            ],
            "pcr_primer_details": [
                {
                    "reverse_primer_name": "reverse_primer_name.$d{}",
                    "reverse_primer_file_id": "reverse_primer_file_id.$t{number}",
                    "forward_primer_name": "forward_primer_name.$d{}",
                    "forward_primer_file_id": "forward_primer_file_id.$t{number}"
                }
            ],
            "clone_info": [
                {
                    "clone_selected": "clone_selected.$d{}",
                    "clone_plate_id": "clone_plate_id",
                    "screening_id": "screening_id.$d{}",
                    "project_id": "project_id.$d{}"
                }
            ],
            "protocol": "protocol",
            "protocol_file_id": "protocol_file_id",
            "gel_image_file_id":"gel_image_file_id.$t{number}"
        }
    }
}

const primer_file_upload={
    "jsonId": 166,
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
        {
            "control": [
                {
                    "type": "dropdown",
                    "name": "file_type",
                    "id": "file_type",
                    "validationButtonType": "",
                    "classname": "d-none",
                    "required": false,
                    "apiEndPoints": [],
                    "options": [
                        {
                            "key": "forward_primer_name",
                            "value": "Forward Primer Name"
                        },
                        {
                            "key": "reverse_primer_name",
                            "value": "Reverse Primer Name"
                        }

                    ],
                    "multiple": false,
                    "isClearable": false,
                    "isStatus": false,
                    "search": false,
                    "singleSelect": true,
                    "showLabel": "value",
                    "errorMessage": {
                        "noData": "This fields is required"
                    },
                    "value": "",
                    "imageUrl": "",
                    "disabled": false,
                    "wrapperClassName": "mb-3",
                    "connectedArrays": [],
                    "showBlankInPlaceholder": false,
                    "blankDataList": [],
                    "commonPathOfImage": "",
                    "__schema_id": "file_type",
                    "showInputLabel": false,
                    "labelContent": "Select File to Upload",
                    "labelClass": "form-label",
                    "isLabelAfter": false,
                    "showAdditionalInputContent": false,
                    "additionalInputContent": "",
                    "isAdditonalInputAfter": true,
                    "draggable": false
                },
                {
                    "type": "file",
                    "name": "file",
                    "id": "file",
                    "value": "upload",
                    "classname": "btn btn-file",
                    "fileNameShow": true,
                    "size": 10,
                    "url": "",
                    "functionName": "",
                    "wrapperClassName": "",
                    "fileCount": 1,
                    "sizeKB": "50000",
                    "allowMultiple": false,
                    "required": false,
                    "validationButtonType": "upload",
                    "formDataType": "blob",
                    "errorMessage": {
                        "invalidFormat": "Invalid File Format",
                        "invalidSize": "File Size exceeded",
                        "classname":"invalid-feedback d-block"
                    },
                    "fileTypes": [ "pdf","doc","docx","dot", "dotm", "dotx", "ab1", "txt", "xlxs", "xlsx", "xlsm", "xlsb", "xltx", "xltm", "xls", "xlt", "xml", "xla", "xlam", "xlw", "xlr", "jpeg", "pneg", "png", "fasta", "fas", "fa", "fna", "ffn", "faa", "mpfa", "frn" ],
                    "listClass": "",
                    "mainClass": "",
                    "btnDivClass": "",
                    "showInputLabel": true,
                    "labelContent": "Drag and drop files here or browse files  ",
                    "labelClass": "form-label",
                    "requiredErrorMessage": "This field is required",
                    "isLabelAfter": false,
                    "showAdditionalInputContent": false,
                    "additionalInputContent": "",
                    "isAdditonalInputAfter": true,
                    "draggable": false,
                    "showViewIcon": false,
                    "showDownloadIcon": false
                }
            ],
            "type": "",
            "name": "wrapper_379",
            "classname": "col-12 mb-3 drag-and-drop",
            "wrapperType": "BOX",
            "id": "wrapper_461",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
        }
    ],
    "type": "",
    "classname": ""
}

const dna_sequence_gel_image = {
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "jsonId": 167,
    "wrapperType": "REGULAR",
    "fields": [
        {
            "control": [
                {
                    "type": "dropdown",
                    "name": "file_type",
                    "id": "file_type",
                    "validationButtonType": "",
                    "classname": "d-none",
                    "required": false,
                    "apiEndPoints": [],
                    "options": [
                        {
                            "key": "gel image",
                            "value": "Gel Image"
                        },
                    ],
                    "multiple": false,
                    "isClearable": false,
                    "isStatus": false,
                    "search": false,
                    "singleSelect": true,
                    "showLabel": "value",
                    "errorMessage": {
                        "noData": "This fields is required"
                    },
                    "value": {
                        "key": "gel image",
                        "value": "Gel Image"
                    },
                    "imageUrl": "",
                    "disabled": false,
                    "wrapperClassName": "mb-3",
                    "connectedArrays": [],
                    "showBlankInPlaceholder": false,
                    "blankDataList": [],
                    "commonPathOfImage": "",
                    "__schema_id": "file_type",
                    "showInputLabel": false,
                    "labelContent": "Select File to Upload",
                    "labelClass": "form-label",
                    "isLabelAfter": false,
                    "showAdditionalInputContent": false,
                    "additionalInputContent": "",
                    "isAdditonalInputAfter": true,
                    "draggable": false
                },
                {
                    "type": "file",
                    "name": "file",
                    "id": "file",
                    "value": "upload",
                    "classname": "btn btn-file ",
                    "fileNameShow": false,
                    "size": 10,
                    "url": "",
                    "validationButtonType": "upload",
                    "wrapperClassName": "",
                    "fileCount": 1,
                    "sizeKB": "50000",
                    "allowMultiple": false,
                    "formDataType": "blob",
                    "fileTypes": [ "jpeg", "pneg", "png", "jpg", "pdf" ],
                    "listClass": "",
                    "mainClass": "",
                    "btnDivClass": "",
                    "outsideFileContainer": false,
                    "errorMessage": {
                        "invalidFormat": "Invalid File Format",
                        "invalidSize": "File Size exceeded",
                        "classname": "invalid-feedback d-block"
                    },
                    "__schema_id": "file",
                    "showInputLabel": true,
                    "labelContent": "Upload Files",
                    "labelClass": "form-label",
                    "isLabelAfter": false,
                    "showAdditionalInputContent": false,
                    "additionalInputContent": "",
                    "isAdditonalInputAfter": true,
                    "draggable": false
                }
            ],
            "type": "",
            "name": "wrapper_433",
            "classname": "col-12 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_985",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
        }
    ],
    "type": "",
    "classname": "",
    "showClass": true
}
const cdr_sequence_file_upload={
    "control": [],
    "jsonId": 168,
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
        {
            "control": [
                {
                    "type": "file",
                    "name": "file",
                    "id": "file",
                    "value": "upload",
                    "classname": "btn btn-file ",
                    "fileNameShow": false,
                    "size": 10,
                    "url": "",
                    "validationButtonType": "upload",
                    "wrapperClassName": "",
                    "fileCount": 10,
                    "sizeKB": "50000",
                    "allowMultiple": true,
                    "formDataType": "blob",
                    "fileTypes": [ "pdf","doc","docx","dot", "dotm", "dotx", "ab1", "txt", "xlxs", "xlsx", "xlsm", "xlsb", "xltx", "xltm", "xls", "xlt", "xml", "xla", "xlam", "xlw", "xlr", "jpeg", "pneg", "png", "fasta", "fas", "fa", "fna", "ffn", "faa", "mpfa", "frn" ],
                    "listClass": "",
                    "mainClass": "",
                    "btnDivClass": "",
                    "outsideFileContainer": true,
                    "errorMessage": {
                        "invalidFormat": "Invalid File Format",
                        "invalidSize": "File Size exceeded",
                        "classname": "invalid-feedback d-block"
                    },
                    "__schema_id": "file",
                    "showInputLabel": true,
                    "labelContent": "Upload Files",
                    "labelClass": "form-label",
                    "isLabelAfter": false,
                    "showAdditionalInputContent": false,
                    "additionalInputContent": "",
                    "isAdditonalInputAfter": true,
                    "draggable": false,
                    "required": true,
                    "showViewIcon": false,
                }
            ],
            "type": "",
            "name": "wrapper_988",
            "classname": "",
            "wrapperType": "BOX",
            "id": "wrapper_471",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
        }
    ],
    "type": "",
    "classname": ""
}

const excel_upload={
    "jsonId": 170,
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
        {
            "control": [
                {
                    "type": "dropdown",
                    "name": "file_type",
                    "id": "file_type",
                    "validationButtonType": "",
                    "classname": "d-none",
                    "required": false,
                    "apiEndPoints": [],
                    "options": [
                        {
                            "key": "project-init",
                            "value": "Project Initiation"
                        }
                    ],
                    "multiple": false,
                    "isClearable": false,
                    "isStatus": false,
                    "search": false,
                    "singleSelect": true,
                    "showLabel": "value",
                    "errorMessage": {
                        "noData": "This fields is required"
                    },
                    "value": {
                        "key": "project-init",
                        "value": "Project Initiation"
                    },
                    "imageUrl": "",
                    "disabled": false,
                    "wrapperClassName": "mb-3",
                    "connectedArrays": [],
                    "showBlankInPlaceholder": false,
                    "blankDataList": [],
                    "commonPathOfImage": "",
                    "__schema_id": "file_type",
                    "showInputLabel": false,
                    "labelContent": "Select File to Upload",
                    "labelClass": "form-label",
                    "isLabelAfter": false,
                    "showAdditionalInputContent": false,
                    "additionalInputContent": "",
                    "isAdditonalInputAfter": true,
                    "draggable": false
                },
                {
                    "type": "file",
                    "name": "file",
                    "id": "file",
                    "value": "upload",
                    "classname": "btn btn-file",
                    "fileNameShow": true,
                    "size": 10,
                    "url": "",
                    "functionName": "",
                    "wrapperClassName": "",
                    "fileCount": 1,
                    "sizeKB": "50000",
                    "allowMultiple": false,
                    "required": false,
                    "validationButtonType": "upload",
                    "formDataType": "blob",
                    "errorMessage": {
                        "invalidFormat": "Invalid File Format",
                        "invalidSize": "File Size exceeded",
                        "classname":"invalid-feedback d-block"
                    },
                    "fileTypes": [ "pdf","doc","docx","dot", "dotm", "dotx", "ab1", "txt", "xlxs", "xlsm", "xlsb", "xltx", "xltm", "xls", "xlsx", "xlt", "xml", "xla", "xlam", "xlw", "xlr", "jpeg", "pneg", "png", "fasta", "fas", "fa", "fna", "ffn", "faa", "mpfa", "frn" ],
                    "listClass": "",
                    "mainClass": "",
                    "btnDivClass": "",
                    "showInputLabel": true,
                    "labelContent": "Drag and drop files here or browse files  ",
                    "labelClass": "form-label",
                    "requiredErrorMessage": "This field is required",
                    "isLabelAfter": false,
                    "showAdditionalInputContent": false,
                    "additionalInputContent": "",
                    "isAdditonalInputAfter": true,
                    "draggable": false,
                    "showViewIcon": false,
                    "showDownloadIcon": false
                }
            ],
            "type": "",
            "name": "wrapper_379",
            "classname": "col-12 mb-3 drag-and-drop",
            "wrapperType": "BOX",
            "id": "wrapper_461",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
        }
    ],
    "type": "",
    "classname": ""
}

const workflow_excel_upload={
    "jsonId": 171,
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
        {
            "control": [
                {
                    "type": "dropdown",
                    "name": "file_type",
                    "id": "file_type",
                    "validationButtonType": "",
                    "classname": "d-none",
                    "required": false,
                    "apiEndPoints": [],
                    "options": [
                        {
                            "key": "project-workflow",
                            "value": "Project Workflow"
                        }
                    ],
                    "multiple": false,
                    "isClearable": false,
                    "isStatus": false,
                    "search": false,
                    "singleSelect": true,
                    "showLabel": "value",
                    "errorMessage": {
                        "noData": "This fields is required"
                    },
                    "value": {
                        "key": "project-workflow",
                        "value": "Project Workflow"
                    },
                    "imageUrl": "",
                    "disabled": false,
                    "wrapperClassName": "mb-3",
                    "connectedArrays": [],
                    "showBlankInPlaceholder": false,
                    "blankDataList": [],
                    "commonPathOfImage": "",
                    "__schema_id": "file_type",
                    "showInputLabel": false,
                    "labelContent": "Select File to Upload",
                    "labelClass": "form-label",
                    "isLabelAfter": false,
                    "showAdditionalInputContent": false,
                    "additionalInputContent": "",
                    "isAdditonalInputAfter": true,
                    "draggable": false
                },
                {
                    "type": "dropdown",
                    "name": "summation_value",
                    "id": "summation_value",
                    "validationButtonType": "upload",
                    "classname": "",
                    "required": true,
                    "apiEndPoints": [],
                    "options": [
                        {
                            "1d": 1,
                            "value": "summation"
                        }
                    ],
                    "multiple": false,
                    "isClearable": false,
                    "isStatus": false,
                    "search": false,
                    "singleSelect": true,
                    "showLabel": "value",
                    "errorMessage": {
                        "noData": "This fields is required"
                    },
                    "value": "",
                    "imageUrl": "",
                    "disabled": false,
                    "wrapperClassName": "mb-3",
                    "connectedArrays": [],
                    "showBlankInPlaceholder": false,
                    "blankDataList": [],
                    "commonPathOfImage": "",
                    "__schema_id": "summation_value",
                    "showInputLabel": true,
                    "labelContent": "Summation Value",
                    "labelClass": "form-label",
                    "isLabelAfter": false,
                    "showAdditionalInputContent": false,
                    "additionalInputContent": "",
                    "isAdditonalInputAfter": true,
                    "draggable": false
                },
                {
                    "type": "file",
                    "name": "file",
                    "id": "file",
                    "value": "upload",
                    "classname": "btn btn-file",
                    "fileNameShow": true,
                    "size": 10,
                    "url": "",
                    "functionName": "",
                    "wrapperClassName": "",
                    "fileCount": 1,
                    "sizeKB": "50000",
                    "allowMultiple": false,
                    "required": true,
                    "validationButtonType": "upload",
                    "formDataType": "blob",
                    "errorMessage": {
                        "invalidFormat": "Invalid File Format",
                        "invalidSize": "File Size exceeded",
                        "classname":"invalid-feedback d-block"
                    },
                    "fileTypes": [ "pdf","doc","docx","dot", "dotm", "dotx", "ab1", "txt", "xlxs", "xlsm", "xlsb", "xltx", "xltm", "xls", "xlsx", "xlt", "xml", "xla", "xlam", "xlw", "xlr", "jpeg", "pneg", "png", "fasta", "fas", "fa", "fna", "ffn", "faa", "mpfa", "frn" ],
                    "listClass": "",
                    "mainClass": "",
                    "btnDivClass": "",
                    "showInputLabel": true,
                    "labelContent": "Drag and drop files here or browse files  ",
                    "labelClass": "form-label",
                    "requiredErrorMessage": "This field is required",
                    "isLabelAfter": false,
                    "showAdditionalInputContent": false,
                    "additionalInputContent": "",
                    "isAdditonalInputAfter": true,
                    "draggable": false,
                    "showViewIcon": false,
                    "showDownloadIcon": false
                }
            ],
            "type": "",
            "name": "wrapper_379",
            "classname": "col-12 mb-3 drag-and-drop",
            "wrapperType": "BOX",
            "id": "wrapper_461",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
        }
    ],
    "type": "",
    "classname": ""
}


export const DNA_SEQUENCING = [
    projectInit,
    labConsumbales,
    cloneSelection,
    summary,
    dnaPreparationSequencing,
    primer_file_upload,
    dna_sequence_gel_image,
    cdr_sequence_file_upload,
    excel_upload,
    workflow_excel_upload
]
