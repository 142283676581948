export const studyDynamicJson = [
  //add study details
  {
    jsonId: 107,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [
          {
            type: 'text',
            name: 'study_title',
            id: 'studyTitle',
            value: '',
            placeholder: 'Enter Title',
            multiline: false,
            classname: 'form-control ',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            validationButtonType: 'details_submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: 'mb-3',
            requiredErrorMessage: 'This field is required',
            __schema_id: '',
            showInputLabel: true,
            labelContent: 'Study Title',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
          {
            type: 'text',
            name: 'study_desc',
            id: 'studyDesc',
            value: '',
            placeholder: '',
            multiline: true,
            classname: 'form-control ',
            regularExpression: '',
            disabled: false,
            required: false,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            validationButtonType: 'details_submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: 'mb-3',
            requiredErrorMessage: 'This field is required',
            __schema_id: '',
            showInputLabel: true,
            labelContent: 'Study Description',
            labelClass: 'form-label',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_230',
        classname: 'col-12',
        wrapperType: 'BOX',
        id: 'wrapper_892',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
        addBtnPostion: null,
      },
    ],
    type: '',
    classname: '',
    showClass: true,
  },
  {
    jsonId: 108,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        head: '',
        name: 'main',
        id: 'main',
        wrapperType: 'REGULAR',
        fields: [
          {
            control: [],
            type: '',
            name: 'wrapper_275',
            classname: 'row',
            wrapperType: 'BOX',
            id: 'wrapper_548',
            fields: [
              {
                control: [
                  {
                    type: 'text',
                    name: 'study_name',
                    id: 'study_name',
                    value: '',
                    placeholder: '',
                    multiline: false,
                    classname: 'form-control ',
                    regularExpression: '',
                    disabled: false,
                    required: false,
                    errorMessage: { noData: 'This field is required', regexFail: '' },
                    validationButtonType: '',
                    rows: 0,
                    columns: 0,
                    onDemandError: false,
                    customValidation: [],
                    multiError: false,
                    applicationError: '',
                    wrapperClassName: '',
                    requiredErrorMessage: 'This field is required',
                    showInputLabel: true,
                    labelContent: 'Study Name',
                    labelClass: 'form-label',
                    isLabelAfter: false,
                    showAdditionalInputContent: false,
                    additionalInputContent: '',
                    isAdditonalInputAfter: true,
                    draggable: false,
                  },
                ],
                type: '',
                name: 'wrapper_541',
                classname: 'col-6 mb-3',
                wrapperType: 'BOX',
                id: 'wrapper_205',
                fields: [],
                head: '',
                tabHeadClass: '',
              },
              {
                control: [
                  {
                    type: 'text',
                    name: 'study_code',
                    id: 'study_code',
                    value: '',
                    placeholder: '',
                    multiline: false,
                    classname: 'form-control ',
                    regularExpression: '',
                    disabled: false,
                    required: false,
                    errorMessage: { noData: 'This field is required', regexFail: '' },
                    validationButtonType: '',
                    rows: 0,
                    columns: 0,
                    onDemandError: false,
                    customValidation: [],
                    multiError: false,
                    applicationError: '',
                    wrapperClassName: '',
                    requiredErrorMessage: 'This field is required',
                    showInputLabel: true,
                    labelContent: 'Study ID',
                    labelClass: 'form-label',
                    isLabelAfter: false,
                    showAdditionalInputContent: false,
                    additionalInputContent: '',
                    isAdditonalInputAfter: true,
                    draggable: false,
                  },
                ],
                type: '',
                name: 'wrapper_541',
                classname: 'col-6 mb-3',
                wrapperType: 'BOX',
                id: 'wrapper_205',
                fields: [],
                head: '',
                tabHeadClass: '',
              },

              {
                control: [
                  {
                    type: 'dropdown',
                    name: 'created_by',
                    id: 'created_by',
                    validationButtonType: 'submit',
                    classname: '',
                    required: false,
                    apiEndPoints: [
                      {
                        type: 'init',
                        method: 'get',
                        url: 'user_management/users_list',
                      },
                    ],
                    options: [],
                    multiple: false,
                    isClearable: false,
                    isStatus: false,
                    search: true,
                    singleSelect: true,
                    showLabel: 'value',
                    errorMessage: { noData: 'This field is required' },
                    value: '',
                    imageUrl: '',
                    disabled: false,
                    wrapperClassName: '',
                    connectedArrays: [],
                    showBlankInPlaceholder: false,
                    blankDataList: ["",undefined,null],
                    showInputLabel: true,
                    labelContent: 'Added By',
                    labelClass: 'form-label ',
                    isLabelAfter: false,
                    showAdditionalInputContent: false,
                    additionalInputContent: '',
                    isAdditonalInputAfter: true,
                    draggable: false,
                  },
                ],
                type: '',
                name: 'wrapper_995',
                classname: 'col-6 mb-3',
                wrapperType: 'BOX',
                id: 'wrapper_192',
                fields: [],
                head: '',
                tabHeadClass: '',
                draggable: false,
              },
            ],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        type: '',
        classname: '',
      },
    ],
    type: '',
    classname: '',
  },
];