
const projectInit = {
    "id": "main",
    "head": "",
    "name": "main",
    "type": "",
    "fields": [
        {
            "id": "wrapper_790",
            "head": "",
            "name": "wrapper_576",
            "type": "",
            "fields": [
                {
                    "id": "wrapper_808",
                    "head": "",
                    "name": "wrapper_50",
                    "type": "",
                    "fields": [],
                    "control": [
                        {
                            "id": "project_title",
                            "name": "project_title",
                            "__schema_id": "project_title",
                            "rows": 0,
                            "type": "text",
                            "value": "",
                            "columns": 0,
                            "disabled": false,
                            "required": true,
                            "classname": "form-control ",
                            "draggable": false,
                            "multiline": false,
                            "labelClass": "form-label mandatory",
                            "multiError": false,
                            "placeholder": "",
                            "errorMessage": {
                                "noData": "This field is required",
                                "regexFail": ""
                            },
                            "isLabelAfter": false,
                            "labelContent": "Project Title",
                            "onDemandError": false,
                            "showInputLabel": true,
                            "applicationError": "",
                            "customValidation": [],
                            "wrapperClassName": "",
                            "regularExpression": "",
                            "requiredErrorMessage": "This field is required",
                            "validationButtonType": "initial-submit",
                            "isAdditonalInputAfter": true,
                            "additionalInputContent": "",
                            "showAdditionalInputContent": false
                        }
                    ],
                    "classname": "col-12 mb-3",
                    "draggable": false,
                    "wrapperType": "BOX",
                    "tabHeadClass": ""
                },
                {
                    "id": "wrapper_654",
                    "head": "",
                    "name": "wrapper_290",
                    "type": "",
                    "fields": [],
                    "control": [
                        {
                            "id": "project_init_by",
                            "name": "project_init_by",
                            "__schema_id": "project_init_by",
                            "type": "dropdown",
                            "value": "",
                            "search": true,
                            "disabled": false,
                            "imageUrl": "",
                            "isStatus": false,
                            "multiple": false,
                            "required": true,
                            "classname": "",
                            "draggable": false,
                            "showLabel": "value",
                            "labelClass": "form-label mandatory",
                            "isClearable": false,
                            "apiEndPoints": [
                                {
                                    "url": "user_management/users_list",
                                    "type": "init",
                                    "method": "get"
                                }
                            ],
                            "errorMessage": {
                                "noData": "This field is required"
                            },
                            "isLabelAfter": false,
                            "labelContent": "Initiated By",
                            "singleSelect": true,
                            "showInputLabel": true,
                            "wrapperClassName": "",
                            "validationButtonType": "initial-submit",
                            "isAdditonalInputAfter": true,
                            "additionalInputContent": "",
                            "showAdditionalInputContent": false
                        }
                    ],
                    "classname": "col-12 mb-3",
                    "draggable": false,
                    "wrapperType": "BOX",
                    "tabHeadClass": ""
                },
                {
                    "id": "wrapper_778",
                    "head": "",
                    "name": "wrapper_209",
                    "type": "",
                    "fields": [],
                    "control": [
                        {
                            "id": "project_start_date",
                            "name": "project_start_date",
                            "__schema_id": "project_start_date",
                            "type": "datepicker_v2",
                            "range": false,
                            "timer": false,
                            "value": "",
                            "locale": "",
                            "disabled": false,
                            "multiple": false,
                            "required": true,
                            "classname": "",
                            "draggable": false,
                            "dateFormat": "dd/mm/yyyy",
                            "hourFormat": 24,
                            "labelClass": "form-label mandatory",
                            "multiAmount": 3,
                            "placeholder": "DD-MM-YYYY",
                            "dayClassName": "dd",
                            "errorMessage": {
                                "noData": "This field is required",
                                "regexFail": "Date is not valid!!"
                            },
                            "isHostParent": false,
                            "isLabelAfter": false,
                            "labelContent": "Start Date",
                            "dateSeperator": "",
                            "keyboardInput": false,
                            "arrowClassName": "arrow",
                            "fordisabledEnd": "",
                            "inputClassName": "form-control date-input",
                            "monthClassName": "mtn",
                            "showInputLabel": true,
                            "todayClassName": "now",
                            "endDateReadOnly": false,
                            "inputDateFormat": false,
                            "fordisabledStart": "",
                            "wrapperClassName": "",
                            "startDateReadOnly": false,
                            "startDatePlaceHolder": "MM/DD/YYYY",
                            "validationButtonType": "initial-submit",
                            "isAdditonalInputAfter": true,
                            "selectedDateClassName": "selectedDate",
                            "additionalInputContent": "",
                            "showAdditionalInputContent": false
                        }
                    ],
                    "classname": "col-6 mb-3",
                    "draggable": false,
                    "wrapperType": "BOX",
                    "tabHeadClass": ""
                },
                {
                    "id": "wrapper_92",
                    "head": "",
                    "name": "wrapper_643",
                    "type": "",
                    "fields": [],
                    "control": [
                        {
                            "id": "project_end_date",
                            "name": "project_end_date",
                            "__schema_id": "project_end_date",
                            "type": "datepicker_v2",
                            "range": false,
                            "timer": false,
                            "value": "",
                            "locale": "",
                            "disabled": false,
                            "multiple": false,
                            "required": false,
                            "classname": "",
                            "draggable": false,
                            "dateFormat": "dd/mm/yyyy",
                            "hourFormat": 24,
                            "labelClass": "form-label",
                            "multiAmount": 3,
                            "placeholder": "DD-MM-YYYY",
                            "dayClassName": "dd",
                            "errorMessage": {
                                "noData": "This field is required",
                                "regexFail": "Date is not valid!!"
                            },
                            "isHostParent": false,
                            "isLabelAfter": false,
                            "labelContent": "End Date",
                            "dateSeperator": "",
                            "keyboardInput": false,
                            "arrowClassName": "arrow",
                            "fordisabledEnd": "",
                            "inputClassName": "form-control date-input",
                            "monthClassName": "mtn",
                            "showInputLabel": true,
                            "todayClassName": "now",
                            "endDateReadOnly": false,
                            "inputDateFormat": false,
                            "fordisabledStart": "",
                            "wrapperClassName": "",
                            "startDateReadOnly": false,
                            "startDatePlaceHolder": "MM/DD/YYYY",
                            "validationButtonType": "",
                            "isAdditonalInputAfter": true,
                            "selectedDateClassName": "selectedDate",
                            "additionalInputContent": "",
                            "showAdditionalInputContent": false
                        }
                    ],
                    "classname": "col-6 mb-3",
                    "draggable": false,
                    "wrapperType": "BOX",
                    "tabHeadClass": ""
                },
                {
                    "control": [
                        {
                            "type": "text",
                            "name": "status",
                            "id": "status",
                            "value": "",
                            "placeholder": "",
                            "multiline": false,
                            "classname": "form-control ",
                            "regularExpression": "",
                            "disabled": true,
                            "required": false,
                            "errorMessage": {
                                "noData": "This fields is required",
                                "regexFail": ""
                            },
                            "validationButtonType": "",
                            "rows": 0,
                            "columns": 0,
                            "onDemandError": false,
                            "customValidation": [],
                            "multiError": false,
                            "applicationError": "",
                            "wrapperClassName": "",
                            "requiredErrorMessage": "This fields is required",
                            "__schema_id": "status",
                            "showInputLabel": false,
                            "labelContent": "text_977",
                            "labelClass": "form-label",
                            "isLabelAfter": false,
                            "showAdditionalInputContent": false,
                            "additionalInputContent": "",
                            "isAdditonalInputAfter": true,
                            "draggable": false
                        },
                        {
                            "type": "text",
                            "name": "experiment_type",
                            "id": "experiment_type",
                            "value": "",
                            "placeholder": "",
                            "multiline": false,
                            "classname": "form-control ",
                            "regularExpression": "",
                            "disabled": true,
                            "required": false,
                            "errorMessage": {
                                "noData": "This fields is required",
                                "regexFail": ""
                            },
                            "validationButtonType": "",
                            "rows": 0,
                            "columns": 0,
                            "onDemandError": false,
                            "customValidation": [],
                            "multiError": false,
                            "applicationError": "",
                            "wrapperClassName": "",
                            "requiredErrorMessage": "This fields is required",
                            "__schema_id": "experiment_type",
                            "showInputLabel": false,
                            "labelContent": "text_581",
                            "labelClass": "form-label",
                            "isLabelAfter": false,
                            "showAdditionalInputContent": false,
                            "additionalInputContent": "",
                            "isAdditonalInputAfter": true,
                            "draggable": false
                        }
                    ],
                    "type": "",
                    "name": "wrapper_237",
                    "classname": "col-12 d-none",
                    "wrapperType": "BOX",
                    "id": "wrapper_929",
                    "fields": [],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false
                }
            ],
            "control": [],
            "classname": "row",
            "draggable": false,
            "wrapperType": "BOX",
            "tabHeadClass": ""
        }
    ],
    "jsonId": 137,
    "control": [],
    "classname": "form-container",
    "wrapperType": "REGULAR",
    "payload_schema": {
        "projectInitDetails": {
            "title": "project_title",
            "init_user": "project_init_by",
            "experiment_type": "experiment_type",
            "start_date": "project_start_date",
            "end_date": "project_end_date",
            "status": "status"
        }
    },
    "validation_type": "initial-submit",
}

const lab_consumables = {
    "jsonId": 140,
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
        {
            "control": [
                // {
                //     "type": "label",
                //     "name": "<h2 class=\"add-heading heading-without-icon\"><span>Reagents & Labwares</span></h2>",
                //     "classname": "w-100",
                //     "for": "",
                //     "required": false,
                //     "wrapperClassName": "",
                //     "id": "label_221",
                //     "draggable": false
                // }
            ],
            "type": "",
            "name": "wrapper_217",
            "classname": "project-table",
            "wrapperType": "BOX",
            "id": "wrapper_7",
            "fields": [
                {
                    "control": [],
                    "type": "",
                    "name": "lab",
                    "classname": "table reagents-table-p2",
                    "wrapperType": "TABLE",
                    "id": "lab_consumables",
                    "fields": [],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false,
                    "hideSingleItemDelete": false,
                    "tableFields": {
                        "showHead": true,
                        "isVertical": false,
                        "isIteratable": true,
                        "addButton": {
                            "classname": "add-expand",
                            "name": "",
                            "value": "<div class='custom-tooltip'><p>Add Row</p></div>"
                        },
                        "removeButton": {
                            "classname": "add-minus ",
                            "name": "",
                            "value": "<div class='custom-tooltip'><p>Remove Row </p></div>"
                        },
                        "tableHead": {
                            "heading": [
                                {
                                    "value": "Select from  Inventory",
                                    "classname": ""
                                },
                                {
                                    "value": "Name",
                                    "classname": ""
                                },
                                {
                                    "value": "Vendor",
                                    "classname": ""
                                },
                                {
                                    "value": "Vol/Capacity (ml)",
                                    "classname": ""
                                },
                                {
                                    "value": "Units  available",
                                    "classname": ""
                                },
                                {
                                    "value": "Units  taken",
                                    "classname": ""
                                },
                                {
                                    "value": "Storage Location",
                                    "classname": ""
                                },
                                {
                                    "value": "Action",
                                    "classname": ""
                                },
                                {
                                    "value": "",
                                    "classname": ""
                                }
                            ],
                            "classname": ""
                        },
                        "initRecordCount": 1,
                        "showRecordCount": false,
                        "recordCounterConfig": {
                            "classname": "",
                            "adjValue": "",
                            "isAdjValueAfter": false
                        },
                        "tableRow": {
                            "name": "",
                            "classname": "trclass",
                            "tableCols": [
                                {
                                    "fields": {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "select_inventory",
                                                "id": "select_inventory",
                                                "validationButtonType": "",
                                                "classname": "",
                                                "required": false,
                                                "apiEndPoints": [
                                                    {
                                                        "type": "change",
                                                        "method": "post",
                                                        "url": "inventory_item",
                                                        "Mapto": [
                                                            {
                                                                "id": "main.wrapper_7.lab_consumables.${i}.box_90.inv_item",
                                                                "urlParameters": "",
                                                                "value": "",
                                                                "payload": {
                                                                    "perma": "plasticwares",
                                                                    "user_input": ""
                                                                },
                                                                "type": null
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        "type": "init",
                                                        "method": "post",
                                                        "url": "inventory_item/item-type",
                                                        "Mapto": [],
                                                        "payload": {
                                                            "perma": [
                                                                "assay-kits",
                                                                "cell-culture-media-antibiotics-agar-plates",
                                                                "gels-buffers-lab-reagents",
                                                                "plasticwares",
                                                                "glasswares",
                                                                "cell-lines",
                                                                "bacterial-strains",
                                                                "plasmids"
                                                            ]
                                                        }
                                                    }
                                                ],
                                                "options": [],
                                                "multiple": false,
                                                "isClearable": false,
                                                "isStatus": false,
                                                "search": true,
                                                "singleSelect": true,
                                                "showLabel": "inv_item_type",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": "",
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "select_inventory",
                                                "showInputLabel": false,
                                                "labelContent": "dropdown_448",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "head": "",
                                        "name": "box_893",
                                        "id": "box_760",
                                        "wrapperType": "BOX",
                                        "fields": [],
                                        "type": "",
                                        "classname": ""
                                    },
                                    "classname": ""
                                },
                                {
                                    "fields": {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "inv_item",
                                                "id": "inv_item",
                                                "validationButtonType": "",
                                                "classname": "",
                                                "required": false,
                                                "apiEndPoints": [
                                                    {
                                                        "type": "change",
                                                        "method": "get",
                                                        "url": "inventory_item/${id}",
                                                        "Mapto": [
                                                            {
                                                                "id": "main.wrapper_7.lab_consumables.${i}.box_584.vendor",
                                                                "displayKey": "change",
                                                                "urlParameterValues": {
                                                                    "id": null,
                                                                    "patchProperty": "vendor_name"
                                                                }
                                                            },
                                                            {
                                                                "id": "main.wrapper_7.lab_consumables.${i}.box_908.capacity",
                                                                "displayKey": "change",
                                                                "urlParameterValues": {
                                                                    "id": null,
                                                                    "patchProperty": "item_volume"
                                                                }
                                                            },
                                                            {
                                                                "id": "main.wrapper_7.lab_consumables.${i}.box_293.units_available",
                                                                "displayKey": "change",
                                                                "urlParameterValues": {
                                                                    "id": null,
                                                                    "patchProperty": "item_quantity"
                                                                }
                                                            },
                                                            {
                                                                "id": "main.wrapper_7.lab_consumables.${i}.box_126.item_details",
                                                                "displayKey": "item_details",
                                                                "urlParameterValues": {
                                                                    "id": null
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ],
                                                "options": [],
                                                "multiple": false,
                                                "isClearable": false,
                                                "isStatus": false,
                                                "search": true,
                                                "singleSelect": true,
                                                "showLabel": "value",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": "",
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "inv_item",
                                                "showInputLabel": false,
                                                "labelContent": "dropdown_532",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "name": "box_289",
                                        "id": "box_90",
                                        "wrapperType": "BOX",
                                        "fields": [],
                                        "type": "",
                                        "classname": "",
                                        "addButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "removeButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "wrapperClassName": "",
                                        "draggable": false
                                    },
                                    "classname": ""
                                },
                                {
                                    "fields": {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "vendor",
                                                "id": "vendor",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": true,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "vendor",
                                                "showInputLabel": false,
                                                "labelContent": "text_305",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "name": "box_924",
                                        "id": "box_584",
                                        "wrapperType": "BOX",
                                        "fields": [],
                                        "type": "",
                                        "classname": "",
                                        "addButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "removeButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "wrapperClassName": ""
                                    },
                                    "classname": ""
                                },
                                {
                                    "fields": {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "capacity",
                                                "id": "capacity",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": true,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": true,
                                                "customValidation": [
                                                    {
                                                        "regularExpression": "/^\\d+$/",
                                                        "errorMessage": "Please enter a valid number"
                                                    }
                                                ],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "capacity",
                                                "showInputLabel": false,
                                                "labelContent": "text_166",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "name": "box_12",
                                        "id": "box_908",
                                        "wrapperType": "BOX",
                                        "fields": [],
                                        "type": "",
                                        "classname": "",
                                        "addButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "removeButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "wrapperClassName": ""
                                    },
                                    "classname": ""
                                },
                                {
                                    "fields": {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "units_available",
                                                "id": "units_available",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": true,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": true,
                                                "customValidation": [
                                                    {
                                                        "regularExpression": "/^\\d+$/",
                                                        "errorMessage": "Please enter a valid number"
                                                    }
                                                ],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "units_available",
                                                "showInputLabel": false,
                                                "labelContent": "text_159",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "name": "box_213",
                                        "id": "box_293",
                                        "wrapperType": "BOX",
                                        "fields": [],
                                        "type": "",
                                        "classname": "",
                                        "addButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "removeButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "wrapperClassName": ""
                                    },
                                    "classname": ""
                                },
                                {
                                    "fields": {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "units_taken",
                                                "id": "units_taken",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": true,
                                                "customValidation": [
                                                    {
                                                        "regularExpression": "/^\\d+$/",
                                                        "errorMessage": "Please enter a valid number"
                                                    }
                                                ],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "units_taken",
                                                "showInputLabel": false,
                                                "labelContent": "text_885",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "name": "box_214",
                                        "id": "box_798",
                                        "wrapperType": "BOX",
                                        "fields": [],
                                        "type": "",
                                        "classname": "action-wrap ",
                                        "addButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "removeButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "wrapperClassName": ""
                                    },
                                    "classname": ""
                                },
                                {
                                    "fields": {
                                        "control": [
                                            {
                                                "type": "button",
                                                "id": "infoicon",
                                                "value": "View Location",
                                                "buttonType": "storage_info",
                                                "classname": "info-icon",
                                                "divClass": "",
                                                "controlEndPoints": [],
                                                "disabled": false,
                                                "validationEndPoints": [],
                                                "invalid": false,
                                                "name": "infoicon",
                                                "wrapperClassName": "text-nowrap",
                                                "draggable": false
                                            },
                                            {
                                                "type": "text",
                                                "name": "item_details",
                                                "id": "item_details",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control d-none",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "item_details",
                                                "showInputLabel": false,
                                                "labelContent": "text_995",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "name": "box_261",
                                        "id": "box_126",
                                        "wrapperType": "BOX",
                                        "fields": [],
                                        "type": "",
                                        "classname": " ",
                                        "addButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "removeButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "wrapperClassName": ""
                                    },
                                    "classname": ""
                                },
                                {
                                    "fields": {
                                        "control": [],
                                        "name": "box_564",
                                        "id": "box_24",
                                        "wrapperType": "BOX",
                                        "fields": [],
                                        "type": "",
                                        "classname": "",
                                        "addButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "removeButton": {
                                            "classname": "",
                                            "value": ""
                                        },
                                        "wrapperClassName": ""
                                    },
                                    "classname": ""
                                }
                            ]
                        }
                    }
                }
            ],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
        }
    ],
    "type": "",
    "classname": "",
    "showClass": true,
    "payload_schema": {
        "consumables": [
            {
                "inv_category": "select_inventory",
                "inv_item": "inv_item",
                "vendor": "vendor.$d{}",
                "vol_capacity": "capacity.$t{number}",
                "no_unit_available": "units_available.$t{number}",
                "no_unit_taken": "units_taken.$d{}",
                "storage": "item_details.{{assigned_storage}}"
            }
        ],
        "inventory__": {
            "itm_qty": [
                {
                    "inv_item_id": "inv_item.{{id}}",
                    "qntity_taken": "units_taken.$d{}"
                }
            ]
        }
    }
}

const project_summary = {
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
        {
            "control": [
                {
                    "type": "label",
                    "name": "<h2 class=\"add-heading heading-without-icon\"><span>Summary</span></h2>",
                    "classname": "w-100",
                    "for": "",
                    "required": false,
                    "wrapperClassName": "",
                    "id": "label_98",
                    "draggable": false
                }
            ],
            "type": "",
            "name": "wrapper_847",
            "classname": "col-12",
            "wrapperType": "BOX",
            "id": "wrapper_850",
            "fields": [
                {
                    "control": [],
                    "type": "",
                    "name": "wrapper_650",
                    "classname": "row mb-3",
                    "wrapperType": "BOX",
                    "id": "wrapper_860",
                    "fields": [
                        {
                            "control": [
                                {
                                    "type": "text",
                                    "name": "proj_type",
                                    "id": "proj_type",
                                    "value": "",
                                    "placeholder": "",
                                    "multiline": false,
                                    "classname": "form-control ",
                                    "regularExpression": "",
                                    "disabled": true,
                                    "required": false,
                                    "errorMessage": {
                                        "noData": "This fields is required",
                                        "regexFail": ""
                                    },
                                    "validationButtonType": "",
                                    "rows": 0,
                                    "columns": 0,
                                    "onDemandError": false,
                                    "customValidation": [],
                                    "multiError": false,
                                    "applicationError": "",
                                    "wrapperClassName": "",
                                    "requiredErrorMessage": "This fields is required",
                                    "__schema_id": "proj_type",
                                    "showInputLabel": true,
                                    "labelContent": "Project Type",
                                    "labelClass": "form-label",
                                    "isLabelAfter": false,
                                    "showAdditionalInputContent": false,
                                    "additionalInputContent": "",
                                    "isAdditonalInputAfter": true,
                                    "draggable": false
                                }
                            ],
                            "type": "",
                            "name": "wrapper_440",
                            "classname": "col-4",
                            "wrapperType": "BOX",
                            "id": "wrapper_855",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": ""
                        },
                        {
                            "control": [
                                {
                                    "type": "text",
                                    "name": "title",
                                    "id": "title",
                                    "value": "",
                                    "placeholder": "",
                                    "multiline": false,
                                    "classname": "form-control ",
                                    "regularExpression": "",
                                    "disabled": true,
                                    "required": false,
                                    "errorMessage": {
                                        "noData": "This fields is required",
                                        "regexFail": ""
                                    },
                                    "validationButtonType": "",
                                    "rows": 0,
                                    "columns": 0,
                                    "onDemandError": false,
                                    "customValidation": [],
                                    "multiError": false,
                                    "applicationError": "",
                                    "wrapperClassName": "",
                                    "requiredErrorMessage": "This fields is required",
                                    "__schema_id": "title",
                                    "showInputLabel": true,
                                    "labelContent": "Project Title",
                                    "labelClass": "form-label",
                                    "isLabelAfter": false,
                                    "showAdditionalInputContent": false,
                                    "additionalInputContent": "",
                                    "isAdditonalInputAfter": true,
                                    "draggable": false
                                }
                            ],
                            "type": "",
                            "name": "wrapper_457",
                            "classname": "col-4",
                            "wrapperType": "BOX",
                            "id": "wrapper_393",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": ""
                        }
                    ],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false
                },
                {
                    "control": [],
                    "type": "",
                    "name": "wrapper_195",
                    "classname": "row mb-3",
                    "wrapperType": "BOX",
                    "id": "wrapper_646",
                    "fields": [
                        {
                            "control": [
                                {
                                    "type": "dropdown",
                                    "name": "objective",
                                    "id": "objective",
                                    "validationButtonType": "",
                                    "classname": "",
                                    "required": false,
                                    "apiEndPoints": [],
                                    "options": [
                                        "Naive clone selection",
                                        "Epitope Steering",
                                        "Common light chain incorporation",
                                        "Affinity enhancement",
                                        "Optimization of biophysical properties"
                                    ],
                                    "multiple": false,
                                    "isClearable": false,
                                    "isStatus": false,
                                    "search": false,
                                    "singleSelect": true,
                                    "showLabel": "",
                                    "errorMessage": {
                                        "noData": "This fields is required"
                                    },
                                    "value": "",
                                    "imageUrl": "",
                                    "disabled": false,
                                    "wrapperClassName": "",
                                    "connectedArrays": [],
                                    "showBlankInPlaceholder": false,
                                    "blankDataList": [],
                                    "commonPathOfImage": "",
                                    "__schema_id": "objective",
                                    "showInputLabel": true,
                                    "labelContent": "Objective",
                                    "labelClass": "form-label",
                                    "isLabelAfter": false,
                                    "showAdditionalInputContent": false,
                                    "additionalInputContent": "",
                                    "isAdditonalInputAfter": true,
                                    "draggable": false
                                },
                                {
                                    "type": "text",
                                    "name": "proj_code",
                                    "id": "proj_code",
                                    "value": "SIM1001",
                                    "placeholder": "",
                                    "multiline": false,
                                    "classname": "form-control d-none",
                                    "regularExpression": "",
                                    "disabled": true,
                                    "required": false,
                                    "errorMessage": {
                                        "noData": "This fields is required",
                                        "regexFail": ""
                                    },
                                    "validationButtonType": "",
                                    "rows": 0,
                                    "columns": 0,
                                    "onDemandError": false,
                                    "customValidation": [],
                                    "multiError": false,
                                    "applicationError": "",
                                    "wrapperClassName": "",
                                    "requiredErrorMessage": "This fields is required",
                                    "__schema_id": "proj_code",
                                    "showInputLabel": false,
                                    "labelContent": "Objective",
                                    "labelClass": "form-label",
                                    "isLabelAfter": false,
                                    "showAdditionalInputContent": false,
                                    "additionalInputContent": "",
                                    "isAdditonalInputAfter": true,
                                    "draggable": false
                                }
                            ],
                            "type": "",
                            "name": "wrapper_413",
                            "classname": "col-4",
                            "wrapperType": "BOX",
                            "id": "wrapper_270",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": "",
                            "draggable": false
                        },
                        {
                            "control": [],
                            "type": "",
                            "name": "wrapper_834",
                            "classname": "col-8",
                            "wrapperType": "BOX",
                            "id": "wrapper_697",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": ""
                        }
                    ],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false
                },
                {
                    "control": [
                        {
                            "type": "text",
                            "name": "comments",
                            "id": "comments",
                            "value": "",
                            "placeholder": "Enter your comments if any",
                            "multiline": true,
                            "classname": "form-control ",
                            "regularExpression": "",
                            "disabled": false,
                            "required": false,
                            "errorMessage": {
                                "noData": "This fields is required",
                                "regexFail": ""
                            },
                            "validationButtonType": "",
                            "rows": 0,
                            "columns": 0,
                            "onDemandError": false,
                            "customValidation": [],
                            "multiError": false,
                            "applicationError": "",
                            "wrapperClassName": "",
                            "requiredErrorMessage": "This fields is required",
                            "__schema_id": "comments",
                            "showInputLabel": true,
                            "labelContent": "Experiment Notes/Comments",
                            "labelClass": "form-label",
                            "isLabelAfter": false,
                            "showAdditionalInputContent": false,
                            "additionalInputContent": "",
                            "isAdditonalInputAfter": true,
                            "draggable": false
                        }
                    ],
                    "type": "",
                    "name": "wrapper_269",
                    "classname": "col-12",
                    "wrapperType": "BOX",
                    "id": "wrapper_521",
                    "fields": [],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false
                }
            ],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
        }
    ],
    "type": "",
    "jsonId": 141,
    "classname": "",
    "showClass": true,
    "payload_schema": {
        "project_summary": {
            "title": "title",
            "proj_type": "proj_type",
            "objective": "objective",
            "comments": "comments"
        }
    },
    "validation_type": "submit"
}

const antigen_info = {
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "jsonId": 138,
    "fields": [
        {
            "control": [],
            "type": "",
            "name": "wrapper_761",
            "classname": "col-12",
            "wrapperType": "BOX",
            "id": "wrapper_793",
            "fields": [
                {
                    "control": [],
                    "type": "",
                    "name": "wrapper_576",
                    "classname": "row",
                    "wrapperType": "BOX",
                    "id": "wrapper_39",
                    "fields": [
                        {
                            "control": [
                                {
                                    "type": "radio",
                                    "name": "antigen_source",
                                    "label": {
                                        "type": "label",
                                        "name": "",
                                        "classname": "",
                                        "for": "",
                                        "required": false,
                                        "wrapperClassName": ""
                                    },
                                    "id": "antigen_source",
                                    "value": {
                                        "id": 2,
                                        "value": "Commercial"
                                    },
                                    "required": false,
                                    "classname": "form-check-wrap",
                                    "errorMessage": {
                                        "noData": ""
                                    },
                                    "options": [
                                        {
                                            "key": {
                                                "id": 1,
                                                "value": "In House"
                                            },
                                            "label": "In House"
                                        },
                                        {
                                            "key": {
                                                "id": 2,
                                                "value": "Commercial"
                                            },
                                            "label": "Commercial"
                                        }
                                    ],
                                    "readonly": false,
                                    "validationButtonType": "",
                                    "disabled": false,
                                    "wrapperClassName": "form-check form-check-inline no-border",
                                    "__schema_id": "antigen_source",
                                    "showInputLabel": true,
                                    "labelContent": "Source of Antigen ",
                                    "labelClass": "form-label d-block ps-0",
                                    "isLabelAfter": false,
                                    "showAdditionalInputContent": false,
                                    "additionalInputContent": "",
                                    "isAdditonalInputAfter": true,
                                    "draggable": false,
                                    "controlPoints": [
                                        {
                                            "type": "change",
                                            "value": {
                                                "id": 1,
                                                "value": "In House"
                                            },
                                            "Mapto": [
                                                {
                                                    "id": "main.wrapper_793.wrapper_564.wrapper_306",
                                                    "type": "showField"
                                                },
                                                {
                                                    "id": "main.wrapper_793.wrapper_564.wrapper_898",
                                                    "type": "hideField"
                                                },
                                                {
                                                    "id": "main.wrapper_793.wrapper_927",
                                                    "type": "hideField"
                                                },
                                            ]
                                        },
                                        {
                                            "type": "change",
                                            "value": {
                                                "id": 2,
                                                "value": "Commercial"
                                            },
                                            "Mapto": [
                                                {
                                                    "id": "main.wrapper_793.wrapper_564.wrapper_898",
                                                    "type": "showField"
                                                },
                                                {
                                                    "id": "main.wrapper_793.wrapper_927",
                                                    "type": "showField"
                                                },
                                                {
                                                    "id": "main.wrapper_793.wrapper_564.wrapper_306",
                                                    "type": "hideField"
                                                }
                                                
                                            ]
                                        }
                                    ]
                                }
                            ],
                            "type": "",
                            "name": "wrapper_436",
                            "classname": "col-3",
                            "wrapperType": "BOX",
                            "id": "wrapper_828",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": ""
                        },
                        {
                            "control": [],
                            "type": "",
                            "name": "wrapper_386",
                            "classname": "col-6",
                            "wrapperType": "BOX",
                            "id": "wrapper_768",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": ""
                        },
                        {
                            "control": [
                                {
                                    "type": "button",
                                    "id": "button_780",
                                    "value": "Attachments",
                                    "buttonType": "antigen_file_upload",
                                    "classname": "btn btn-primary mt-2 attachment-btn",
                                    "divClass": "",
                                    "controlEndPoints": [],
                                    "disabled": false,
                                    "validationEndPoints": [],
                                    "invalid": false,
                                    "name": "button_664",
                                    "wrapperClassName": "",
                                    "draggable": false
                                }
                            ],
                            "type": "",
                            "name": "wrapper_282",
                            "classname": "col-3 d-flex justify-content-end",
                            "wrapperType": "BOX",
                            "id": "wrapper_861",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": ""
                        }
                    ],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false
                },
                {
                    "control": [],
                    "type": "",
                    "name": "wrapper_806",
                    "classname": "row mt-3 mb-3",
                    "wrapperType": "BOX",
                    "id": "wrapper_564",
                    "fields": [
                        {
                            "control": [
                                {
                                    "type": "text",
                                    "name": "antigen",
                                    "id": "antigen",
                                    "value": "",
                                    "placeholder": "",
                                    "multiline": false,
                                    "classname": "form-control ",
                                    "regularExpression": "",
                                    "disabled": false,
                                    "required": false,
                                    "errorMessage": {
                                        "noData": "This fields is required",
                                        "regexFail": ""
                                    },
                                    "validationButtonType": "",
                                    "rows": 0,
                                    "columns": 0,
                                    "onDemandError": false,
                                    "customValidation": [],
                                    "multiError": false,
                                    "applicationError": "",
                                    "wrapperClassName": "",
                                    "requiredErrorMessage": "This fields is required",
                                    "__schema_id": "antigen",
                                    "showInputLabel": true,
                                    "labelContent": "Antigen",
                                    "labelClass": "form-label",
                                    "isLabelAfter": false,
                                    "showAdditionalInputContent": false,
                                    "additionalInputContent": "",
                                    "isAdditonalInputAfter": true,
                                    "draggable": false
                                }
                            ],
                            "type": "",
                            "name": "wrapper_571",
                            "classname": "col-3",
                            "wrapperType": "BOX",
                            "id": "wrapper_833",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": ""
                        },
                        {
                            "control": [
                                {
                                    "type": "text",
                                    "name": "inhouse_antigen_id",
                                    "id": "inhouse_antigen_id",
                                    "value": "",
                                    "placeholder": "",
                                    "multiline": false,
                                    "classname": "form-control ",
                                    "regularExpression": "",
                                    "disabled": false,
                                    "required": false,
                                    "errorMessage": {
                                        "noData": "This fields is required",
                                        "regexFail": ""
                                    },
                                    "validationButtonType": "",
                                    "rows": 0,
                                    "columns": 0,
                                    "onDemandError": false,
                                    "customValidation": [],
                                    "multiError": false,
                                    "applicationError": "",
                                    "wrapperClassName": "",
                                    "requiredErrorMessage": "This fields is required",
                                    "__schema_id": "inhouse_antigen_id",
                                    "showInputLabel": true,
                                    "labelContent": "In House Antigen ID",
                                    "labelClass": "form-label",
                                    "isLabelAfter": false,
                                    "showAdditionalInputContent": false,
                                    "additionalInputContent": "",
                                    "isAdditonalInputAfter": true,
                                    "draggable": false
                                }
                            ],
                            "type": "",
                            "name": "wrapper_565",
                            "classname": "col-3",
                            "wrapperType": "BOX",
                            "id": "wrapper_306",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": "",
                            "disableValue": {
                                "inhouse_antigen_id": "",
                            }
                        },
                        {
                            "control": [
                                {
                                    "type": "text",
                                    "name": "antigen_id",
                                    "id": "antigen_id",
                                    "value": "",
                                    "placeholder": "",
                                    "multiline": false,
                                    "classname": "form-control ",
                                    "regularExpression": "",
                                    "disabled": false,
                                    "required": false,
                                    "errorMessage": {
                                        "noData": "This fields is required",
                                        "regexFail": ""
                                    },
                                    "validationButtonType": "",
                                    "rows": 0,
                                    "columns": 0,
                                    "onDemandError": false,
                                    "customValidation": [],
                                    "multiError": false,
                                    "applicationError": "",
                                    "wrapperClassName": "",
                                    "requiredErrorMessage": "This fields is required",
                                    "__schema_id": "antigen_id",
                                    "showInputLabel": true,
                                    "labelContent": "Antigen ID",
                                    "labelClass": "form-label",
                                    "isLabelAfter": false,
                                    "showAdditionalInputContent": false,
                                    "additionalInputContent": "",
                                    "isAdditonalInputAfter": true,
                                    "draggable": false
                                }
                            ],
                            "type": "",
                            "name": "wrapper_117",
                            "classname": "col-3",
                            "wrapperType": "BOX",
                            "id": "wrapper_898",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": "",
                            "draggable": false,
                            "disableValue": {
                                "antigen_id": "",
                            }
                        },
                        {
                            "control": [
                                {
                                    "type": "text",
                                    "name": "gene_name",
                                    "id": "gene_name",
                                    "value": "",
                                    "placeholder": "",
                                    "multiline": false,
                                    "classname": "form-control ",
                                    "regularExpression": "",
                                    "disabled": false,
                                    "required": false,
                                    "errorMessage": {
                                        "noData": "This fields is required",
                                        "regexFail": ""
                                    },
                                    "validationButtonType": "",
                                    "rows": 0,
                                    "columns": 0,
                                    "onDemandError": false,
                                    "customValidation": [],
                                    "multiError": false,
                                    "applicationError": "",
                                    "wrapperClassName": "",
                                    "requiredErrorMessage": "This fields is required",
                                    "__schema_id": "gene_name",
                                    "showInputLabel": true,
                                    "labelContent": "Gene Name",
                                    "labelClass": "form-label",
                                    "isLabelAfter": false,
                                    "showAdditionalInputContent": false,
                                    "additionalInputContent": "",
                                    "isAdditonalInputAfter": true,
                                    "draggable": false
                                }
                            ],
                            "type": "",
                            "name": "wrapper_844",
                            "classname": "col-3",
                            "wrapperType": "BOX",
                            "id": "wrapper_341",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": "",
                            "draggable": false
                        },
                        {
                            "control": [
                                {
                                    "type": "text",
                                    "name": "conc",
                                    "id": "conc",
                                    "value": "",
                                    "placeholder": "",
                                    "multiline": false,
                                    "classname": "form-control ",
                                    "regularExpression": "",
                                    "disabled": false,
                                    "required": false,
                                    "errorMessage": {
                                        "noData": "This fields is required",
                                        "regexFail": ""
                                    },
                                    "validationButtonType": "",
                                    "rows": 0,
                                    "columns": 0,
                                    "onDemandError": false,
                                    "customValidation": [],
                                    "multiError": false,
                                    "applicationError": "",
                                    "wrapperClassName": "",
                                    "requiredErrorMessage": "This fields is required",
                                    "__schema_id": "conc",
                                    "showInputLabel": true,
                                    "labelContent": "Conc (mg/ml)",
                                    "labelClass": "form-label",
                                    "isLabelAfter": false,
                                    "showAdditionalInputContent": false,
                                    "additionalInputContent": "",
                                    "isAdditonalInputAfter": true,
                                    "draggable": false
                                }
                            ],
                            "type": "",
                            "name": "wrapper_482",
                            "classname": "col-3",
                            "wrapperType": "BOX",
                            "id": "wrapper_303",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": "",
                            "draggable": false
                        }
                    ],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false
                },
                {
                    "control": [],
                    "type": "",
                    "name": "wrapper_321",
                    "classname": "row mb-3",
                    "wrapperType": "BOX",
                    "id": "wrapper_481",
                    "fields": [
                        {
                            "control": [
                                {
                                    "type": "text",
                                    "name": "tags",
                                    "id": "tags",
                                    "value": "",
                                    "placeholder": "",
                                    "multiline": false,
                                    "classname": "form-control ",
                                    "regularExpression": "",
                                    "disabled": false,
                                    "required": false,
                                    "errorMessage": {
                                        "noData": "This fields is required",
                                        "regexFail": ""
                                    },
                                    "validationButtonType": "",
                                    "rows": 0,
                                    "columns": 0,
                                    "onDemandError": false,
                                    "customValidation": [],
                                    "multiError": false,
                                    "applicationError": "",
                                    "wrapperClassName": "",
                                    "requiredErrorMessage": "This fields is required",
                                    "__schema_id": "tags",
                                    "showInputLabel": true,
                                    "labelContent": "Tags",
                                    "labelClass": "form-label",
                                    "isLabelAfter": false,
                                    "showAdditionalInputContent": false,
                                    "additionalInputContent": "",
                                    "isAdditonalInputAfter": true,
                                    "draggable": false
                                }
                            ],
                            "type": "",
                            "name": "wrapper_416",
                            "classname": "col-3",
                            "wrapperType": "BOX",
                            "id": "wrapper_322",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": ""
                        },
                        {
                            "control": [
                                {
                                    "type": "text",
                                    "name": "aa_boundary",
                                    "id": "aa_boundary",
                                    "value": "",
                                    "placeholder": "",
                                    "multiline": false,
                                    "classname": "form-control ",
                                    "regularExpression": "",
                                    "disabled": false,
                                    "required": false,
                                    "errorMessage": {
                                        "noData": "This fields is required",
                                        "regexFail": ""
                                    },
                                    "validationButtonType": "",
                                    "rows": 0,
                                    "columns": 0,
                                    "onDemandError": false,
                                    "customValidation": [],
                                    "multiError": false,
                                    "applicationError": "",
                                    "wrapperClassName": "",
                                    "requiredErrorMessage": "This fields is required",
                                    "__schema_id": "aa_boundary",
                                    "showInputLabel": true,
                                    "labelContent": "Antigen AA Boundary",
                                    "labelClass": "form-label",
                                    "isLabelAfter": false,
                                    "showAdditionalInputContent": false,
                                    "additionalInputContent": "",
                                    "isAdditonalInputAfter": true,
                                    "draggable": false
                                }
                            ],
                            "type": "",
                            "name": "wrapper_816",
                            "classname": "col-3",
                            "wrapperType": "BOX",
                            "id": "wrapper_312",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": ""
                        },
                        {
                            "control": [
                                {
                                    "type": "text",
                                    "name": "exp_system",
                                    "id": "exp_system",
                                    "value": "",
                                    "placeholder": "",
                                    "multiline": false,
                                    "classname": "form-control ",
                                    "regularExpression": "",
                                    "disabled": false,
                                    "required": false,
                                    "errorMessage": {
                                        "noData": "This fields is required",
                                        "regexFail": ""
                                    },
                                    "validationButtonType": "",
                                    "rows": 0,
                                    "columns": 0,
                                    "onDemandError": false,
                                    "customValidation": [],
                                    "multiError": false,
                                    "applicationError": "",
                                    "wrapperClassName": "",
                                    "requiredErrorMessage": "This fields is required",
                                    "__schema_id": "exp_system",
                                    "showInputLabel": true,
                                    "labelContent": "Expression System",
                                    "labelClass": "form-label",
                                    "isLabelAfter": false,
                                    "showAdditionalInputContent": false,
                                    "additionalInputContent": "",
                                    "isAdditonalInputAfter": true,
                                    "draggable": false
                                }
                            ],
                            "type": "",
                            "name": "wrapper_414",
                            "classname": "col-3",
                            "wrapperType": "BOX",
                            "id": "wrapper_992",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": ""
                        },
                        {
                            "control": [
                                {
                                    "type": "text",
                                    "name": "purity",
                                    "id": "purity",
                                    "value": "",
                                    "placeholder": "",
                                    "multiline": false,
                                    "classname": "form-control ",
                                    "regularExpression": "",
                                    "disabled": false,
                                    "required": false,
                                    "errorMessage": {
                                        "noData": "This fields is required",
                                        "regexFail": ""
                                    },
                                    "validationButtonType": "",
                                    "rows": 0,
                                    "columns": 0,
                                    "onDemandError": false,
                                    "customValidation": [],
                                    "multiError": false,
                                    "applicationError": "",
                                    "wrapperClassName": "",
                                    "requiredErrorMessage": "This fields is required",
                                    "__schema_id": "purity",
                                    "showInputLabel": true,
                                    "labelContent": "Purity",
                                    "labelClass": "form-label",
                                    "isLabelAfter": false,
                                    "showAdditionalInputContent": false,
                                    "additionalInputContent": "",
                                    "isAdditonalInputAfter": true,
                                    "draggable": false
                                }
                            ],
                            "type": "",
                            "name": "wrapper_815",
                            "classname": "col-3",
                            "wrapperType": "BOX",
                            "id": "wrapper_697",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": ""
                        }
                    ],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false
                },
                {
                    "control": [],
                    "type": "",
                    "name": "wrapper_801",
                    "classname": "row mb-3",
                    "wrapperType": "BOX",
                    "id": "wrapper_927",
                    "fields": [
                        {
                            "control": [
                                {
                                    "type": "text",
                                    "name": "vendor",
                                    "id": "vendor",
                                    "value": "",
                                    "placeholder": "",
                                    "multiline": false,
                                    "classname": "form-control ",
                                    "regularExpression": "",
                                    "disabled": false,
                                    "required": false,
                                    "errorMessage": {
                                        "noData": "This fields is required",
                                        "regexFail": ""
                                    },
                                    "validationButtonType": "",
                                    "rows": 0,
                                    "columns": 0,
                                    "onDemandError": false,
                                    "customValidation": [],
                                    "multiError": false,
                                    "applicationError": "",
                                    "wrapperClassName": "",
                                    "requiredErrorMessage": "This fields is required",
                                    "__schema_id": "vendor",
                                    "showInputLabel": true,
                                    "labelContent": "Vendor Details",
                                    "labelClass": "form-label",
                                    "isLabelAfter": false,
                                    "showAdditionalInputContent": false,
                                    "additionalInputContent": "",
                                    "isAdditonalInputAfter": true,
                                    "draggable": false
                                },
                                {
                                    "type": "text",
                                    "name": "amino_acid_file_ids",
                                    "id": "amino_acid_file_ids",
                                    "value": [],
                                    "placeholder": "",
                                    "multiline": false,
                                    "classname": "form-control d-none",
                                    "regularExpression": "",
                                    "disabled": false,
                                    "required": false,
                                    "errorMessage": {
                                        "noData": "This fields is required",
                                        "regexFail": ""
                                    },
                                    "validationButtonType": "",
                                    "rows": 0,
                                    "columns": 0,
                                    "onDemandError": false,
                                    "customValidation": [],
                                    "multiError": false,
                                    "applicationError": "",
                                    "wrapperClassName": "",
                                    "requiredErrorMessage": "This fields is required",
                                    "__schema_id": "amino_acid_file_ids",
                                    "showInputLabel": false,
                                    "labelContent": "Vendor Details",
                                    "labelClass": "form-label",
                                    "isLabelAfter": false,
                                    "showAdditionalInputContent": false,
                                    "additionalInputContent": "",
                                    "isAdditonalInputAfter": true,
                                    "draggable": false
                                },
                                {
                                    "type": "text",
                                    "name": "nucleotide_file_ids",
                                    "id": "nucleotide_file_ids",
                                    "value": [],
                                    "placeholder": "",
                                    "multiline": false,
                                    "classname": "form-control d-none",
                                    "regularExpression": "",
                                    "disabled": false,
                                    "required": false,
                                    "errorMessage": {
                                        "noData": "This fields is required",
                                        "regexFail": ""
                                    },
                                    "validationButtonType": "",
                                    "rows": 0,
                                    "columns": 0,
                                    "onDemandError": false,
                                    "customValidation": [],
                                    "multiError": false,
                                    "applicationError": "",
                                    "wrapperClassName": "",
                                    "requiredErrorMessage": "This fields is required",
                                    "__schema_id": "nucleotide_file_ids",
                                    "showInputLabel": false,
                                    "labelContent": "Vendor Details",
                                    "labelClass": "form-label",
                                    "isLabelAfter": false,
                                    "showAdditionalInputContent": false,
                                    "additionalInputContent": "",
                                    "isAdditonalInputAfter": true,
                                    "draggable": false
                                }
                            ],
                            "type": "",
                            "name": "wrapper_979",
                            "classname": "col-3",
                            "wrapperType": "BOX",
                            "id": "wrapper_967",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": ""
                        },
                        {
                            "control": [
                                {
                                    "type": "text",
                                    "name": "catalog_num",
                                    "id": "catalog_num",
                                    "value": "",
                                    "placeholder": "",
                                    "multiline": false,
                                    "classname": "form-control ",
                                    "regularExpression": "",
                                    "disabled": false,
                                    "required": false,
                                    "errorMessage": {
                                        "noData": "This fields is required",
                                        "regexFail": ""
                                    },
                                    "validationButtonType": "",
                                    "rows": 0,
                                    "columns": 0,
                                    "onDemandError": false,
                                    "customValidation": [],
                                    "multiError": false,
                                    "applicationError": "",
                                    "wrapperClassName": "",
                                    "requiredErrorMessage": "This fields is required",
                                    "__schema_id": "catalog_num",
                                    "showInputLabel": true,
                                    "labelContent": "Catalog Number",
                                    "labelClass": "form-label",
                                    "isLabelAfter": false,
                                    "showAdditionalInputContent": false,
                                    "additionalInputContent": "",
                                    "isAdditonalInputAfter": true,
                                    "draggable": false
                                }
                            ],
                            "type": "",
                            "name": "wrapper_954",
                            "classname": "col-3",
                            "wrapperType": "BOX",
                            "id": "wrapper_312",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": ""
                        },
                        {
                            "control": [
                                {
                                    "type": "text",
                                    "name": "vendor_url",
                                    "id": "vendor_url",
                                    "value": "",
                                    "placeholder": "",
                                    "multiline": false,
                                    "classname": "form-control ",
                                    "regularExpression": "",
                                    "disabled": false,
                                    "required": false,
                                    "errorMessage": {
                                        "noData": "This fields is required",
                                        "regexFail": ""
                                    },
                                    "validationButtonType": "",
                                    "rows": 0,
                                    "columns": 0,
                                    "onDemandError": false,
                                    "customValidation": [],
                                    "multiError": false,
                                    "applicationError": "",
                                    "wrapperClassName": "",
                                    "requiredErrorMessage": "This fields is required",
                                    "__schema_id": "vendor_url",
                                    "showInputLabel": true,
                                    "labelContent": "Vendor Url",
                                    "labelClass": "form-label",
                                    "isLabelAfter": false,
                                    "showAdditionalInputContent": false,
                                    "additionalInputContent": "",
                                    "isAdditonalInputAfter": true,
                                    "draggable": false
                                }
                            ],
                            "type": "",
                            "name": "wrapper_893",
                            "classname": "col-3",
                            "wrapperType": "BOX",
                            "id": "wrapper_777",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": ""
                        },
                        {
                            "control": [],
                            "type": "",
                            "name": "wrapper_757",
                            "classname": "col-3",
                            "wrapperType": "BOX",
                            "id": "wrapper_134",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": ""
                        }
                    ],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false,
                    "disableValue": {
                        "vendor": "",
                        "catalog_num": "",
                        "vendor_url": ""
                    }

                },
                {
                    "control": [
                        {
                            "type": "text",
                            "name": "comments",
                            "id": "comments",
                            "value": "",
                            "placeholder": "",
                            "multiline": true,
                            "classname": "form-control ",
                            "regularExpression": "",
                            "disabled": false,
                            "required": false,
                            "errorMessage": {
                                "noData": "This fields is required",
                                "regexFail": ""
                            },
                            "validationButtonType": "",
                            "rows": 0,
                            "columns": 0,
                            "onDemandError": false,
                            "customValidation": [],
                            "multiError": false,
                            "applicationError": "",
                            "wrapperClassName": "",
                            "requiredErrorMessage": "This fields is required",
                            "__schema_id": "comments",
                            "showInputLabel": true,
                            "labelContent": "Experiment Notes/Comments",
                            "labelClass": "form-label",
                            "isLabelAfter": false,
                            "showAdditionalInputContent": false,
                            "additionalInputContent": "",
                            "isAdditonalInputAfter": true,
                            "draggable": false
                        }
                    ],
                    "type": "",
                    "name": "wrapper_985",
                    "classname": "col-12 mt-3",
                    "wrapperType": "BOX",
                    "id": "wrapper_358",
                    "fields": [],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false
                }
            ],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
        }
    ],
    "type": "",
    "classname": "",
    "showClass": true,
    "payload_schema": {
        "antigen_info": {
            "comments": "comments.$d{}",
            "vendor_url": "vendor_url.$d{}",
            "catalog_num": "catalog_num.$d{}",
            "vendor": "vendor.$d{}",
            "purity": "purity.$d{}",
            "exp_system": "exp_system.$d{}",
            "aa_boundary": "aa_boundary.$d{}",
            "tags": "tags.$d{}",
            "conc": "conc.$d{}",
            "gene_name": "gene_name.$d{}",
            "antigen_id": "antigen_id.$d{}",
            "inhouse_antigen_id": "inhouse_antigen_id.$d{}",
            "antigen": "antigen.$d{}",
            "nucleotide_file_ids": "nucleotide_file_ids",
            "amino_acid_file_ids": "amino_acid_file_ids",
            "antigen_source": "antigen_source"
        }
    }
}

const library_info = {
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "jsonId": 139,
    "wrapperType": "REGULAR",
    "fields": [
        {
            "control": [],
            "type": "",
            "name": "wrapper_373",
            "classname": "row",
            "wrapperType": "BOX",
            "id": "wrapper_473",
            "fields": [
                {
                    "control": [
                        {
                            "type": "text",
                            "name": "antibody_lib",
                            "id": "antibody_lib",
                            "value": "",
                            "placeholder": "",
                            "multiline": false,
                            "classname": "form-control ",
                            "regularExpression": "",
                            "disabled": false,
                            "required": false,
                            "errorMessage": {
                                "noData": "This fields is required",
                                "regexFail": ""
                            },
                            "validationButtonType": "",
                            "rows": 0,
                            "columns": 0,
                            "onDemandError": false,
                            "customValidation": [],
                            "multiError": false,
                            "applicationError": "",
                            "wrapperClassName": "",
                            "requiredErrorMessage": "This fields is required",
                            "__schema_id": "antibody_lib",
                            "showInputLabel": true,
                            "labelContent": "Select Antibody Library",
                            "labelClass": "form-label",
                            "isLabelAfter": false,
                            "showAdditionalInputContent": false,
                            "additionalInputContent": "",
                            "isAdditonalInputAfter": true,
                            "draggable": false
                        }
                    ],
                    "type": "",
                    "name": "wrapper_152",
                    "classname": "col-3",
                    "wrapperType": "BOX",
                    "id": "wrapper_778",
                    "fields": [],
                    "head": "",
                    "tabHeadClass": ""
                },
                {
                    "control": [
                        {
                            "type": "text",
                            "name": "libaray_id",
                            "id": "libaray_id",
                            "value": "",
                            "placeholder": "",
                            "multiline": false,
                            "classname": "form-control ",
                            "regularExpression": "",
                            "disabled": false,
                            "required": false,
                            "errorMessage": {
                                "noData": "This fields is required",
                                "regexFail": ""
                            },
                            "validationButtonType": "",
                            "rows": 0,
                            "columns": 0,
                            "onDemandError": false,
                            "customValidation": [],
                            "multiError": false,
                            "applicationError": "",
                            "wrapperClassName": "",
                            "requiredErrorMessage": "This fields is required",
                            "__schema_id": "libaray_id",
                            "showInputLabel": true,
                            "labelContent": "In House Library ID",
                            "labelClass": "form-label",
                            "isLabelAfter": false,
                            "showAdditionalInputContent": false,
                            "additionalInputContent": "",
                            "isAdditonalInputAfter": true,
                            "draggable": false
                        }
                    ],
                    "type": "",
                    "name": "wrapper_742",
                    "classname": "col-3",
                    "wrapperType": "BOX",
                    "id": "wrapper_180",
                    "fields": [],
                    "head": "",
                    "tabHeadClass": ""
                },
                {
                    "control": [
                        {
                            "type": "text",
                            "name": "libaray_diversity",
                            "id": "libaray_diversity",
                            "value": "",
                            "placeholder": "",
                            "multiline": false,
                            "classname": "form-control ",
                            "regularExpression": "",
                            "disabled": false,
                            "required": false,
                            "errorMessage": {
                                "noData": "This fields is required",
                                "regexFail": ""
                            },
                            "validationButtonType": "",
                            "rows": 0,
                            "columns": 0,
                            "onDemandError": false,
                            "customValidation": [],
                            "multiError": false,
                            "applicationError": "",
                            "wrapperClassName": "",
                            "requiredErrorMessage": "This fields is required",
                            "__schema_id": "libaray_diversity",
                            "showInputLabel": true,
                            "labelContent": "Library Diversity",
                            "labelClass": "form-label",
                            "isLabelAfter": false,
                            "showAdditionalInputContent": false,
                            "additionalInputContent": "",
                            "isAdditonalInputAfter": true,
                            "draggable": false
                        }
                    ],
                    "type": "",
                    "name": "wrapper_268",
                    "classname": "col-3",
                    "wrapperType": "BOX",
                    "id": "wrapper_124",
                    "fields": [],
                    "head": "",
                    "tabHeadClass": ""
                },
                {
                    "control": [
                        {
                            "type": "text",
                            "name": "libaray_conc",
                            "id": "libaray_conc",
                            "value": "",
                            "placeholder": "",
                            "multiline": false,
                            "classname": "form-control ",
                            "regularExpression": "",
                            "disabled": false,
                            "required": false,
                            "errorMessage": {
                                "noData": "This fields is required",
                                "regexFail": ""
                            },
                            "validationButtonType": "",
                            "rows": 0,
                            "columns": 0,
                            "onDemandError": false,
                            "customValidation": [],
                            "multiError": false,
                            "applicationError": "",
                            "wrapperClassName": "",
                            "requiredErrorMessage": "This fields is required",
                            "__schema_id": "libaray_conc",
                            "showInputLabel": true,
                            "labelContent": "Library Conc (phage/ml)",
                            "labelClass": "form-label",
                            "isLabelAfter": false,
                            "showAdditionalInputContent": false,
                            "additionalInputContent": "",
                            "isAdditonalInputAfter": true,
                            "draggable": false
                        }
                    ],
                    "type": "",
                    "name": "wrapper_70",
                    "classname": "col-3",
                    "wrapperType": "BOX",
                    "id": "wrapper_429",
                    "fields": [],
                    "head": "",
                    "tabHeadClass": ""
                }
            ],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
        }
    ],
    "type": "",
    "classname": "",
    "showClass": true,
    "payload_schema": {
        "library_info": {
            "libaray_conc": "libaray_conc.$d{}",
            "libaray_diversity": "libaray_diversity.$d{}",
            "libaray_id": "libaray_id.$d{}",
            "antibody_lib": "antibody_lib.$d{}"
        }
    }

}

const antigen_file_upload = {
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "jsonId": 142,
    "wrapperType": "REGULAR",
    "fields": [
        {
            "control": [
                {
                    "type": "dropdown",
                    "name": "file_type",
                    "id": "file_type",
                    "validationButtonType": "",
                    "classname": "",
                    "required": false,
                    "apiEndPoints": [],
                    "options": [
                        {
                            "key": "nucleotide_sequence",
                            "value": "Nucleotide Sequence"
                        },
                        {
                            "key": "amino_acid_sequence",
                            "value": "Amino Acid Sequence"
                        }
                    ],
                    "multiple": false,
                    "isClearable": false,
                    "isStatus": false,
                    "search": false,
                    "singleSelect": true,
                    "showLabel": "value",
                    "errorMessage": {
                        "noData": "This fields is required"
                    },
                    "value": "",
                    "imageUrl": "",
                    "disabled": false,
                    "wrapperClassName": "mb-3",
                    "connectedArrays": [],
                    "showBlankInPlaceholder": false,
                    "blankDataList": [],
                    "commonPathOfImage": "",
                    "__schema_id": "file_type",
                    "showInputLabel": true,
                    "labelContent": "Select File to Upload",
                    "labelClass": "form-label",
                    "isLabelAfter": false,
                    "showAdditionalInputContent": false,
                    "additionalInputContent": "",
                    "isAdditonalInputAfter": true,
                    "draggable": false
                },
                {
                    "type": "file",
                    "name": "file",
                    "id": "file",
                    "value": "upload",
                    "classname": "btn btn-file ",
                    "fileNameShow": false,
                    "size": 10,
                    "url": "",
                    "validationButtonType": "upload",
                    "wrapperClassName": "",
                    "fileCount": 1,
                    "sizeKB": "50000",
                    "allowMultiple": false,
                    "formDataType": "blob",
                    "fileTypes": [
                        "fasta",
                        "fas",
                        "fa",
                        "fna",
                        "ffn",
                        "faa",
                        "mpfa",
                        "frn",
                        "pdf",
                        "doc",
                        "docx",
                        "dot",
                        "dotm",
                        "dotx",
                        "ab1",
                        "txt",
                        "xlsx",
                        "xlsm",
                        "jpeg",
                        "png",
                        "jpg"
                    ],
                    "listClass": "",
                    "mainClass": "",
                    "btnDivClass": "",
                    "outsideFileContainer": false,
                    "errorMessage": {
                        "invalidFormat": "Invalid File Format",
                        "invalidSize": "File Size exceeded",
                        "classname": ""
                    },
                    "__schema_id": "file",
                    "showInputLabel": true,
                    "labelContent": "Upload Files",
                    "labelClass": "form-label",
                    "isLabelAfter": false,
                    "showAdditionalInputContent": false,
                    "additionalInputContent": "",
                    "isAdditonalInputAfter": true,
                    "draggable": false
                }
            ],
            "type": "",
            "name": "wrapper_433",
            "classname": "col-12 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_985",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
        }
    ],
    "type": "",
    "classname": "",
    "showClass": true
}

const workflow_phage_screening={
    "control": [],
    "jsonId": 147,
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
        {
            "control": [],
            "type": "",
            "name": "wrapper_156",
            "classname": "row",
            "wrapperType": "BOX",
            "id": "wrapper_880",
            "fields": [
                {
                    "control": [
                        {
                            "type": "dropdown",
                            "name": "selectProtocol",
                            "id": "selectProtocol",
                            "validationButtonType": "",
                            "classname": "mb-3",
                            "required": false,
                            "apiEndPoints": [
                                {
                                    "type": "init",
                                    "method": "get",
                                    "url": "protocol/protocol-list",
                                    "Mapto": []
                                },
                                {
                                    "type": "change",
                                    "method": "get",
                                    "url": "protocol/${id}",
                                    "Mapto": [
                                        {
                                            "id": "main.wrapper_880.wrapper_90.protocol_file_id",
                                            "displayKey": "change",
                                            "urlParameterValues": {
                                                "id": null,
                                                "patchProperty": "protocol_file_id"
                                            },
                                            "type": null
                                        }
                                    ]
                                }
                            ],
                            "options": [],
                            "multiple": false,
                            "isClearable": false,
                            "isStatus": false,
                            "search": true,
                            "singleSelect": true,
                            "showLabel": "value",
                            "errorMessage": {
                                "noData": "This fields is required"
                            },
                            "value": "",
                            "imageUrl": "",
                            "disabled": false,
                            "wrapperClassName": "",
                            "connectedArrays": [],
                            "showBlankInPlaceholder": false,
                            "blankDataList": [],
                            "commonPathOfImage": "",
                            "__schema_id": "selectProtocol",
                            "showInputLabel": true,
                            "labelContent": "Select Protocol",
                            "labelClass": "form-label",
                            "isLabelAfter": false,
                            "showAdditionalInputContent": false,
                            "additionalInputContent": "",
                            "isAdditonalInputAfter": true,
                            "draggable": false
                        },
                        {
                            "type": "button",
                            "id": "button_878",
                            "value": "<div class=\"custom-tooltip\"><p>View </p></div>",
                            "buttonType": "view-protocol",
                            "classname": "view icon-align ms-3",
                            "divClass": "",
                            "controlEndPoints": [],
                            "disabled": false,
                            "validationEndPoints": [],
                            "invalid": false,
                            "name": "button_935",
                            "wrapperClassName": "actions position-relative ",
                            "draggable": false
                        },
                        {
                            "type": "text",
                            "name": "protocol_file_id",
                            "id": "protocol_file_id",
                            "value": "",
                            "placeholder": "",
                            "multiline": false,
                            "classname": "form-control d-none",
                            "regularExpression": "",
                            "disabled": false,
                            "required": false,
                            "errorMessage": {
                                "noData": "This fields is required",
                                "regexFail": ""
                            },
                            "validationButtonType": "",
                            "rows": 0,
                            "columns": 0,
                            "onDemandError": false,
                            "customValidation": [],
                            "multiError": false,
                            "applicationError": "",
                            "wrapperClassName": "",
                            "requiredErrorMessage": "This fields is required",
                            "__schema_id": "protocol_file_id",
                            "showInputLabel": false,
                            "labelContent": "text_398",
                            "labelClass": "form-label",
                            "isLabelAfter": false,
                            "showAdditionalInputContent": false,
                            "additionalInputContent": "",
                            "isAdditonalInputAfter": true,
                            "draggable": false
                        }
                    ],
                    "type": "",
                    "name": "wrapper_652",
                    "classname": "col-3 mb-3 action-wrap",
                    "wrapperType": "BOX",
                    "id": "wrapper_90",
                    "fields": [],
                    "head": "",
                    "tabHeadClass": ""
                }
            ],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
        },
        {
            "control": [],
            "type": "",
            "name": "wrapper_547",
            "classname": "accordion accordion-custom",
            "wrapperType": "ACCORDION",
            "id": "wrapper_120",
            "fields": [
                {
                    "control": [],
                    "type": "",
                    "name": "selection_details",
                    "classname": "col-12",
                    "wrapperType": "REGULAR",
                    "id": "wrapper_456",
                    "fields": [
                        {
                            "control": [],
                            "type": "",
                            "name": "wrapper_808",
                            "classname": "row",
                            "wrapperType": "BOX",
                            "id": "wrapper_917",
                            "fields": [
                                {
                                    "control": [
                                        {
                                            "type": "text",
                                            "name": "library",
                                            "id": "Library_839",
                                            "value": "",
                                            "placeholder": "",
                                            "multiline": false,
                                            "classname": "form-control ",
                                            "regularExpression": "",
                                            "disabled": true,
                                            "required": false,
                                            "errorMessage": {
                                                "noData": "This fields is required",
                                                "regexFail": ""
                                            },
                                            "validationButtonType": "",
                                            "rows": 0,
                                            "columns": 0,
                                            "onDemandError": false,
                                            "customValidation": [],
                                            "multiError": false,
                                            "applicationError": "",
                                            "wrapperClassName": "",
                                            "requiredErrorMessage": "This fields is required",
                                            "__schema_id": "library",
                                            "showInputLabel": true,
                                            "labelContent": "Library",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_598",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_165",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [
                                        {
                                            "type": "text",
                                            "name": "library_diversity",
                                            "id": "library_diversity",
                                            "value": "",
                                            "placeholder": "",
                                            "multiline": false,
                                            "classname": "form-control ",
                                            "regularExpression": "",
                                            "disabled": true,
                                            "required": false,
                                            "errorMessage": {
                                                "noData": "This fields is required",
                                                "regexFail": ""
                                            },
                                            "validationButtonType": "",
                                            "rows": 0,
                                            "columns": 0,
                                            "onDemandError": false,
                                            "customValidation": [],
                                            "multiError": false,
                                            "applicationError": "",
                                            "wrapperClassName": "",
                                            "requiredErrorMessage": "This fields is required",
                                            "__schema_id": "library_diversity",
                                            "showInputLabel": true,
                                            "labelContent": "Library Diversity",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_81",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_736",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [
                                        {
                                            "type": "text",
                                            "name": "library_conc",
                                            "id": "Library_Conc_217",
                                            "value": "",
                                            "placeholder": "",
                                            "multiline": false,
                                            "classname": "form-control ",
                                            "regularExpression": "",
                                            "disabled": true,
                                            "required": false,
                                            "errorMessage": {
                                                "noData": "This fields is required",
                                                "regexFail": ""
                                            },
                                            "validationButtonType": "",
                                            "rows": 0,
                                            "columns": 0,
                                            "onDemandError": false,
                                            "customValidation": [],
                                            "multiError": false,
                                            "applicationError": "",
                                            "wrapperClassName": "",
                                            "requiredErrorMessage": "This fields is required",
                                            "__schema_id": "library_conc",
                                            "showInputLabel": true,
                                            "labelContent": "Library Conc (phage/ml)",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_698",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_510",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [
                                        {
                                            "type": "dropdown",
                                            "name": "plate_type",
                                            "id": "Plate_Type_240",
                                            "validationButtonType": "",
                                            "classname": "",
                                            "required": false,
                                            "apiEndPoints": [],
                                            "options": [],
                                            "multiple": false,
                                            "isClearable": false,
                                            "isStatus": false,
                                            "search": false,
                                            "singleSelect": true,
                                            "showLabel": "",
                                            "errorMessage": {
                                                "noData": "This fields is required"
                                            },
                                            "value": "8*12 Well Plate",
                                            "imageUrl": "",
                                            "disabled": false,
                                            "wrapperClassName": "",
                                            "connectedArrays": [],
                                            "showBlankInPlaceholder": false,
                                            "blankDataList": [],
                                            "commonPathOfImage": "",
                                            "__schema_id": "plate_type",
                                            "showInputLabel": true,
                                            "labelContent": "Plate Type",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_495",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_533",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [],
                                    "type": "",
                                    "name": "wrapper_573",
                                    "classname": "row mt-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_89",
                                    "fields": [
                                        {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "plate_id",
                                                    "id": "Plate_id",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": false,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": false,
                                                    "customValidation": [],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "plate_id",
                                                    "showInputLabel": true,
                                                    "labelContent": "Plate ID",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "type": "",
                                            "name": "wrapper_111",
                                            "classname": "col-3",
                                            "wrapperType": "BOX",
                                            "id": "wrapper_105",
                                            "fields": [],
                                            "head": "",
                                            "tabHeadClass": ""
                                        },
                                        {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "barcode_id",
                                                    "id": "Barcode_ID",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": false,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": false,
                                                    "customValidation": [],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "barcode_id",
                                                    "showInputLabel": true,
                                                    "labelContent": "Barcode ID",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "type": "",
                                            "name": "wrapper_807",
                                            "classname": "col-3",
                                            "wrapperType": "BOX",
                                            "id": "wrapper_702",
                                            "fields": [],
                                            "head": "",
                                            "tabHeadClass": ""
                                        },
                                        {
                                            "control": [],
                                            "type": "",
                                            "name": "wrapper_810",
                                            "classname": "col-3",
                                            "wrapperType": "BOX",
                                            "id": "wrapper_146",
                                            "fields": [],
                                            "head": "",
                                            "tabHeadClass": ""
                                        },
                                        {
                                            "control": [],
                                            "type": "",
                                            "name": "wrapper_984",
                                            "classname": "col-3",
                                            "wrapperType": "BOX",
                                            "id": "wrapper_761",
                                            "fields": [],
                                            "head": "",
                                            "tabHeadClass": ""
                                        }
                                    ],
                                    "head": "",
                                    "tabHeadClass": "",
                                    "draggable": false
                                }
                            ],
                            "head": "",
                            "tabHeadClass": "",
                            "draggable": false
                        },
                        {
                            "control": [],
                            "type": "",
                            "name": "wrapper_530",
                            "classname": "col-12",
                            "wrapperType": "BOX",
                            "id": "wrapper_97",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": "",
                            "draggable": false
                        }
                    ],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false,
                    "isAccordion": true,
                    "accordionConfig": {
                        "headerClassName": "",
                        "headerBtnClassName": "",
                        "header": "<span>Selection Details</span>",
                        "collapseDivClassName": "",
                        "bodyClassName": "",
                        "collapseOthers": true
                    }
                },
                {
                    "control": [],
                    "type": "",
                    "name": "pre_clearance_info",
                    "classname": "col-12",
                    "wrapperType": "REGULAR",
                    "id": "wrapper_844",
                    "fields": [
                        {
                            "control": [],
                            "type": "",
                            "name": "wrapper_355",
                            "classname": "row mb-3",
                            "wrapperType": "BOX",
                            "id": "wrapper_865",
                            "fields": [
                                {
                                    "control": [
                                        {
                                            "type": "text",
                                            "name": "non_specific_antigen",
                                            "id": "Non_Specific_Antigen",
                                            "value": "Blocking Peptide",
                                            "placeholder": "",
                                            "multiline": false,
                                            "classname": "form-control ",
                                            "regularExpression": "",
                                            "disabled": false,
                                            "required": false,
                                            "errorMessage": {
                                                "noData": "This fields is required",
                                                "regexFail": ""
                                            },
                                            "validationButtonType": "",
                                            "rows": 0,
                                            "columns": 0,
                                            "onDemandError": false,
                                            "customValidation": [],
                                            "multiError": false,
                                            "applicationError": "",
                                            "wrapperClassName": "",
                                            "requiredErrorMessage": "This fields is required",
                                            "__schema_id": "non_specific_antigen",
                                            "showInputLabel": true,
                                            "labelContent": "Non Specific Antigen",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_886",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_617",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [
                                        {
                                            "type": "dropdown",
                                            "name": "antigen_form",
                                            "id": "Antigen_Form",
                                            "validationButtonType": "",
                                            "classname": "",
                                            "required": false,
                                            "apiEndPoints": [],
                                            "options": [
                                                {
                                                    "id": 1,
                                                    "value": "Immobilised"
                                                },
                                                {
                                                    "id": 2,
                                                    "value": "Biotinylated"
                                                }
                                            ],
                                            "multiple": false,
                                            "isClearable": false,
                                            "isStatus": false,
                                            "search": false,
                                            "singleSelect": true,
                                            "showLabel": "value",
                                            "errorMessage": {
                                                "noData": "This fields is required"
                                            },
                                            "value": "",
                                            "imageUrl": "",
                                            "disabled": false,
                                            "wrapperClassName": "",
                                            "connectedArrays": [],
                                            "showBlankInPlaceholder": false,
                                            "blankDataList": [],
                                            "commonPathOfImage": "",
                                            "__schema_id": "pre_antigen_form",
                                            "showInputLabel": true,
                                            "labelContent": "Antigen Form",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_713",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_227",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [
                                        {
                                            "type": "text",
                                            "name": "volume_well",
                                            "id": "Volume_Well",
                                            "value": "",
                                            "placeholder": "",
                                            "multiline": false,
                                            "classname": "form-control ",
                                            "regularExpression": "",
                                            "disabled": false,
                                            "required": false,
                                            "errorMessage": {
                                                "noData": "This fields is required",
                                                "regexFail": ""
                                            },
                                            "validationButtonType": "",
                                            "rows": 0,
                                            "columns": 0,
                                            "onDemandError": false,
                                            "customValidation": [],
                                            "multiError": false,
                                            "applicationError": "",
                                            "wrapperClassName": "",
                                            "requiredErrorMessage": "This fields is required",
                                            "__schema_id": "pre_volume_well",
                                            "showInputLabel": true,
                                            "labelContent": "Volume/Well (ml)",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_624",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_620",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [
                                        {
                                            "type": "text",
                                            "name": "antigen_conc",
                                            "id": "Antigen_Conc",
                                            "value": "",
                                            "placeholder": "",
                                            "multiline": false,
                                            "classname": "form-control ",
                                            "regularExpression": "",
                                            "disabled": false,
                                            "required": false,
                                            "errorMessage": {
                                                "noData": "This fields is required",
                                                "regexFail": ""
                                            },
                                            "validationButtonType": "",
                                            "rows": 0,
                                            "columns": 0,
                                            "onDemandError": false,
                                            "customValidation": [],
                                            "multiError": false,
                                            "applicationError": "",
                                            "wrapperClassName": "",
                                            "requiredErrorMessage": "This fields is required",
                                            "__schema_id": "pre_antigen_conc",
                                            "showInputLabel": true,
                                            "labelContent": "Antigen Conc (g/ml)",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_809",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_911",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                }
                            ],
                            "head": "",
                            "tabHeadClass": "",
                            "draggable": false
                        },
                        {
                            "control": [],
                            "type": "",
                            "name": "wrapper_520",
                            "classname": "row",
                            "wrapperType": "BOX",
                            "id": "wrapper_366",
                            "fields": [
                                {
                                    "control": [
                                        {
                                            "type": "text",
                                            "name": "incubation_time",
                                            "id": "Incubation_Time",
                                            "value": "",
                                            "placeholder": "",
                                            "multiline": false,
                                            "classname": "form-control ",
                                            "regularExpression": "",
                                            "disabled": false,
                                            "required": false,
                                            "errorMessage": {
                                                "noData": "This fields is required",
                                                "regexFail": ""
                                            },
                                            "validationButtonType": "",
                                            "rows": 0,
                                            "columns": 0,
                                            "onDemandError": false,
                                            "customValidation": [],
                                            "multiError": false,
                                            "applicationError": "",
                                            "wrapperClassName": "",
                                            "requiredErrorMessage": "This fields is required",
                                            "__schema_id": "incubation_time",
                                            "showInputLabel": true,
                                            "labelContent": "Incubation Time (h)",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_807",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_938",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [
                                        {
                                            "type": "text",
                                            "name": "incubation_temperature",
                                            "id": "Incubation_Temperature",
                                            "value": "",
                                            "placeholder": "",
                                            "multiline": false,
                                            "classname": "form-control ",
                                            "regularExpression": "",
                                            "disabled": false,
                                            "required": false,
                                            "errorMessage": {
                                                "noData": "This fields is required",
                                                "regexFail": ""
                                            },
                                            "validationButtonType": "",
                                            "rows": 0,
                                            "columns": 0,
                                            "onDemandError": false,
                                            "customValidation": [],
                                            "multiError": false,
                                            "applicationError": "",
                                            "wrapperClassName": "",
                                            "requiredErrorMessage": "This fields is required",
                                            "__schema_id": "incubation_temperature",
                                            "showInputLabel": true,
                                            "labelContent": "Incubation Temperature (°C)",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_501",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_993",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [],
                                    "type": "",
                                    "name": "wrapper_328",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_998",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [],
                                    "type": "",
                                    "name": "wrapper_17",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_840",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                }
                            ],
                            "head": "",
                            "tabHeadClass": "",
                            "draggable": false
                        }
                    ],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false,
                    "isAccordion": true,
                    "accordionConfig": {
                        "headerClassName": "",
                        "headerBtnClassName": "",
                        "header": "<span>Pre Clearance Info</span>",
                        "collapseDivClassName": "",
                        "bodyClassName": "",
                        "collapseOthers": true
                    }
                },
                {
                    "control": [],
                    "type": "",
                    "name": "antigen_info",
                    "classname": "col-12",
                    "wrapperType": "REGULAR",
                    "id": "wrapper_243",
                    "fields": [
                        {
                            "control": [],
                            "type": "",
                            "name": "wrapper_195",
                            "classname": "row",
                            "wrapperType": "BOX",
                            "id": "wrapper_11",
                            "fields": [
                                {
                                    "control": [
                                        {
                                            "type": "text",
                                            "name": "antigen",
                                            "id": "Antigen",
                                            "value": "",
                                            "placeholder": "",
                                            "multiline": false,
                                            "classname": "form-control ",
                                            "regularExpression": "",
                                            "disabled": true,
                                            "required": false,
                                            "errorMessage": {
                                                "noData": "This fields is required",
                                                "regexFail": ""
                                            },
                                            "validationButtonType": "",
                                            "rows": 0,
                                            "columns": 0,
                                            "onDemandError": false,
                                            "customValidation": [],
                                            "multiError": false,
                                            "applicationError": "",
                                            "wrapperClassName": "",
                                            "requiredErrorMessage": "This fields is required",
                                            "__schema_id": "antigen",
                                            "showInputLabel": true,
                                            "labelContent": "Antigen",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_569",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_839",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [
                                        {
                                            "type": "dropdown",
                                            "name": "antigen_form",
                                            "id": "antigen-form",
                                            "validationButtonType": "",
                                            "classname": "",
                                            "required": false,
                                            "apiEndPoints": [],
                                            "options": [
                                                {
                                                    "id": 1,
                                                    "value": "Immobilised"
                                                },
                                                {
                                                    "id": 2,
                                                    "value": "Biotinylated"
                                                }
                                            ],
                                            "multiple": false,
                                            "isClearable": false,
                                            "isStatus": false,
                                            "search": false,
                                            "singleSelect": true,
                                            "showLabel": "value",
                                            "errorMessage": {
                                                "noData": "This fields is required"
                                            },
                                            "value": "",
                                            "imageUrl": "",
                                            "disabled": false,
                                            "wrapperClassName": "",
                                            "connectedArrays": [],
                                            "showBlankInPlaceholder": false,
                                            "blankDataList": [],
                                            "commonPathOfImage": "",
                                            "__schema_id": "antigen-form",
                                            "showInputLabel": true,
                                            "labelContent": "Antigen Form",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_633",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_610",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [
                                        {
                                            "type": "text",
                                            "name": "volume_well",
                                            "id": "Volume-Well",
                                            "value": "",
                                            "placeholder": "",
                                            "multiline": false,
                                            "classname": "form-control ",
                                            "regularExpression": "",
                                            "disabled": false,
                                            "required": false,
                                            "errorMessage": {
                                                "noData": "This fields is required",
                                                "regexFail": ""
                                            },
                                            "validationButtonType": "",
                                            "rows": 0,
                                            "columns": 0,
                                            "onDemandError": false,
                                            "customValidation": [],
                                            "multiError": false,
                                            "applicationError": "",
                                            "wrapperClassName": "",
                                            "requiredErrorMessage": "This fields is required",
                                            "__schema_id": "volume-well",
                                            "showInputLabel": true,
                                            "labelContent": "Volume/Well (ml)",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_150",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_128",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [
                                        {
                                            "type": "text",
                                            "name": "antigen_conc",
                                            "id": "Antigen-Conc",
                                            "value": "",
                                            "placeholder": "",
                                            "multiline": false,
                                            "classname": "form-control ",
                                            "regularExpression": "",
                                            "disabled": false,
                                            "required": false,
                                            "errorMessage": {
                                                "noData": "This fields is required",
                                                "regexFail": ""
                                            },
                                            "validationButtonType": "",
                                            "rows": 0,
                                            "columns": 0,
                                            "onDemandError": false,
                                            "customValidation": [],
                                            "multiError": false,
                                            "applicationError": "",
                                            "wrapperClassName": "",
                                            "requiredErrorMessage": "This fields is required",
                                            "__schema_id": "antigen-conc",
                                            "showInputLabel": true,
                                            "labelContent": "Antigen Conc (g/ml)",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_185",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_921",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [],
                                    "type": "",
                                    "name": "wrapper_117",
                                    "classname": "row mt-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_760",
                                    "fields": [
                                        {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "antigen_incubation_time",
                                                    "id": "Antigen_Incubation_Time",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": false,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": false,
                                                    "customValidation": [],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "antigen_incubation_time",
                                                    "showInputLabel": true,
                                                    "labelContent": "Incubation Time (h)",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "type": "",
                                            "name": "wrapper_28",
                                            "classname": "col-3",
                                            "wrapperType": "BOX",
                                            "id": "wrapper_266",
                                            "fields": [],
                                            "head": "",
                                            "tabHeadClass": ""
                                        },
                                        {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "antigen_incubation_temperature",
                                                    "id": "Antigen_Incubation_Temperature",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": false,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": false,
                                                    "customValidation": [],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "antigen_incubation_temperature",
                                                    "showInputLabel": true,
                                                    "labelContent": "Incubation Temperature (°C)",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "type": "",
                                            "name": "wrapper_570",
                                            "classname": "col-3",
                                            "wrapperType": "BOX",
                                            "id": "wrapper_444",
                                            "fields": [],
                                            "head": "",
                                            "tabHeadClass": ""
                                        },
                                        {
                                            "control": [],
                                            "type": "",
                                            "name": "wrapper_733",
                                            "classname": "col-3",
                                            "wrapperType": "BOX",
                                            "id": "wrapper_536",
                                            "fields": [],
                                            "head": "",
                                            "tabHeadClass": ""
                                        },
                                        {
                                            "control": [],
                                            "type": "",
                                            "name": "wrapper_172",
                                            "classname": "col-3",
                                            "wrapperType": "BOX",
                                            "id": "wrapper_414",
                                            "fields": [],
                                            "head": "",
                                            "tabHeadClass": ""
                                        }
                                    ],
                                    "head": "",
                                    "tabHeadClass": "",
                                    "draggable": false
                                }
                            ],
                            "head": "",
                            "tabHeadClass": "",
                            "draggable": false
                        }
                    ],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false,
                    "isAccordion": true,
                    "accordionConfig": {
                        "headerClassName": "",
                        "headerBtnClassName": "",
                        "header": "<span>Antigen Info</span>",
                        "collapseDivClassName": "",
                        "bodyClassName": "",
                        "collapseOthers": true
                    }
                },
                {
                    "control": [],
                    "type": "",
                    "name": "blocking_washing",
                    "classname": "col-12",
                    "wrapperType": "REGULAR",
                    "id": "wrapper_855",
                    "fields": [
                        {
                            "control": [],
                            "type": "",
                            "name": "wrapper_867",
                            "classname": "row",
                            "wrapperType": "BOX",
                            "id": "wrapper_779",
                            "fields": [
                                {
                                    "control": [
                                        {
                                            "type": "dropdown",
                                            "name": "block_solution",
                                            "id": "block-solution",
                                            "validationButtonType": "",
                                            "classname": "",
                                            "required": false,
                                            "apiEndPoints": [
                                                {
                                                    "type": "init",
                                                    "method": "post",
                                                    "url": "inventory_item",
                                                    "Mapto": [],
                                                    "payload": {
                                                        "perma": "lab-reagents",
                                                        "user_input": ""
                                                    }
                                                }
                                            ],
                                            "options": [],
                                            "multiple": false,
                                            "isClearable": false,
                                            "isStatus": false,
                                            "search": true,
                                            "singleSelect": true,
                                            "showLabel": "value",
                                            "errorMessage": {
                                                "noData": "This fields is required"
                                            },
                                            "value": "",
                                            "imageUrl": "",
                                            "disabled": false,
                                            "wrapperClassName": "",
                                            "connectedArrays": [],
                                            "showBlankInPlaceholder": false,
                                            "blankDataList": [],
                                            "commonPathOfImage": "",
                                            "__schema_id": "block-solution",
                                            "showInputLabel": true,
                                            "labelContent": "Block Solution",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_66",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_555",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [
                                        {
                                            "type": "text",
                                            "name": "block_solution_conc",
                                            "id": "Block_Solution_Conc _360",
                                            "value": "",
                                            "placeholder": "",
                                            "multiline": false,
                                            "classname": "form-control ",
                                            "regularExpression": "",
                                            "disabled": false,
                                            "required": false,
                                            "errorMessage": {
                                                "noData": "This fields is required",
                                                "regexFail": ""
                                            },
                                            "validationButtonType": "",
                                            "rows": 0,
                                            "columns": 0,
                                            "onDemandError": false,
                                            "customValidation": [],
                                            "multiError": false,
                                            "applicationError": "",
                                            "wrapperClassName": "",
                                            "requiredErrorMessage": "This fields is required",
                                            "__schema_id": "block_solution_conc",
                                            "showInputLabel": true,
                                            "labelContent": "Block Solution Conc (µg/µL)",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_643",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_318",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [
                                        {
                                            "type": "text",
                                            "name": "blocking_time",
                                            "id": "blocking_Time_711",
                                            "value": "",
                                            "placeholder": "",
                                            "multiline": false,
                                            "classname": "form-control ",
                                            "regularExpression": "",
                                            "disabled": false,
                                            "required": false,
                                            "errorMessage": {
                                                "noData": "This fields is required",
                                                "regexFail": ""
                                            },
                                            "validationButtonType": "",
                                            "rows": 0,
                                            "columns": 0,
                                            "onDemandError": false,
                                            "customValidation": [],
                                            "multiError": false,
                                            "applicationError": "",
                                            "wrapperClassName": "",
                                            "requiredErrorMessage": "This fields is required",
                                            "__schema_id": "blocking_time",
                                            "showInputLabel": true,
                                            "labelContent": "Blocking Time (hr)",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_532",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_68",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [
                                        {
                                            "type": "dropdown",
                                            "name": "wash_buffer",
                                            "id": "wash_buffer",
                                            "validationButtonType": "",
                                            "classname": "",
                                            "required": false,
                                            "apiEndPoints": [
                                                {
                                                    "type": "init",
                                                    "method": "post",
                                                    "url": "inventory_item",
                                                    "Mapto": [],
                                                    "payload": {
                                                        "perma": "buffers",
                                                        "user_input": ""
                                                    }
                                                }
                                            ],
                                            "options": [],
                                            "multiple": false,
                                            "isClearable": false,
                                            "isStatus": false,
                                            "search": true,
                                            "singleSelect": true,
                                            "showLabel": "value",
                                            "errorMessage": {
                                                "noData": "This fields is required"
                                            },
                                            "value": "",
                                            "imageUrl": "",
                                            "disabled": false,
                                            "wrapperClassName": "",
                                            "connectedArrays": [],
                                            "showBlankInPlaceholder": false,
                                            "blankDataList": [],
                                            "commonPathOfImage": "",
                                            "__schema_id": "wash_buffer",
                                            "showInputLabel": true,
                                            "labelContent": "Wash Buffer",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_841",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_696",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [],
                                    "type": "",
                                    "name": "wrapper_571",
                                    "classname": "row mt-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_110",
                                    "fields": [
                                        {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "wash_cycle",
                                                    "id": "Wash_Cycle_361",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": false,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": false,
                                                    "customValidation": [],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "wash_cycle",
                                                    "showInputLabel": true,
                                                    "labelContent": "No.Of Wash Cycle",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "type": "",
                                            "name": "wrapper_173",
                                            "classname": "col-3",
                                            "wrapperType": "BOX",
                                            "id": "wrapper_473",
                                            "fields": [],
                                            "head": "",
                                            "tabHeadClass": ""
                                        },
                                        {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "wash_buffer_vol",
                                                    "id": "Wash_Buffer_443",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": false,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": false,
                                                    "customValidation": [],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "wash_buffer_vol",
                                                    "showInputLabel": true,
                                                    "labelContent": "Wash Buffer Vol (µL)",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "type": "",
                                            "name": "wrapper_454",
                                            "classname": "col-3",
                                            "wrapperType": "BOX",
                                            "id": "wrapper_215",
                                            "fields": [],
                                            "head": "",
                                            "tabHeadClass": ""
                                        },
                                        {
                                            "control": [],
                                            "type": "",
                                            "name": "wrapper_361",
                                            "classname": "col-3",
                                            "wrapperType": "BOX",
                                            "id": "wrapper_387",
                                            "fields": [],
                                            "head": "",
                                            "tabHeadClass": ""
                                        },
                                        {
                                            "control": [],
                                            "type": "",
                                            "name": "wrapper_799",
                                            "classname": "col-3",
                                            "wrapperType": "BOX",
                                            "id": "wrapper_213",
                                            "fields": [],
                                            "head": "",
                                            "tabHeadClass": ""
                                        }
                                    ],
                                    "head": "",
                                    "tabHeadClass": "",
                                    "draggable": false
                                }
                            ],
                            "head": "",
                            "tabHeadClass": "",
                            "draggable": false
                        }
                    ],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false,
                    "isAccordion": true,
                    "accordionConfig": {
                        "headerClassName": "",
                        "headerBtnClassName": "",
                        "header": "<span>Blocking & Washing Process</span>",
                        "collapseDivClassName": "",
                        "bodyClassName": "",
                        "collapseOthers": true
                    }
                },
                {
                    "control": [],
                    "type": "",
                    "name": "elution_process",
                    "classname": "col-12",
                    "wrapperType": "REGULAR",
                    "id": "wrapper_807",
                    "fields": [
                        {
                            "control": [],
                            "type": "",
                            "name": "wrapper_776",
                            "classname": "row",
                            "wrapperType": "BOX",
                            "id": "wrapper_49",
                            "fields": [
                                {
                                    "control": [
                                        {
                                            "type": "dropdown",
                                            "name": "elution_buffer",
                                            "id": "elution_buffer",
                                            "validationButtonType": "",
                                            "classname": "",
                                            "required": false,
                                            "apiEndPoints": [
                                                {
                                                    "type": "init",
                                                    "method": "post",
                                                    "url": "inventory_item",
                                                    "Mapto": [],
                                                    "payload": {
                                                        "perma": "buffers",
                                                        "user_input": ""
                                                    }
                                                }
                                            ],
                                            "options": [],
                                            "multiple": false,
                                            "isClearable": false,
                                            "isStatus": false,
                                            "search": true,
                                            "singleSelect": true,
                                            "showLabel": "value",
                                            "errorMessage": {
                                                "noData": "This fields is required"
                                            },
                                            "value": "",
                                            "imageUrl": "",
                                            "disabled": false,
                                            "wrapperClassName": "",
                                            "connectedArrays": [],
                                            "showBlankInPlaceholder": false,
                                            "blankDataList": [],
                                            "commonPathOfImage": "",
                                            "__schema_id": "elution_buffer",
                                            "showInputLabel": true,
                                            "labelContent": "Elution Buffer",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_172",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_298",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [
                                        {
                                            "type": "text",
                                            "name": "elution_buffer_vol",
                                            "id": "Elution_Buffer_280",
                                            "value": "100",
                                            "placeholder": "",
                                            "multiline": false,
                                            "classname": "form-control ",
                                            "regularExpression": "",
                                            "disabled": false,
                                            "required": false,
                                            "errorMessage": {
                                                "noData": "This fields is required",
                                                "regexFail": ""
                                            },
                                            "validationButtonType": "",
                                            "rows": 0,
                                            "columns": 0,
                                            "onDemandError": false,
                                            "customValidation": [],
                                            "multiError": false,
                                            "applicationError": "",
                                            "wrapperClassName": "",
                                            "requiredErrorMessage": "This fields is required",
                                            "__schema_id": "elution_buffer_vol",
                                            "showInputLabel": true,
                                            "labelContent": "Elution Buffer Vol (µL)",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_417",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_990",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [
                                        {
                                            "type": "text",
                                            "name": "elution_time",
                                            "id": "elution_Time",
                                            "value": "5",
                                            "placeholder": "",
                                            "multiline": false,
                                            "classname": "form-control ",
                                            "regularExpression": "",
                                            "disabled": false,
                                            "required": false,
                                            "errorMessage": {
                                                "noData": "This fields is required",
                                                "regexFail": ""
                                            },
                                            "validationButtonType": "",
                                            "rows": 0,
                                            "columns": 0,
                                            "onDemandError": false,
                                            "customValidation": [],
                                            "multiError": false,
                                            "applicationError": "",
                                            "wrapperClassName": "",
                                            "requiredErrorMessage": "This fields is required",
                                            "__schema_id": "elution_time",
                                            "showInputLabel": true,
                                            "labelContent": "Elution Time (min)",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_624",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_735",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [
                                        {
                                            "type": "dropdown",
                                            "name": "neutralization_buffer",
                                            "id": "neutralization_buffer",
                                            "validationButtonType": "",
                                            "classname": "",
                                            "required": false,
                                            "apiEndPoints": [
                                                {
                                                    "type": "init",
                                                    "method": "post",
                                                    "url": "inventory_item",
                                                    "Mapto": [],
                                                    "payload": {
                                                        "perma": "buffers",
                                                        "user_input": ""
                                                    }
                                                }
                                            ],
                                            "options": [],
                                            "multiple": false,
                                            "isClearable": false,
                                            "isStatus": false,
                                            "search": true,
                                            "singleSelect": true,
                                            "showLabel": "value",
                                            "errorMessage": {
                                                "noData": "This fields is required"
                                            },
                                            "value": "",
                                            "imageUrl": "",
                                            "disabled": false,
                                            "wrapperClassName": "",
                                            "connectedArrays": [],
                                            "showBlankInPlaceholder": false,
                                            "blankDataList": [],
                                            "commonPathOfImage": "",
                                            "__schema_id": "neutralization_buffer",
                                            "showInputLabel": true,
                                            "labelContent": "Neutralization Buffer",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_651",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_875",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                }
                            ],
                            "head": "",
                            "tabHeadClass": "",
                            "draggable": false
                        }
                    ],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false,
                    "isAccordion": true,
                    "accordionConfig": {
                        "headerClassName": "",
                        "headerBtnClassName": "",
                        "header": "<span>Elution Process</span>",
                        "collapseDivClassName": "",
                        "bodyClassName": "",
                        "collapseOthers": true
                    }
                }
            ],
            "head": "",
            "tabHeadClass": "",
            "draggable": false,
            "isAccordion": true,
            "accordionConfig": {
                "headerClassName": "",
                "headerBtnClassName": "",
                "header": "",
                "collapseDivClassName": "",
                "bodyClassName": "",
                "collapseOthers": true
            }
        }
    ],
    "type": "",
    "classname": "",
    "showClass": true,
    "payload_schema": {
        "phage_screening": {
            "selection_details": {
                "barcode_id": "barcode_id",
                "plate_id": "plate_id",
                "plate_type": "plate_type",
                "library_conc": "library_conc",
                "library_diversity": "library_diversity",
                "library": "library"
            },
            "pre_clearance_info": {
                "incubation_temperature": "incubation_temperature",
                "incubation_time": "incubation_time",
                "antigen_conc": "pre_antigen_conc",
                "volume_well": "pre_volume_well",
                "antigen_form": "pre_antigen_form",
                "non_specific_antigen": "non_specific_antigen"
            },
            "antigen_info": {
                "antigen_incubation_temperature": "antigen_incubation_temperature",
                "antigen_incubation_time": "antigen_incubation_time",
                "antigen_conc": "antigen-conc",
                "volume_well": "volume-well",
                "antigen_form": "antigen-form",
                "antigen": "antigen"
            },
            "blocking_washing": {
                "wash_buffer": "wash_buffer",
                "wash_cycle": "wash_cycle",
                "wash_buffer_vol":"wash_buffer_vol",
                "blocking_time": "blocking_time",
                "block_solution_conc": "block_solution_conc",
                "block_solution": "block-solution"
            },
            "elution_process": {
                "neutralization_buffer": "neutralization_buffer",
                "elution_time": "elution_time",
                "elution_buffer_vol": "elution_buffer_vol",
                "elution_buffer": "elution_buffer"
            },
            "selectProtocol": "selectProtocol"
        }
    }
}

const workflow_phage_enrichment={
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "jsonId": 148,
    "wrapperType": "REGULAR",
    "fields": [
        {
            "control": [],
            "type": "",
            "name": "wrapper_753",
            "classname": "accordion accordion-custom",
            "wrapperType": "ACCORDION",
            "id": "wrapper_567",
            "fields": [
                {
                    "control": [],
                    "type": "",
                    "name": "wrapper_367",
                    "classname": "col-12",
                    "wrapperType": "BOX",
                    "id": "wrapper_288",
                    "fields": [
                        {
                            "control": [],
                            "type": "",
                            "name": "wrapper_171",
                            "classname": "row mb-3",
                            "wrapperType": "BOX",
                            "id": "wrapper_43",
                            "fields": [
                                {
                                    "control": [
                                        {
                                            "type": "dropdown",
                                            "name": "container_type",
                                            "id": "container_type",
                                            "validationButtonType": "",
                                            "classname": "",
                                            "required": false,
                                            "apiEndPoints": [
                                                {
                                                    "type": "init",
                                                    "method": "post",
                                                    "url": "inventory_item",
                                                    "Mapto": [],
                                                    "payload": {
                                                        "perma": "plasticwares",
                                                        "user_input": ""
                                                    }
                                                }
                                            ],
                                            "options": [],
                                            "multiple": false,
                                            "isClearable": false,
                                            "isStatus": false,
                                            "search": true,
                                            "singleSelect": true,
                                            "showLabel": "value",
                                            "errorMessage": {
                                                "noData": "This fields is required"
                                            },
                                            "value": "",
                                            "imageUrl": "",
                                            "disabled": false,
                                            "wrapperClassName": "",
                                            "connectedArrays": [],
                                            "showBlankInPlaceholder": false,
                                            "blankDataList": [],
                                            "commonPathOfImage": "",
                                            "__schema_id": "container_type",
                                            "showInputLabel": true,
                                            "labelContent": "Container Type",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_48",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_43",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [
                                        {
                                            "type": "text",
                                            "name": "bacteria_culture",
                                            "id": "bacteria_culture",
                                            "value": "",
                                            "placeholder": "",
                                            "multiline": false,
                                            "classname": "form-control ",
                                            "regularExpression": "",
                                            "disabled": false,
                                            "required": false,
                                            "errorMessage": {
                                                "noData": "This fields is required",
                                                "regexFail": ""
                                            },
                                            "validationButtonType": "",
                                            "rows": 0,
                                            "columns": 0,
                                            "onDemandError": false,
                                            "customValidation": [],
                                            "multiError": false,
                                            "applicationError": "",
                                            "wrapperClassName": "",
                                            "requiredErrorMessage": "This fields is required",
                                            "__schema_id": "bacteria_culture",
                                            "showInputLabel": true,
                                            "labelContent": "Bacteria Culture",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_641",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_837",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [
                                        {
                                            "type": "text",
                                            "name": "helper_phage",
                                            "id": "helper_phage",
                                            "value": "M13KO7",
                                            "placeholder": "",
                                            "multiline": false,
                                            "classname": "form-control ",
                                            "regularExpression": "",
                                            "disabled": false,
                                            "required": false,
                                            "errorMessage": {
                                                "noData": "This fields is required",
                                                "regexFail": ""
                                            },
                                            "validationButtonType": "",
                                            "rows": 0,
                                            "columns": 0,
                                            "onDemandError": false,
                                            "customValidation": [],
                                            "multiError": false,
                                            "applicationError": "",
                                            "wrapperClassName": "",
                                            "requiredErrorMessage": "This fields is required",
                                            "__schema_id": "helper_phage",
                                            "showInputLabel": true,
                                            "labelContent": "Helper Phage",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_298",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_480",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [
                                        {
                                            "type": "dropdown",
                                            "name": "medium",
                                            "id": "medium",
                                            "validationButtonType": "",
                                            "classname": "",
                                            "required": false,
                                            "apiEndPoints": [
                                                {
                                                    "type": "init",
                                                    "method": "post",
                                                    "url": "inventory_item",
                                                    "Mapto": [],
                                                    "payload": {
                                                        "perma": "cell-culture-media",
                                                        "user_input": ""
                                                    }
                                                }
                                            ],
                                            "options": [],
                                            "multiple": false,
                                            "isClearable": false,
                                            "isStatus": false,
                                            "search": true,
                                            "singleSelect": true,
                                            "showLabel": "value",
                                            "errorMessage": {
                                                "noData": "This fields is required"
                                            },
                                            "value": "",
                                            "imageUrl": "",
                                            "disabled": false,
                                            "wrapperClassName": "",
                                            "connectedArrays": [],
                                            "showBlankInPlaceholder": false,
                                            "blankDataList": [],
                                            "commonPathOfImage": "",
                                            "__schema_id": "medium",
                                            "showInputLabel": true,
                                            "labelContent": "Medium",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_878",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_414",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                }
                            ],
                            "head": "",
                            "tabHeadClass": "",
                            "draggable": false
                        },
                        {
                            "control": [],
                            "type": "",
                            "name": "wrapper_149",
                            "classname": "row mt-3",
                            "wrapperType": "BOX",
                            "id": "wrapper_510",
                            "fields": [
                                {
                                    "control": [
                                        {
                                            "type": "text",
                                            "name": "rpm",
                                            "id": "rpm",
                                            "value": "200",
                                            "placeholder": "",
                                            "multiline": false,
                                            "classname": "form-control ",
                                            "regularExpression": "",
                                            "disabled": false,
                                            "required": false,
                                            "errorMessage": {
                                                "noData": "This fields is required",
                                                "regexFail": ""
                                            },
                                            "validationButtonType": "",
                                            "rows": 0,
                                            "columns": 0,
                                            "onDemandError": false,
                                            "customValidation": [],
                                            "multiError": false,
                                            "applicationError": "",
                                            "wrapperClassName": "",
                                            "requiredErrorMessage": "This fields is required",
                                            "__schema_id": "rpm",
                                            "showInputLabel": true,
                                            "labelContent": "RPM",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_880",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_836",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [
                                        {
                                            "type": "text",
                                            "name": "amplification_inc_time",
                                            "id": "amplification_inc_time",
                                            "value": "20",
                                            "placeholder": "",
                                            "multiline": false,
                                            "classname": "form-control ",
                                            "regularExpression": "",
                                            "disabled": false,
                                            "required": false,
                                            "errorMessage": {
                                                "noData": "This fields is required",
                                                "regexFail": ""
                                            },
                                            "validationButtonType": "",
                                            "rows": 0,
                                            "columns": 0,
                                            "onDemandError": false,
                                            "customValidation": [],
                                            "multiError": false,
                                            "applicationError": "",
                                            "wrapperClassName": "",
                                            "requiredErrorMessage": "This fields is required",
                                            "__schema_id": "amplification_inc_time",
                                            "showInputLabel": true,
                                            "labelContent": "Incubation Time (h)",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_684",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_454",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [
                                        {
                                            "type": "text",
                                            "name": "amplification_inc_temp",
                                            "id": "amplification_inc_temp",
                                            "value": "27",
                                            "placeholder": "",
                                            "multiline": false,
                                            "classname": "form-control ",
                                            "regularExpression": "",
                                            "disabled": false,
                                            "required": false,
                                            "errorMessage": {
                                                "noData": "This fields is required",
                                                "regexFail": ""
                                            },
                                            "validationButtonType": "",
                                            "rows": 0,
                                            "columns": 0,
                                            "onDemandError": false,
                                            "customValidation": [],
                                            "multiError": false,
                                            "applicationError": "",
                                            "wrapperClassName": "",
                                            "requiredErrorMessage": "This fields is required",
                                            "__schema_id": "amplification_inc_temp",
                                            "showInputLabel": true,
                                            "labelContent": "Incubation Temperature (°C)",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_515",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_269",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [],
                                    "type": "",
                                    "name": "wrapper_688",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_468",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                }
                            ],
                            "head": "",
                            "tabHeadClass": "",
                            "draggable": false
                        }
                    ],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false,
                    "isAccordion": true,
                    "accordionConfig": {
                        "headerClassName": "",
                        "headerBtnClassName": "",
                        "header": "<span>Phagemid Amplification</span>",
                        "collapseDivClassName": "",
                        "bodyClassName": "",
                        "collapseOthers": true
                    }
                },
                {
                    "control": [],
                    "type": "",
                    "name": "wrapper_518",
                    "classname": "col-12",
                    "wrapperType": "BOX",
                    "id": "wrapper_899",
                    "fields": [
                        {
                            "control": [],
                            "type": "",
                            "name": "wrapper_438",
                            "classname": "col-12",
                            "wrapperType": "BOX",
                            "id": "wrapper_950",
                            "fields": [
                                {
                                    "control": [],
                                    "type": "",
                                    "name": "wrapper_976",
                                    "classname": "row mb-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_252",
                                    "fields": [
                                        {
                                            "control": [
                                                {
                                                    "type": "dropdown",
                                                    "name": "dilution_media",
                                                    "id": "dilution_media",
                                                    "validationButtonType": "",
                                                    "classname": "",
                                                    "required": false,
                                                    "apiEndPoints": [
                                                        {
                                                            "type": "init",
                                                            "method": "post",
                                                            "url": "inventory_item",
                                                            "Mapto": [],
                                                            "payload": {
                                                                "perma": "cell-culture-media",
                                                                "user_input": ""
                                                            }
                                                        }
                                                    ],
                                                    "options": [],
                                                    "multiple": false,
                                                    "isClearable": false,
                                                    "isStatus": false,
                                                    "search": true,
                                                    "singleSelect": true,
                                                    "showLabel": "value",
                                                    "errorMessage": {
                                                        "noData": "This fields is required"
                                                    },
                                                    "value": "",
                                                    "imageUrl": "",
                                                    "disabled": false,
                                                    "wrapperClassName": "",
                                                    "connectedArrays": [],
                                                    "showBlankInPlaceholder": false,
                                                    "blankDataList": [],
                                                    "commonPathOfImage": "",
                                                    "__schema_id": "dilution_media",
                                                    "showInputLabel": true,
                                                    "labelContent": "Dilution Media",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "type": "",
                                            "name": "wrapper_160",
                                            "classname": "col-3",
                                            "wrapperType": "BOX",
                                            "id": "wrapper_472",
                                            "fields": [],
                                            "head": "",
                                            "tabHeadClass": ""
                                        },
                                        {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "fold_dilution",
                                                    "id": "fold_dilution",
                                                    "value": "10.2",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": false,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": false,
                                                    "customValidation": [],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "fold_dilution",
                                                    "showInputLabel": true,
                                                    "labelContent": "Fold Dilution",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "type": "",
                                            "name": "wrapper_78",
                                            "classname": "col-3",
                                            "wrapperType": "BOX",
                                            "id": "wrapper_606",
                                            "fields": [],
                                            "head": "",
                                            "tabHeadClass": ""
                                        },
                                        {
                                            "control": [
                                                {
                                                    "type": "dropdown",
                                                    "name": "agar_plate",
                                                    "id": "agar_plate",
                                                    "validationButtonType": "",
                                                    "classname": "",
                                                    "required": false,
                                                    "apiEndPoints": [
                                                        {
                                                            "type": "init",
                                                            "method": "post",
                                                            "url": "inventory_item",
                                                            "Mapto": [],
                                                            "payload": {
                                                                "perma": "agar-plates",
                                                                "user_input": ""
                                                            }
                                                        }
                                                    ],
                                                    "options": [],
                                                    "multiple": false,
                                                    "isClearable": false,
                                                    "isStatus": false,
                                                    "search": true,
                                                    "singleSelect": true,
                                                    "showLabel": "value",
                                                    "errorMessage": {
                                                        "noData": "This fields is required"
                                                    },
                                                    "value": "",
                                                    "imageUrl": "",
                                                    "disabled": false,
                                                    "wrapperClassName": "",
                                                    "connectedArrays": [],
                                                    "showBlankInPlaceholder": false,
                                                    "blankDataList": [],
                                                    "commonPathOfImage": "",
                                                    "__schema_id": "agar_plate",
                                                    "showInputLabel": true,
                                                    "labelContent": "Ager Plate",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "type": "",
                                            "name": "wrapper_346",
                                            "classname": "col-3",
                                            "wrapperType": "BOX",
                                            "id": "wrapper_172",
                                            "fields": [],
                                            "head": "",
                                            "tabHeadClass": ""
                                        },
                                        {
                                            "control": [
                                                {
                                                    "type": "dropdown",
                                                    "name": "resistance",
                                                    "id": "resistance",
                                                    "validationButtonType": "",
                                                    "classname": "",
                                                    "required": false,
                                                    "apiEndPoints": [],
                                                    "options": [
                                                        {
                                                            "id": 1,
                                                            "value": "Tetracycline"
                                                        },
                                                        {
                                                            "id": 2,
                                                            "value": "Kanamycin"
                                                        }
                                                    ],
                                                    "multiple": false,
                                                    "isClearable": false,
                                                    "isStatus": false,
                                                    "search": true,
                                                    "singleSelect": true,
                                                    "showLabel": "value",
                                                    "errorMessage": {
                                                        "noData": "This fields is required"
                                                    },
                                                    "value": {
                                                        "id": 2,
                                                        "value": "Kanamycin"
                                                    },
                                                    "imageUrl": "",
                                                    "disabled": false,
                                                    "wrapperClassName": "",
                                                    "connectedArrays": [],
                                                    "showBlankInPlaceholder": false,
                                                    "blankDataList": [],
                                                    "commonPathOfImage": "",
                                                    "__schema_id": "resistance",
                                                    "showInputLabel": true,
                                                    "labelContent": "Resistance",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "type": "",
                                            "name": "wrapper_991",
                                            "classname": "col-3",
                                            "wrapperType": "BOX",
                                            "id": "wrapper_190",
                                            "fields": [],
                                            "head": "",
                                            "tabHeadClass": ""
                                        }
                                    ],
                                    "head": "",
                                    "tabHeadClass": "",
                                    "draggable": false
                                },
                                {
                                    "control": [],
                                    "type": "",
                                    "name": "wrapper_898",
                                    "classname": "row mb-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_23",
                                    "fields": [
                                        {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "no_of_plates",
                                                    "id": "no_of_plates",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": false,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": false,
                                                    "customValidation": [],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "no_of_plates",
                                                    "showInputLabel": true,
                                                    "labelContent": "Total No.Of Plates",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "type": "",
                                            "name": "wrapper_937",
                                            "classname": "col-3",
                                            "wrapperType": "BOX",
                                            "id": "wrapper_917",
                                            "fields": [],
                                            "head": "",
                                            "tabHeadClass": ""
                                        },
                                        {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "plating_inc_time",
                                                    "id": "plating_inc_time",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": false,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": false,
                                                    "customValidation": [],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "plating_inc_time",
                                                    "showInputLabel": true,
                                                    "labelContent": "Incubation Time (h)",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "type": "",
                                            "name": "wrapper_246",
                                            "classname": "col-3",
                                            "wrapperType": "BOX",
                                            "id": "wrapper_718",
                                            "fields": [],
                                            "head": "",
                                            "tabHeadClass": ""
                                        },
                                        {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "plating_inc_temp",
                                                    "id": "plating_inc_temp",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": false,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": false,
                                                    "customValidation": [],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "plating_inc_temp",
                                                    "showInputLabel": true,
                                                    "labelContent": "Incubation Temperature (°C)",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "type": "",
                                            "name": "wrapper_184",
                                            "classname": "col-3",
                                            "wrapperType": "BOX",
                                            "id": "wrapper_3",
                                            "fields": [],
                                            "head": "",
                                            "tabHeadClass": ""
                                        },
                                        {
                                            "control": [],
                                            "type": "",
                                            "name": "wrapper_733",
                                            "classname": "col-3",
                                            "wrapperType": "BOX",
                                            "id": "wrapper_250",
                                            "fields": [],
                                            "head": "",
                                            "tabHeadClass": ""
                                        }
                                    ],
                                    "head": "",
                                    "tabHeadClass": "",
                                    "draggable": false
                                }
                            ],
                            "head": "",
                            "tabHeadClass": "",
                            "draggable": false,
                            "isAccordion": false
                        }
                    ],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false,
                    "isAccordion": true,
                    "accordionConfig": {
                        "headerClassName": "",
                        "headerBtnClassName": "",
                        "header": "<span>Phage Plating</span>",
                        "collapseDivClassName": "",
                        "bodyClassName": "",
                        "collapseOthers": true
                    }
                },
                {
                    "control": [],
                    "type": "",
                    "name": "wrapper_984",
                    "classname": "col-12",
                    "wrapperType": "BOX",
                    "id": "wrapper_835",
                    "fields": [
                        {
                            "control": [],
                            "type": "",
                            "name": "wrapper_789",
                            "classname": "row mb-3",
                            "wrapperType": "BOX",
                            "id": "wrapper_754",
                            "fields": [
                                {
                                    "control": [
                                        {
                                            "type": "dropdown",
                                            "name": "analysis_method",
                                            "id": "analysis_method",
                                            "validationButtonType": "",
                                            "classname": "",
                                            "required": false,
                                            "apiEndPoints": [],
                                            "options": [
                                                {
                                                    "id": 1,
                                                    "value": "Phage Titre"
                                                },
                                                {
                                                    "id": 2,
                                                    "value": "Pooled ELISA"
                                                }
                                            ],
                                            "multiple": true,
                                            "isClearable": true,
                                            "isStatus": false,
                                            "search": true,
                                            "singleSelect": false,
                                            "showLabel": "value",
                                            "errorMessage": {
                                                "noData": "This fields is required"
                                            },
                                            "value":[
                                                {
                                                    "id": 1,
                                                    "value": "Phage Titre"
                                                },
                                                {
                                                    "id": 2,
                                                    "value": "Pooled ELISA"
                                                }
                                            ],
                                            "imageUrl": "",
                                            "disabled": false,
                                            "wrapperClassName": "",
                                            "connectedArrays": [],
                                            "showBlankInPlaceholder": false,
                                            "blankDataList": [],
                                            "commonPathOfImage": "",
                                            "__schema_id": "analysis_method",
                                            "showInputLabel": true,
                                            "labelContent": "Analysis Method",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_911",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_868",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [
                                        {
                                            "type": "text",
                                            "name": "no_of_rounds",
                                            "id": "no_of_rounds_noOfRounds",
                                            "value": 1,
                                            "placeholder": "",
                                            "multiline": false,
                                            "classname": "form-control ",
                                            "regularExpression": "",
                                            "disabled": false,
                                            "required": false,
                                            "errorMessage": {
                                                "noData": "This fields is required",
                                                "regexFail": ""
                                            },
                                            "validationButtonType": "",
                                            "rows": 0,
                                            "columns": 0,
                                            "onDemandError": false,
                                            "customValidation": [],
                                            "multiError": false,
                                            "applicationError": "",
                                            "wrapperClassName": "",
                                            "requiredErrorMessage": "This fields is required",
                                            "__schema_id": "no_of_rounds",
                                            "showInputLabel": true,
                                            "labelContent": "No.Of  Rounds Performed",
                                            "labelClass": "form-label",
                                            "isLabelAfter": false,
                                            "showAdditionalInputContent": false,
                                            "additionalInputContent": "",
                                            "isAdditonalInputAfter": true,
                                            "draggable": false,
                                            "callBackEvents": ['input'],
                                        }
                                    ],
                                    "type": "",
                                    "name": "wrapper_37",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_597",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [],
                                    "type": "",
                                    "name": "wrapper_374",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_960",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                },
                                {
                                    "control": [],
                                    "type": "",
                                    "name": "wrapper_687",
                                    "classname": "col-3",
                                    "wrapperType": "BOX",
                                    "id": "wrapper_417",
                                    "fields": [],
                                    "head": "",
                                    "tabHeadClass": ""
                                }
                            ],
                            "head": "",
                            "tabHeadClass": "",
                            "draggable": false
                        },
                        {
                            "control": [],
                            "type": "",
                            "name": "analysis_rounds",
                            "classname": "table enrichment-table",
                            "wrapperType": "TABLE",
                            "id": "analysis_rounds",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": "",
                            "draggable": false,
                            "hideSingleItemDelete": false,
                            "tableFields": {
                                "showHead": true,
                                "isVertical": false,
                                "isIteratable": false,
                                "addButton": {
                                    "classname": "btn btn-primary",
                                    "name": "",
                                    "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                },
                                "removeButton": {
                                    "classname": "btn btn-danger",
                                    "name": "",
                                    "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                },
                                "tableHead": {
                                    "heading": [
                                        {
                                            "value": "Rounds",
                                            "classname": ""
                                        },
                                        {
                                            "value": "Target",
                                            "classname": ""
                                        },
                                        {
                                            "value": "control Proteins",
                                            "classname": ""
                                        },
                                        {
                                            "value": "Enrichment Ratio",
                                            "classname": ""
                                        },
                                        {
                                            "value": "No.Of Clones formed ",
                                            "classname": ""
                                        },
                                        {
                                            "value": "Clone Plate ID",
                                            "classname": ""
                                        },
                                        {
                                            "value": "Action",
                                            "classname": ""
                                        }
                                    ],
                                    "classname": ""
                                },
                                "initRecordCount": 1,
                                "showRecordCount": false,
                                "recordCounterConfig": {
                                    "classname": "",
                                    "adjValue": "",
                                    "isAdjValueAfter": false
                                },
                                "tableRow": {
                                    "name": "",
                                    "classname": "trclass",
                                    "tableCols": [
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "rounds",
                                                        "id": "rounds",
                                                        "value": "R1",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": true,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "rounds",
                                                        "showInputLabel": false,
                                                        "labelContent": "text_184",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "head": "",
                                                "name": "box_369",
                                                "id": "box_389",
                                                "wrapperType": "BOX",
                                                "fields": [],
                                                "type": "",
                                                "classname": ""
                                            },
                                            "classname": ""
                                        },
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "target",
                                                        "id": "target",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "target",
                                                        "showInputLabel": false,
                                                        "labelContent": "text_58",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "name": "box_948",
                                                "id": "box_449",
                                                "wrapperType": "BOX",
                                                "fields": [],
                                                "type": "",
                                                "classname": "",
                                                "addButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "removeButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "wrapperClassName": ""
                                            },
                                            "classname": ""
                                        },
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "control_proteins",
                                                        "id": "control_proteins",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "control_proteins",
                                                        "showInputLabel": false,
                                                        "labelContent": "text_937",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "name": "box_681",
                                                "id": "box_848",
                                                "wrapperType": "BOX",
                                                "fields": [],
                                                "type": "",
                                                "classname": "",
                                                "addButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "removeButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "wrapperClassName": ""
                                            },
                                            "classname": ""
                                        },
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "enrichment_ratio",
                                                        "id": "enrichment_ratio",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "enrichment_ratio",
                                                        "showInputLabel": false,
                                                        "labelContent": "text_205",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "name": "box_948",
                                                "id": "box_627",
                                                "wrapperType": "BOX",
                                                "fields": [],
                                                "type": "",
                                                "classname": "",
                                                "addButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "removeButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "wrapperClassName": ""
                                            },
                                            "classname": ""
                                        },
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "no_of_clones",
                                                        "id": "no_of_clones",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "no_of_clones",
                                                        "showInputLabel": false,
                                                        "labelContent": "text_487",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "name": "box_455",
                                                "id": "box_542",
                                                "wrapperType": "BOX",
                                                "fields": [],
                                                "type": "",
                                                "classname": "",
                                                "addButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "removeButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "wrapperClassName": ""
                                            },
                                            "classname": ""
                                        },
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "analysis_clone_plate_id",
                                                        "id": "analysis_clone_plate_id",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "analysis_clone_plate_id",
                                                        "showInputLabel": false,
                                                        "labelContent": "text_267",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "name": "box_880",
                                                "id": "box_890",
                                                "wrapperType": "BOX",
                                                "fields": [],
                                                "type": "",
                                                "classname": "",
                                                "addButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "removeButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "wrapperClassName": ""
                                            },
                                            "classname": ""
                                        },
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "button",
                                                        "id": "button_461",
                                                        "value": "<div class=\"custom-tooltip\"><p>Upload Selection Summary Details</p></div>",
                                                        "buttonType": "upload_selection_summaray",
                                                        "classname": "upload-blue me-3",
                                                        "divClass": "",
                                                        "controlEndPoints": [],
                                                        "disabled": false,
                                                        "validationEndPoints": [],
                                                        "invalid": false,
                                                        "name": "button_426",
                                                        "wrapperClassName": "",
                                                        "draggable": false
                                                    },
                                                    {
                                                        "type": "button",
                                                        "id": "button_737",
                                                        "value": "<div class=\"custom-tooltip\"><p>View </p></div>",
                                                        "buttonType": "view_selection_summaray",
                                                        "classname": "plasmid-view",
                                                        "divClass": "",
                                                        "controlEndPoints": [],
                                                        "disabled": true,
                                                        "validationEndPoints": [],
                                                        "invalid": false,
                                                        "name": "button_130",
                                                        "wrapperClassName": "",
                                                        "draggable": false
                                                    },
                                                    {
                                                        "type": "text",
                                                        "name": "selection_file_id",
                                                        "id": "selection_file_id",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control d-none",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "selection_file_id",
                                                        "showInputLabel": false,
                                                        "labelContent": "",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "name": "box_442",
                                                "id": "box_517",
                                                "wrapperType": "BOX",
                                                "fields": [],
                                                "type": "",
                                                "classname": "action-wrap",
                                                "addButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "removeButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "wrapperClassName": ""
                                            },
                                            "classname": ""
                                        }
                                    ]
                                }
                            }
                        }
                    ],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false,
                    "isAccordion": true,
                    "accordionConfig": {
                        "headerClassName": "",
                        "headerBtnClassName": "",
                        "header": "<span>Enrichment Analysis</span>",
                        "collapseDivClassName": "",
                        "bodyClassName": "",
                        "collapseOthers": true
                    }
                },
                {
                    "control": [],
                    "type": "",
                    "name": "wrapper_782",
                    "classname": "col-12",
                    "wrapperType": "BOX",
                    "id": "wrapper_129",
                    "fields": [
                        {
                            "control": [],
                            "type": "",
                            "name": "selected_clone_info",
                            "classname": "table selected-clone-info-table",
                            "wrapperType": "TABLE",
                            "id": "wrapper_545",
                            "fields": [],
                            "head": "",
                            "tabHeadClass": "",
                            "draggable": false,
                            "hideSingleItemDelete": false,
                            "tableFields": {
                                "showHead": true,
                                "isVertical": false,
                                "isIteratable": false,
                                "addButton": {
                                    "classname": "btn btn-primary",
                                    "name": "",
                                    "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                },
                                "removeButton": {
                                    "classname": "btn btn-danger",
                                    "name": "",
                                    "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                },
                                "tableHead": {
                                    "heading": [
                                        {
                                            "value": "Rounds",
                                            "classname": ""
                                        },
                                        {
                                            "value": "Clones Picked",
                                            "classname": ""
                                        },
                                        {
                                            "value": "clone Plate ID",
                                            "classname": ""
                                        },
                                        {
                                            "value": "Phage Dilution",
                                            "classname": ""
                                        },
                                        {
                                            "value": "Phage Titre (PFU/ml)",
                                            "classname": ""
                                        },
                                        {
                                            "value": "Action",
                                            "classname": ""
                                        }
                                    ],
                                    "classname": ""
                                },
                                "initRecordCount": 1,
                                "showRecordCount": false,
                                "recordCounterConfig": {
                                    "classname": "",
                                    "adjValue": "",
                                    "isAdjValueAfter": false
                                },
                                "tableRow": {
                                    "name": "",
                                    "classname": "trclass",
                                    "tableCols": [
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "clone_rounds",
                                                        "id": "clone_rounds",
                                                        "value": "R1",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": true,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "clone_rounds",
                                                        "showInputLabel": false,
                                                        "labelContent": "text_970",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "head": "",
                                                "name": "box_208",
                                                "id": "box_610",
                                                "wrapperType": "BOX",
                                                "fields": [],
                                                "type": "",
                                                "classname": ""
                                            },
                                            "classname": ""
                                        },
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "clone_picked",
                                                        "id": "clone_picked",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "clone_picked",
                                                        "showInputLabel": false,
                                                        "labelContent": "text_538",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "name": "box_242",
                                                "id": "box_650",
                                                "wrapperType": "BOX",
                                                "fields": [],
                                                "type": "",
                                                "classname": "",
                                                "addButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "removeButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "wrapperClassName": ""
                                            },
                                            "classname": ""
                                        },
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "clone_plate_id",
                                                        "id": "clone_plate_id_plateIdChange",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "clone_plate_id",
                                                        "showInputLabel": false,
                                                        "labelContent": "text_190",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false,
                                                        "callBackEvents": ['input']
                                                    }
                                                ],
                                                "name": "box_107",
                                                "id": "box_409",
                                                "wrapperType": "BOX",
                                                "fields": [],
                                                "type": "",
                                                "classname": "",
                                                "addButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "removeButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "wrapperClassName": ""
                                            },
                                            "classname": ""
                                        },
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "clone_phage_dilution",
                                                        "id": "clone_phage_dilution",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "clone_phage_dilution",
                                                        "showInputLabel": false,
                                                        "labelContent": "text_408",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "name": "box_668",
                                                "id": "box_272",
                                                "wrapperType": "BOX",
                                                "fields": [],
                                                "type": "",
                                                "classname": "",
                                                "addButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "removeButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "wrapperClassName": ""
                                            },
                                            "classname": ""
                                        },
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "clone_phage_titre",
                                                        "id": "clone_phage_titre",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "clone_phage_titre",
                                                        "showInputLabel": false,
                                                        "labelContent": "text_547",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "name": "box_910",
                                                "id": "box_775",
                                                "wrapperType": "BOX",
                                                "fields": [],
                                                "type": "",
                                                "classname": "",
                                                "addButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "removeButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "wrapperClassName": ""
                                            },
                                            "classname": ""
                                        },
                                        {
                                            "fields": {
                                                "control": [
                                                    {
                                                        "type": "button",
                                                        "id": "button_863",
                                                        "value": "<div class=\"custom-tooltip\"><p>Mark Clone To Plate</p></div>",
                                                        "buttonType": "assign_clone_storage",
                                                        "classname": "well-icon position-relative",
                                                        "divClass": "",
                                                        "controlEndPoints": [],
                                                        "disabled": false,
                                                        "validationEndPoints": [],
                                                        "invalid": false,
                                                        "name": "button_130",
                                                        "wrapperClassName": "",
                                                        "draggable": false
                                                    },
                                                    {
                                                        "type": "text",
                                                        "name": "clone_plate_details",
                                                        "id": "clone_plate_details",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control d-none",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "clone_plate_details",
                                                        "showInputLabel": false,
                                                        "labelContent": "text_547",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "name": "box_691",
                                                "id": "box_134",
                                                "wrapperType": "BOX",
                                                "fields": [],
                                                "type": "",
                                                "classname": "action-wrap",
                                                "addButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "removeButton": {
                                                    "classname": "",
                                                    "value": ""
                                                },
                                                "wrapperClassName": ""
                                            },
                                            "classname": ""
                                        }
                                    ]
                                }
                            }
                        }
                    ],
                    "head": "",
                    "tabHeadClass": "",
                    "draggable": false,
                    "isAccordion": true,
                    "accordionConfig": {
                        "headerClassName": "",
                        "headerBtnClassName": "",
                        "header": "<span>Selected Clone Info</span>",
                        "collapseDivClassName": "",
                        "bodyClassName": "",
                        "collapseOthers": true
                    }
                }
            ],
            "head": "",
            "tabHeadClass": "",
            "draggable": false,
            "isAccordion": true,
            "accordionConfig": {
                "headerClassName": "",
                "headerBtnClassName": "",
                "header": "<span>Phagemid Amplification</span>",
                "collapseDivClassName": "",
                "bodyClassName": "",
                "collapseOthers": true
            }
        }
    ],
    "type": "",
    "classname": "",
    "showClass": true,
    "payload_schema": {
        "phage_enrichment": {
            "selected_clone_info": [
                {
                    "clone_phage_titre": "clone_phage_titre.$d{}",
                    "clone_phage_dilution": "clone_phage_dilution.$d{}",
                    "clone_plate_id": "clone_plate_id.$d{}",
                    "clone_picked": "clone_picked.$d{}",
                    "clone_rounds": "clone_rounds.$d{}",
                    "clone_plate_details": "clone_plate_details"
                },
            ],
            "no_of_rounds": "no_of_rounds.$t{number}",
            "analysis_method": "analysis_method",
            "analysis_rounds": [
                {
                    "selection_file_id": "selection_file_id.$t{number}",
                    "analysis_clone_plate_id": "analysis_clone_plate_id.$d{}",
                    "no_of_clones": "no_of_clones.$t{number}",
                    "enrichment_ratio": "enrichment_ratio.$d{}",
                    "control_proteins": "control_proteins.$d{}",
                    "target": "target.$d{}",
                    "rounds":"rounds.$d{}"
                },
            ],
            "plating_inc_temp": "plating_inc_temp.$t{number}",
            "plating_inc_time": "plating_inc_time.$t{number}",
            "no_of_plates": "no_of_plates.$t{number}",
            "resistance": "resistance",
            "agar_plate":"agar_plate",
            "fold_dilution": "fold_dilution.$t{number}",
            "dilution_media": "dilution_media",
            "amplification_inc_temp": "amplification_inc_temp.$t{number}",
            "amplification_inc_time": "amplification_inc_time.$t{number}",
            "rpm": "rpm.$t{number}",
            "medium": "medium",
            "helper_phage": "helper_phage.$d{}",
            "bacteria_culture": "bacteria_culture.$d{}",
            "container_type": "container_type"
        }
    }
}

const selection_summary_file_upload={
    "jsonId": 156,
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
        {
            "control": [
                {
                    "type": "dropdown",
                    "showBlankInPlaceholder": true,
                    "blankDataList": [
                        "\"\"",
                        "undefined",
                        "null"
                    ],
                    "name": "file_type",
                    "id": "file_type",
                    "validationButtonType": "upload",
                    "classname": "",
                    "required": false,
                    "apiEndPoints": [],
                    "options": [
                        {
                            "key": "selection_summary_details",
                            "value": "Selection Summary Details"
                        }
                    ],
                    "multiple": false,
                    "isClearable": false,
                    "isStatus": false,
                    "search": true,
                    "singleSelect": true,
                    "showLabel": "value",
                    "errorMessage": {
                        "noData": "This field is required"
                    },
                    "value": {
                        "key": "selection_summary_details",
                        "value": "Selection Summary"
                    },
                    "imageUrl": "",
                    "disabled": true,
                    "wrapperClassName": "",
                    "showInputLabel": true,
                    "labelContent": "Select File Type",
                    "labelClass": "form-label",
                    "isLabelAfter": false,
                    "showAdditionalInputContent": false,
                    "additionalInputContent": "",
                    "isAdditonalInputAfter": true,
                    "draggable": false,
                    "formDataType": "blob",
                    "fileTypes": [
                        "pdf",
                        "doc",
                        "docx",
                        "dot",
                        "dotm",
                        "dotx",
                        "txt",
                        "jpeg",
                        "png",
                        "jpg",
                    ]
                }
            ],
            "type": "",
            "name": "wrapper_801",
            "classname": "col-12 mb-3 d-none",
            "wrapperType": "BOX",
            "id": "wrapper_766",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
        },
        {
            "control": [
                {
                    "type": "file",
                    "name": "file",
                    "id": "file",
                    "value": "upload",
                    "classname": "btn btn-file",
                    "fileNameShow": true,
                    "size": 10,
                    "url": "",
                    "functionName": "",
                    "wrapperClassName": "",
                    "fileCount": 1,
                    "sizeKB": "50000",
                    "allowMultiple": false,
                    "required": false,
                    "validationButtonType": "upload",
                    "formDataType": "blob",
                    "errorMsg": {
                        "invalidFormat": "Invalid File Format",
                        "invalidSize": "File Size exceeded"
                    },
                    "fileTypes": [
                        "pdf",
                        "doc",
                        "ab1",
                        "txt",
                        "xlxs", 
                        "xlsx",
                        "xlsm",
                        "xlsb",
                        "xltx",
                        "xltm",
                        "xls",
                        "xlt",
                        "xml",
                        "xla",
                        "xlam",
                        "xlw",
                        "xlr",
                        "jpeg",
                        "pneg"
                    ],
                    "listClass": "",
                    "mainClass": "",
                    "btnDivClass": "",
                    "showInputLabel": true,
                    "labelContent": "Drag and drop files here or browse files  ",
                    "labelClass": "form-label",
                    "requiredErrorMessage": "This field is required",
                    "isLabelAfter": false,
                    "showAdditionalInputContent": false,
                    "additionalInputContent": "",
                    "isAdditonalInputAfter": true,
                    "draggable": false,
                    "showViewIcon": false,
                    "showDownloadIcon": false
                }
            ],
            "type": "",
            "name": "wrapper_379",
            "classname": "col-12 mb-3 drag-and-drop",
            "wrapperType": "BOX",
            "id": "wrapper_461",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
        }
    ],
    "type": "",
    "classname": ""
}
export const SELECTION_ATIGEN_SPEC_ANTOBODY_JSON = [
    projectInit,
    antigen_info,
    library_info,
    lab_consumables,
    project_summary,
    antigen_file_upload,
    workflow_phage_screening,
    workflow_phage_enrichment,
    selection_summary_file_upload

]

