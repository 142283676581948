export const ELECTROCOMPETENTINITIATION = [
    //project init
    {
        "id": "main",
        "head": "",
        "name": "main",
        "type": "",
        "fields": [
            {
                "id": "wrapper_790",
                "head": "",
                "name": "wrapper_576",
                "type": "",
                "fields": [
                    {
                        "id": "wrapper_808",
                        "head": "",
                        "name": "wrapper_50",
                        "type": "",
                        "fields": [],
                        "control": [
                            {
                                "id": "project_title",
                                "name": "project_title",
                                "__schema_id": "project_title",
                                "rows": 0,
                                "type": "text",
                                "value": "",
                                "columns": 0,
                                "disabled": false,
                                "required": true,
                                "classname": "form-control ",
                                "draggable": false,
                                "multiline": false,
                                "labelClass": "form-label mandatory",
                                "multiError": false,
                                "placeholder": "",
                                "errorMessage": {
                                    "noData": "This field is required",
                                    "regexFail": ""
                                },
                                "isLabelAfter": false,
                                "labelContent": "Project Title",
                                "onDemandError": false,
                                "showInputLabel": true,
                                "applicationError": "",
                                "customValidation": [],
                                "wrapperClassName": "",
                                "regularExpression": "",
                                "requiredErrorMessage": "This field is required",
                                "validationButtonType": "initial-submit",
                                "isAdditonalInputAfter": true,
                                "additionalInputContent": "",
                                "showAdditionalInputContent": false
                            }
                        ],
                        "classname": "col-12 mb-3",
                        "draggable": false,
                        "wrapperType": "BOX",
                        "tabHeadClass": ""
                    },
                    {
                        "id": "wrapper_654",
                        "head": "",
                        "name": "wrapper_290",
                        "type": "",
                        "fields": [],
                        "control": [
                            {
                                "id": "project_init_by",
                                "name": "project_init_by",
                                "__schema_id": "project_init_by",
                                "type": "dropdown",
                                "value": "",
                                "search": true,
                                "disabled": false,
                                "imageUrl": "",
                                "isStatus": false,
                                "multiple": false,
                                "required": true,
                                "classname": "",
                                "draggable": false,
                                "showLabel": "value",
                                "labelClass": "form-label mandatory",
                                "isClearable": false,
                                "apiEndPoints": [
                                    {
                                        "url": "user_management/users_list",
                                        "type": "init",
                                        "method": "get"
                                    }
                                ],
                                "errorMessage": {
                                    "noData": "This field is required"
                                },
                                "isLabelAfter": false,
                                "labelContent": "Initiated By",
                                "singleSelect": true,
                                "showInputLabel": true,
                                "wrapperClassName": "",
                                "validationButtonType": "initial-submit",
                                "isAdditonalInputAfter": true,
                                "additionalInputContent": "",
                                "showAdditionalInputContent": false
                            }
                        ],
                        "classname": "col-12 mb-3",
                        "draggable": false,
                        "wrapperType": "BOX",
                        "tabHeadClass": ""
                    },
                    {
                        "id": "wrapper_778",
                        "head": "",
                        "name": "wrapper_209",
                        "type": "",
                        "fields": [],
                        "control": [
                            {
                                "id": "project_start_date",
                                "name": "project_start_date",
                                "__schema_id": "project_start_date",
                                "type": "datepicker_v2",
                                "range": false,
                                "timer": false,
                                "value": "",
                                "locale": "",
                                "disabled": false,
                                "multiple": false,
                                "required": true,
                                "classname": "",
                                "draggable": false,
                                "dateFormat": "dd/mm/yyyy",
                                "hourFormat": 24,
                                "labelClass": "form-label mandatory",
                                "multiAmount": 3,
                                "placeholder": "DD-MM-YYYY",
                                "dayClassName": "dd",
                                "errorMessage": {
                                    "noData": "This field is required",
                                    "regexFail": "Date is not valid!!"
                                },
                                "isHostParent": false,
                                "isLabelAfter": false,
                                "labelContent": "Start Date",
                                "dateSeperator": "",
                                "keyboardInput": false,
                                "arrowClassName": "arrow",
                                "fordisabledEnd": "",
                                "inputClassName": "form-control date-input",
                                "monthClassName": "mtn",
                                "showInputLabel": true,
                                "todayClassName": "now",
                                "endDateReadOnly": false,
                                "inputDateFormat": false,
                                "fordisabledStart": "",
                                "wrapperClassName": "",
                                "startDateReadOnly": false,
                                "startDatePlaceHolder": "MM/DD/YYYY",
                                "validationButtonType": "initial-submit",
                                "isAdditonalInputAfter": true,
                                "selectedDateClassName": "selectedDate",
                                "additionalInputContent": "",
                                "showAdditionalInputContent": false
                            }
                        ],
                        "classname": "col-6 mb-3",
                        "draggable": false,
                        "wrapperType": "BOX",
                        "tabHeadClass": ""
                    },
                    {
                        "id": "wrapper_92",
                        "head": "",
                        "name": "wrapper_643",
                        "type": "",
                        "fields": [],
                        "control": [
                            {
                                "id": "project_end_date",
                                "name": "project_end_date",
                                "__schema_id": "project_end_date",
                                "type": "datepicker_v2",
                                "range": false,
                                "timer": false,
                                "value": "",
                                "locale": "",
                                "disabled": false,
                                "multiple": false,
                                "required": false,
                                "classname": "",
                                "draggable": false,
                                "dateFormat": "dd/mm/yyyy",
                                "hourFormat": 24,
                                "labelClass": "form-label",
                                "multiAmount": 3,
                                "placeholder": "DD-MM-YYYY",
                                "dayClassName": "dd",
                                "errorMessage": {
                                    "noData": "This field is required",
                                    "regexFail": "Date is not valid!!"
                                },
                                "isHostParent": false,
                                "isLabelAfter": false,
                                "labelContent": "End Date",
                                "dateSeperator": "",
                                "keyboardInput": false,
                                "arrowClassName": "arrow",
                                "fordisabledEnd": "",
                                "inputClassName": "form-control date-input",
                                "monthClassName": "mtn",
                                "showInputLabel": true,
                                "todayClassName": "now",
                                "endDateReadOnly": false,
                                "inputDateFormat": false,
                                "fordisabledStart": "",
                                "wrapperClassName": "",
                                "startDateReadOnly": false,
                                "startDatePlaceHolder": "MM/DD/YYYY",
                                "validationButtonType": "",
                                "isAdditonalInputAfter": true,
                                "selectedDateClassName": "selectedDate",
                                "additionalInputContent": "",
                                "showAdditionalInputContent": false
                            }
                        ],
                        "classname": "col-6 mb-3",
                        "draggable": false,
                        "wrapperType": "BOX",
                        "tabHeadClass": ""
                    },
                    {
                        "control": [
                            {
                                "type": "text",
                                "name": "status",
                                "id": "status",
                                "value": "",
                                "placeholder": "",
                                "multiline": false,
                                "classname": "form-control ",
                                "regularExpression": "",
                                "disabled": true,
                                "required": false,
                                "errorMessage": {
                                    "noData": "This fields is required",
                                    "regexFail": ""
                                },
                                "validationButtonType": "",
                                "rows": 0,
                                "columns": 0,
                                "onDemandError": false,
                                "customValidation": [],
                                "multiError": false,
                                "applicationError": "",
                                "wrapperClassName": "",
                                "requiredErrorMessage": "This fields is required",
                                "__schema_id": "status",
                                "showInputLabel": false,
                                "labelContent": "text_977",
                                "labelClass": "form-label",
                                "isLabelAfter": false,
                                "showAdditionalInputContent": false,
                                "additionalInputContent": "",
                                "isAdditonalInputAfter": true,
                                "draggable": false
                            },
                            {
                                "type": "text",
                                "name": "experiment_type",
                                "id": "experiment_type",
                                "value": "",
                                "placeholder": "",
                                "multiline": false,
                                "classname": "form-control ",
                                "regularExpression": "",
                                "disabled": true,
                                "required": false,
                                "errorMessage": {
                                    "noData": "This fields is required",
                                    "regexFail": ""
                                },
                                "validationButtonType": "",
                                "rows": 0,
                                "columns": 0,
                                "onDemandError": false,
                                "customValidation": [],
                                "multiError": false,
                                "applicationError": "",
                                "wrapperClassName": "",
                                "requiredErrorMessage": "This fields is required",
                                "__schema_id": "experiment_type",
                                "showInputLabel": false,
                                "labelContent": "text_581",
                                "labelClass": "form-label",
                                "isLabelAfter": false,
                                "showAdditionalInputContent": false,
                                "additionalInputContent": "",
                                "isAdditonalInputAfter": true,
                                "draggable": false
                            }
                        ],
                        "type": "",
                        "name": "wrapper_237",
                        "classname": "col-12 d-none",
                        "wrapperType": "BOX",
                        "id": "wrapper_929",
                        "fields": [],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    }
                ],
                "control": [],
                "classname": "row",
                "draggable": false,
                "wrapperType": "BOX",
                "tabHeadClass": ""
            }
        ],
        "jsonId": 120,
        "control": [],
        "classname": "form-container",
        "wrapperType": "REGULAR",
        "payload_schema": {
            "projectInitDetails": {
                "title": "project_title",
                "init_user": "project_init_by",
                "experiment_type": "experiment_type",
                "start_date": "project_start_date",
                "end_date": "project_end_date",
                "status": "status"
            }
        },
        "validation_type": "initial-submit",
    },
]
export const ELCTROCOMPETENTLABCONSUMABLES = [
    //lab
    {
        "jsonId": 122,
        "control": [],
        "head": "",
        "name": "main",
        "id": "main",
        "wrapperType": "REGULAR",
        "fields": [
            {
                "control": [],
                "type": "",
                "name": "wrapper_217",
                "classname": "project-table",
                "wrapperType": "BOX",
                "id": "wrapper_7",
                "fields": [
                    {
                        "control": [],
                        "type": "",
                        "name": "lab",
                        "classname": "table reagents-table-p2",
                        "wrapperType": "TABLE",
                        "id": "lab_consumables",
                        "fields": [],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "hideSingleItemDelete": false,
                        "tableFields": {
                            "showHead": true,
                            "isVertical": false,
                            "isIteratable": true,
                            "addButton": {
                                "classname": "add-expand",
                                "name": "",
                                "value": "<div class='custom-tooltip'><p>Add Row</p></div>"
                            },
                            "removeButton": {
                                "classname": "add-minus ",
                                "name": "",
                                "value": "<div class='custom-tooltip'><p>Remove Row </p></div>"
                            },
                            "tableHead": {
                                "heading": [
                                    {
                                        "value": "Select from  Inventory",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Name",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Vendor",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Capacity (ml)",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Units  available",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Units  taken",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Storage Location",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Action",
                                        "classname": ""
                                    },
                                    {
                                        "value": "",
                                        "classname": ""
                                    }
                                ],
                                "classname": ""
                            },
                            "initRecordCount": 1,
                            "showRecordCount": false,
                            "recordCounterConfig": {
                                "classname": "",
                                "adjValue": "",
                                "isAdjValueAfter": false
                            },
                            "tableRow": {
                                "name": "",
                                "classname": "trclass",
                                "tableCols": [
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "dropdown",
                                                    "name": "select_inventory",
                                                    "id": "select_inventory",
                                                    "validationButtonType": "",
                                                    "classname": "",
                                                    "required": false,
                                                    "apiEndPoints": [
                                                        {
                                                            "type": "change",
                                                            "method": "post",
                                                            "url": "inventory_item",
                                                            "Mapto": [
                                                                {
                                                                    "id": "main.wrapper_7.lab_consumables.${i}.box_90.inv_item",
                                                                    "urlParameters": "",
                                                                    "value": "",
                                                                    "payload": {
                                                                        "perma": "plasticwares",
                                                                        "user_input": ""
                                                                    },
                                                                    "type": null
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "type": "init",
                                                            "method": "post",
                                                            "url": "inventory_item/item-type",
                                                            "Mapto": [],
                                                            "payload": {
                                                                "perma": [
                                                                    "assay-kits",
                                                                    "cell-culture-media-antibiotics-agar-plates",
                                                                    "gels-buffers-lab-reagents",
                                                                    "plasticwares",
                                                                    "glasswares",
                                                                    "cell-lines",
                                                                    "bacterial-strains",
                                                                    "plasmids"
                                                                ]
                                                            }
                                                        }
                                                    ],
                                                    "options": [],
                                                    "multiple": false,
                                                    "isClearable": false,
                                                    "isStatus": false,
                                                    "search": true,
                                                    "singleSelect": true,
                                                    "showLabel": "inv_item_type",
                                                    "errorMessage": {
                                                        "noData": "This fields is required"
                                                    },
                                                    "value": "",
                                                    "imageUrl": "",
                                                    "disabled": false,
                                                    "wrapperClassName": "",
                                                    "connectedArrays": [],
                                                    "showBlankInPlaceholder": false,
                                                    "blankDataList": [],
                                                    "commonPathOfImage": "",
                                                    "__schema_id": "select_inventory",
                                                    "showInputLabel": false,
                                                    "labelContent": "dropdown_448",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "head": "",
                                            "name": "box_893",
                                            "id": "box_760",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": ""
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "dropdown",
                                                    "name": "inv_item",
                                                    "id": "inv_item",
                                                    "validationButtonType": "",
                                                    "classname": "",
                                                    "required": false,
                                                    "apiEndPoints": [
                                                        {
                                                            "type": "change",
                                                            "method": "get",
                                                            "url": "inventory_item/${id}",
                                                            "Mapto": [
                                                                {
                                                                    "id": "main.wrapper_7.lab_consumables.${i}.box_584.vendor",
                                                                    "displayKey": "change",
                                                                    "urlParameterValues": {
                                                                        "id": null,
                                                                        "patchProperty": "vendor_name"
                                                                    }
                                                                },
                                                                {
                                                                    "id": "main.wrapper_7.lab_consumables.${i}.box_908.capacity",
                                                                    "displayKey": "change",
                                                                    "urlParameterValues": {
                                                                        "id": null,
                                                                        "patchProperty": "item_volume"
                                                                    }
                                                                },
                                                                {
                                                                    "id": "main.wrapper_7.lab_consumables.${i}.box_293.units_available",
                                                                    "displayKey": "change",
                                                                    "urlParameterValues": {
                                                                        "id": null,
                                                                        "patchProperty": "item_quantity"
                                                                    }
                                                                },
                                                                {
                                                                    "id": "main.wrapper_7.lab_consumables.${i}.box_126.item_details",
                                                                    "displayKey": "item_details",
                                                                    "urlParameterValues": {
                                                                        "id": null
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ],
                                                    "options": [],
                                                    "multiple": false,
                                                    "isClearable": false,
                                                    "isStatus": false,
                                                    "search": true,
                                                    "singleSelect": true,
                                                    "showLabel": "value",
                                                    "errorMessage": {
                                                        "noData": "This fields is required"
                                                    },
                                                    "value": "",
                                                    "imageUrl": "",
                                                    "disabled": false,
                                                    "wrapperClassName": "",
                                                    "connectedArrays": [],
                                                    "showBlankInPlaceholder": false,
                                                    "blankDataList": [],
                                                    "commonPathOfImage": "",
                                                    "__schema_id": "inv_item",
                                                    "showInputLabel": false,
                                                    "labelContent": "dropdown_532",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_289",
                                            "id": "box_90",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": "",
                                            "draggable": false
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "vendor",
                                                    "id": "vendor",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": true,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": false,
                                                    "customValidation": [],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "vendor",
                                                    "showInputLabel": false,
                                                    "labelContent": "text_305",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_924",
                                            "id": "box_584",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": ""
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "capacity",
                                                    "id": "capacity",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": true,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": true,
                                                    "customValidation": [
                                                        {
                                                            "regularExpression": "/^\\d+$/",
                                                            "errorMessage": "Please enter a valid number"
                                                        }
                                                    ],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "capacity",
                                                    "showInputLabel": false,
                                                    "labelContent": "text_166",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_12",
                                            "id": "box_908",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": ""
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "units_available",
                                                    "id": "units_available",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": true,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": true,
                                                    "customValidation": [
                                                        {
                                                            "regularExpression": "/^\\d+$/",
                                                            "errorMessage": "Please enter a valid number"
                                                        }
                                                    ],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "units_available",
                                                    "showInputLabel": false,
                                                    "labelContent": "text_159",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_213",
                                            "id": "box_293",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": ""
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "units_taken",
                                                    "id": "units_taken",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": false,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": true,
                                                    "customValidation": [
                                                        {
                                                            "regularExpression": "/^\\d+$/",
                                                            "errorMessage": "Please enter a valid number"
                                                        }
                                                    ],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "units_taken",
                                                    "showInputLabel": false,
                                                    "labelContent": "text_885",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_214",
                                            "id": "box_798",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "action-wrap ",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": ""
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "button",
                                                    "id": "infoicon",
                                                    "value": "View Location",
                                                    "buttonType": "storage_info",
                                                    "classname": "info-icon",
                                                    "divClass": "",
                                                    "controlEndPoints": [],
                                                    "disabled": false,
                                                    "validationEndPoints": [],
                                                    "invalid": false,
                                                    "name": "infoicon",
                                                    "wrapperClassName": "text-nowrap",
                                                    "draggable": false
                                                },
                                                {
                                                    "type": "text",
                                                    "name": "item_details",
                                                    "id": "item_details",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control d-none",
                                                    "regularExpression": "",
                                                    "disabled": false,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": false,
                                                    "customValidation": [],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "item_details",
                                                    "showInputLabel": false,
                                                    "labelContent": "text_995",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_261",
                                            "id": "box_126",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": " ",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": ""
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [],
                                            "name": "box_564",
                                            "id": "box_24",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": ""
                                        },
                                        "classname": ""
                                    }
                                ]
                            }
                        }
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false
            }
        ],
        "type": "",
        "classname": "",
        "showClass": true,
        "payload_schema": {
            "consumables": [
                {
                    "inv_category": "select_inventory",
                    "inv_item": "inv_item",
                    "vendor": "vendor.$d{}",
                    "vol_capacity": "capacity.$t{number}",
                    "no_unit_available": "units_available.$t{number}",
                    "no_unit_taken": "units_taken.$d{}",
                    "storage": "item_details.{{assigned_storage}}"
                }
            ],
            "inventory__": {
                "itm_qty": [
                    {
                        "inv_item_id": "inv_item.{{id}}",
                        "qntity_taken": "units_taken.$d{}"
                    }
                ]
            }
        }
    }
]
export const ELECTROCOMPETENTBACTERIAPHAGE = [
    //bacteria Phage
    {
        "control": [],
        "jsonId": 121,
        "head": "",
        "name": "main",
        "id": "main",
        "wrapperType": "REGULAR",
        "fields": [
            {
                "control": [
                    {
                        "type": "label",
                        "name": "<h2 class=\"add-heading heading-without-icon\"><span>Bacteria & Phage</span></h2>",
                        "classname": "w-100",
                        "for": "",
                        "required": false,
                        "wrapperClassName": "",
                        "id": "label_560",
                        "draggable": false
                    }
                ],
                "type": "",
                "name": "wrapper_983",
                "classname": "accordion accordion-custom",
                "wrapperType": "BOX",
                "id": "wrapper_197",
                "fields": [
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_893",
                        "classname": "project-table",
                        "wrapperType": "BOX",
                        "id": "wrapper_714",
                        "fields": [
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_456",
                                "classname": "table  project-ini-table bact-table1",
                                "wrapperType": "TABLE",
                                "id": "wrapper_983",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false,
                                "hideSingleItemDelete": false,
                                "tableFields": {
                                    "showHead": true,
                                    "isVertical": false,
                                    "isIteratable": false,
                                    "addButton": {
                                        "classname": "add-expand",
                                        "name": "",
                                        "value": ""
                                    },
                                    "removeButton": {
                                        "classname": "add-minus",
                                        "name": "",
                                        "value": ""
                                    },
                                    "tableHead": {
                                        "heading": [
                                            {
                                                "value": "",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Name",
                                                "classname": ""
                                            },
                                            {
                                                "value": "ID",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Source",
                                                "classname": ""
                                            },
                                            {
                                                "value": "ANTIBIOTIC RESISTANCE",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Action",
                                                "classname": ""
                                            },
                                            {
                                                "value": "",
                                                "classname": ""
                                            }
                                        ],
                                        "classname": ""
                                    },
                                    "initRecordCount": 1,
                                    "showRecordCount": false,
                                    "recordCounterConfig": {
                                        "classname": "",
                                        "adjValue": "",
                                        "isAdjValueAfter": false
                                    },
                                    "tableRow": {
                                        "name": "",
                                        "classname": "trclass",
                                        "tableCols": [
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "phage",
                                                            "id": "textBox_69",
                                                            "value": "Phage",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": true,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "phage_val",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_249",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "head": "",
                                                    "name": "box_960",
                                                    "id": "box_760",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "name",
                                                            "id": "textBox_658",
                                                            "value": "M13K07",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "phage_name",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_91",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_348",
                                                    "id": "box_383",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": " ",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "id",
                                                            "id": "textBox_919",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "phage_code",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_859",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_266",
                                                    "id": "box_651",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "source",
                                                            "id": "textBox_78",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "phage_source",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_713",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_595",
                                                    "id": "box_126",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "antibiotic_resistance",
                                                            "id": "textBox_610",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "phage_antibiotic_resistance",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_878",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_789",
                                                    "id": "box_963",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [],
                                                    "name": "box_947",
                                                    "id": "box_464",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [],
                                                    "name": "box_104",
                                                    "id": "box_772",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            }
                                        ]
                                    }
                                }
                            },
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_79",
                                "classname": "table  project-ini-table bact-table2 bacteria-phage-table",
                                "wrapperType": "TABLE",
                                "id": "wrapper_559",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false,
                                "tableFields": {
                                    "showHead": false,
                                    "isVertical": false,
                                    "isIteratable": true,
                                    "addButton": {
                                        "classname": "add-expand",
                                        "name": "",
                                        "value": "<div class='custom-tooltip'><p>Add Row </p></div>"
                                    },
                                    "removeButton": {
                                        "classname": "add-minus",
                                        "name": "",
                                        "value": "<div class='custom-tooltip'><p>Remove Row </p></div>"
                                    },
                                    "tableHead": {
                                        "heading": [
                                            {
                                                "value": "",
                                                "classname": ""
                                            },
                                            {
                                                "value": "",
                                                "classname": ""
                                            },
                                            {
                                                "value": "",
                                                "classname": ""
                                            },
                                            {
                                                "value": "",
                                                "classname": ""
                                            },
                                            {
                                                "value": "",
                                                "classname": ""
                                            },
                                            {
                                                "value": "",
                                                "classname": ""
                                            }
                                        ],
                                        "classname": ""
                                    },
                                    "initRecordCount": 1,
                                    "showRecordCount": false,
                                    "recordCounterConfig": {
                                        "classname": "",
                                        "adjValue": "",
                                        "isAdjValueAfter": false
                                    },
                                    "tableRow": {
                                        "name": "",
                                        "classname": "trclass",
                                        "tableCols": [
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "dropdown",
                                                            "name": "inv_category",
                                                            "id": "dropDown_76",
                                                            "validationButtonType": "",
                                                            "classname": "",
                                                            "required": false,
                                                            "apiEndPoints": [
                                                                // {
                                                                //     "type": "init",
                                                                //     "method": "post",
                                                                //     "url": "inventory_item/item-type",
                                                                //     "Mapto": [],
                                                                //     "payload": {
                                                                //         "perma": [
                                                                //             "assay-kits",
                                                                //             "cell-culture-media-antibiotics-agar-plates",
                                                                //             "gels-buffers-lab-reagents",
                                                                //             "plasticwares",
                                                                //             "glasswares",
                                                                //             "cell-lines",
                                                                //             "bacterial-strains",
                                                                //             "plasmids"
                                                                //         ]
                                                                //     }
                                                                // },
                                                                {
                                                                    "type": "change",
                                                                    "method": "post",
                                                                    "url": "inventory_item",
                                                                    "Mapto": [
                                                                        {
                                                                            "id": "main.wrapper_197.wrapper_714.wrapper_559.${i}.box_820.dropDown_281",
                                                                            "urlParameters": "",
                                                                            "value": "",
                                                                            "payload": {
                                                                                "perma": "bacterial-strains",
                                                                                "user_input": ""
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ],
                                                            "options": [],
                                                            "multiple": false,
                                                            "isClearable": false,
                                                            "isStatus": false,
                                                            "search": true,
                                                            "singleSelect": true,
                                                            "showLabel": "inv_item_type",
                                                            "errorMessage": {
                                                                "noData": "This fields is required"
                                                            },
                                                            "value": {
                                                                "id": 7,
                                                                "inv_item_type": "Bacterial Strains",
                                                                "perma": "bacterial-strains"
                                                            },
                                                            "imageUrl": "",
                                                            "disabled": false,
                                                            "wrapperClassName": "",
                                                            "connectedArrays": [],
                                                            "showBlankInPlaceholder": false,
                                                            "blankDataList": [],
                                                            "commonPathOfImage": "",
                                                            "__schema_id": "inv_category",
                                                            "showInputLabel": false,
                                                            "labelContent": "dropdown_175",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "head": "",
                                                    "name": "box_162",
                                                    "id": "box_452",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "dropdown",
                                                            "name": "inv_item",
                                                            "id": "dropDown_281",
                                                            "validationButtonType": "initial-submit",
                                                            "classname": "",
                                                            "required": true,
                                                            "apiEndPoints": [
                                                                {
                                                                    "type": "change",
                                                                    "method": "get",
                                                                    "url": "inventory_item/${id}",
                                                                    "Mapto": [
                                                                        // {
                                                                        //     "id": "main.wrapper_197.wrapper_714.wrapper_559.${i}.box_691.inv_item_id",
                                                                        //     "displayKey": "change",
                                                                        //     "urlParameterValues": {
                                                                        //         "id": null,
                                                                        //         "patchProperty": "item_code"
                                                                        //     }
                                                                        // },
                                                                        {
                                                                            "id": "main.wrapper_197.wrapper_714.wrapper_559.${i}.box_691.item_details",
                                                                            "displayKey": "item_details",
                                                                            "urlParameterValues": {
                                                                                "id": null
                                                                            }
                                                                        },
                                                                        // {
                                                                        //     "id": "main.wrapper_197.wrapper_714.wrapper_559.${i}.box_847.bacteria_source",
                                                                        //     "displayKey": "change",
                                                                        //     "urlParameterValues": {
                                                                        //         "id": null,
                                                                        //         "patchProperty": "source_name"
                                                                        //     }
                                                                        // }
                                                                    ]
                                                                }
                                                            ],
                                                            "options": [],
                                                            "multiple": false,
                                                            "isClearable": false,
                                                            "isStatus": false,
                                                            "search": true,
                                                            "singleSelect": true,
                                                            "showLabel": "value",
                                                            "errorMessage": {
                                                                "noData": "This fields is required"
                                                            },
                                                            "value": "",
                                                            "imageUrl": "",
                                                            "disabled": false,
                                                            "wrapperClassName": "",
                                                            "connectedArrays": [],
                                                            "showBlankInPlaceholder": false,
                                                            "blankDataList": [],
                                                            "commonPathOfImage": "",
                                                            "__schema_id": "inv_item",
                                                            "showInputLabel": false,
                                                            "labelContent": "dropdown_507",
                                                            "labelClass": "form-label mandatory",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        },
                                                        {
                                                            "type": "button",
                                                            "id": "button_161",
                                                            "value": "",
                                                            "buttonType": "",
                                                            "classname": "info d-none",
                                                            "divClass": "",
                                                            "controlEndPoints": [],
                                                            "disabled": false,
                                                            "validationEndPoints": [],
                                                            "invalid": false,
                                                            "name": "button_23",
                                                            "wrapperClassName": "",
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_226",
                                                    "id": "box_820",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "inv_item_id",
                                                            "id": "inv_item_id",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "inv_item_id",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_720",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_962",
                                                    "id": "box_691",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "source",
                                                            "id": "bacteria_source",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "bacteria_source",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_720",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_288",
                                                    "id": "box_847",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "bacteria_antibiotic_resistance",
                                                            "id": "textBox_353",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "bacteria_antibiotic_resistance",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_134",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_36",
                                                    "id": "box_489",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [],
                                                    "name": "box_644",
                                                    "id": "box_477",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            }
                                        ]
                                    }
                                },
                                "addBtnPostion": null,
                                "hideSingleItemDelete": false
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "bacteria-phage",
                            "headerBtnClassName": "",
                            "header": "Bacteria, Phage & Template",
                            "collapseDivClassName": "",
                            "bodyClassName": "",
                            "collapseOthers": true
                        }
                    },
                    {
                        "control": [
                            {
                                "type": "label",
                                "name": "<h2 class=\"add-heading heading-without-icon\"><span>Experiment Notes/Comments</span></h2>",
                                "classname": "mt-2 w-100",
                                "for": "",
                                "required": false,
                                "wrapperClassName": "",
                                "id": "label_935",
                                "draggable": false
                            }
                        ],
                        "type": "",
                        "name": "wrapper_620",
                        "classname": "col-12",
                        "wrapperType": "BOX",
                        "id": "wrapper_723",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "comment",
                                        "id": "textBox_620",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": true,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "comment",
                                        "showInputLabel": false,
                                        "labelContent": "text_125",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_864",
                                "classname": "col-12",
                                "wrapperType": "BOX",
                                "id": "wrapper_776",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "experiment-note",
                            "headerBtnClassName": "",
                            "header": "Experiment Notes/Comments",
                            "collapseDivClassName": "",
                            "bodyClassName": "",
                            "collapseOthers": true
                        }
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false,
                "addBtnPostion": null,
                "isAccordion": true,
                "accordionConfig": {
                    "headerClassName": "",
                    "headerBtnClassName": "",
                    "header": "",
                    "collapseDivClassName": "",
                    "bodyClassName": "",
                    "collapseOthers": true
                }
            }
        ],
        "type": "",
        "classname": "",
        "showClass": true,
        "payload_schema": {
            "bacteriaandphage": {
                "bacteria": [
                    {
                        "inv_category": "inv_category",
                        "inv_item": "inv_item",
                        "inv_item_code": "inv_item_id.$d{}",
                        "source": "bacteria_source.$d{}",
                        "antibiotic_resistance": "bacteria_antibiotic_resistance.$d{}"
                    }
                ],
                "phage": [
                    {
                        "phage": "phage_val.$d{}",
                        "name": "phage_name.$d{}",
                        "item_code": "phage_code.$d{}",
                        "source": "phage_source.$d{}",
                        "antibiotic_resistance": "phage_antibiotic_resistance.$d{}"
                    }
                ],
                "comment": "comment.$d{}"
            }
        },
        "validation_type": "initial-submit",
    }
]
export const ELECTROCOMPETENTSUMMARY = [
    //summary
    {
        "control": [],
        "jsonId": 123,
        "head": "",
        "name": "main",
        "id": "main",
        "wrapperType": "REGULAR",
        "fields": [
            {
                "control": [
                    {
                        "type": "label",
                        "name": "<h2 class=\"add-heading heading-without-icon\"><span>Summary</span></h2>",
                        "classname": "w-100",
                        "for": "",
                        "required": false,
                        "wrapperClassName": "",
                        "id": "label_98",
                        "draggable": false
                    }
                ],
                "type": "",
                "name": "wrapper_847",
                "classname": "col-12",
                "wrapperType": "BOX",
                "id": "wrapper_850",
                "fields": [
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_650",
                        "classname": "row mb-3",
                        "wrapperType": "BOX",
                        "id": "wrapper_860",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "proj_type",
                                        "id": "proj_type",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": true,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "proj_type",
                                        "showInputLabel": true,
                                        "labelContent": "Project Type",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_440",
                                "classname": "col-4",
                                "wrapperType": "BOX",
                                "id": "wrapper_855",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "title",
                                        "id": "title",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": true,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "title",
                                        "showInputLabel": true,
                                        "labelContent": "Project Title",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_457",
                                "classname": "col-4",
                                "wrapperType": "BOX",
                                "id": "wrapper_393",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_195",
                        "classname": "row mb-3",
                        "wrapperType": "BOX",
                        "id": "wrapper_646",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "objective",
                                        "id": "objective",
                                        "value": "To  make clean, high  competency cell  aliquots suitable  for library  electroporation",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": true,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "objective",
                                        "showInputLabel": true,
                                        "labelContent": "Objective",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    },
                                    {
                                        "type": "text",
                                        "name": "proj_code",
                                        "id": "proj_code",
                                        "value": "SIM1001",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control d-none",
                                        "regularExpression": "",
                                        "disabled": true,
                                        "required": true,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "proj_code",
                                        "showInputLabel": false,
                                        "labelContent": "Objective",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_413",
                                "classname": "col-12",
                                "wrapperType": "BOX",
                                "id": "wrapper_270",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_834",
                                "classname": "col-8",
                                "wrapperType": "BOX",
                                "id": "wrapper_697",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    },
                    {
                        "control": [
                            {
                                "type": "text",
                                "name": "comments",
                                "id": "comments",
                                "value": "",
                                "placeholder": "Enter your comments if any",
                                "multiline": true,
                                "classname": "form-control ",
                                "regularExpression": "",
                                "disabled": false,
                                "required": false,
                                "errorMessage": {
                                    "noData": "This fields is required",
                                    "regexFail": ""
                                },
                                "validationButtonType": "",
                                "rows": 0,
                                "columns": 0,
                                "onDemandError": false,
                                "customValidation": [],
                                "multiError": false,
                                "applicationError": "",
                                "wrapperClassName": "",
                                "requiredErrorMessage": "This fields is required",
                                "__schema_id": "comments",
                                "showInputLabel": true,
                                "labelContent": "Experiment Notes/Comments",
                                "labelClass": "form-label",
                                "isLabelAfter": false,
                                "showAdditionalInputContent": false,
                                "additionalInputContent": "",
                                "isAdditonalInputAfter": true,
                                "draggable": false
                            }
                        ],
                        "type": "",
                        "name": "wrapper_269",
                        "classname": "col-12",
                        "wrapperType": "BOX",
                        "id": "wrapper_521",
                        "fields": [],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false
            }
        ],
        "type": "",
        "classname": "",
        "showClass": true,
        "payload_schema": {
            "project_summary": {
                "title": "title",
                "proj_type": "proj_type",
                "objective": "objective",
                "comments": "comments"
            }
        },
        "validation_type": "submit"
    }
]

export const WORKFLOWBACTERIALSTRAINGROWTH =[
    //workflow
    {
        "control": [],
        "jsonId": 124,
        "head": "",
        "name": "main",
        "id": "main",
        "wrapperType": "REGULAR",
        "fields": [
            {
                "control": [],
                "type": "",
                "name": "Protocol_Section",
                "classname": "row",
                "wrapperType": "BOX",
                "id": "Protocol_Section",
                "fields": [
                    {
                        "control": [
                            {
                                "type": "dropdown",
                                "name": "selectProtocol",
                                "id": "selectProtocol",
                                "validationButtonType": "",
                                "classname": "mb-3",
                                "required": false,
                                "apiEndPoints": [
                                    {
                                        "type": "init",
                                        "method": "get",
                                        "url": "protocol/protocol-list"
                                    },
                                    {
                                        "type": "change",
                                        "method": "get",
                                        "Mapto": [
                                            {
                                                "id": "main.form.strainCulture.strain_culture_form.od_wrapper.protocol_file_id",
                                                "displayKey": "change",
                                                "urlParameterValues": {
                                                    "id": null,
                                                    "patchProperty": "protocol_file_id"
                                                }
                                            }
                                        ],
                                        "url": "protocol/${id}"
                                    }
                                ],
                                "options": [],
                                "multiple": false,
                                "isClearable": false,
                                "isStatus": false,
                                "search": true,
                                "singleSelect": true,
                                "showLabel": "value",
                                "errorMessage": {
                                    "noData": "This fields is required"
                                },
                                "value": "",
                                "imageUrl": "",
                                "disabled": false,
                                "wrapperClassName": "",
                                "connectedArrays": [],
                                "showBlankInPlaceholder": false,
                                "blankDataList": [],
                                "commonPathOfImage": "",
                                "__schema_id": "",
                                "showInputLabel": true,
                                "labelContent": "Select Protocol",
                                "labelClass": "form-label",
                                "isLabelAfter": false,
                                "showAdditionalInputContent": false,
                                "additionalInputContent": "",
                                "isAdditonalInputAfter": true,
                                "draggable": false
                            }
                        ],
                        "type": "",
                        "name": "protocol",
                        "classname": "col-3 mb-3",
                        "wrapperType": "BOX",
                        "id": "protocol",
                        "fields": [],
                        "head": "",
                        "tabHeadClass": ""
                    },
                    {
                        "control": [
                            {
                                "type": "button",
                                "id": "button_878",
                                "value": "<div class=\"custom-tooltip\"><p>View </p></div>",
                                "buttonType": "view-protocol",
                                "classname": "view mt-2",
                                "divClass": "",
                                "controlEndPoints": [],
                                "disabled": false,
                                "validationEndPoints": [],
                                "invalid": false,
                                "name": "button_935",
                                "wrapperClassName": "actions position-relative ",
                                "draggable": false
                            }
                        ],
                        "type": "",
                        "name": "protocolActions",
                        "classname": "col-1  mt-4",
                        "wrapperType": "BOX",
                        "id": "protocolActions",
                        "fields": [],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false
            },
            {
                "control": [],
                "type": "",
                "name": "form",
                "classname": "accordion accordion-custom",
                "wrapperType": "ACCORDION",
                "id": "form",
                "fields": [
                    {
                        "control": [],
                        "type": "",
                        "name": "strainCulture",
                        "classname": "accordion accordion-flush accordion-custom",
                        "wrapperType": "BOX",
                        "id": "strainCulture",
                        "fields": [
                            {
                                "control": [],
                                "type": "",
                                "name": "strain_culture_form",
                                "classname": "row",
                                "wrapperType": "REGULAR",
                                "id": "strain_culture_form",
                                "fields": [
                                    {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "bacterialStrain",
                                                "id": "bacterialStrain",
                                                "validationButtonType": "",
                                                "classname": "",
                                                "required": false,
                                                "apiEndPoints": [
                                                    {
                                                        "type": "init",
                                                        "method": "post",
                                                        "url": "inventory_item",
                                                        "Mapto": [],
                                                        "payload": {
                                                            "perma": "bacterial-strains",
                                                            "user_input": ""
                                                        }
                                                    }
                                                ],
                                                "options": [],
                                                "multiple": false,
                                                "isClearable": false,
                                                "isStatus": false,
                                                "search": false,
                                                "singleSelect": true,
                                                "showLabel": "value",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": "",
                                                "imageUrl": "",
                                                "disabled": true,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "bacterialStrain",
                                                "showInputLabel": true,
                                                "labelContent": "Bacteria strain",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "bacteria_strain",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "bacteria_strain",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "containerType",
                                                "id": "containerType",
                                                "value": "Lb/Tet Plate",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control  ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "containerType",
                                                "showInputLabel": true,
                                                "labelContent": "Container Type",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "container_Type",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "container_Type",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "cultureMedia",
                                                "id": "cultureMedia",
                                                "validationButtonType": "",
                                                "classname": "",
                                                "required": false,
                                                "apiEndPoints": [
                                                    {
                                                        "type": "init",
                                                        "method": "post",
                                                        "url": "inventory_item",
                                                        "payload": {
                                                            "user_input": "",
                                                            "perma": "cell-culture-media"
                                                        }
                                                    }
                                                ],
                                                "options": [],
                                                "multiple": false,
                                                "isClearable": false,
                                                "isStatus": false,
                                                "search": true,
                                                "singleSelect": true,
                                                "showLabel": "value",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": "",
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "cultureMedia",
                                                "showInputLabel": true,
                                                "labelContent": "Culture Media Used",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "Culture_Medai",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "Culture_Medai",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "medai",
                                                "id": "media",
                                                "validationButtonType": "",
                                                "classname": "",
                                                "required": false,
                                                "apiEndPoints": [],
                                                "options": [
                                                    {
                                                        "id": 1,
                                                        "value": "Tetracycline"
                                                    },
                                                    {
                                                        "id": 2,
                                                        "value": "Kanamycin"
                                                    }
                                                ],
                                                "multiple": true,
                                                "isClearable": false,
                                                "isStatus": false,
                                                "search": false,
                                                "singleSelect": true,
                                                "showLabel": "value",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": {
                                                    "id": 1,
                                                    "value": "Tetracycline"
                                                },
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "media",
                                                "showInputLabel": true,
                                                "labelContent": "Media/Antibiotics",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "media_wrapper",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "media_wrapper",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "mediaVol",
                                                "id": "mediaVol",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control  mb-3",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "mediaVol",
                                                "showInputLabel": true,
                                                "labelContent": "Media Vol(ml)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "Media_Vol",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "Media_Vol",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "rpm",
                                                "id": "rpm",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control mb-3",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "rpm",
                                                "showInputLabel": true,
                                                "labelContent": "RPM",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "rpm_wrapper",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "rpm_wrapper",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "incubationTime",
                                                "id": "incubationTime",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control  ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "incubationTime",
                                                "showInputLabel": true,
                                                "labelContent": "Incubation Time(h)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "incubation_Time",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "incubation_Time",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "incubationTemp",
                                                "id": "incubationTemp",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "incubationTemp",
                                                "showInputLabel": true,
                                                "labelContent": "Incubation Temperature (°C)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "Incubation_Temp",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "Incubation_Temp",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                   
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "od",
                                                "id": "od",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control  ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "od",
                                                "showInputLabel": true,
                                                "labelContent": "0.D (550 nm)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            },
                                            {
                                                "type": "text",
                                                "name": "protocol_file_id",
                                                "id": "protocol_file_id",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control d-none",
                                                "regularExpression": "",
                                                "disabled": true,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "protocol_file_id",
                                                "showInputLabel": false,
                                                "labelContent": "text_41",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "od_wrapper",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "od_wrapper",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    }
                                ],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "",
                            "headerBtnClassName": "",
                            "header": "<span>Strain Culture</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "row",
                            "collapseOthers": true
                        }
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "Serial_Dilution_header ",
                        "classname": "col-12 project-table",
                        "wrapperType": "BOX",
                        "id": "Serial_Dilution_header ",
                        "fields": [
                            {
                                "control": [],
                                "type": "",
                                "name": "serial_diultion",
                                "classname": "row",
                                "wrapperType": "REGULAR",
                                "id": "serial_diultion",
                                "fields": [
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "helperPhage",
                                                "id": "helperPhage",
                                                "value": "M13KO7",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": true,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "helperPhage",
                                                "showInputLabel": true,
                                                "labelContent": "Helper Phage",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "helper_Phage",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "helper_Phage",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "phageAntibiotics",
                                                "id": "phageAntibiotics",
                                                "validationButtonType": "",
                                                "classname": "",
                                                "required": false,
                                                "apiEndPoints": [],
                                                "options": [
                                                    {
                                                        "id": 1,
                                                        "value": "Tetracycline"
                                                    },
                                                    {
                                                        "id": 2,
                                                        "value": "Kanamycin"
                                                    }
                                                ],
                                                "multiple": false,
                                                "isClearable": false,
                                                "isStatus": false,
                                                "search": false,
                                                "singleSelect": true,
                                                "showLabel": "value",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": {
                                                    "id": 2,
                                                    "value": "Kanamycin"
                                                },
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "phageAntibiotics",
                                                "showInputLabel": true,
                                                "labelContent": "Phage Antibiotics  ",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "phage_Antibiotics",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "phage_Antibiotics",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "serialDilution",
                                                "id": "serialDilution",
                                                "value": "10",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "serialDilution",
                                                "showInputLabel": true,
                                                "labelContent": "Serial Fold Dilution",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "serial_Dilution",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "serial_Dilution",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "strainvol",
                                                "id": "strainvol",
                                                "value": "0.5",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "strainvol",
                                                "showInputLabel": true,
                                                "labelContent": "Strain Vol(ml)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "strainvol",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "strainvol",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "phagevol",
                                                "id": "phagevol",
                                                "value": "200",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "phagevol",
                                                "showInputLabel": true,
                                                "labelContent": "Helper Phage Vol(µl)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "phage_vol",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "phage_vol",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "topAgarvol",
                                                "id": "topAgarvol",
                                                "value": "4",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "topAgarvol",
                                                "showInputLabel": true,
                                                "labelContent": "Top Agar Vol(ml)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "top_Agarvol",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "top_Agarvol",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "containerType",
                                                "id": "containerType",
                                                "value": "LB/Kan plate",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "containerType",
                                                "showInputLabel": true,
                                                "labelContent": "Container Type",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "container_Type",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "container_Type",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "incubationT",
                                                "id": "incubationT",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "incubationT",
                                                "showInputLabel": true,
                                                "labelContent": "Incubation Time(h)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "incubation_Time",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "incubation_Time",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "incubationTemptr",
                                                "id": "incubationTemptr",
                                                "value": "37",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "incubationTemptr",
                                                "showInputLabel": true,
                                                "labelContent": "Incubation Temperature (°C)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "incubation_Temptr",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "incubation_Temptr",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    }
                                ],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "plague-formation",
                            "headerBtnClassName": "",
                            "header": "<span>Serial Dilution and Plaque Formation</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "row",
                            "collapseOthers": true
                        }
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "Plaque_Growth_Header",
                        "classname": "col-12",
                        "wrapperType": "BOX",
                        "id": "Plaque_Growth_Header",
                        "fields": [
                            {
                                "control": [],
                                "type": "",
                                "name": "plaque_form",
                                "classname": "row",
                                "wrapperType": "REGULAR",
                                "id": "plaque_form",
                                "fields": [
                                    {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "growthMedia",
                                                "id": "growthMedia",
                                                "validationButtonType": "",
                                                "classname": "",
                                                "required": false,
                                                "apiEndPoints": [
                                                    {
                                                        "type": "init",
                                                        "method": "post",
                                                        "url": "inventory_item",
                                                        "payload": {
                                                            "user_input": "",
                                                            "perma": "cell-culture-media"
                                                        }
                                                    }
                                                ],
                                                "options": [],
                                                "multiple": false,
                                                "isClearable": false,
                                                "isStatus": false,
                                                "search": true,
                                                "singleSelect": true,
                                                "showLabel": "value",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": "",
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "growthMedia",
                                                "showInputLabel": true,
                                                "labelContent": "Growth Media Used",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "growth_Media",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "growth_Media",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                   
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "antibiotics",
                                                "id": "antibiotics",
                                                "value": "Kanamycin",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "antibiotics",
                                                "showInputLabel": true,
                                                "labelContent": "Media Antibiotics",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "antibiotics_wrapper",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "antibiotics_wrapper",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "cultureVol",
                                                "id": "cultureVol",
                                                "value": "900",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "cultureVol",
                                                "showInputLabel": true,
                                                "labelContent": "Culture Vol(ml)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "culture_Vol",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "culture_Vol",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "rpm",
                                                "id": "rpm",
                                                "value": "200",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "rpm",
                                                "showInputLabel": true,
                                                "labelContent": "RPM",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "rpm_wrapper",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "rpm_wrapper",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "incubationT",
                                                "id": "incubationT",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "incubationT",
                                                "showInputLabel": true,
                                                "labelContent": "Incubation Time(h)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "incubationT_wrapper",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "incubationT_wrapper",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "incubationTmp",
                                                "id": "incubationTmp",
                                                "value": "37",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "incubationTmp",
                                                "showInputLabel": true,
                                                "labelContent": "Incubation Temperature (°C)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "incubationTmp_wrapper",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "incubationTmp_wrapper",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "idod",
                                                "id": "idod",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "idod",
                                                "showInputLabel": true,
                                                "labelContent": "ID OD at 550 nm",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "idod_wrapper",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "idod_wrapper",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "phageTitre",
                                                "id": "phageTitre",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "phageTitre",
                                                "showInputLabel": true,
                                                "labelContent": "Phage Titre(cfu/mL)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "phageTitre_wrapper",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "phageTitre_wrapper",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "radio",
                                                "name": "glycerolstock",
                                                "label": {
                                                    "type": "label",
                                                    "name": "",
                                                    "classname": "",
                                                    "for": "",
                                                    "required": false,
                                                    "wrapperClassName": ""
                                                },
                                                "id": "glycerolstock",
                                                "value": 1,
                                                "required": false,
                                                "classname": "  form-check-wrap",
                                                "errorMessage": {
                                                    "noData": ""
                                                },
                                                "options": [
                                                    {
                                                        "key": 1,
                                                        "label": "Yes"
                                                    },
                                                    {
                                                        "key": 2,
                                                        "label": "No"
                                                    }
                                                ],
                                                "readonly": false,
                                                "validationButtonType": "",
                                                "disabled": false,
                                                "wrapperClassName": "form-check form-check-inline no-border",
                                                "__schema_id": "glycerolstock",
                                                "showInputLabel": true,
                                                "labelContent": "Perform Glycerol stock preparation",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "glycerolstock_wrapper",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "glycerolstock_wrapper",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    }
                                ],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "extraction",
                            "headerBtnClassName": "",
                            "header": "<span>Plaque Growth and Extraction</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "row",
                            "collapseOthers": true
                        }
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false,
                "addBtnPostion": null,
                "isAccordion": true,
                "accordionConfig": {
                    "headerClassName": "",
                    "headerBtnClassName": "",
                    "header": "",
                    "collapseDivClassName": "",
                    "bodyClassName": "",
                    "collapseOthers": true
                }
            }
        ],
        "type": "",
        "classname": "",
        "showClass": true
    }
]


export const WORKFLOWTELECTROCOMPETENTSTRAINGLYCEROL=[
    {
        "control": [],
        "head": "",
        "name": "main",
        "id": "main",
        "wrapperType": "REGULAR",
        "jsonId": 129,
        "fields": [
            {
                "control": [],
                "type": "",
                "name": "wrapper_114",
                "classname": "accordion accordion-custom",
                "wrapperType": "ACCORDION",
                "id": "wrapper_490",
                "fields": [
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_627",
                        "classname": "accordion accordion-flush accordion-custom",
                        "wrapperType": "BOX",
                        "id": "wrapper_152",
                        "fields": [
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_905",
                                "classname": "row",
                                "wrapperType": "BOX",
                                "id": "wrapper_387",
                                "fields": [
                                    {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "starin_name",
                                                "id": "starin_name",
                                                "validationButtonType": "submit",
                                                "classname": "",
                                                "required": false,
                                                "apiEndPoints": [],
                                                "options": [],
                                                "multiple": false,
                                                "isClearable": false,
                                                "isStatus": false,
                                                "search": false,
                                                "singleSelect": true,
                                                "showLabel": "value",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": "",
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "starin_name",
                                                "showInputLabel": true,
                                                "labelContent": "Stain Name",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_914",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_45",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "stock_Id",
                                                "id": "stock_Id",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": true,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "submit",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "stock_Id",
                                                "showInputLabel": true,
                                                "labelContent": "Stock ID",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_324",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_1",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "glycerol",
                                                "id": "glycerol",
                                                "validationButtonType": "submit",
                                                "classname": "",
                                                "required": false,
                                                "apiEndPoints": [],
                                                "options": [
                                                    {
                                                        "id": 1,
                                                        "value": "10 %"
                                                    }
                                                ],
                                                "multiple": false,
                                                "isClearable": false,
                                                "isStatus": false,
                                                "search": false,
                                                "singleSelect": true,
                                                "showLabel": "value",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": {
                                                    "id": 1,
                                                    "value": "10 %"
                                                },
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "glycerol",
                                                "showInputLabel": true,
                                                "labelContent": "Glycerol %",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_78",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_526",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "tube_type",
                                                "id": "tube_type",
                                                "validationButtonType": "submit",
                                                "classname": "",
                                                "required": false,
                                                "apiEndPoints": [],
                                                "options": [
                                                    {
                                                        "id": 1,
                                                        "value": "1.5 ml Eppendorf"
                                                    }
                                                ],
                                                "multiple": true,
                                                "isClearable": false,
                                                "isStatus": false,
                                                "search": false,
                                                "singleSelect": true,
                                                "showLabel": "value",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": {
                                                    "id": 1,
                                                    "value": "1.5 ml Eppendorf"
                                                },
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "tube_type",
                                                "showInputLabel": true,
                                                "labelContent": "Tubetype",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_429",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_588",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "no_of_vials",
                                                "id": "no_of_vials",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": true,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "submit",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [
                                                    {
                                                        "errorMessage": "Please type a valid non zero integer",
                                                        "regularExpression": "/^[1-9][0-9]*$/"
                                                    }
                                                ],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "no_of_vials",
                                                "showInputLabel": true,
                                                "labelContent": "No of Vials",
                                                "labelClass": "form-label mandatory",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_482",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_641",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "vol_per_vial",
                                                "id": "vol_per_vial",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": true,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "submit",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [
                                                    {
                                                        "errorMessage": "Please type a number",
                                                        "regularExpression": "/^\\d+(\\.\\d+)?$/"
                                                    }
                                                ],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "vol_per_vial",
                                                "showInputLabel": true,
                                                "labelContent": "Aliquot volume per vial(ml)",
                                                "labelClass": "form-label mandatory",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_701",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_48",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "total_vol",
                                                "id": "total_vol",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": true,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "total_vol",
                                                "showInputLabel": true,
                                                "labelContent": "Total Volume(ml)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_693",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_270",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    }
                                ],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "",
                            "headerBtnClassName": "",
                            "header": "<span>Bacterial Stocks Info",
                            "collapseDivClassName": "",
                            "bodyClassName": "",
                            "collapseOthers": true
                        }
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false,
                "isAccordion": true,
                "accordionConfig": {
                    "headerClassName": "",
                    "headerBtnClassName": "",
                    "header": "",
                    "collapseDivClassName": "",
                    "bodyClassName": "",
                    "collapseOthers": true
                }
            }
        ],
        "type": "",
        "classname": "",
        "payload_schema": {
            "glycerol_stock": {
                "total_vol": "total_vol.$t{number}",
                "vol_per_vial": "vol_per_vial.$t{number}",
                "no_of_vials": "no_of_vials.$t{number}",
                "tube_type": "tube_type",
                "glycerol": "glycerol",
                "stock_Id": "stock_Id",
                "starin_name": "starin_name"
            }
        },
        "validation_type": "submit"
    }
]