export const STRAIN_ELECTROPORATION = [
    //project intiation
    {
        "id": "main",
        "jsonId": 130,
        "head": "",
        "name": "main",
        "type": "",
        "fields": [
            {
                "id": "wrapper_790",
                "head": "",
                "name": "wrapper_576",
                "type": "",
                "fields": [
                    {
                        "id": "wrapper_808",
                        "head": "",
                        "name": "wrapper_50",
                        "type": "",
                        "fields": [],
                        "control": [
                            {
                                "id": "project_title",
                                "name": "project_title",
                                "__schema_id": "project_title",
                                "rows": 0,
                                "type": "text",
                                "value": "",
                                "columns": 0,
                                "disabled": false,
                                "required": true,
                                "classname": "form-control ",
                                "draggable": false,
                                "multiline": false,
                                "labelClass": "form-label mandatory",
                                "multiError": false,
                                "placeholder": "",
                                "errorMessage": {
                                    "noData": "This field is required",
                                    "regexFail": ""
                                },
                                "isLabelAfter": false,
                                "labelContent": "Project Title",
                                "onDemandError": false,
                                "showInputLabel": true,
                                "applicationError": "",
                                "customValidation": [],
                                "wrapperClassName": "",
                                "regularExpression": "",
                                "requiredErrorMessage": "This field is required",
                                "validationButtonType": "initial-submit",
                                "isAdditonalInputAfter": true,
                                "additionalInputContent": "",
                                "showAdditionalInputContent": false
                            }
                        ],
                        "classname": "col-12 mb-3",
                        "draggable": false,
                        "wrapperType": "BOX",
                        "tabHeadClass": ""
                    },
                    {
                        "id": "wrapper_654",
                        "head": "",
                        "name": "wrapper_290",
                        "type": "",
                        "fields": [],
                        "control": [
                            {
                                "id": "project_init_by",
                                "name": "project_init_by",
                                "__schema_id": "project_init_by",
                                "type": "dropdown",
                                "value": "",
                                "search": true,
                                "disabled": false,
                                "imageUrl": "",
                                "isStatus": false,
                                "multiple": false,
                                "required": true,
                                "classname": "",
                                "draggable": false,
                                "showLabel": "value",
                                "labelClass": "form-label mandatory",
                                "isClearable": false,
                                "apiEndPoints": [
                                    {
                                        "url": "user_management/users_list",
                                        "type": "init",
                                        "method": "get"
                                    }
                                ],
                                "errorMessage": {
                                    "noData": "This field is required"
                                },
                                "isLabelAfter": false,
                                "labelContent": "Initiated By",
                                "singleSelect": true,
                                "showInputLabel": true,
                                "wrapperClassName": "",
                                "validationButtonType": "initial-submit",
                                "isAdditonalInputAfter": true,
                                "additionalInputContent": "",
                                "showAdditionalInputContent": false
                            }
                        ],
                        "classname": "col-12 mb-3",
                        "draggable": false,
                        "wrapperType": "BOX",
                        "tabHeadClass": ""
                    },
                    {
                        "id": "wrapper_778",
                        "head": "",
                        "name": "wrapper_209",
                        "type": "",
                        "fields": [],
                        "control": [
                            {
                                "id": "project_start_date",
                                "name": "project_start_date",
                                "__schema_id": "project_start_date",
                                "type": "datepicker_v2",
                                "range": false,
                                "timer": false,
                                "value": "",
                                "locale": "",
                                "disabled": false,
                                "multiple": false,
                                "required": true,
                                "classname": "",
                                "draggable": false,
                                "dateFormat": "dd/mm/yyyy",
                                "hourFormat": 24,
                                "labelClass": "form-label mandatory",
                                "multiAmount": 3,
                                "placeholder": "DD-MM-YYYY",
                                "dayClassName": "dd",
                                "errorMessage": {
                                    "noData": "This field is required",
                                    "regexFail": "Date is not valid!!"
                                },
                                "isHostParent": false,
                                "isLabelAfter": false,
                                "labelContent": "Start Date",
                                "dateSeperator": "",
                                "keyboardInput": false,
                                "arrowClassName": "arrow",
                                "fordisabledEnd": "",
                                "inputClassName": "form-control date-input",
                                "monthClassName": "mtn",
                                "showInputLabel": true,
                                "todayClassName": "now",
                                "endDateReadOnly": false,
                                "inputDateFormat": false,
                                "fordisabledStart": "",
                                "wrapperClassName": "",
                                "startDateReadOnly": false,
                                "startDatePlaceHolder": "MM/DD/YYYY",
                                "validationButtonType": "initial-submit",
                                "isAdditonalInputAfter": true,
                                "selectedDateClassName": "selectedDate",
                                "additionalInputContent": "",
                                "showAdditionalInputContent": false
                            }
                        ],
                        "classname": "col-6 mb-3",
                        "draggable": false,
                        "wrapperType": "BOX",
                        "tabHeadClass": ""
                    },
                    {
                        "id": "wrapper_92",
                        "head": "",
                        "name": "wrapper_643",
                        "type": "",
                        "fields": [],
                        "control": [
                            {
                                "id": "project_end_date",
                                "name": "project_end_date",
                                "__schema_id": "project_end_date",
                                "type": "datepicker_v2",
                                "range": false,
                                "timer": false,
                                "value": "",
                                "locale": "",
                                "disabled": false,
                                "multiple": false,
                                "required": false,
                                "classname": "",
                                "draggable": false,
                                "dateFormat": "dd/mm/yyyy",
                                "hourFormat": 24,
                                "labelClass": "form-label",
                                "multiAmount": 3,
                                "placeholder": "DD-MM-YYYY",
                                "dayClassName": "dd",
                                "errorMessage": {
                                    "noData": "This field is required",
                                    "regexFail": "Date is not valid!!"
                                },
                                "isHostParent": false,
                                "isLabelAfter": false,
                                "labelContent": "End Date",
                                "dateSeperator": "",
                                "keyboardInput": false,
                                "arrowClassName": "arrow",
                                "fordisabledEnd": "",
                                "inputClassName": "form-control date-input",
                                "monthClassName": "mtn",
                                "showInputLabel": true,
                                "todayClassName": "now",
                                "endDateReadOnly": false,
                                "inputDateFormat": false,
                                "fordisabledStart": "",
                                "wrapperClassName": "",
                                "startDateReadOnly": false,
                                "startDatePlaceHolder": "MM/DD/YYYY",
                                "validationButtonType": "",
                                "isAdditonalInputAfter": true,
                                "selectedDateClassName": "selectedDate",
                                "additionalInputContent": "",
                                "showAdditionalInputContent": false
                            }
                        ],
                        "classname": "col-6 mb-3",
                        "draggable": false,
                        "wrapperType": "BOX",
                        "tabHeadClass": ""
                    },
                    {
                        "control": [
                            {
                                "type": "text",
                                "name": "status",
                                "id": "status",
                                "value": "",
                                "placeholder": "",
                                "multiline": false,
                                "classname": "form-control ",
                                "regularExpression": "",
                                "disabled": true,
                                "required": false,
                                "errorMessage": {
                                    "noData": "This fields is required",
                                    "regexFail": ""
                                },
                                "validationButtonType": "",
                                "rows": 0,
                                "columns": 0,
                                "onDemandError": false,
                                "customValidation": [],
                                "multiError": false,
                                "applicationError": "",
                                "wrapperClassName": "",
                                "requiredErrorMessage": "This fields is required",
                                "__schema_id": "status",
                                "showInputLabel": false,
                                "labelContent": "text_977",
                                "labelClass": "form-label",
                                "isLabelAfter": false,
                                "showAdditionalInputContent": false,
                                "additionalInputContent": "",
                                "isAdditonalInputAfter": true,
                                "draggable": false
                            },
                            {
                                "type": "text",
                                "name": "experiment_type",
                                "id": "experiment_type",
                                "value": "",
                                "placeholder": "",
                                "multiline": false,
                                "classname": "form-control ",
                                "regularExpression": "",
                                "disabled": true,
                                "required": false,
                                "errorMessage": {
                                    "noData": "This fields is required",
                                    "regexFail": ""
                                },
                                "validationButtonType": "",
                                "rows": 0,
                                "columns": 0,
                                "onDemandError": false,
                                "customValidation": [],
                                "multiError": false,
                                "applicationError": "",
                                "wrapperClassName": "",
                                "requiredErrorMessage": "This fields is required",
                                "__schema_id": "experiment_type",
                                "showInputLabel": false,
                                "labelContent": "text_581",
                                "labelClass": "form-label",
                                "isLabelAfter": false,
                                "showAdditionalInputContent": false,
                                "additionalInputContent": "",
                                "isAdditonalInputAfter": true,
                                "draggable": false
                            }
                        ],
                        "type": "",
                        "name": "wrapper_237",
                        "classname": "col-12 d-none",
                        "wrapperType": "BOX",
                        "id": "wrapper_929",
                        "fields": [],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    }
                ],
                "control": [],
                "classname": "row",
                "draggable": false,
                "wrapperType": "BOX",
                "tabHeadClass": ""
            }
        ],
        "control": [],
        "classname": "form-container",
        "wrapperType": "REGULAR",
        "payload_schema": {
            "projectInitDetails": {
                "title": "project_title",
                "init_user": "project_init_by",
                "experiment_type": "experiment_type",
                "start_date": "project_start_date",
                "end_date": "project_end_date",
                "status": "status"
            }
        },
        "validation_type": "initial-submit"
    },
    //bacteria
    {
        "jsonId": 131,
        "control": [],
        "head": "",
        "name": "main",
        "id": "main",
        "wrapperType": "REGULAR",
        "fields": [
            {
                "control": [
                    {
                        "type": "label",
                        "name": "<h2 class=\"add-heading heading-without-icon\"><span>Bacteria Strain</span></h2>",
                        "classname": "w-100",
                        "for": "",
                        "required": false,
                        "wrapperClassName": "",
                        "id": "label_221",
                        "draggable": false
                    }
                ],
                "type": "",
                "name": "wrapper_217",
                "classname": "project-table",
                "wrapperType": "BOX",
                "id": "wrapper_7",
                "fields": [
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_699",
                        "classname": "table-responsive1",
                        "wrapperType": "BOX",
                        "id": "wrapper_691",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "label",
                                        "name": "label_727",
                                        "classname": "",
                                        "for": "",
                                        "required": false,
                                        "wrapperClassName": "",
                                        "id": "label_143",
                                        "draggable": false
                                    },
                                    {
                                        "type": "label",
                                        "name": "label_230",
                                        "classname": "",
                                        "for": "",
                                        "required": false,
                                        "wrapperClassName": "",
                                        "id": "label_863",
                                        "draggable": false
                                    },
                                    {
                                        "type": "text",
                                        "name": "textBox_313",
                                        "id": "textBox_98",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "",
                                        "showInputLabel": true,
                                        "labelContent": "text_0",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "bacteria",
                                "classname": "table bacteria-table-p4",
                                "wrapperType": "TABLE",
                                "id": "lab_consumables",
                                "fields": [
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_605",
                                        "classname": "col-12",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_724",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_924",
                                        "classname": "col-12",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_450",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_726",
                                        "classname": "col-12",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_955",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_434",
                                        "classname": "col-12",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_476",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_97",
                                        "classname": "col-12",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_651",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_293",
                                        "classname": "col-12",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_789",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_803",
                                        "classname": "col-12",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_895",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_289",
                                        "classname": "col-12",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_779",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_431",
                                        "classname": "col-12",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_509",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_613",
                                        "classname": "col-12",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_840",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    }
                                ],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false,
                                "hideSingleItemDelete": false,
                                "tableFields": {
                                    "showHead": true,
                                    "isVertical": false,
                                    "isIteratable": true,
                                    "addButton": {
                                        "classname": "add-expand",
                                        "name": "",
                                        "value": "<div class='custom-tooltip'><p>Add Row</p></div>"
                                    },
                                    "removeButton": {
                                        "classname": "add-minus ",
                                        "name": "",
                                        "value": "<div class='custom-tooltip'><p>Remove Row </p></div>"
                                    },
                                    "tableHead": {
                                        "heading": [
                                            {
                                                "value": "Select Preparation Of<br> Electrocompetent Strains",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Project Name",
                                                "classname": ""
                                            },
                                            {
                                                "value": "bacteria strain",
                                                "classname": ""
                                            },
                                            {
                                                "value": "ANTIBIOTIC RESISTANCE",
                                                "classname": ""
                                            },
                                            {
                                                "value": "glycerol stock id",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Well Selected",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Storage Location",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Action",
                                                "classname": ""
                                            },
                                            {
                                                "value": "",
                                                "classname": ""
                                            }
                                        ],
                                        "classname": ""
                                    },
                                    "initRecordCount": 1,
                                    "showRecordCount": false,
                                    "recordCounterConfig": {
                                        "classname": "",
                                        "adjValue": "",
                                        "isAdjValueAfter": false
                                    },
                                    "tableRow": {
                                        "name": "",
                                        "classname": "trclass",
                                        "tableCols": [
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "dropdown",
                                                            "name": "electrocompetent_strains",
                                                            "id": "electrocompetent_strains",
                                                            "validationButtonType": "",
                                                            "classname": "",
                                                            "required": false,
                                                            "apiEndPoints": [
                                                                {
                                                                    "type": "init",
                                                                    "method": "post",
                                                                    "url": "project/8/instance/search",
                                                                    "payload": {
                                                                        "filter": {
                                                                            "instance_title": "",
                                                                            "instance_status": {
                                                                                "id": 5,
                                                                                "value": ""
                                                                            }
                                                                        }
                                                                    }
                                                                },
                                                                {
                                                                    "type": "change",
                                                                    "method": "get",
                                                                    "url": "project/8/instance/{project_instance_id}/flow/{flow_instance_id}",
                                                                    "Mapto": [
                                                                        {
                                                                            "id": "main.wrapper_7.wrapper_691.lab_consumables.box_90.project_name.${i}",
                                                                            "displayKey": "change",
                                                                            "urlParameterValues": {
                                                                                "id": null,
                                                                                "patchProperty": "attributeData.project_summary.title"
                                                                            }
                                                                        },
                                                                        {
                                                                            "id": "main.wrapper_7.wrapper_691.lab_consumables.${i}.box_584.bacteria_strain",
                                                                            "displayKey": "change",
                                                                            "urlParameterValues": {
                                                                                "id": null,
                                                                                "patchProperty": "attributeData.bacteriaandphage.bacteria[0].inv_item.value"
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ],
                                                            "options": [],
                                                            "multiple": false,
                                                            "isClearable": false,
                                                            "isStatus": false,
                                                            "search": true,
                                                            "singleSelect": true,
                                                            "showLabel": "project_instance_code",
                                                            "errorMessage": {
                                                                "noData": "This fields is required"
                                                            },
                                                            "value": "",
                                                            "imageUrl": "",
                                                            "disabled": false,
                                                            "wrapperClassName": "",
                                                            "connectedArrays": [],
                                                            "showBlankInPlaceholder": false,
                                                            "blankDataList": [],
                                                            "commonPathOfImage": "",
                                                            "__schema_id": "electrocompetent_strains",
                                                            "showInputLabel": false,
                                                            "labelContent": "dropdown_448",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "head": "",
                                                    "name": "box_893",
                                                    "id": "box_760",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "project_name",
                                                            "id": "project_name",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": true,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "project_name",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_479",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_289",
                                                    "id": "box_90",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": "",
                                                    "draggable": false
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "bacteria_strain",
                                                            "id": "bacteria_strain",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": true,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "bacteria_strain",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_466",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_924",
                                                    "id": "box_584",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "antibiotic_resistance",
                                                            "id": "antibiotic_resistance",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": true,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "antibiotic_resistance",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_305",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_12",
                                                    "id": "box_908",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "glycerol_stock_id",
                                                            "id": "glycerol_stock_id",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": true,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "glycerol_stock_id",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_166",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_213",
                                                    "id": "box_293",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "well_selected",
                                                            "id": "well_selected",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": true,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "well_selected",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_159",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        },

                                                        {
                                                            "type": "text",
                                                            "name": "vial_storage",
                                                            "id": "vial_storage",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control d-none",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "vial_storage",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_159",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_214",
                                                    "id": "box_798",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "action-wrap ",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "button",
                                                            "id": "infoicon",
                                                            "value": "View Location",
                                                            "buttonType": "vial_storage",
                                                            "classname": "info-icon",
                                                            "divClass": "",
                                                            "controlEndPoints": [],
                                                            "disabled": false,
                                                            "validationEndPoints": [],
                                                            "invalid": false,
                                                            "name": "infoicon",
                                                            "wrapperClassName": "",
                                                            "draggable": false
                                                        },
                                                        {
                                                            "type": "text",
                                                            "name": "item_details",
                                                            "id": "item_details",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control d-none",
                                                            "regularExpression": "",
                                                            "disabled": true,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "item_details",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_959",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_261",
                                                    "id": "box_126",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "button",
                                                            "id": "button_976",
                                                            "value": "<div class=\"custom-tooltip\"><p>Select Well </p></div>",
                                                            "buttonType": "SEP_Add_Well",
                                                            "classname": "well-icon position-relative well-position",
                                                            "divClass": "",
                                                            "controlEndPoints": [],
                                                            "disabled": false,
                                                            "validationEndPoints": [],
                                                            "invalid": false,
                                                            "name": "button_157",
                                                            "wrapperClassName": "",
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_869",
                                                    "id": "box_306",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            }
                                        ]
                                    }
                                }
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    },
                    {
                        "control": [
                            {
                                "type": "label",
                                "name": "<h2 class=\"add-heading heading-without-icon\"><span>Experiment Notes/Comments</span></h2>",
                                "classname": "w-100",
                                "for": "",
                                "required": false,
                                "wrapperClassName": "",
                                "id": "label_803",
                                "draggable": false
                            }
                        ],
                        "type": "",
                        "name": "wrapper_311",
                        "classname": "col-12",
                        "wrapperType": "BOX",
                        "id": "wrapper_18",
                        "fields": [],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    },
                    {
                        "control": [
                            {
                                "type": "text",
                                "name": "comments",
                                "id": "textBox_547",
                                "value": "",
                                "placeholder": "",
                                "multiline": true,
                                "classname": "form-control ",
                                "regularExpression": "",
                                "disabled": false,
                                "required": false,
                                "errorMessage": {
                                    "noData": "This fields is required",
                                    "regexFail": ""
                                },
                                "validationButtonType": "",
                                "rows": 0,
                                "columns": 0,
                                "onDemandError": false,
                                "customValidation": [],
                                "multiError": false,
                                "applicationError": "",
                                "wrapperClassName": "",
                                "requiredErrorMessage": "This fields is required",
                                "__schema_id": "comments",
                                "showInputLabel": false,
                                "labelContent": "text_510",
                                "labelClass": "form-label",
                                "isLabelAfter": false,
                                "showAdditionalInputContent": false,
                                "additionalInputContent": "",
                                "isAdditonalInputAfter": true,
                                "draggable": false
                            }
                        ],
                        "type": "",
                        "name": "wrapper_747",
                        "classname": "col-12",
                        "wrapperType": "BOX",
                        "id": "wrapper_734",
                        "fields": [],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false
            }
        ],
        "type": "",
        "classname": "",
        "showClass": true,
        "payload_schema": {
            "bacteriaandphage":{
                "bacteria": [
                    {
                        "electrocompetent_strains": "electrocompetent_strains",
                        "project_name": "project_name",
                        "bacteria_strain": "bacteria_strain",
                        "antibiotic_resistance": "antibiotic_resistance",
                        "glycerol_stock_id": "glycerol_stock_id",
                        "well_selected": "well_selected",
                        "vial_storage":"vial_storage"
                    }
                ],
                "comments": "comments"
            } 
        }
    },
    {
        "jsonId": 132,
        "control": [],
        "head": "",
        "name": "main",
        "id": "main",
        "wrapperType": "REGULAR",
        "fields": [
            {
                "control": [],
                "type": "",
                "name": "wrapper_217",
                "classname": "project-table",
                "wrapperType": "BOX",
                "id": "wrapper_7",
                "fields": [
                    {
                        "control": [],
                        "type": "",
                        "name": "lab",
                        "classname": "table reagents-table-p2",
                        "wrapperType": "TABLE",
                        "id": "lab_consumables",
                        "fields": [],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "hideSingleItemDelete": false,
                        "tableFields": {
                            "showHead": true,
                            "isVertical": false,
                            "isIteratable": true,
                            "addButton": {
                                "classname": "add-expand",
                                "name": "",
                                "value": "<div class='custom-tooltip'><p>Add Row</p></div>"
                            },
                            "removeButton": {
                                "classname": "add-minus ",
                                "name": "",
                                "value": "<div class='custom-tooltip'><p>Remove Row </p></div>"
                            },
                            "tableHead": {
                                "heading": [
                                    {
                                        "value": "Select from  Inventory",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Name",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Vendor",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Capacity (ml)",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Units  available",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Units  taken",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Storage Location",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Action",
                                        "classname": ""
                                    },
                                    {
                                        "value": "",
                                        "classname": ""
                                    }
                                ],
                                "classname": ""
                            },
                            "initRecordCount": 1,
                            "showRecordCount": false,
                            "recordCounterConfig": {
                                "classname": "",
                                "adjValue": "",
                                "isAdjValueAfter": false
                            },
                            "tableRow": {
                                "name": "",
                                "classname": "trclass",
                                "tableCols": [
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "dropdown",
                                                    "name": "select_inventory",
                                                    "id": "select_inventory",
                                                    "validationButtonType": "",
                                                    "classname": "",
                                                    "required": false,
                                                    "apiEndPoints": [
                                                        {
                                                            "type": "change",
                                                            "method": "post",
                                                            "url": "inventory_item",
                                                            "Mapto": [
                                                                {
                                                                    "id": "main.wrapper_7.lab_consumables.${i}.box_90.inv_item",
                                                                    "urlParameters": "",
                                                                    "value": "",
                                                                    "payload": {
                                                                        "perma": "plasticwares",
                                                                        "user_input": ""
                                                                    },
                                                                    "type": null
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "type": "init",
                                                            "method": "post",
                                                            "url": "inventory_item/item-type",
                                                            "Mapto": [],
                                                            "payload": {
                                                                "perma": [
                                                                    "assay-kits",
                                                                    "cell-culture-media-antibiotics-agar-plates",
                                                                    "gels-buffers-lab-reagents",
                                                                    "plasticwares",
                                                                    "glasswares",
                                                                    "cell-lines",
                                                                    "bacterial-strains",
                                                                    "plasmids"
                                                                ]
                                                            }
                                                        }
                                                    ],
                                                    "options": [],
                                                    "multiple": false,
                                                    "isClearable": false,
                                                    "isStatus": false,
                                                    "search": true,
                                                    "singleSelect": true,
                                                    "showLabel": "inv_item_type",
                                                    "errorMessage": {
                                                        "noData": "This fields is required"
                                                    },
                                                    "value": "",
                                                    "imageUrl": "",
                                                    "disabled": false,
                                                    "wrapperClassName": "",
                                                    "connectedArrays": [],
                                                    "showBlankInPlaceholder": false,
                                                    "blankDataList": [],
                                                    "commonPathOfImage": "",
                                                    "__schema_id": "select_inventory",
                                                    "showInputLabel": false,
                                                    "labelContent": "dropdown_448",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "head": "",
                                            "name": "box_893",
                                            "id": "box_760",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": ""
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "dropdown",
                                                    "name": "inv_item",
                                                    "id": "inv_item",
                                                    "validationButtonType": "",
                                                    "classname": "",
                                                    "required": false,
                                                    "apiEndPoints": [
                                                        {
                                                            "type": "change",
                                                            "method": "get",
                                                            "url": "inventory_item/${id}",
                                                            "Mapto": [
                                                                {
                                                                    "id": "main.wrapper_7.lab_consumables.${i}.box_584.vendor",
                                                                    "displayKey": "change",
                                                                    "urlParameterValues": {
                                                                        "id": null,
                                                                        "patchProperty": "vendor_name"
                                                                    }
                                                                },
                                                                {
                                                                    "id": "main.wrapper_7.lab_consumables.${i}.box_908.capacity",
                                                                    "displayKey": "change",
                                                                    "urlParameterValues": {
                                                                        "id": null,
                                                                        "patchProperty": "item_volume"
                                                                    }
                                                                },
                                                                {
                                                                    "id": "main.wrapper_7.lab_consumables.${i}.box_293.units_available",
                                                                    "displayKey": "change",
                                                                    "urlParameterValues": {
                                                                        "id": null,
                                                                        "patchProperty": "item_quantity"
                                                                    }
                                                                },
                                                                {
                                                                    "id": "main.wrapper_7.lab_consumables.${i}.box_126.item_details",
                                                                    "displayKey": "item_details",
                                                                    "urlParameterValues": {
                                                                        "id": null
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ],
                                                    "options": [],
                                                    "multiple": false,
                                                    "isClearable": false,
                                                    "isStatus": false,
                                                    "search": true,
                                                    "singleSelect": true,
                                                    "showLabel": "value",
                                                    "errorMessage": {
                                                        "noData": "This fields is required"
                                                    },
                                                    "value": "",
                                                    "imageUrl": "",
                                                    "disabled": false,
                                                    "wrapperClassName": "",
                                                    "connectedArrays": [],
                                                    "showBlankInPlaceholder": false,
                                                    "blankDataList": [],
                                                    "commonPathOfImage": "",
                                                    "__schema_id": "inv_item",
                                                    "showInputLabel": false,
                                                    "labelContent": "dropdown_532",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_289",
                                            "id": "box_90",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": "",
                                            "draggable": false
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "vendor",
                                                    "id": "vendor",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": true,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": false,
                                                    "customValidation": [],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "vendor",
                                                    "showInputLabel": false,
                                                    "labelContent": "text_305",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_924",
                                            "id": "box_584",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": ""
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "capacity",
                                                    "id": "capacity",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": true,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": true,
                                                    "customValidation": [
                                                        {
                                                            "regularExpression": "/^\\d+$/",
                                                            "errorMessage": "Please enter a valid number"
                                                        }
                                                    ],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "capacity",
                                                    "showInputLabel": false,
                                                    "labelContent": "text_166",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_12",
                                            "id": "box_908",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": ""
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "units_available",
                                                    "id": "units_available",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": true,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": true,
                                                    "customValidation": [
                                                        {
                                                            "regularExpression": "/^\\d+$/",
                                                            "errorMessage": "Please enter a valid number"
                                                        }
                                                    ],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "units_available",
                                                    "showInputLabel": false,
                                                    "labelContent": "text_159",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_213",
                                            "id": "box_293",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": ""
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "units_taken",
                                                    "id": "units_taken",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": false,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": true,
                                                    "customValidation": [
                                                        {
                                                            "regularExpression": "/^\\d+$/",
                                                            "errorMessage": "Please enter a valid number"
                                                        }
                                                    ],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "units_taken",
                                                    "showInputLabel": false,
                                                    "labelContent": "text_885",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_214",
                                            "id": "box_798",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "action-wrap ",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": ""
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "button",
                                                    "id": "infoicon",
                                                    "value": "View Location",
                                                    "buttonType": "storage_info",
                                                    "classname": "info-icon",
                                                    "divClass": "",
                                                    "controlEndPoints": [],
                                                    "disabled": false,
                                                    "validationEndPoints": [],
                                                    "invalid": false,
                                                    "name": "infoicon",
                                                    "wrapperClassName": "text-nowrap",
                                                    "draggable": false
                                                },
                                                {
                                                    "type": "text",
                                                    "name": "item_details",
                                                    "id": "item_details",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control d-none",
                                                    "regularExpression": "",
                                                    "disabled": false,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": false,
                                                    "customValidation": [],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "item_details",
                                                    "showInputLabel": false,
                                                    "labelContent": "text_995",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_261",
                                            "id": "box_126",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": " ",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": ""
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [],
                                            "name": "box_564",
                                            "id": "box_24",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": ""
                                        },
                                        "classname": ""
                                    }
                                ]
                            }
                        }
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false
            }
        ],
        "type": "",
        "classname": "",
        "showClass": true,
        "payload_schema": {
            "consumables": [
                {
                    "inv_category": "select_inventory",
                    "inv_item": "inv_item",
                    "vendor": "vendor.$d{}",
                    "vol_capacity": "capacity.$t{number}",
                    "no_unit_available": "units_available.$t{number}",
                    "no_unit_taken": "units_taken.$d{}",
                    "storage": "item_details.{{assigned_storage}}"
                }
            ],
            "inventory__": {
                "itm_qty": [
                    {
                        "inv_item_id": "inv_item.{{id}}",
                        "qntity_taken": "units_taken.$d{}"
                    }
                ]
            }
        }
    },

    {
        "control": [],
        "jsonId": 133,
        "head": "",
        "name": "main",
        "id": "main",
        "wrapperType": "REGULAR",
        "fields": [
            {
                "control": [
                    {
                        "type": "label",
                        "name": "<h2 class=\"add-heading heading-without-icon\"><span>Summary</span></h2>",
                        "classname": "w-100",
                        "for": "",
                        "required": false,
                        "wrapperClassName": "",
                        "id": "label_98",
                        "draggable": false
                    }
                ],
                "type": "",
                "name": "wrapper_847",
                "classname": "col-12",
                "wrapperType": "BOX",
                "id": "wrapper_850",
                "fields": [
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_650",
                        "classname": "row mb-3",
                        "wrapperType": "BOX",
                        "id": "wrapper_860",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "proj_type",
                                        "id": "proj_type",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": true,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "proj_type",
                                        "showInputLabel": true,
                                        "labelContent": "Project Type",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_440",
                                "classname": "col-4",
                                "wrapperType": "BOX",
                                "id": "wrapper_855",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "title",
                                        "id": "title",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": true,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "title",
                                        "showInputLabel": true,
                                        "labelContent": "Project Title",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_457",
                                "classname": "col-4",
                                "wrapperType": "BOX",
                                "id": "wrapper_393",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_195",
                        "classname": "row mb-3",
                        "wrapperType": "BOX",
                        "id": "wrapper_646",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "objective",
                                        "id": "objective",
                                        "value": "To generate phage displayed antibody libraries from randomized dsDNA",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": true,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "objective",
                                        "showInputLabel": true,
                                        "labelContent": "Objective",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    },
                                    {
                                        "type": "text",
                                        "name": "proj_code",
                                        "id": "proj_code",
                                        "value": "SIM1001",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control d-none",
                                        "regularExpression": "",
                                        "disabled": true,
                                        "required": true,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "proj_code",
                                        "showInputLabel": false,
                                        "labelContent": "Objective",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_413",
                                "classname": "col-12",
                                "wrapperType": "BOX",
                                "id": "wrapper_270",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_834",
                                "classname": "col-8",
                                "wrapperType": "BOX",
                                "id": "wrapper_697",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    },
                    {
                        "control": [
                            {
                                "type": "text",
                                "name": "comments",
                                "id": "comments",
                                "value": "",
                                "placeholder": "Enter your comments if any",
                                "multiline": true,
                                "classname": "form-control ",
                                "regularExpression": "",
                                "disabled": false,
                                "required": false,
                                "errorMessage": {
                                    "noData": "This fields is required",
                                    "regexFail": ""
                                },
                                "validationButtonType": "",
                                "rows": 0,
                                "columns": 0,
                                "onDemandError": false,
                                "customValidation": [],
                                "multiError": false,
                                "applicationError": "",
                                "wrapperClassName": "",
                                "requiredErrorMessage": "This fields is required",
                                "__schema_id": "comments",
                                "showInputLabel": true,
                                "labelContent": "Experiment Notes/Comments",
                                "labelClass": "form-label",
                                "isLabelAfter": false,
                                "showAdditionalInputContent": false,
                                "additionalInputContent": "",
                                "isAdditonalInputAfter": true,
                                "draggable": false
                            }
                        ],
                        "type": "",
                        "name": "wrapper_269",
                        "classname": "col-12",
                        "wrapperType": "BOX",
                        "id": "wrapper_521",
                        "fields": [],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false
            }
        ],
        "type": "",
        "classname": "",
        "showClass": true,
        "payload_schema": {
            "project_summary": {
                "title": "title",
                "proj_type": "proj_type",
                "objective": "objective",
                "comments": "comments"
            }
        },
        "validation_type": "submit"
    }
]

export const STRAIN_ELECTROPORATION_WORKFLOW = [
    {
        "jsonId": 134,
        "control": [],
        "head": "",
        "name": "main",
        "id": "main",
        "wrapperType": "REGULAR",
        "fields": [
            {
                "control": [],
                "type": "",
                "name": "wrapper_156",
                "classname": "row",
                "wrapperType": "BOX",
                "id": "wrapper_880",
                "fields": [
                    {
                        "control": [
                            {
                                "type": "dropdown",
                                "name": "protocol_id",
                                "id": "selectProtocol",
                                "validationButtonType": "",
                                "classname": "mb-3",
                                "required": false,
                                "apiEndPoints": [
                                    {
                                        "type": "init",
                                        "method": "get",
                                        "url": "protocol/protocol-list"
                                    },
                                    {
                                        "type": "change",
                                        "method": "get",
                                        "Mapto": [
                                            {
                                                "id": "main.wrapper_880.wrapper_90.protocol_file_id_1",
                                                "displayKey": "change",
                                                "urlParameterValues": {
                                                    "id": null,
                                                    "patchProperty": "protocol_file_id"
                                                }
                                            }
                                        ],
                                        "url": "protocol/${id}"
                                    }
                                ],
                                "options": [],
                                "multiple": false,
                                "isClearable": false,
                                "isStatus": false,
                                "search": false,
                                "singleSelect": true,
                                "showLabel": "value",
                                "errorMessage": {
                                    "noData": "This fields is required"
                                },
                                "value": "",
                                "imageUrl": "",
                                "disabled": false,
                                "wrapperClassName": "",
                                "connectedArrays": [],
                                "showBlankInPlaceholder": false,
                                "blankDataList": [],
                                "commonPathOfImage": "",
                                "__schema_id": "protocol_id",
                                "showInputLabel": true,
                                "labelContent": "Select Protocol",
                                "labelClass": "form-label",
                                "isLabelAfter": false,
                                "showAdditionalInputContent": false,
                                "additionalInputContent": "",
                                "isAdditonalInputAfter": true,
                                "draggable": false
                            },
                            {
                                "type": "text",
                                "name": "protocol_file_id",
                                "id": "protocol_file_id_1",
                                "value": "",
                                "placeholder": "",
                                "multiline": false,
                                "classname": "form-control d-none",
                                "regularExpression": "",
                                "disabled": true,
                                "required": false,
                                "errorMessage": {
                                    "noData": "This fields is required",
                                    "regexFail": ""
                                },
                                "validationButtonType": "",
                                "rows": 0,
                                "columns": 0,
                                "onDemandError": false,
                                "customValidation": [],
                                "multiError": false,
                                "applicationError": "",
                                "wrapperClassName": "",
                                "requiredErrorMessage": "This fields is required",
                                "__schema_id": "protocol_file_id",
                                "showInputLabel": false,
                                "labelContent": "Bacteria Strain",
                                "labelClass": "form-label mandatory ",
                                "isLabelAfter": false,
                                "showAdditionalInputContent": false,
                                "additionalInputContent": "",
                                "isAdditonalInputAfter": true,
                                "draggable": false
                            },
                            {
                                "type": "button",
                                "id": "button_878",
                                "value": "<div class=\"custom-tooltip\"><p>View </p></div>",
                                "buttonType": "view-protocol",
                                "classname": "view icon-align ms-3",
                                "divClass": "",
                                "controlEndPoints": [],
                                "disabled": false,
                                "validationEndPoints": [],
                                "invalid": false,
                                "name": "button_935",
                                "wrapperClassName": "actions position-relative ",
                                "draggable": false
                            }
                        ],
                        "type": "",
                        "name": "wrapper_652",
                        "classname": "col-3 mb-3 action-wrap",
                        "wrapperType": "BOX",
                        "id": "wrapper_90",
                        "fields": [],
                        "head": "",
                        "tabHeadClass": ""
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false
            },
            {
                "control": [],
                "type": "",
                "name": "wrapper_273",
                "classname": "col-12",
                "wrapperType": "BOX",
                "id": "wrapper_205",
                "fields": [
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_347",
                        "classname": "row",
                        "wrapperType": "BOX",
                        "id": "wrapper_235",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "no_of_Electroporation",
                                        "id": "no_of_Electroporation",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "no_of_Electroporation",
                                        "showInputLabel": true,
                                        "labelContent": "No.of Electroporation to be done",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_491",
                                "classname": "col-3 mb-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_647",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_583",
                                "classname": "col-9 mb-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_96",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false
            },
            {
                "control": [],
                "type": "",
                "name": "wrapper_983",
                "classname": "accordion accordion-custom",
                "wrapperType": "ACCORDION",
                "id": "wrapper_197",
                "fields": [
                    {
                        "control": [],
                        "type": "",
                        "name": "strainCulture",
                        "classname": "accordion accordion-flush accordion-custom",
                        "wrapperType": "BOX",
                        "id": "strainCulture",
                        "fields": [
                            {
                                "control": [],
                                "type": "",
                                "name": "ccc_dsdna_info",
                                "classname": "row project-table scrollable",
                                "wrapperType": "REGULAR",
                                "id": "wrapper_67",
                                "fields": [
                                    {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "ccc_dsDNA",
                                                "id": "CultureMedai",
                                                "validationButtonType": "",
                                                "classname": "",
                                                "required": false,
                                                "apiEndPoints": [
                                                    {
                                                        "type": "init",
                                                        "method": "post",
                                                        "url": "project/7/instance/search",
                                                        "payload": {
                                                            filter: {
                                                                "instance_title": "",
                                                                "instance_status": {
                                                                    "id": 5,
                                                                    "value": "Project Completed",
                                                                    "className": "project-completed"
                                                                }
                                                            }
                                                        }
                                                    }
                                                ],
                                                "options": [],
                                                "multiple": false,
                                                "isClearable": false,
                                                "isStatus": false,
                                                "search": false,
                                                "singleSelect": true,
                                                "showLabel": "project_instance_code",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": "",
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "ccc_dsDNA",
                                                "showInputLabel": true,
                                                "labelContent": "Import CCC dsDNA Data",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_300",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_293",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "project_title",
                                                "id": "ProjectTitle_465",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": true,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "project_title",
                                                "showInputLabel": true,
                                                "labelContent": "Project Title",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_889",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_670",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_553",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_850",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_685",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_27",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "template_info",
                                        "classname": "table table-top-align dsdna-info-table",
                                        "wrapperType": "TABLE",
                                        "id": "wrapper_664",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false,
                                        "hideSingleItemDelete": false,
                                        "tableFields": {
                                            "showHead": true,
                                            "isVertical": false,
                                            "isIteratable": false,
                                            "addButton": {
                                                "classname": "btn btn-primary",
                                                "name": "",
                                                "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                            },
                                            "removeButton": {
                                                "classname": "btn btn-danger",
                                                "name": "",
                                                "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                            },
                                            "tableHead": {
                                                "heading": [
                                                    {
                                                        "value": "Template <span class=\"text-initial\">ss</span>dna ID",
                                                        "classname": ""
                                                    },
                                                    {
                                                        "value": "CDRs",
                                                        "classname": ""
                                                    },
                                                    {
                                                        "value": "CCC-<span class=\"text-initial\">ds</span>DNA Name",
                                                        "classname": ""
                                                    },
                                                    {
                                                        "value": "Eluent Volume <span class=\"text-initial\">(μL)</span>",
                                                        "classname": ""
                                                    },
                                                    {
                                                        "value": "DNA Conc  <span class=\"text-initial\">(ng/μL)</span>",
                                                        "classname": ""
                                                    },
                                                    {
                                                        "value": "Total Yield <span class=\"text-initial\">(ng)</span>",
                                                        "classname": ""
                                                    },
                                                    {
                                                        "value": "Vol used <span class=\"text-initial\">(μL)</span>",
                                                        "classname": ""
                                                    }
                                                ],
                                                "classname": ""
                                            },
                                            "initRecordCount": 1,
                                            "showRecordCount": false,
                                            "recordCounterConfig": {
                                                "classname": "",
                                                "adjValue": "",
                                                "isAdjValueAfter": false
                                            },
                                            "tableRow": {
                                                "name": "",
                                                "classname": "trclass",
                                                "tableCols": [
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "template_ssdna_id",
                                                                    "id": "template_ssdna_id",
                                                                    "value": "",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": true,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "__schema_id": "template_ssdna_id",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_886",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "head": "",
                                                            "name": "box_426",
                                                            "id": "box_767",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": ""
                                                        },
                                                        "classname": ""
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "cdrs",
                                                                    "id": "cdrs",
                                                                    "value": "",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": true,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "__schema_id": "cdrs",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_616",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "cdrs",
                                                            "id": "box_534",
                                                            "wrapperType": "ITERATE",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "",
                                                            "addButton": {
                                                                "classname": "d-none",
                                                                "name": "",
                                                                "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                                            },
                                                            "removeButton": {
                                                                "classname": "d-none",
                                                                "name": "",
                                                                "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                                            },
                                                            "wrapperClassName": "",
                                                            "ButtonPosition": "top",
                                                            "controlButtonClass": "",
                                                            "hideSingleItemDelete": false,
                                                            "addBtnPostion": null
                                                        },
                                                        "classname": ""
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "ccc_dsdna_name",
                                                                    "id": "textBox_69",
                                                                    "value": "",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": true,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "__schema_id": "ccc_dsDNA_name",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_781",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "box_820",
                                                            "id": "box_60",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "",
                                                            "addButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "removeButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "wrapperClassName": ""
                                                        },
                                                        "classname": ""
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "eluent_vol",
                                                                    "id": "textBox_126",
                                                                    "value": "",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": true,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "__schema_id": "eluent_vol",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_771",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "box_325",
                                                            "id": "box_222",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "",
                                                            "addButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "removeButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "wrapperClassName": ""
                                                        },
                                                        "classname": ""
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "dna_conc",
                                                                    "id": "textBox_300",
                                                                    "value": "",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": true,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "__schema_id": "dna_conc",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_180",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "box_874",
                                                            "id": "box_299",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "",
                                                            "addButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "removeButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "wrapperClassName": ""
                                                        },
                                                        "classname": ""
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "total_yield",
                                                                    "id": "textBox_303",
                                                                    "value": "",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": true,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "__schema_id": "total_yield",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_965",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "box_263",
                                                            "id": "box_236",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "",
                                                            "addButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "removeButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "wrapperClassName": ""
                                                        },
                                                        "classname": ""
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "vol_used",
                                                                    "id": "textBox_795",
                                                                    "value": "",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": false,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "__schema_id": "vol_used",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_15",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "box_21",
                                                            "id": "box_744",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "",
                                                            "addButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "removeButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "wrapperClassName": ""
                                                        },
                                                        "classname": ""
                                                    }
                                                ]
                                            }
                                        }
                                    }
                                ],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "",
                            "headerBtnClassName": "",
                            "header": "<span>CCC dsDNA Info</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "row",
                            "collapseOthers": true
                        }
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_115",
                        "classname": "col-12 project-table",
                        "wrapperType": "BOX",
                        "id": "wrapper_98",
                        "fields": [
                            {
                                "control": [],
                                "type": "",
                                "name": "electroporation",
                                "classname": "row",
                                "wrapperType": "REGULAR",
                                "id": "wrapper_306",
                                "fields": [
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "bacteria_strain",
                                                "id": "BacteriaStrain",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "bacteria_strain",
                                                "showInputLabel": true,
                                                "labelContent": "Bacteria Strain",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_914",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_911",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "cell_vol",
                                                "id": "CellVol",
                                                "value": 350,
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "cell_vol",
                                                "showInputLabel": true,
                                                "labelContent": "Cell Vol (μL)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_394",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_557",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "curette_type",
                                                "id": "CuretteType",
                                                "value": " 0.2cm Gap",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "curette_type",
                                                "showInputLabel": true,
                                                "labelContent": "Cuvette Type",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_275",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_536",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "antibiotics_resistance_of_strain",
                                                "id": "AntibioticsResistanceOfStrain",
                                                "validationButtonType": "",
                                                "classname": "",
                                                "required": false,
                                                "apiEndPoints": [],
                                                "options": [
                                                    {
                                                        "key": "Tetracycline",
                                                        "value": "Tetracycline"
                                                    },
                                                    {
                                                        "key": "Kanamycin",
                                                        "value": "Kanamycin"
                                                    }
                                                ],
                                                "multiple": true,
                                                "isClearable": true,
                                                "isStatus": false,
                                                "search": false,
                                                "singleSelect": false,
                                                "showLabel": "value",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": [
                                                    {
                                                        "key": "Tetracycline",
                                                        "value": "Tetracycline"
                                                    },
                                                    {
                                                        "key": "Kanamycin",
                                                        "value": "Kanamycin"
                                                    }
                                                ],
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "antibiotics_resistance_of_strain",
                                                "showInputLabel": true,
                                                "labelContent": "Antibiotics Resistance Of Strain",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_286",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_403",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "instrument",
                                                "id": "Instrument_300",
                                                "validationButtonType": "",
                                                "classname": "",
                                                "required": false,
                                                "apiEndPoints": [
                                                    {
                                                        "type": "init",
                                                        "method": "post",
                                                        "url": "inventory_item",
                                                        "payload": {
                                                            "user_input": "",
                                                            "perma": "instruments"
                                                        }
                                                    }
                                                ],
                                                "options": [],
                                                "multiple": false,
                                                "isClearable": false,
                                                "isStatus": false,
                                                "search": false,
                                                "singleSelect": true,
                                                "showLabel": "value",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": "",
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "instrument",
                                                "showInputLabel": true,
                                                "labelContent": "Instrument",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_464",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_382",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "voltage",
                                                "id": "Voltage_kv",
                                                "value": 2.5,
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "voltage",
                                                "showInputLabel": true,
                                                "labelContent": "Voltage (kV)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_487",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_391",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "resistance",
                                                "id": "Resistance_Ohm",
                                                "value": 129,
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "resistance",
                                                "showInputLabel": true,
                                                "labelContent": "Resistance (Ohm)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_538",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_123",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "capacitance",
                                                "id": "Capacitance_μF",
                                                "value": 50,
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "capacitance",
                                                "showInputLabel": true,
                                                "labelContent": "Capacitance (μF)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_516",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_508",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    }
                                ],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "plague-formation",
                            "headerBtnClassName": "",
                            "header": "<span>Electroporation</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "row",
                            "collapseOthers": true
                        }
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_620",
                        "classname": "col-12",
                        "wrapperType": "BOX",
                        "id": "wrapper_723",
                        "fields": [
                            {
                                "control": [],
                                "type": "",
                                "name": "electroporated_cell_rescue",
                                "classname": "row",
                                "wrapperType": "REGULAR",
                                "id": "wrapper_439",
                                "fields": [
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "soc_media_vol",
                                                "id": "SOC_Media_Vol",
                                                "value": 25,
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "soc_media_vol",
                                                "showInputLabel": true,
                                                "labelContent": "SOC Media Vol(ml)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_587",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_116",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "container_type",
                                                "id": "ContainerType",
                                                "validationButtonType": "",
                                                "classname": "",
                                                "required": false,
                                                "apiEndPoints": [],
                                                "options": [],
                                                "multiple": false,
                                                "isClearable": false,
                                                "isStatus": false,
                                                "search": false,
                                                "singleSelect": true,
                                                "showLabel": "value",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": {
                                                  "key": "96 well plate",
                                                  "value": "96 well plate"
                                                },
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "container_type",
                                                "showInputLabel": true,
                                                "labelContent": "Container Type",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_900",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_603",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "rpm",
                                                "id": "RPM_388",
                                                "value": 200,
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "rpm",
                                                "showInputLabel": true,
                                                "labelContent": "RPM",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_585",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_503",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "incubation_time",
                                                "id": "IncubationTime_hr",
                                                "value": 0.5,
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "incubation_time",
                                                "showInputLabel": true,
                                                "labelContent": "Incubation Time (hr)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_314",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_538",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": ""
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "incubation_temperature",
                                                "id": "IncubationTemperature",
                                                "value": 37,
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "incubation_temperature",
                                                "showInputLabel": true,
                                                "labelContent": "Incubation Temperature (°C)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_287",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_841",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_562",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_465",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_401",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_933",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_5",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_128",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    }
                                ],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "extraction",
                            "headerBtnClassName": "",
                            "header": "<span>Electroporated Cell Rescue</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "row",
                            "collapseOthers": true
                        }
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_635",
                        "classname": "col-12 scrollable",
                        "wrapperType": "BOX",
                        "id": "wrapper_724",
                        "fields": [
                            {
                                "control": [],
                                "type": "",
                                "name": "titration_of_library_diversity",
                                "classname": "col-12 project-table mb-4",
                                "wrapperType": "REGULAR",
                                "id": "wrapper_183",
                                "fields": [
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_893",
                                        "classname": "row mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_424",
                                        "fields": [
                                            {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "no_of_plate",
                                                        "id": "No_ofPlate",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "no_of_plate",
                                                        "showInputLabel": true,
                                                        "labelContent": "No.of Plates",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "type": "",
                                                "name": "wrapper_122",
                                                "classname": "col-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_743",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            },
                                            {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "dilution_factor",
                                                        "id": "DilutionFactor",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "dilution_factor",
                                                        "showInputLabel": true,
                                                        "labelContent": "Dilution Factor",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "type": "",
                                                "name": "wrapper_599",
                                                "classname": "col-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_225",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            },
                                            {
                                                "control": [],
                                                "type": "",
                                                "name": "wrapper_43",
                                                "classname": "col-6",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_741",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            }
                                        ],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "titration_info",
                                        "classname": "table titration-library-table",
                                        "wrapperType": "TABLE",
                                        "id": "wrapper_140",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false,
                                        "hideSingleItemDelete": false,
                                        "tableFields": {
                                            "showHead": true,
                                            "isVertical": false,
                                            "isIteratable": false,
                                            "addButton": {
                                                "classname": "btn btn-primary",
                                                "name": "",
                                                "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                            },
                                            "removeButton": {
                                                "classname": "btn btn-danger",
                                                "name": "",
                                                "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                            },
                                            "tableHead": {
                                                "heading": [
                                                    {
                                                        "value": "Plate ID",
                                                        "classname": ""
                                                    },
                                                    {
                                                        "value": "No.of wells used",
                                                        "classname": ""
                                                    },
                                                    {
                                                        "value": "Sub Library ID",
                                                        "classname": ""
                                                    },
                                                    {
                                                        "value": "Library Vol <span class=\"text-initial\">(µL)</span>",
                                                        "classname": ""
                                                    },
                                                    {
                                                        "value": "No.of colonies Obtained",
                                                        "classname": ""
                                                    },
                                                    {
                                                        "value": "Library Diversity",
                                                        "classname": ""
                                                    },
                                                    {
                                                        "value": "Action",
                                                        "classname": ""
                                                    }
                                                ],
                                                "classname": ""
                                            },
                                            "initRecordCount": 1,
                                            "showRecordCount": false,
                                            "recordCounterConfig": {
                                                "classname": "",
                                                "adjValue": "",
                                                "isAdjValueAfter": false
                                            },
                                            "tableRow": {
                                                "name": "",
                                                "classname": "trclass",
                                                "tableCols": [
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "plate_id",
                                                                    "id": "textBox_852",
                                                                    "value": "",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": false,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "__schema_id": "plate_id",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_610",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "head": "",
                                                            "name": "box_149",
                                                            "id": "box_368",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": ""
                                                        },
                                                        "classname": ""
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "no_of_wells_used",
                                                                    "id": "textBox_295",
                                                                    "value": "",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": false,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "__schema_id": "no_of_wells_used",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_401",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "box_561",
                                                            "id": "box_518",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "",
                                                            "addButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "removeButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "wrapperClassName": ""
                                                        },
                                                        "classname": ""
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "sub_library_id",
                                                                    "id": "textBox_501",
                                                                    "value": "",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": false,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "__schema_id": "sub_library_id",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_668",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "box_326",
                                                            "id": "box_403",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "",
                                                            "addButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "removeButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "wrapperClassName": ""
                                                        },
                                                        "classname": ""
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "library_vol",
                                                                    "id": "textBox_865",
                                                                    "value": "",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": false,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "__schema_id": "library_vol",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_660",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "box_467",
                                                            "id": "box_835",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "",
                                                            "addButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "removeButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "wrapperClassName": ""
                                                        },
                                                        "classname": ""
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "no_of_colonies_obtained",
                                                                    "id": "textBox_210",
                                                                    "value": "",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": false,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "__schema_id": "no_of_colonies_obtained",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_169",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "box_638",
                                                            "id": "box_560",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "",
                                                            "addButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "removeButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "wrapperClassName": ""
                                                        },
                                                        "classname": ""
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "library_diversity",
                                                                    "id": "textBox_585",
                                                                    "value": "",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": false,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "__schema_id": "library_diversity",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_816",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "box_550",
                                                            "id": "box_420",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "",
                                                            "addButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "removeButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "wrapperClassName": ""
                                                        },
                                                        "classname": ""
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "button",
                                                                    "id": "button_920",
                                                                    "value": "<div class='custom-tooltip'><p>Upload library diversity Titration File</p></div>",
                                                                    "buttonType": "titration_lib_div_file_upload",
                                                                    "classname": "upload-blue me-3",
                                                                    "divClass": "",
                                                                    "controlEndPoints": [],
                                                                    "disabled": false,
                                                                    "validationEndPoints": [],
                                                                    "invalid": false,
                                                                    "name": "button_311",
                                                                    "wrapperClassName": "",
                                                                    "draggable": false
                                                                },
                                                                {
                                                                    "type": "text",
                                                                    "name": "titration_file_id",
                                                                    "id": "sep_file_id",
                                                                    "value": "",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control d-none",
                                                                    "regularExpression": "",
                                                                    "disabled": false,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "__schema_id": "titration_file_id",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_816",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                },
                                                                {
                                                                    "type": "button",
                                                                    "id": "button_877",
                                                                    "value": "<div class='custom-tooltip small-tooltip'><p>View</p></div>",
                                                                    "buttonType": "sep_file_view",
                                                                    "classname": "plasmid-view",
                                                                    "divClass": "",
                                                                    "controlEndPoints": [],
                                                                    "disabled": false,
                                                                    "validationEndPoints": [],
                                                                    "invalid": false,
                                                                    "name": "button_164",
                                                                    "wrapperClassName": "",
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "box_526",
                                                            "id": "box_677",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "action-wrap",
                                                            "addButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "removeButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "wrapperClassName": ""
                                                        },
                                                        "classname": ""
                                                    }
                                                ]
                                            }
                                        }
                                    }
                                ],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "",
                            "headerBtnClassName": "",
                            "header": "<span>Titration of Library Diversity</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "",
                            "collapseOthers": true
                        }
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_271",
                        "classname": "col-12",
                        "wrapperType": "BOX",
                        "id": "wrapper_356",
                        "fields": [
                            {
                                "control": [],
                                "type": "",
                                "name": "phage_library_amplification",
                                "classname": "project-table",
                                "wrapperType": "REGULAR",
                                "id": "wrapper_659",
                                "fields": [
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "phage_library_amplification_info",
                                        "classname": "table phage-library-table",
                                        "wrapperType": "TABLE",
                                        "id": "wrapper_230",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false,
                                        "hideSingleItemDelete": true,
                                        "tableFields": {
                                            "showHead": true,
                                            "isVertical": false,
                                            "isIteratable": false,
                                            "addButton": {
                                                "classname": "add-expand",
                                                "name": "",
                                                "value": "<div class='custom-tooltip'><p>Add Row</p></div>"
                                            },
                                            "removeButton": {
                                                "classname": "add-minus ",
                                                "name": "",
                                                "value": "<div class='custom-tooltip'><p>Remove Row </p></div>"
                                            },
                                            "tableHead": {
                                                "heading": [
                                                    {
                                                        "value": "Select the sub libraries/electroporation",
                                                        "classname": ""
                                                    },
                                                    {
                                                        "value": "Library ID",
                                                        "classname": ""
                                                    },
                                                    {
                                                        "value": "Library Name",
                                                        "classname": ""
                                                    },
                                                    {
                                                        "value": "No.Of colonies obtained",
                                                        "classname": ""
                                                    },
                                                    {
                                                        "value": "Action",
                                                        "classname": ""
                                                    }
                                                ],
                                                "classname": ""
                                            },
                                            "initRecordCount": 1,
                                            "showRecordCount": false,
                                            "recordCounterConfig": {
                                                "classname": "",
                                                "adjValue": "",
                                                "isAdjValueAfter": false
                                            },
                                            "tableRow": {
                                                "name": "",
                                                "classname": "trclass",
                                                "tableCols": [
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "dropdown",
                                                                    "name": "sub_libraries",
                                                                    "id": "dropDown_138",
                                                                    "validationButtonType": "",
                                                                    "classname": "",
                                                                    "required": false,
                                                                    "apiEndPoints": [
                                                                        {
                                                                            "type": "init",
                                                                            "method": "sub_libraries_patch",
                                                                        }
                                                                    ],
                                                                    "options": [],
                                                                    "multiple": true,
                                                                    "isClearable": false,
                                                                    "isStatus": false,
                                                                    "search": false,
                                                                    "singleSelect": true,
                                                                    "showLabel": "",
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required"
                                                                    },
                                                                    "value": "",
                                                                    "imageUrl": "",
                                                                    "disabled": false,
                                                                    "wrapperClassName": "",
                                                                    "connectedArrays": ['main.wrapper_197.wrapper_356.wrapper_659.wrapper_230.*.box_585.dropDown_138'],
                                                                    "showBlankInPlaceholder": false,
                                                                    "blankDataList": [],
                                                                    "commonPathOfImage": "",
                                                                    "__schema_id": "sub_libraries",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "dropdown_700",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "head": "",
                                                            "name": "box_452",
                                                            "id": "box_585",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": ""
                                                        },
                                                        "classname": ""
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "library_id",
                                                                    "id": "textBox_974",
                                                                    "value": "",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": false,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "__schema_id": "library_id",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_232",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "box_293",
                                                            "id": "box_707",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "",
                                                            "addButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "removeButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "wrapperClassName": ""
                                                        },
                                                        "classname": ""
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "library_name",
                                                                    "id": "textBox_147",
                                                                    "value": "",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": false,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "__schema_id": "library_name",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_658",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "box_976",
                                                            "id": "box_362",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "",
                                                            "addButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "removeButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "wrapperClassName": ""
                                                        },
                                                        "classname": ""
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "text",
                                                                    "name": "no_of_colonies_obtained",
                                                                    "id": "textBox_659",
                                                                    "value": "",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control ",
                                                                    "regularExpression": "",
                                                                    "disabled": false,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "__schema_id": "no_of_colonies_obtained",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_825",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "box_244",
                                                            "id": "box_578",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "",
                                                            "addButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "removeButton": {
                                                                "classname": "",
                                                                "value": ""
                                                            },
                                                            "wrapperClassName": ""
                                                        },
                                                        "classname": ""
                                                    },
                                                    {
                                                        "fields": {
                                                            "control": [
                                                                {
                                                                    "type": "button",
                                                                    "id": "button_85",
                                                                    "value": "<div class='custom-tooltip'><p>Upload</p></div>",
                                                                    "buttonType": "phage_lib_amp_file_upload",
                                                                    "classname": "upload-blue me-3",
                                                                    "divClass": "",
                                                                    "controlEndPoints": [],
                                                                    "disabled": false,
                                                                    "validationEndPoints": [],
                                                                    "invalid": false,
                                                                    "name": "button_651",
                                                                    "wrapperClassName": "",
                                                                    "draggable": false
                                                                },
                                                                {
                                                                    "type": "text",
                                                                    "name": "sep_file_id",
                                                                    "id": "phage_lib_amp_file_id",
                                                                    "value": "",
                                                                    "placeholder": "",
                                                                    "multiline": false,
                                                                    "classname": "form-control d-none",
                                                                    "regularExpression": "",
                                                                    "disabled": false,
                                                                    "required": false,
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required",
                                                                        "regexFail": ""
                                                                    },
                                                                    "validationButtonType": "",
                                                                    "rows": 0,
                                                                    "columns": 0,
                                                                    "onDemandError": false,
                                                                    "customValidation": [],
                                                                    "multiError": false,
                                                                    "applicationError": "",
                                                                    "wrapperClassName": "",
                                                                    "requiredErrorMessage": "This fields is required",
                                                                    "__schema_id": "no_of_colonies_obtained",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "text_825",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                },
                                                                {
                                                                    "type": "button",
                                                                    "id": "button_98",
                                                                    "value": "<div class='custom-tooltip small-tooltip'><p>View</p></div>",
                                                                    "buttonType": "sep_file_view",
                                                                    "classname": "plasmid-view",
                                                                    "divClass": "",
                                                                    "controlEndPoints": [],
                                                                    "disabled": false,
                                                                    "validationEndPoints": [],
                                                                    "invalid": false,
                                                                    "name": "button_386",
                                                                    "wrapperClassName": "",
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "name": "box_20",
                                                            "id": "box_879",
                                                            "wrapperType": "BOX",
                                                            "fields": [],
                                                            "type": "",
                                                            "classname": "action-wrap",
                                                            "addButton": {
                                                                "classname": "",
                                                                "value": "add",
                                                            },
                                                            "removeButton": {
                                                                "classname": "",
                                                                "value": "remove"
                                                            },
                                                            "wrapperClassName": ""
                                                        },
                                                        "classname": ""
                                                    }
                                                ]
                                            }
                                        }
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_985",
                                        "classname": "row mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_132",
                                        "fields": [
                                            {
                                                "control": [
                                                    {
                                                        "type": "dropdown",
                                                        "name": "phage_media_antibiotics",
                                                        "id": "phage_MediaAntibiotics",
                                                        "validationButtonType": "",
                                                        "classname": "",
                                                        "required": false,
                                                        "apiEndPoints": [],
                                                        "options": [
                                                            {
                                                                "key": "Kanamycin",
                                                                "value": "Kanamycin"
                                                            },
                                                            {
                                                                "key": "Carbenicillin",
                                                                "value": "Carbenicillin"
                                                            }
                                                        ],
                                                        "multiple": true,
                                                        "isClearable": true,
                                                        "isStatus": false,
                                                        "search": false,
                                                        "singleSelect": false,
                                                        "showLabel": "value",
                                                        "errorMessage": {
                                                            "noData": "This fields is required"
                                                        },
                                                        "value": [
                                                            {
                                                                "key": "Kanamycin",
                                                                "value": "Kanamycin"
                                                            },
                                                            {
                                                                "key": "Carbenicillin",
                                                                "value": "Carbenicillin"
                                                            }
                                                        ],
                                                        "imageUrl": "",
                                                        "disabled": false,
                                                        "wrapperClassName": "",
                                                        "connectedArrays": [],
                                                        "showBlankInPlaceholder": false,
                                                        "blankDataList": [],
                                                        "commonPathOfImage": "",
                                                        "__schema_id": "phage_media_antibiotics",
                                                        "showInputLabel": true,
                                                        "labelContent": "Phage / Media Antibiotics",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "type": "",
                                                "name": "wrapper_69",
                                                "classname": "col-3 mb-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_639",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            },
                                            {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "media_vol",
                                                        "id": "MediaVol_ml",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "media_vol",
                                                        "showInputLabel": true,
                                                        "labelContent": "Media Vol (ml)",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "type": "",
                                                "name": "wrapper_701",
                                                "classname": "col-3 mb-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_685",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            },
                                            // {
                                            //     "control": [
                                            //         {
                                            //             "type": "text",
                                            //             "name": "growth_media_used",
                                            //             "id": "GrowthMediaUsed",
                                            //             "value": "",
                                            //             "placeholder": "",
                                            //             "multiline": false,
                                            //             "classname": "form-control ",
                                            //             "regularExpression": "",
                                            //             "disabled": false,
                                            //             "required": false,
                                            //             "errorMessage": {
                                            //                 "noData": "This fields is required",
                                            //                 "regexFail": ""
                                            //             },
                                            //             "validationButtonType": "",
                                            //             "rows": 0,
                                            //             "columns": 0,
                                            //             "onDemandError": false,
                                            //             "customValidation": [],
                                            //             "multiError": false,
                                            //             "applicationError": "",
                                            //             "wrapperClassName": "",
                                            //             "requiredErrorMessage": "This fields is required",
                                            //             "__schema_id": "growth_media_used",
                                            //             "showInputLabel": true,
                                            //             "labelContent": "Growth Media Used",
                                            //             "labelClass": "form-label",
                                            //             "isLabelAfter": false,
                                            //             "showAdditionalInputContent": false,
                                            //             "additionalInputContent": "",
                                            //             "isAdditonalInputAfter": true,
                                            //             "draggable": false
                                            //         }
                                            //     ],
                                            //     "type": "",
                                            //     "name": "wrapper_777",
                                            //     "classname": "col-3 mb-3",
                                            //     "wrapperType": "BOX",
                                            //     "id": "wrapper_139",
                                            //     "fields": [],
                                            //     "head": "",
                                            //     "tabHeadClass": ""
                                            // },
                                            {
                                                "control": [
                                                    {
                                                        "type": "dropdown",
                                                        "name": "growth_media_used",
                                                        "id": "GrowthMediaUsed",
                                                        "validationButtonType": "",
                                                        "classname": "",
                                                        "required": false,
                                                        "apiEndPoints": [
                                                            {
                                                                "type": "init",
                                                                "method": "post",
                                                                "url": "inventory_item",
                                                                "payload": {
                                                                    "user_input": "",
                                                                    "perma": "cell-culture-media"
                                                                }
                                                            }
                                                        ],
                                                        "options": [],
                                                        "multiple": false,
                                                        "isClearable": false,
                                                        "isStatus": false,
                                                        "search": true,
                                                        "singleSelect": true,
                                                        "showLabel": "value",
                                                        "errorMessage": {
                                                            "noData": "This fields is required"
                                                        },
                                                        "value": "",
                                                        "imageUrl": "",
                                                        "disabled": false,
                                                        "wrapperClassName": "",
                                                        "connectedArrays": [],
                                                        "showBlankInPlaceholder": false,
                                                        "blankDataList": [],
                                                        "commonPathOfImage": "",
                                                        "__schema_id": "growth_media_used",
                                                        "showInputLabel": true,
                                                        "labelContent": "Growth Media Used",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "type": "",
                                                "name": "Culture_Medai",
                                                "classname": "col-3 mb-3",
                                                "wrapperType": "BOX",
                                                "id": "Culture_Medai",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            },
                                            {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "rpm",
                                                        "id": "RPM_244",
                                                        "value":  "200",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "phage_rpm",
                                                        "showInputLabel": true,
                                                        "labelContent": "RPM",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "type": "",
                                                "name": "wrapper_817",
                                                "classname": "col-3 mb-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_930",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            },
                                            {
                                                "control": [],
                                                "type": "",
                                                "name": "wrapper_926",
                                                "classname": "row",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_137",
                                                "fields": [
                                                    {
                                                        "control": [
                                                            {
                                                                "type": "text",
                                                                "name": "incubationtime",
                                                                "id": "IncubationTime_hr",
                                                                "value": "",
                                                                "placeholder": "",
                                                                "multiline": false,
                                                                "classname": "form-control ",
                                                                "regularExpression": "",
                                                                "disabled": false,
                                                                "required": false,
                                                                "errorMessage": {
                                                                    "noData": "This fields is required",
                                                                    "regexFail": ""
                                                                },
                                                                "validationButtonType": "",
                                                                "rows": 0,
                                                                "columns": 0,
                                                                "onDemandError": false,
                                                                "customValidation": [],
                                                                "multiError": false,
                                                                "applicationError": "",
                                                                "wrapperClassName": "",
                                                                "requiredErrorMessage": "This fields is required",
                                                                "__schema_id": "phage_incubationtime",
                                                                "showInputLabel": true,
                                                                "labelContent": "Incubation Time (hr)",
                                                                "labelClass": "form-label",
                                                                "isLabelAfter": false,
                                                                "showAdditionalInputContent": false,
                                                                "additionalInputContent": "",
                                                                "isAdditonalInputAfter": true,
                                                                "draggable": false
                                                            }
                                                        ],
                                                        "type": "",
                                                        "name": "wrapper_840",
                                                        "classname": "col-3 mb-3",
                                                        "wrapperType": "BOX",
                                                        "id": "wrapper_433",
                                                        "fields": [],
                                                        "head": "",
                                                        "tabHeadClass": ""
                                                    },
                                                    {
                                                        "control": [
                                                            {
                                                                "type": "text",
                                                                "name": "incubation_temprature",
                                                                "id": "IncubationT_442",
                                                                "value": "37",
                                                                "placeholder": "",
                                                                "multiline": false,
                                                                "classname": "form-control ",
                                                                "regularExpression": "",
                                                                "disabled": false,
                                                                "required": false,
                                                                "errorMessage": {
                                                                    "noData": "This fields is required",
                                                                    "regexFail": ""
                                                                },
                                                                "validationButtonType": "",
                                                                "rows": 0,
                                                                "columns": 0,
                                                                "onDemandError": false,
                                                                "customValidation": [],
                                                                "multiError": false,
                                                                "applicationError": "",
                                                                "wrapperClassName": "",
                                                                "requiredErrorMessage": "This fields is required",
                                                                "__schema_id": "phage_incubation_temprature",
                                                                "showInputLabel": true,
                                                                "labelContent": "Incubation Temperature (°C)",
                                                                "labelClass": "form-label",
                                                                "isLabelAfter": false,
                                                                "showAdditionalInputContent": false,
                                                                "additionalInputContent": "",
                                                                "isAdditonalInputAfter": true,
                                                                "draggable": false
                                                            }
                                                        ],
                                                        "type": "",
                                                        "name": "wrapper_286",
                                                        "classname": "col-3 mb-3",
                                                        "wrapperType": "BOX",
                                                        "id": "wrapper_245",
                                                        "fields": [],
                                                        "head": "",
                                                        "tabHeadClass": ""
                                                    },
                                                    {
                                                        "control": [],
                                                        "type": "",
                                                        "name": "wrapper_462",
                                                        "classname": "col-6",
                                                        "wrapperType": "BOX",
                                                        "id": "wrapper_462",
                                                        "fields": [],
                                                        "head": "",
                                                        "tabHeadClass": ""
                                                    }
                                                ],
                                                "head": "",
                                                "tabHeadClass": "",
                                                "draggable": false
                                            }
                                        ],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    }
                                ],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "",
                            "headerBtnClassName": "",
                            "header": "<span>Phage Library Amplification</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "",
                            "collapseOthers": true
                        }
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_60",
                        "classname": "col-12",
                        "wrapperType": "BOX",
                        "id": "wrapper_112",
                        "fields": [
                            {
                                "control": [],
                                "type": "",
                                "name": "phage_purification",
                                "classname": "col-12",
                                "wrapperType": "REGULAR",
                                "id": "wrapper_672",
                                "fields": [
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_679",
                                        "classname": "row mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_154",
                                        "fields": [
                                            {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "vol_of_phage_culture",
                                                        "id": "Volofphageculture",
                                                        "value": "500",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "vol_of_phage_culture",
                                                        "showInputLabel": true,
                                                        "labelContent": "Vol of Phage Culture Collected (ml)",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "type": "",
                                                "name": "wrapper_593",
                                                "classname": "col-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_130",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            },
                                            {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "peg_nacl_buffer",
                                                        "id": "PEG_NaCl_buffer",
                                                        "value": "125",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "peg_nacl_buffer",
                                                        "showInputLabel": true,
                                                        "labelContent": "PEG/NaCl Buffer Vol (ml)",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "type": "",
                                                "name": "wrapper_324",
                                                "classname": "col-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_662",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            },
                                            {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "pbs_buffer",
                                                        "id": "PBSBufferVol_ml",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "pbs_buffer",
                                                        "showInputLabel": true,
                                                        "labelContent": "PBS Buffer Vol (ml)",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "type": "",
                                                "name": "wrapper_441",
                                                "classname": "col-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_31",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            },
                                            {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "final_centrifuge",
                                                        "id": "FinalCentrifugeRPM",
                                                        "value": "8000",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "final_centrifuge",
                                                        "showInputLabel": true,
                                                        "labelContent": "Final Centrifuge RPM",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "type": "",
                                                "name": "wrapper_893",
                                                "classname": "col-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_175",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            }
                                        ],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_638",
                                        "classname": "row mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_525",
                                        "fields": [
                                            {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "motor_temp",
                                                        "id": "MotorTemp",
                                                        "value": "4",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "motor_temp",
                                                        "showInputLabel": true,
                                                        "labelContent": "Motor Temp",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "type": "",
                                                "name": "wrapper_757",
                                                "classname": "col-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_678",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            },
                                            {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "incubation_time",
                                                        "id": "IncubationTime_732",
                                                        "value": "15",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "purification_incubation_time",
                                                        "showInputLabel": true,
                                                        "labelContent": "Incubation Time (min)",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "type": "",
                                                "name": "wrapper_831",
                                                "classname": "col-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_603",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            },
                                            {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "incubation_temperature",
                                                        "id": "IncubationTemperature_120",
                                                        "value": "37",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "purification_incubation_temperature",
                                                        "showInputLabel": true,
                                                        "labelContent": "Incubation Temperature (°C)",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "type": "",
                                                "name": "wrapper_673",
                                                "classname": "col-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_286",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            },
                                            {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "absorbance",
                                                        "id": "Absorbance_268nM",
                                                        "value": "1",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "absorbance",
                                                        "showInputLabel": true,
                                                        "labelContent": "Absorbance (268 nM)",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "type": "",
                                                "name": "wrapper_424",
                                                "classname": "col-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_725",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            }
                                        ],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_205",
                                        "classname": "row mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_292",
                                        "fields": [
                                            {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "phage_conc",
                                                        "id": "PhageConc_Phageperml",
                                                        "value": "5 x 10^12",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": true,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "phage_conc",
                                                        "showInputLabel": true,
                                                        "labelContent": "Phage Conc (Phage per ml)",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "type": "",
                                                "name": "wrapper_356",
                                                "classname": "col-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_171",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            },
                                            {
                                                "control": [],
                                                "type": "",
                                                "name": "wrapper_474",
                                                "classname": "col-9",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_8",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            }
                                        ],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_848",
                                        "classname": "row mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_507",
                                        "fields": [
                                            {
                                                "control": [
                                                    {
                                                        "type": "radio",
                                                        "name": "glycerol_stock_preparation",
                                                        "label": {
                                                            "type": "label",
                                                            "name": "",
                                                            "classname": "",
                                                            "for": "",
                                                            "required": false,
                                                            "wrapperClassName": ""
                                                        },
                                                        "id": "glycerolstock_221",
                                                        "value": "Yes",
                                                        "required": false,
                                                        "classname": "  form-check-wrap",
                                                        "errorMessage": {
                                                            "noData": ""
                                                        },
                                                        "options": [
                                                            {
                                                                "key": "Yes",
                                                                "label": "Yes"
                                                            },
                                                            {
                                                                "key": "No",
                                                                "label": "No"
                                                            }
                                                        ],
                                                        "readonly": false,
                                                        "validationButtonType": "",
                                                        "disabled": false,
                                                        "wrapperClassName": "form-check form-check-inline no-border",
                                                        "__schema_id": "glycerol_stock_preparation",
                                                        "showInputLabel": true,
                                                        "labelContent": "Perform Glycerol Stock Preparation ",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "type": "",
                                                "name": "wrapper_479",
                                                "classname": "col-3",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_192",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            },
                                            {
                                                "control": [],
                                                "type": "",
                                                "name": "wrapper_711",
                                                "classname": "col-9",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_924",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            }
                                        ],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    }
                                ],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "",
                            "headerBtnClassName": "",
                            "header": "<span>Phage Purification</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "",
                            "collapseOthers": true
                        }
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false,
                "addBtnPostion": null,
                "isAccordion": true,
                "accordionConfig": {
                    "headerClassName": "",
                    "headerBtnClassName": "",
                    "header": "",
                    "collapseDivClassName": "",
                    "bodyClassName": "",
                    "collapseOthers": true
                }
            }
        ],
        "type": "",
        "classname": "",
        "showClass": true
    },
    {
        "jsonId": 135,
        "control": [],
        "head": "",
        "name": "main",
        "id": "main",
        "wrapperType": "REGULAR",
        "fields": [
            {
                "control": [],
                "type": "",
                "name": "wrapper_114",
                "classname": "accordion accordion-custom",
                "wrapperType": "ACCORDION",
                "id": "wrapper_490",
                "fields": [
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_627",
                        "classname": "accordion accordion-flush accordion-custom",
                        "wrapperType": "BOX",
                        "id": "wrapper_152",
                        "fields": [
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_905",
                                "classname": "row",
                                "wrapperType": "BOX",
                                "id": "wrapper_387",
                                "fields": [
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "library_name",
                                                "id": "Libraryname",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": true,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "library_name",
                                                "showInputLabel": true,
                                                "labelContent": "Library name",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_914",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_45",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "stock_id",
                                                "id": "stockId",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": true,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "stock_id",
                                                "showInputLabel": true,
                                                "labelContent": "Stock ID",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_324",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_1",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "glycerol_percentage",
                                                "id": "Glycerol_percentage",
                                                "validationButtonType": "",
                                                "classname": "",
                                                "required": false,
                                                "apiEndPoints": [],
                                                "options": [
                                                     "10%",
                                                ],
                                                "multiple": false,
                                                "isClearable": false,
                                                "isStatus": false,
                                                "search": false,
                                                "singleSelect": true,
                                                "showLabel": "",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": "10%",
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "glycerol_percentage",
                                                "showInputLabel": true,
                                                "labelContent": "Glycerol %",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_78",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_526",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "dropdown",
                                                "name": "tube_type",
                                                "id": "tubeType",
                                                "validationButtonType": "",
                                                "classname": "",
                                                "required": false,
                                                "apiEndPoints": [],
                                                "options": ["1.5 ml Eppendorf"],
                                                "multiple": true,
                                                "isClearable": false,
                                                "isStatus": false,
                                                "search": false,
                                                "singleSelect": true,
                                                "showLabel": "",
                                                "errorMessage": {
                                                    "noData": "This fields is required"
                                                },
                                                "value": "1.5 ml Eppendorf",
                                                "imageUrl": "",
                                                "disabled": false,
                                                "wrapperClassName": "",
                                                "connectedArrays": [],
                                                "showBlankInPlaceholder": false,
                                                "blankDataList": [],
                                                "commonPathOfImage": "",
                                                "__schema_id": "tube_type",
                                                "showInputLabel": true,
                                                "labelContent": "Tubetype",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_429",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_588",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "no_of_vials",
                                                "id": "_noofVials",
                                                "value": "",
                                                "callBackEvents": ['input'],
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "no_of_vials",
                                                "showInputLabel": true,
                                                "labelContent": "No of Vials",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_482",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_641",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "vol_per_vial",
                                                "id": "volpervial_totalVol",
                                                "value": "",
                                                "callBackEvents": ['input'
                                            ],
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": false,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "vol_per_vial",
                                                "showInputLabel": true,
                                                "labelContent": "Aliquot volume per vial(ml)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_701",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_48",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [
                                            {
                                                "type": "text",
                                                "name": "total_vol",
                                                "id": "totalVol",
                                                "value": "",
                                                "placeholder": "",
                                                "multiline": false,
                                                "classname": "form-control ",
                                                "regularExpression": "",
                                                "disabled": true,
                                                "required": false,
                                                "errorMessage": {
                                                    "noData": "This fields is required",
                                                    "regexFail": ""
                                                },
                                                "validationButtonType": "",
                                                "rows": 0,
                                                "columns": 0,
                                                "onDemandError": false,
                                                "customValidation": [],
                                                "multiError": false,
                                                "applicationError": "",
                                                "wrapperClassName": "",
                                                "requiredErrorMessage": "This fields is required",
                                                "__schema_id": "total_vol",
                                                "showInputLabel": true,
                                                "labelContent": "Total Volume(ml)",
                                                "labelClass": "form-label",
                                                "isLabelAfter": false,
                                                "showAdditionalInputContent": false,
                                                "additionalInputContent": "",
                                                "isAdditonalInputAfter": true,
                                                "draggable": false
                                            }
                                        ],
                                        "type": "",
                                        "name": "wrapper_693",
                                        "classname": "col-3 mb-3",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_270",
                                        "fields": [],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    }
                                ],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "",
                            "headerBtnClassName": "",
                            "header": "<span>Library Stocks Info</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "",
                            "collapseOthers": true
                        }
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false,
                "isAccordion": true,
                "accordionConfig": {
                    "headerClassName": "",
                    "headerBtnClassName": "",
                    "header": "",
                    "collapseDivClassName": "",
                    "bodyClassName": "",
                    "collapseOthers": true
                }
            }
        ],
        "type": "",
        "classname": "",
        "showClass": true
    },
    {
        jsonId: 136,
        control: [],
        head: '',
        name: 'main',
        id: 'main',
        wrapperType: 'REGULAR',
        fields: [
            {
                control: [
                    {
                        type: 'dropdown',
                        showBlankInPlaceholder: true,
                        blankDataList: ['""', 'undefined', 'null'],
                        name: 'file_type',
                        id: 'file_type',
                        validationButtonType: 'upload',
                        classname: '',
                        required: false,
                        apiEndPoints: [],
                        options: [
                            {
                                key: 'protocol',
                                value: 'Protocol',
                            },
                        ],
                        multiple: false,
                        isClearable: false,
                        isStatus: false,
                        search: true,
                        singleSelect: true,
                        showLabel: 'value',
                        errorMessage: { noData: 'This field is required' },
                        value: {
                            key: 'Sequence File',
                            value: 'Sequence File',
                        },
                        imageUrl: '',
                        disabled: true,
                        wrapperClassName: '',
                        showInputLabel: true,
                        labelContent: 'Select File Type',
                        labelClass: 'form-label',
                        isLabelAfter: false,
                        showAdditionalInputContent: false,
                        additionalInputContent: '',
                        isAdditonalInputAfter: true,
                        draggable: false,
                    },
                ],
                type: '',
                name: 'wrapper_801',
                classname: 'col-12 mb-3 d-none',
                wrapperType: 'BOX',
                id: 'wrapper_766',
                fields: [],
                head: '',
                tabHeadClass: '',
                draggable: false,
            },
            {
                control: [
                    {
                        type: 'file',
                        name: 'file',
                        id: 'file',
                        value: 'upload',
                        classname: 'btn btn-file',
                        fileNameShow: true,
                        size: 10,
                        url: '',
                        functionName: '',
                        wrapperClassName: '',
                        fileCount: 1,
                        sizeKB: '50000',
                        allowMultiple: false,
                        required: false,
                        validationButtonType: 'upload',
                        formDataType: 'blob',
                        errorMsg: { invalidFormat: 'Invalid File Format', invalidSize: 'File Size exceeded' },
                        fileTypes: ['pdf', "doc", "docx", "dot", "dotm", "dotx", 'ab1', 'txt', 'xlsx', 'xlsm',  'jpeg', 'png','jpg'],
                        listClass: '',
                        mainClass: '',
                        btnDivClass: '',
                        showInputLabel: true,
                        labelContent: 'Drag and drop files here or browse files  ',
                        labelClass: 'form-label',
                        requiredErrorMessage: 'This field is required',
                        isLabelAfter: false,
                        showAdditionalInputContent: false,
                        additionalInputContent: '',
                        isAdditonalInputAfter: true,
                        draggable: false,
                        showViewIcon: false,
                        showDownloadIcon: false,
                    },
                ],
                type: '',
                name: 'wrapper_379',
                classname: 'col-12 mb-3 drag-and-drop',
                wrapperType: 'BOX',
                id: 'wrapper_461',
                fields: [],
                head: '',
                tabHeadClass: '',
                draggable: false,
            },
        ],
        type: '',
        classname: '',
    },
]