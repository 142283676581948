export const ANALYSIS_OF_PHAGE_CLONES = [
    //project intiation
    {
        "id": "main",
        "jsonId": 149,
        "head": "",
        "name": "main",
        "type": "",
        "fields": [
            {
                "id": "wrapper_654",
                "head": "",
                "name": "wrapper_290",
                "type": "",
                "fields": [],
                "control": [
                    {
                        "id": "elisa_method",
                        "name": "elisa_method",
                        "__schema_id": "elisa_method",
                        "type": "dropdown",
                        "value": "",
                        "search": true,
                        "disabled": false,
                        "imageUrl": "",
                        "isStatus": false,
                        "multiple": false,
                        "required": true,
                        "classname": "",
                        "draggable": false,
                        "showLabel": "value",
                        "labelClass": "form-label mandatory",
                        "isClearable": false,
                        "apiEndPoints": [],
                        "options": [
                            {
                                "key": "1",
                                "value": "Specificity ELISA"
                            },
                            {
                                "key": "2",
                                "value": "Competitive ELISA"
                            }
                        ],
                        "errorMessage": {
                            "noData": "This field is required"
                        },
                        "isLabelAfter": false,
                        "labelContent": "ELISA Method",
                        "singleSelect": true,
                        "showInputLabel": true,
                        "wrapperClassName": "",
                        "validationButtonType": "initial-submit",
                        "isAdditonalInputAfter": true,
                        "additionalInputContent": "",
                        "showAdditionalInputContent": false
                    }
                ],
                "classname": "col-12 mb-3",
                "draggable": false,
                "wrapperType": "BOX",
                "tabHeadClass": ""
            },
            {
                "id": "wrapper_790",
                "head": "",
                "name": "wrapper_576",
                "type": "",
                "fields": [
                    {
                        "id": "wrapper_808",
                        "head": "",
                        "name": "wrapper_50",
                        "type": "",
                        "fields": [],
                        "control": [
                            {
                                "id": "project_title",
                                "name": "project_title",
                                "__schema_id": "project_title",
                                "rows": 0,
                                "type": "text",
                                "value": "",
                                "columns": 0,
                                "disabled": false,
                                "required": true,
                                "classname": "form-control ",
                                "draggable": false,
                                "multiline": false,
                                "labelClass": "form-label mandatory",
                                "multiError": false,
                                "placeholder": "",
                                "errorMessage": {
                                    "noData": "This field is required",
                                    "regexFail": ""
                                },
                                "isLabelAfter": false,
                                "labelContent": "Project Title",
                                "onDemandError": false,
                                "showInputLabel": true,
                                "applicationError": "",
                                "customValidation": [],
                                "wrapperClassName": "",
                                "regularExpression": "",
                                "requiredErrorMessage": "This field is required",
                                "validationButtonType": "initial-submit",
                                "isAdditonalInputAfter": true,
                                "additionalInputContent": "",
                                "showAdditionalInputContent": false
                            }
                        ],
                        "classname": "col-12 mb-3",
                        "draggable": false,
                        "wrapperType": "BOX",
                        "tabHeadClass": ""
                    },
                    {
                        "id": "wrapper_654",
                        "head": "",
                        "name": "wrapper_290",
                        "type": "",
                        "fields": [],
                        "control": [
                            {
                                "id": "project_init_by",
                                "name": "project_init_by",
                                "__schema_id": "project_init_by",
                                "type": "dropdown",
                                "value": "",
                                "search": true,
                                "disabled": false,
                                "imageUrl": "",
                                "isStatus": false,
                                "multiple": false,
                                "required": true,
                                "classname": "",
                                "draggable": false,
                                "showLabel": "value",
                                "labelClass": "form-label mandatory",
                                "isClearable": false,
                                "apiEndPoints": [
                                    {
                                        "url": "user_management/users_list",
                                        "type": "init",
                                        "method": "get"
                                    }
                                ],
                                "errorMessage": {
                                    "noData": "This field is required"
                                },
                                "isLabelAfter": false,
                                "labelContent": "Initiated By",
                                "singleSelect": true,
                                "showInputLabel": true,
                                "wrapperClassName": "",
                                "validationButtonType": "initial-submit",
                                "isAdditonalInputAfter": true,
                                "additionalInputContent": "",
                                "showAdditionalInputContent": false
                            }
                        ],
                        "classname": "col-12 mb-3",
                        "draggable": false,
                        "wrapperType": "BOX",
                        "tabHeadClass": ""
                    },
                    {
                        "id": "wrapper_778",
                        "head": "",
                        "name": "wrapper_209",
                        "type": "",
                        "fields": [],
                        "control": [
                            {
                                "id": "project_start_date",
                                "name": "project_start_date",
                                "__schema_id": "project_start_date",
                                "type": "datepicker_v2",
                                "range": false,
                                "timer": false,
                                "value": "",
                                "locale": "",
                                "disabled": false,
                                "multiple": false,
                                "required": true,
                                "classname": "",
                                "draggable": false,
                                "dateFormat": "dd/mm/yyyy",
                                "hourFormat": 24,
                                "labelClass": "form-label mandatory",
                                "multiAmount": 3,
                                "placeholder": "DD-MM-YYYY",
                                "dayClassName": "dd",
                                "errorMessage": {
                                    "noData": "This field is required",
                                    "regexFail": "Date is not valid!!"
                                },
                                "isHostParent": false,
                                "isLabelAfter": false,
                                "labelContent": "Start Date",
                                "dateSeperator": "",
                                "keyboardInput": false,
                                "arrowClassName": "arrow",
                                "fordisabledEnd": "",
                                "inputClassName": "form-control date-input",
                                "monthClassName": "mtn",
                                "showInputLabel": true,
                                "todayClassName": "now",
                                "endDateReadOnly": false,
                                "inputDateFormat": false,
                                "fordisabledStart": "",
                                "wrapperClassName": "",
                                "startDateReadOnly": false,
                                "startDatePlaceHolder": "MM/DD/YYYY",
                                "validationButtonType": "initial-submit",
                                "isAdditonalInputAfter": true,
                                "selectedDateClassName": "selectedDate",
                                "additionalInputContent": "",
                                "showAdditionalInputContent": false
                            }
                        ],
                        "classname": "col-6 mb-3",
                        "draggable": false,
                        "wrapperType": "BOX",
                        "tabHeadClass": ""
                    },
                    {
                        "id": "wrapper_92",
                        "head": "",
                        "name": "wrapper_643",
                        "type": "",
                        "fields": [],
                        "control": [
                            {
                                "id": "project_end_date",
                                "name": "project_end_date",
                                "__schema_id": "project_end_date",
                                "type": "datepicker_v2",
                                "range": false,
                                "timer": false,
                                "value": "",
                                "locale": "",
                                "disabled": false,
                                "multiple": false,
                                "required": false,
                                "classname": "",
                                "draggable": false,
                                "dateFormat": "dd/mm/yyyy",
                                "hourFormat": 24,
                                "labelClass": "form-label",
                                "multiAmount": 3,
                                "placeholder": "DD-MM-YYYY",
                                "dayClassName": "dd",
                                "errorMessage": {
                                    "noData": "This field is required",
                                    "regexFail": "Date is not valid!!"
                                },
                                "isHostParent": false,
                                "isLabelAfter": false,
                                "labelContent": "End Date",
                                "dateSeperator": "",
                                "keyboardInput": false,
                                "arrowClassName": "arrow",
                                "fordisabledEnd": "",
                                "inputClassName": "form-control date-input",
                                "monthClassName": "mtn",
                                "showInputLabel": true,
                                "todayClassName": "now",
                                "endDateReadOnly": false,
                                "inputDateFormat": false,
                                "fordisabledStart": "",
                                "wrapperClassName": "",
                                "startDateReadOnly": false,
                                "startDatePlaceHolder": "MM/DD/YYYY",
                                "validationButtonType": "",
                                "isAdditonalInputAfter": true,
                                "selectedDateClassName": "selectedDate",
                                "additionalInputContent": "",
                                "showAdditionalInputContent": false
                            }
                        ],
                        "classname": "col-6 mb-3",
                        "draggable": false,
                        "wrapperType": "BOX",
                        "tabHeadClass": ""
                    },
                    {
                        "control": [
                            {
                                "type": "text",
                                "name": "status",
                                "id": "status",
                                "value": "",
                                "placeholder": "",
                                "multiline": false,
                                "classname": "form-control ",
                                "regularExpression": "",
                                "disabled": true,
                                "required": false,
                                "errorMessage": {
                                    "noData": "This fields is required",
                                    "regexFail": ""
                                },
                                "validationButtonType": "",
                                "rows": 0,
                                "columns": 0,
                                "onDemandError": false,
                                "customValidation": [],
                                "multiError": false,
                                "applicationError": "",
                                "wrapperClassName": "",
                                "requiredErrorMessage": "This fields is required",
                                "__schema_id": "status",
                                "showInputLabel": false,
                                "labelContent": "text_977",
                                "labelClass": "form-label",
                                "isLabelAfter": false,
                                "showAdditionalInputContent": false,
                                "additionalInputContent": "",
                                "isAdditonalInputAfter": true,
                                "draggable": false
                            },
                            {
                                "type": "text",
                                "name": "experiment_type",
                                "id": "experiment_type",
                                "value": "",
                                "placeholder": "",
                                "multiline": false,
                                "classname": "form-control ",
                                "regularExpression": "",
                                "disabled": true,
                                "required": false,
                                "errorMessage": {
                                    "noData": "This fields is required",
                                    "regexFail": ""
                                },
                                "validationButtonType": "",
                                "rows": 0,
                                "columns": 0,
                                "onDemandError": false,
                                "customValidation": [],
                                "multiError": false,
                                "applicationError": "",
                                "wrapperClassName": "",
                                "requiredErrorMessage": "This fields is required",
                                "__schema_id": "experiment_type",
                                "showInputLabel": false,
                                "labelContent": "text_581",
                                "labelClass": "form-label",
                                "isLabelAfter": false,
                                "showAdditionalInputContent": false,
                                "additionalInputContent": "",
                                "isAdditonalInputAfter": true,
                                "draggable": false
                            }
                        ],
                        "type": "",
                        "name": "wrapper_237",
                        "classname": "col-12 d-none",
                        "wrapperType": "BOX",
                        "id": "wrapper_929",
                        "fields": [],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    }
                ],
                "control": [],
                "classname": "row",
                "draggable": false,
                "wrapperType": "BOX",
                "tabHeadClass": ""
            }
        ],
        "control": [],
        "classname": "form-container",
        "wrapperType": "REGULAR",
        "payload_schema": {
            "projectInitDetails": {
                "elisa_method": "elisa_method",
                "title": "project_title",
                "init_user": "project_init_by",
                "experiment_type": "experiment_type",
                "start_date": "project_start_date",
                "end_date": "project_end_date.$d{}",
                "status": "status"
            }
        },
        "validation_type": "initial-submit"
    },
    //Antigen info commercial
    {
        "control": [],
        "head": "",
        "name": "main",
        "id": "main",
        "jsonId": 150,
        "wrapperType": "REGULAR",
        "fields": [
            {
                "control": [],
                "type": "",
                "name": "wrapper_761",
                "classname": "col-12",
                "wrapperType": "BOX",
                "id": "wrapper_793",
                "fields": [
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_576",
                        "classname": "row",
                        "wrapperType": "BOX",
                        "id": "wrapper_39",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "radio",
                                        "name": "antigen_source",
                                        "callBackEvents": ['change'
                                        ],
                                        "label": {
                                            "type": "label",
                                            "name": "",
                                            "classname": "",
                                            "for": "",
                                            "required": false,
                                            "wrapperClassName": ""
                                        },
                                        "id": "radioButton_259_antigenSourceChange",
                                        "value": "Commercial",
                                        "required": false,
                                        "classname": "form-check-wrap",
                                        "errorMessage": {
                                            "noData": ""
                                        },
                                        "options": [
                                            {
                                                "key": "Commercial",
                                                "label": "Commercial"
                                            },
                                            {
                                                "key": "In House",
                                                "label": "In House"
                                            }
                                        ],
                                        "readonly": false,
                                        "validationButtonType": "",
                                        "disabled": false,
                                        "wrapperClassName": "form-check form-check-inline no-border",
                                        "__schema_id": "antigen_source",
                                        "showInputLabel": true,
                                        "labelContent": "Source of Antigen ",
                                        "labelClass": "form-label d-block ps-0",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_436",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_828",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_386",
                                "classname": "col-6",
                                "wrapperType": "BOX",
                                "id": "wrapper_768",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "button",
                                        "id": "button_780",
                                        "value": "Attachments",
                                        "buttonType": "elisa_fileupload",
                                        "classname": "btn btn-primary mt-2 attachment-btn",
                                        "divClass": "",
                                        "controlEndPoints": [],
                                        "disabled": false,
                                        "validationEndPoints": [],
                                        "invalid": false,
                                        "name": "button_664",
                                        "wrapperClassName": "",
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_282",
                                "classname": "col-3 d-flex justify-content-end",
                                "wrapperType": "BOX",
                                "id": "wrapper_861",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "nucleotide_file_id",
                                        "id": "textBox_616",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "nucleotide_file_id",
                                        "showInputLabel": true,
                                        "labelContent": "Antigen",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_571",
                                "classname": "col-3 d-none",
                                "wrapperType": "BOX",
                                "id": "wrapper_833",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "amino_acid_file_id",
                                        "id": "textBox_616",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "amino_acid_file_id",
                                        "showInputLabel": true,
                                        "labelContent": "Antigen",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_571",
                                "classname": "col-3 d-none",
                                "wrapperType": "BOX",
                                "id": "wrapper_833",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_806",
                        "classname": "row mt-3 mb-3",
                        "wrapperType": "BOX",
                        "id": "wrapper_564",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "antigen",
                                        "id": "textBox_616",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "antigen",
                                        "showInputLabel": true,
                                        "labelContent": "Antigen",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_571",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_833",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "antigen_id",
                                        "id": "CommercialAntigenID",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "antigen_id",
                                        "showInputLabel": true,
                                        "labelContent": "Commercial Antigen ID",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_565",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_306",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "gene_name",
                                        "id": "textBox_511",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "gene_name",
                                        "showInputLabel": true,
                                        "labelContent": "Gene Name",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_844",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_341",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "conc",
                                        "id": "textBox_590",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "conc",
                                        "showInputLabel": true,
                                        "labelContent": "Conc (mg/ml)",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_482",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_303",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_321",
                        "classname": "row mb-3",
                        "wrapperType": "BOX",
                        "id": "wrapper_481",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "tags",
                                        "id": "textBox_318",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "tags",
                                        "showInputLabel": true,
                                        "labelContent": "Tags",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_416",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_322",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "antigen_aa_boundary",
                                        "id": "textBox_701",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "antigen_aa_boundary",
                                        "showInputLabel": true,
                                        "labelContent": "Antigen AA Boundary",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_816",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_312",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "expression_system",
                                        "id": "textBox_961",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "expression_system",
                                        "showInputLabel": true,
                                        "labelContent": "Expression System",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_414",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_992",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "purity",
                                        "id": "textBox_233",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "purity",
                                        "showInputLabel": true,
                                        "labelContent": "Purity",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_815",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_697",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_801",
                        "classname": "row mb-3",
                        "wrapperType": "BOX",
                        "id": "wrapper_927",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "vendor_details",
                                        "id": "textBox_928",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "vendor_details",
                                        "showInputLabel": true,
                                        "labelContent": "Vendor Details",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_979",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_967",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "catalog_number",
                                        "id": "textBox_219",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "catalog_number",
                                        "showInputLabel": true,
                                        "labelContent": "Catalog Number",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_954",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_312",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "vendor_url",
                                        "id": "textBox_612",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "vendor_url",
                                        "showInputLabel": true,
                                        "labelContent": "Vendor Url",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_893",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_777",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_757",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_134",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    },
                    {
                        "control": [
                            {
                                "type": "text",
                                "name": "comments",
                                "id": "textBox_694",
                                "value": "",
                                "placeholder": "",
                                "multiline": true,
                                "classname": "form-control ",
                                "regularExpression": "",
                                "disabled": false,
                                "required": false,
                                "errorMessage": {
                                    "noData": "This fields is required",
                                    "regexFail": ""
                                },
                                "validationButtonType": "",
                                "rows": 0,
                                "columns": 0,
                                "onDemandError": false,
                                "customValidation": [],
                                "multiError": false,
                                "applicationError": "",
                                "wrapperClassName": "",
                                "requiredErrorMessage": "This fields is required",
                                "__schema_id": "comments",
                                "showInputLabel": true,
                                "labelContent": "Experiment Notes/Comments",
                                "labelClass": "form-label",
                                "isLabelAfter": false,
                                "showAdditionalInputContent": false,
                                "additionalInputContent": "",
                                "isAdditonalInputAfter": true,
                                "draggable": false
                            }
                        ],
                        "type": "",
                        "name": "wrapper_985",
                        "classname": "col-12 mt-3",
                        "wrapperType": "BOX",
                        "id": "wrapper_358",
                        "fields": [],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false
            }
        ],
        "type": "",
        "classname": "",
        "showClass": true,
        "payload_schema": {
            "antigenInfo": {
                "antigen_source": "antigen_source",
                "antigen": "antigen",
                "antigen_id": "antigen_id",
                "gene_name": "gene_name",
                "conc": "conc",
                "tags": "tags",
                "antigen_aa_boundary": "antigen_aa_boundary",
                "expression_system": "expression_system",
                "purity": "purity",
                "vendor_details": "vendor_details",
                "catalog_number": "catalog_number",
                "vendor_url": "vendor_url",
                "comments": "comments",
                "nucleotide_file_id": "nucleotide_file_id",
                "amino_acid_file_id": "amino_acid_file_id"
            }
        },
    },
    // In house
    {
        "control": [],
        "head": "",
        "name": "main",
        "id": "main",
        "jsonId": 151,
        "wrapperType": "REGULAR",
        "fields": [
            {
                "control": [],
                "type": "",
                "name": "wrapper_761",
                "classname": "col-12",
                "wrapperType": "BOX",
                "id": "wrapper_793",
                "fields": [
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_576",
                        "classname": "row",
                        "wrapperType": "BOX",
                        "id": "wrapper_39",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "radio",
                                        "name": "antigen_source",
                                        "callBackEvents": ['change'
                                        ],
                                        "label": {
                                            "type": "label",
                                            "name": "",
                                            "classname": "",
                                            "for": "",
                                            "required": false,
                                            "wrapperClassName": ""
                                        },
                                        "id": "radioButton_259_antigenSourceChange",
                                        "value": "In House",
                                        "required": false,
                                        "classname": "form-check-wrap",
                                        "errorMessage": {
                                            "noData": ""
                                        },
                                        "options": [
                                            {
                                                "key": "Commercial",
                                                "label": "Commercial"
                                            },
                                            {
                                                "key": "In House",
                                                "label": "In House"
                                            }
                                        ],
                                        "readonly": false,
                                        "validationButtonType": "",
                                        "disabled": false,
                                        "wrapperClassName": "form-check form-check-inline no-border",
                                        "__schema_id": "",
                                        "showInputLabel": true,
                                        "labelContent": "Source of Antigen ",
                                        "labelClass": "form-label d-block ps-0",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_436",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_828",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [],
                                "type": "",
                                "name": "wrapper_386",
                                "classname": "col-6",
                                "wrapperType": "BOX",
                                "id": "wrapper_768",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "button",
                                        "id": "button_780",
                                        "value": "Attachments",
                                        "buttonType": "elisa_fileupload",
                                        "classname": "btn btn-primary mt-2 attachment-btn",
                                        "divClass": "",
                                        "controlEndPoints": [],
                                        "disabled": false,
                                        "validationEndPoints": [],
                                        "invalid": false,
                                        "name": "button_664",
                                        "wrapperClassName": "",
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_282",
                                "classname": "col-3 d-flex justify-content-end",
                                "wrapperType": "BOX",
                                "id": "wrapper_861",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "nucleotide_file_id",
                                        "id": "textBox_616",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "nucleotide_file_id",
                                        "showInputLabel": true,
                                        "labelContent": "Antigen",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_571",
                                "classname": "col-3 d-none",
                                "wrapperType": "BOX",
                                "id": "wrapper_833",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "amino_acid_file_id",
                                        "id": "textBox_616",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "amino_acid_file_id",
                                        "showInputLabel": true,
                                        "labelContent": "Antigen",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_571",
                                "classname": "col-3 d-none",
                                "wrapperType": "BOX",
                                "id": "wrapper_833",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_806",
                        "classname": "row mt-3 mb-3",
                        "wrapperType": "BOX",
                        "id": "wrapper_564",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "antigen",
                                        "id": "textBox_616",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "antigen",
                                        "showInputLabel": true,
                                        "labelContent": "Antigen",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_571",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_833",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "antigen_id",
                                        "id": "textBox_337",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "antigen_id",
                                        "showInputLabel": true,
                                        "labelContent": "In House Antigen ID",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_565",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_306",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "gene_name",
                                        "id": "textBox_511",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "gene_name",
                                        "showInputLabel": true,
                                        "labelContent": "Gene Name",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_844",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_341",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "conc",
                                        "id": "textBox_590",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "conc",
                                        "showInputLabel": true,
                                        "labelContent": "Conc (mg/ml)",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_482",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_303",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_321",
                        "classname": "row mb-3",
                        "wrapperType": "BOX",
                        "id": "wrapper_481",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "tags",
                                        "id": "textBox_318",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "tags",
                                        "showInputLabel": true,
                                        "labelContent": "Tags",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_416",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_322",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "antigen_aa_boundary",
                                        "id": "textBox_701",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "antigen_aa_boundary",
                                        "showInputLabel": true,
                                        "labelContent": "Antigen AA Boundary",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_816",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_312",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "expression_system",
                                        "id": "textBox_961",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "expression_system",
                                        "showInputLabel": true,
                                        "labelContent": "Expression System",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_414",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_992",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "purity",
                                        "id": "textBox_233",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "purity",
                                        "showInputLabel": true,
                                        "labelContent": "Purity",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_815",
                                "classname": "col-3",
                                "wrapperType": "BOX",
                                "id": "wrapper_697",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    },
                    {
                        "control": [
                            {
                                "type": "text",
                                "name": "comments",
                                "id": "textBox_694",
                                "value": "",
                                "placeholder": "",
                                "multiline": true,
                                "classname": "form-control ",
                                "regularExpression": "",
                                "disabled": false,
                                "required": false,
                                "errorMessage": {
                                    "noData": "This fields is required",
                                    "regexFail": ""
                                },
                                "validationButtonType": "",
                                "rows": 0,
                                "columns": 0,
                                "onDemandError": false,
                                "customValidation": [],
                                "multiError": false,
                                "applicationError": "",
                                "wrapperClassName": "",
                                "requiredErrorMessage": "This fields is required",
                                "__schema_id": "comments",
                                "showInputLabel": true,
                                "labelContent": "Experiment Notes/Comments",
                                "labelClass": "form-label",
                                "isLabelAfter": false,
                                "showAdditionalInputContent": false,
                                "additionalInputContent": "",
                                "isAdditonalInputAfter": true,
                                "draggable": false
                            }
                        ],
                        "type": "",
                        "name": "wrapper_985",
                        "classname": "col-12 mt-3",
                        "wrapperType": "BOX",
                        "id": "wrapper_358",
                        "fields": [],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false
            }
        ],
        "type": "",
        "classname": "",
        "showClass": true,
        "payload_schema": {
            "antigenInfo": {
                "antigen_source": "antigen_source",
                "antigen": "antigen",
                "antigen_id": "antigen_id",
                "gene_name": "gene_name",
                "conc": "conc",
                "tags": "tags",
                "antigen_aa_boundary": "antigen_aa_boundary",
                "expression_system": "expression_system",
                "purity": "purity",
                "comments": "comments",
                "nucleotide_file_id": "nucleotide_file_id",
                "amino_acid_file_id": "amino_acid_file_id"
            }
        },
    },
    // clone selection
    {
        "control": [],
        "head": "",
        "name": "main",
        "id": "main",
        "jsonId": 152,
        "wrapperType": "REGULAR",
        "fields": [
            {
                "control": [],
                "type": "",
                "name": "wrapper_63",
                "classname": "col-12 project-table",
                "wrapperType": "BOX",
                "id": "wrapper_742",
                "fields": [
                    {
                        "control": [],
                        "type": "",
                        "name": "clone_selection",
                        "classname": "table clone-selection-table",
                        "wrapperType": "TABLE",
                        "id": "wrapper_274",
                        "fields": [],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "hideSingleItemDelete": false,
                        "tableFields": {
                            "showHead": true,
                            "isVertical": false,
                            "isIteratable": false,
                            "addButton": {
                                "classname": "btn btn-primary",
                                "name": "",
                                "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                            },
                            "removeButton": {
                                "classname": "btn btn-danger",
                                "name": "",
                                "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                            },
                            "tableHead": {
                                "heading": [
                                    {
                                        "value": "PROJECT ID",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Screening ID",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Rounds",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Clone PLATE ID",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Phage Dilution",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Storage Location",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Action",
                                        "classname": ""
                                    }
                                ],
                                "classname": ""
                            },
                            "initRecordCount": 1,
                            "showRecordCount": false,
                            "recordCounterConfig": {
                                "classname": "",
                                "adjValue": "",
                                "isAdjValueAfter": false
                            },
                            "tableRow": {
                                "name": "",
                                "classname": "trclass",
                                "tableCols": [
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "dropdown",
                                                    "name": "project_id",
                                                    "id": "dropDown_636",
                                                    "validationButtonType": "",
                                                    "classname": "",
                                                    "required": false,
                                                    "apiEndPoints": [
                                                        {
                                                            "type": "init",
                                                            "method": "post",
                                                            "url": "project/10/instance/search",
                                                            "payload": {
                                                                "filter": {
                                                                    "instance_title": "",
                                                                    "instance_status": {
                                                                        "id": 5,
                                                                        "value": ""
                                                                    }
                                                                }
                                                            }
                                                        },
                                                        {
                                                            "type": "change",
                                                            "method": "get",
                                                            "url": "project/10/instance/{project_instance_id}/flow/{flow_instance_id}",
                                                            "Mapto": []
                                                        }
                                                    ],
                                                    "options": [],
                                                    "multiple": false,
                                                    "isClearable": false,
                                                    "isStatus": false,
                                                    "search": true,
                                                    "singleSelect": true,
                                                    "showLabel": "project_instance_code",
                                                    "errorMessage": {
                                                        "noData": "This fields is required"
                                                    },
                                                    "value": "",
                                                    "imageUrl": "",
                                                    "disabled": false,
                                                    "wrapperClassName": "",
                                                    "connectedArrays": [],
                                                    "showBlankInPlaceholder": false,
                                                    "blankDataList": [],
                                                    "commonPathOfImage": "",
                                                    "__schema_id": "project_id",
                                                    "showInputLabel": false,
                                                    "labelContent": "dropdown_533",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "head": "",
                                            "name": "box_350",
                                            "id": "box_459",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": ""
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "dropdown",
                                                    "name": "screening_id",
                                                    "id": "dropDown_728",
                                                    "validationButtonType": "",
                                                    "classname": "",
                                                    "required": false,
                                                    "apiEndPoints": [],
                                                    "options": [],
                                                    "multiple": false,
                                                    "isClearable": false,
                                                    "isStatus": false,
                                                    "search": true,
                                                    "singleSelect": true,
                                                    "showLabel": "",
                                                    "errorMessage": {
                                                        "noData": "This fields is required"
                                                    },
                                                    "value": "",
                                                    "imageUrl": "",
                                                    "disabled": false,
                                                    "wrapperClassName": "",
                                                    "connectedArrays": [],
                                                    "showBlankInPlaceholder": false,
                                                    "blankDataList": [],
                                                    "commonPathOfImage": "",
                                                    "__schema_id": "screening_id",
                                                    "showInputLabel": false,
                                                    "labelContent": "dropdown_393",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_214",
                                            "id": "box_501",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": ""
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "dropdown",
                                                    "name": "rounds",
                                                    "id": "dropDown_846",
                                                    "validationButtonType": "",
                                                    "classname": "",
                                                    "required": false,
                                                    "apiEndPoints": [],
                                                    "options": [],
                                                    "multiple": true,
                                                    "isClearable": true,
                                                    "isStatus": false,
                                                    "search": true,
                                                    "singleSelect": false,
                                                    "showLabel": "clone_rounds",
                                                    "errorMessage": {
                                                        "noData": "This fields is required"
                                                    },
                                                    "value": "",
                                                    "imageUrl": "",
                                                    "disabled": false,
                                                    "wrapperClassName": "",
                                                    "connectedArrays": [],
                                                    "showBlankInPlaceholder": false,
                                                    "blankDataList": [],
                                                    "commonPathOfImage": "",
                                                    "__schema_id": "rounds",
                                                    "showInputLabel": false,
                                                    "labelContent": "dropdown_593",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_265",
                                            "id": "box_254",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "",
                                            "wrapperClassName": "",
                                            "addBtnPostion": null
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "dropdown",
                                                    "name": "clone_plate_id",
                                                    "id": "dropDown_881",
                                                    "validationButtonType": "",
                                                    "classname": "",
                                                    "required": false,
                                                    "apiEndPoints": [],
                                                    "options": [],
                                                    "multiple": true,
                                                    "isClearable": true,
                                                    "isStatus": false,
                                                    "search": true,
                                                    "singleSelect": false,
                                                    "showLabel": "clone_plate_id",
                                                    "errorMessage": {
                                                        "noData": "This fields is required"
                                                    },
                                                    "value": "",
                                                    "imageUrl": "",
                                                    "disabled": false,
                                                    "wrapperClassName": "",
                                                    "connectedArrays": [],
                                                    "showBlankInPlaceholder": false,
                                                    "blankDataList": [],
                                                    "commonPathOfImage": "",
                                                    "__schema_id": "clone_plate_id",
                                                    "showInputLabel": false,
                                                    "labelContent": "dropdown_356",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_628",
                                            "id": "box_101",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "",
                                            "wrapperClassName": "",
                                            "addBtnPostion": null
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "phage_dilution",
                                                    "id": "textBox_105",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": false,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": false,
                                                    "customValidation": [],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "phage_dilution",
                                                    "showInputLabel": false,
                                                    "labelContent": "text_289",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_101",
                                            "id": "box_622",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "",
                                            "wrapperClassName": "",
                                            "addBtnPostion": null
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "button",
                                                    "id": "button_896",
                                                    "value": "View Location",
                                                    "buttonType": "plate_storage",
                                                    "classname": "info-icon",
                                                    "divClass": "",
                                                    "controlEndPoints": [],
                                                    "disabled": false,
                                                    "validationEndPoints": [],
                                                    "invalid": false,
                                                    "name": "button_590",
                                                    "wrapperClassName": "",
                                                    "draggable": false
                                                },
                                                {
                                                    "type": "text",
                                                    "name": "assign_storage",
                                                    "id": "textBox_105",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control d-none",
                                                    "regularExpression": "",
                                                    "disabled": false,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": false,
                                                    "customValidation": [],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "assign_storage",
                                                    "showInputLabel": false,
                                                    "labelContent": "text_289",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_59",
                                            "id": "box_42",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "",
                                            "wrapperClassName": "",
                                            "addBtnPostion": null
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "button",
                                                    "id": "button_62",
                                                    "value": "<div class='custom-tooltip small-tooltip'><p>View Plates</p></div>",
                                                    "buttonType": "view_plates",
                                                    "classname": "plasmid-view",
                                                    "divClass": "",
                                                    "controlEndPoints": [],
                                                    "disabled": false,
                                                    "validationEndPoints": [],
                                                    "invalid": false,
                                                    "name": "button_770",
                                                    "wrapperClassName": "",
                                                    "draggable": false
                                                },
                                                {
                                                    "type": "text",
                                                    "name": "plate_details",
                                                    "id": "textBox_105",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control d-none",
                                                    "regularExpression": "",
                                                    "disabled": false,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": false,
                                                    "customValidation": [],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "plate_details",
                                                    "showInputLabel": false,
                                                    "labelContent": "text_289",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_985",
                                            "id": "box_441",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "text-center",
                                            "wrapperClassName": "",
                                            "addBtnPostion": null
                                        },
                                        "classname": ""
                                    }
                                ]
                            }
                        }
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false
            }
        ],
        "type": "",
        "classname": "",
        "payload_schema": {
            "cloneSelection": [
                {
                    "project_id": "project_id",
                    "screening_id": "screening_id",
                    "rounds": "rounds",
                    "clone_plate_id": "clone_plate_id",
                    "phage_dilution": "phage_dilution",
                }
            ]
        },
    },
    // lab consumables
    {
        "jsonId": 153,
        "control": [],
        "head": "",
        "name": "main",
        "id": "main",
        "wrapperType": "REGULAR",
        "fields": [
            {
                "control": [],
                "type": "",
                "name": "wrapper_217",
                "classname": "project-table",
                "wrapperType": "BOX",
                "id": "wrapper_7",
                "fields": [
                    {
                        "control": [],
                        "type": "",
                        "name": "lab",
                        "classname": "table reagents-table-p2",
                        "wrapperType": "TABLE",
                        "id": "lab_consumables",
                        "fields": [],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "hideSingleItemDelete": false,
                        "tableFields": {
                            "showHead": true,
                            "isVertical": false,
                            "isIteratable": true,
                            "addButton": {
                                "classname": "add-expand",
                                "name": "",
                                "value": "<div class='custom-tooltip'><p>Add Row</p></div>"
                            },
                            "removeButton": {
                                "classname": "add-minus ",
                                "name": "",
                                "value": "<div class='custom-tooltip'><p>Remove Row </p></div>"
                            },
                            "tableHead": {
                                "heading": [
                                    {
                                        "value": "Select from  Inventory",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Name",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Vendor",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Capacity (ml)",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Units  available",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Units  taken",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Storage Location",
                                        "classname": ""
                                    },
                                    {
                                        "value": "Action",
                                        "classname": ""
                                    },
                                    {
                                        "value": "",
                                        "classname": ""
                                    }
                                ],
                                "classname": ""
                            },
                            "initRecordCount": 1,
                            "showRecordCount": false,
                            "recordCounterConfig": {
                                "classname": "",
                                "adjValue": "",
                                "isAdjValueAfter": false
                            },
                            "tableRow": {
                                "name": "",
                                "classname": "trclass",
                                "tableCols": [
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "dropdown",
                                                    "name": "select_inventory",
                                                    "id": "select_inventory",
                                                    "validationButtonType": "",
                                                    "classname": "",
                                                    "required": false,
                                                    "apiEndPoints": [
                                                        {
                                                            "type": "change",
                                                            "method": "post",
                                                            "url": "inventory_item",
                                                            "Mapto": [
                                                                {
                                                                    "id": "main.wrapper_7.lab_consumables.${i}.box_90.inv_item",
                                                                    "urlParameters": "",
                                                                    "value": "",
                                                                    "payload": {
                                                                        "perma": "plasticwares",
                                                                        "user_input": ""
                                                                    },
                                                                    "type": null
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "type": "init",
                                                            "method": "post",
                                                            "url": "inventory_item/item-type",
                                                            "Mapto": [],
                                                            "payload": {
                                                                "perma": [
                                                                    "assay-kits",
                                                                    "cell-culture-media-antibiotics-agar-plates",
                                                                    "gels-buffers-lab-reagents",
                                                                    "plasticwares",
                                                                    "glasswares",
                                                                    "cell-lines",
                                                                    "bacterial-strains",
                                                                    "plasmids"
                                                                ]
                                                            }
                                                        }
                                                    ],
                                                    "options": [],
                                                    "multiple": false,
                                                    "isClearable": false,
                                                    "isStatus": false,
                                                    "search": true,
                                                    "singleSelect": true,
                                                    "showLabel": "inv_item_type",
                                                    "errorMessage": {
                                                        "noData": "This fields is required"
                                                    },
                                                    "value": "",
                                                    "imageUrl": "",
                                                    "disabled": false,
                                                    "wrapperClassName": "",
                                                    "connectedArrays": [],
                                                    "showBlankInPlaceholder": false,
                                                    "blankDataList": [],
                                                    "commonPathOfImage": "",
                                                    "__schema_id": "select_inventory",
                                                    "showInputLabel": false,
                                                    "labelContent": "dropdown_448",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "head": "",
                                            "name": "box_893",
                                            "id": "box_760",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": ""
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "dropdown",
                                                    "name": "inv_item",
                                                    "id": "inv_item",
                                                    "validationButtonType": "",
                                                    "classname": "",
                                                    "required": false,
                                                    "apiEndPoints": [
                                                        {
                                                            "type": "change",
                                                            "method": "get",
                                                            "url": "inventory_item/${id}",
                                                            "Mapto": [
                                                                {
                                                                    "id": "main.wrapper_7.lab_consumables.${i}.box_584.vendor",
                                                                    "displayKey": "change",
                                                                    "urlParameterValues": {
                                                                        "id": null,
                                                                        "patchProperty": "vendor_name"
                                                                    }
                                                                },
                                                                {
                                                                    "id": "main.wrapper_7.lab_consumables.${i}.box_908.capacity",
                                                                    "displayKey": "change",
                                                                    "urlParameterValues": {
                                                                        "id": null,
                                                                        "patchProperty": "item_volume"
                                                                    }
                                                                },
                                                                {
                                                                    "id": "main.wrapper_7.lab_consumables.${i}.box_293.units_available",
                                                                    "displayKey": "change",
                                                                    "urlParameterValues": {
                                                                        "id": null,
                                                                        "patchProperty": "item_quantity"
                                                                    }
                                                                },
                                                                {
                                                                    "id": "main.wrapper_7.lab_consumables.${i}.box_126.item_details",
                                                                    "displayKey": "item_details",
                                                                    "urlParameterValues": {
                                                                        "id": null
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ],
                                                    "options": [],
                                                    "multiple": false,
                                                    "isClearable": false,
                                                    "isStatus": false,
                                                    "search": true,
                                                    "singleSelect": true,
                                                    "showLabel": "value",
                                                    "errorMessage": {
                                                        "noData": "This fields is required"
                                                    },
                                                    "value": "",
                                                    "imageUrl": "",
                                                    "disabled": false,
                                                    "wrapperClassName": "",
                                                    "connectedArrays": [],
                                                    "showBlankInPlaceholder": false,
                                                    "blankDataList": [],
                                                    "commonPathOfImage": "",
                                                    "__schema_id": "inv_item",
                                                    "showInputLabel": false,
                                                    "labelContent": "dropdown_532",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_289",
                                            "id": "box_90",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": "",
                                            "draggable": false
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "vendor",
                                                    "id": "vendor",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": true,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": false,
                                                    "customValidation": [],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "vendor",
                                                    "showInputLabel": false,
                                                    "labelContent": "text_305",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_924",
                                            "id": "box_584",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": ""
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "capacity",
                                                    "id": "capacity",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": true,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": true,
                                                    "customValidation": [
                                                        {
                                                            "regularExpression": "/^\\d+$/",
                                                            "errorMessage": "Please enter a valid number"
                                                        }
                                                    ],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "capacity",
                                                    "showInputLabel": false,
                                                    "labelContent": "text_166",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_12",
                                            "id": "box_908",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": ""
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "units_available",
                                                    "id": "units_available",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": true,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": true,
                                                    "customValidation": [
                                                        {
                                                            "regularExpression": "/^\\d+$/",
                                                            "errorMessage": "Please enter a valid number"
                                                        }
                                                    ],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "units_available",
                                                    "showInputLabel": false,
                                                    "labelContent": "text_159",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_213",
                                            "id": "box_293",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": ""
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "text",
                                                    "name": "units_taken",
                                                    "id": "units_taken",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control ",
                                                    "regularExpression": "",
                                                    "disabled": false,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": true,
                                                    "customValidation": [
                                                        {
                                                            "regularExpression": "/^\\d+$/",
                                                            "errorMessage": "Please enter a valid number"
                                                        }
                                                    ],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "units_taken",
                                                    "showInputLabel": false,
                                                    "labelContent": "text_885",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_214",
                                            "id": "box_798",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "action-wrap ",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": ""
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [
                                                {
                                                    "type": "button",
                                                    "id": "infoicon",
                                                    "value": "View Location",
                                                    "buttonType": "storage_info",
                                                    "classname": "info-icon",
                                                    "divClass": "",
                                                    "controlEndPoints": [],
                                                    "disabled": false,
                                                    "validationEndPoints": [],
                                                    "invalid": false,
                                                    "name": "infoicon",
                                                    "wrapperClassName": "text-nowrap",
                                                    "draggable": false
                                                },
                                                {
                                                    "type": "text",
                                                    "name": "item_details",
                                                    "id": "item_details",
                                                    "value": "",
                                                    "placeholder": "",
                                                    "multiline": false,
                                                    "classname": "form-control d-none",
                                                    "regularExpression": "",
                                                    "disabled": false,
                                                    "required": false,
                                                    "errorMessage": {
                                                        "noData": "This fields is required",
                                                        "regexFail": ""
                                                    },
                                                    "validationButtonType": "",
                                                    "rows": 0,
                                                    "columns": 0,
                                                    "onDemandError": false,
                                                    "customValidation": [],
                                                    "multiError": false,
                                                    "applicationError": "",
                                                    "wrapperClassName": "",
                                                    "requiredErrorMessage": "This fields is required",
                                                    "__schema_id": "item_details",
                                                    "showInputLabel": false,
                                                    "labelContent": "text_995",
                                                    "labelClass": "form-label",
                                                    "isLabelAfter": false,
                                                    "showAdditionalInputContent": false,
                                                    "additionalInputContent": "",
                                                    "isAdditonalInputAfter": true,
                                                    "draggable": false
                                                }
                                            ],
                                            "name": "box_261",
                                            "id": "box_126",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": " ",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": ""
                                        },
                                        "classname": ""
                                    },
                                    {
                                        "fields": {
                                            "control": [],
                                            "name": "box_564",
                                            "id": "box_24",
                                            "wrapperType": "BOX",
                                            "fields": [],
                                            "type": "",
                                            "classname": "",
                                            "addButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "removeButton": {
                                                "classname": "",
                                                "value": ""
                                            },
                                            "wrapperClassName": ""
                                        },
                                        "classname": ""
                                    }
                                ]
                            }
                        }
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false
            }
        ],
        "type": "",
        "classname": "",
        "showClass": true,
        "payload_schema": {
            "consumables": [
                {
                    "inv_category": "select_inventory",
                    "inv_item": "inv_item",
                    "vendor": "vendor.$d{}",
                    "vol_capacity": "capacity.$t{number}",
                    "no_unit_available": "units_available.$t{number}",
                    "no_unit_taken": "units_taken.$d{}",
                    "storage": "item_details.{{assigned_storage}}"
                }
            ],
            "inventory__": {
                "itm_qty": [
                    {
                        "inv_item_id": "inv_item.{{id}}",
                        "qntity_taken": "units_taken.$d{}"
                    }
                ]
            }
        }
    },
    //project summary
    {
        "control": [],
        "head": "",
        "name": "main",
        "id": "main",
        "jsonId": 154,
        "wrapperType": "REGULAR",
        "fields": [
            {
                "control": [
                    {
                        "type": "label",
                        "name": "<h2 class=\"add-heading heading-without-icon\"><span>Summary</span></h2>",
                        "classname": "w-100",
                        "for": "",
                        "required": false,
                        "wrapperClassName": "",
                        "id": "label_98",
                        "draggable": false
                    }
                ],
                "type": "",
                "name": "wrapper_847",
                "classname": "col-12",
                "wrapperType": "BOX",
                "id": "wrapper_850",
                "fields": [
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_650",
                        "classname": "row mb-3",
                        "wrapperType": "BOX",
                        "id": "wrapper_860",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "proj_type",
                                        "id": "textBox_622",
                                        "value": "Analysis of Binding Clones by ELISA",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": true,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "proj_type",
                                        "showInputLabel": true,
                                        "labelContent": "Project Type",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_440",
                                "classname": "col-4 mb-4",
                                "wrapperType": "BOX",
                                "id": "wrapper_855",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "elisa_type",
                                        "id": "textBox_918",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": true,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "elisa_type",
                                        "showInputLabel": true,
                                        "labelContent": "ELISA Type",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_995",
                                "classname": "col-4 mb-4",
                                "wrapperType": "BOX",
                                "id": "wrapper_596",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "title",
                                        "id": "textBox_420",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": true,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "title",
                                        "showInputLabel": true,
                                        "labelContent": "Project Title",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_457",
                                "classname": "col-4 mb-4",
                                "wrapperType": "BOX",
                                "id": "wrapper_393",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                            {
                                "control": [
                                    {
                                        "type": "text",
                                        "name": "objective",
                                        "id": "textBox_420",
                                        "value": "",
                                        "placeholder": "",
                                        "multiline": false,
                                        "classname": "form-control ",
                                        "regularExpression": "",
                                        "disabled": false,
                                        "required": false,
                                        "errorMessage": {
                                            "noData": "This fields is required",
                                            "regexFail": ""
                                        },
                                        "validationButtonType": "",
                                        "rows": 0,
                                        "columns": 0,
                                        "onDemandError": false,
                                        "customValidation": [],
                                        "multiError": false,
                                        "applicationError": "",
                                        "wrapperClassName": "",
                                        "requiredErrorMessage": "This fields is required",
                                        "__schema_id": "objective",
                                        "showInputLabel": true,
                                        "labelContent": "Objective",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "wrapper_457",
                                "classname": "col-6",
                                "wrapperType": "BOX",
                                "id": "wrapper_393",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": ""
                            },
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    },
                    // {
                    //     "control": [],
                    //     "type": "",
                    //     "name": "wrapper_195",
                    //     "classname": "row mb-3",
                    //     "wrapperType": "BOX",
                    //     "id": "wrapper_646",
                    //     "fields": [
                    //         {
                    //             "control": [
                    //                 {
                    //                     "type": "dropdown",
                    //                     "name": "objective",
                    //                     "id": "dropDown_637",
                    //                     "validationButtonType": "",
                    //                     "classname": "",
                    //                     "required": false,
                    //                     "apiEndPoints": [],
                    //                     "options": [],
                    //                     "multiple": false,
                    //                     "isClearable": false,
                    //                     "isStatus": false,
                    //                     "search": false,
                    //                     "singleSelect": true,
                    //                     "showLabel": "",
                    //                     "errorMessage": {
                    //                         "noData": "This fields is required"
                    //                     },
                    //                     "value": "",
                    //                     "imageUrl": "",
                    //                     "disabled": false,
                    //                     "wrapperClassName": "",
                    //                     "connectedArrays": [],
                    //                     "showBlankInPlaceholder": false,
                    //                     "blankDataList": [],
                    //                     "commonPathOfImage": "",
                    //                     "__schema_id": "",
                    //                     "showInputLabel": true,
                    //                     "labelContent": "Objective",
                    //                     "labelClass": "form-label",
                    //                     "isLabelAfter": false,
                    //                     "showAdditionalInputContent": false,
                    //                     "additionalInputContent": "",
                    //                     "isAdditonalInputAfter": true,
                    //                     "draggable": false
                    //                 }
                    //             ],
                    //             "type": "",
                    //             "name": "wrapper_413",
                    //             "classname": "col-12",
                    //             "wrapperType": "BOX",
                    //             "id": "wrapper_270",
                    //             "fields": [],
                    //             "head": "",
                    //             "tabHeadClass": ""
                    //         }
                    //     ],
                    //     "head": "",
                    //     "tabHeadClass": "",
                    //     "draggable": false
                    // },
                    {
                        "control": [
                            {
                                "type": "text",
                                "name": "comments",
                                "id": "textBox_256",
                                "value": "",
                                "placeholder": "Enter your comments if any",
                                "multiline": true,
                                "classname": "form-control ",
                                "regularExpression": "",
                                "disabled": false,
                                "required": false,
                                "errorMessage": {
                                    "noData": "This fields is required",
                                    "regexFail": ""
                                },
                                "validationButtonType": "",
                                "rows": 0,
                                "columns": 0,
                                "onDemandError": false,
                                "customValidation": [],
                                "multiError": false,
                                "applicationError": "",
                                "wrapperClassName": "",
                                "requiredErrorMessage": "This fields is required",
                                "__schema_id": "comments",
                                "showInputLabel": true,
                                "labelContent": "Experiment Notes/Comments",
                                "labelClass": "form-label",
                                "isLabelAfter": false,
                                "showAdditionalInputContent": false,
                                "additionalInputContent": "",
                                "isAdditonalInputAfter": true,
                                "draggable": false
                            }
                        ],
                        "type": "",
                        "name": "wrapper_269",
                        "classname": "col-12",
                        "wrapperType": "BOX",
                        "id": "wrapper_521",
                        "fields": [],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false
            }
        ],
        "type": "",
        "classname": "",
        "showClass": true,
        "payload_schema": {
            "project_summary": {
                "title": "title",
                "proj_type": "proj_type",
                "elisa_type": "elisa_type",
                "objective": "objective",
                "comments": "comments"
            }
        },
    },
    // upload file
    {
        jsonId: 155,
        control: [],
        head: '',
        name: 'main',
        id: 'main',
        wrapperType: 'REGULAR',
        fields: [
            {
                control: [
                    {
                        type: 'dropdown',
                        showBlankInPlaceholder: true,
                        blankDataList: ['""', 'undefined', 'null'
                        ],
                        name: 'file_type',
                        id: 'file_type',
                        validationButtonType: 'upload',
                        classname: '',
                        required: false,
                        apiEndPoints: [],
                        options: [
                            {
                                key: 'nucleotide_sequence',
                                value: 'Nucleotide Sequence File',
                            },
                            {
                                key: 'amino_acid_sequence',
                                value: 'Amino Acid Sequence File',
                            }
                        ],
                        multiple: false,
                        isClearable: false,
                        isStatus: false,
                        search: true,
                        singleSelect: true,
                        showLabel: 'value',
                        errorMessage: { noData: 'This field is required'
                        },
                        value: "",
                        imageUrl: '',
                        disabled: false,
                        wrapperClassName: '',
                        showInputLabel: true,
                        labelContent: 'Select File Type',
                        labelClass: 'form-label',
                        isLabelAfter: false,
                        showAdditionalInputContent: false,
                        additionalInputContent: '',
                        isAdditonalInputAfter: true,
                        draggable: false,
                    },
                ],
                type: '',
                name: 'wrapper_801',
                classname: 'col-12 mb-3',
                wrapperType: 'BOX',
                id: 'wrapper_766',
                fields: [],
                head: '',
                tabHeadClass: '',
                draggable: false,
            },
            {
                control: [
                    {
                        type: 'file',
                        name: 'file',
                        id: 'file',
                        value: 'upload',
                        classname: 'btn btn-file',
                        fileNameShow: true,
                        size: 10,
                        url: '',
                        functionName: '',
                        wrapperClassName: '',
                        fileCount: 1,
                        sizeKB: '50000',
                        allowMultiple: false,
                        required: false,
                        validationButtonType: 'upload',
                        formDataType: 'blob',
                        errorMsg: { invalidFormat: 'Invalid File Format', invalidSize: 'File Size exceeded'
                        },
                        // fileTypes: ['all'
                        // ],
                        fileTypes: ['pdf', 'doc', 'ab1', 'txt', 'xlsx', 'xlsm', 'xlsb', 'xltx', 'xltm', 'xls', 'xlt', 'xml', 'xla', 'xlam', 'xlw', 'xlr', 'jpeg', 'png'],
                        listClass: '',
                        mainClass: '',
                        btnDivClass: '',
                        showInputLabel: true,
                        labelContent: 'Drag and drop files here or browse files  ',
                        labelClass: 'form-label',
                        requiredErrorMessage: 'This field is required',
                        isLabelAfter: false,
                        showAdditionalInputContent: false,
                        additionalInputContent: '',
                        isAdditonalInputAfter: true,
                        draggable: false,
                        showViewIcon: false,
                        showDownloadIcon: false,
                    },
                ],
                type: '',
                name: 'wrapper_379',
                classname: 'col-12 mb-3 drag-and-drop',
                wrapperType: 'BOX',
                id: 'wrapper_461',
                fields: [],
                head: '',
                tabHeadClass: '',
                draggable: false,
            },
        ],
        type: '',
        classname: '',
    },
]

export const APCE_WORKFLOW = [
    {
        "control": [],
        "head": "",
        "name": "main",
        "id": "main",
        "jsonId": 157,
        "wrapperType": "REGULAR",
        "fields": [
            {
                "control": [],
                "type": "",
                "name": "wrapper_156",
                "classname": "row",
                "wrapperType": "BOX",
                "id": "wrapper_880",
                "fields": [
                    {
                        "control": [
                            {
                                "type": "dropdown",
                                "name": "protocol_id",
                                "id": "selectProtocol",
                                "validationButtonType": "",
                                "classname": "mb-3",
                                "required": false,
                                "apiEndPoints": [
                                    {
                                        "type": "init",
                                        "method": "get",
                                        "url": "protocol/protocol-list"
                                    },
                                    {
                                        "type": "change",
                                        "method": "get",
                                        "Mapto": [
                                            {
                                                "id": "main.wrapper_880.wrapper_90.protocol_file_id_1",
                                                "displayKey": "change",
                                                "urlParameterValues": {
                                                    "id": null,
                                                    "patchProperty": "protocol_file_id"
                                                }
                                            }
                                        ],
                                        "url": "protocol/${id}"
                                    }
                                ],
                                "options": [],
                                "multiple": false,
                                "isClearable": false,
                                "isStatus": false,
                                "search": false,
                                "singleSelect": true,
                                "showLabel": "value",
                                "errorMessage": {
                                    "noData": "This fields is required"
                                },
                                "value": "",
                                "imageUrl": "",
                                "disabled": false,
                                "wrapperClassName": "",
                                "connectedArrays": [],
                                "showBlankInPlaceholder": false,
                                "blankDataList": [],
                                "commonPathOfImage": "",
                                "__schema_id": "protocol_id",
                                "showInputLabel": true,
                                "labelContent": "Select Protocol",
                                "labelClass": "form-label",
                                "isLabelAfter": false,
                                "showAdditionalInputContent": false,
                                "additionalInputContent": "",
                                "isAdditonalInputAfter": true,
                                "draggable": false
                            },
                            {
                                "type": "text",
                                "name": "protocol_file_id",
                                "id": "protocol_file_id_1",
                                "value": "",
                                "placeholder": "",
                                "multiline": false,
                                "classname": "form-control d-none",
                                "regularExpression": "",
                                "disabled": true,
                                "required": false,
                                "errorMessage": {
                                    "noData": "This fields is required",
                                    "regexFail": ""
                                },
                                "validationButtonType": "",
                                "rows": 0,
                                "columns": 0,
                                "onDemandError": false,
                                "customValidation": [],
                                "multiError": false,
                                "applicationError": "",
                                "wrapperClassName": "",
                                "requiredErrorMessage": "This fields is required",
                                "__schema_id": "protocol_file_id",
                                "showInputLabel": false,
                                "labelContent": "Bacteria Strain",
                                "labelClass": "form-label mandatory ",
                                "isLabelAfter": false,
                                "showAdditionalInputContent": false,
                                "additionalInputContent": "",
                                "isAdditonalInputAfter": true,
                                "draggable": false
                            },
                            {
                                "type": "button",
                                "id": "button_878",
                                "value": "<div class=\"custom-tooltip\"><p>View </p></div>",
                                "buttonType": "view-protocol",
                                "classname": "view icon-align ms-3",
                                "divClass": "",
                                "controlEndPoints": [],
                                "disabled": false,
                                "validationEndPoints": [],
                                "invalid": false,
                                "name": "button_935",
                                "wrapperClassName": "actions position-relative ",
                                "draggable": false
                            }
                        ],
                        "type": "",
                        "name": "wrapper_652",
                        "classname": "col-3 mb-3 action-wrap",
                        "wrapperType": "BOX",
                        "id": "wrapper_90",
                        "fields": [],
                        "head": "",
                        "tabHeadClass": ""
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false
            },
            {
                "control": [],
                "type": "",
                "name": "wrapper_547",
                "classname": "accordion accordion-custom",
                "wrapperType": "ACCORDION",
                "id": "wrapper_120",
                "fields": [
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_240",
                        "classname": "col-12",
                        "wrapperType": "BOX",
                        "id": "wrapper_456",
                        "fields": [
                            {
                                "control": [],
                                "type": "",
                                "name": "clone_antigen_info",
                                "classname": "table selected-clone-antigen-table",
                                "wrapperType": "TABLE",
                                "id": "wrapper_97",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false,
                                "hideSingleItemDelete": false,
                                "tableFields": {
                                    "showHead": true,
                                    "isVertical": false,
                                    "isIteratable": false,
                                    "addButton": {
                                        "classname": "btn btn-primary",
                                        "name": "",
                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                    },
                                    "removeButton": {
                                        "classname": "btn btn-danger",
                                        "name": "",
                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                    },
                                    "tableHead": {
                                        "heading": [
                                            {
                                                "value": "Clonal Phage Plate id",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Clones picked",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Phage Dilution",
                                                "classname": ""
                                            }
                                        ],
                                        "classname": ""
                                    },
                                    "initRecordCount": 1,
                                    "showRecordCount": false,
                                    "recordCounterConfig": {
                                        "classname": "",
                                        "adjValue": "",
                                        "isAdjValueAfter": false
                                    },
                                    "tableRow": {
                                        "name": "",
                                        "classname": "trclass",
                                        "tableCols": [
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "clonal_phage_plate_id",
                                                            "id": "textBox_814",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": true,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "clonal_phage_plate_id",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_759",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "head": "",
                                                    "name": "box_876",
                                                    "id": "box_894",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "clones_picked",
                                                            "id": "textBox_374",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": true,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "clones_picked",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_508",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_139",
                                                    "id": "box_440",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "phage_dilution",
                                                            "id": "textBox_901",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": true,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "phage_dilution",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_469",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_854",
                                                    "id": "box_413",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            }
                                        ]
                                    }
                                }
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "",
                            "headerBtnClassName": "",
                            "header": "<span>Selected Clone & Antigen Info</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "",
                            "collapseOthers": true
                        }
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_282",
                        "classname": "col-12",
                        "wrapperType": "BOX",
                        "id": "wrapper_844",
                        "fields": [
                            {
                                "control": [
                                    {
                                        "type": "dropdown",
                                        "name": "dropDown_408",
                                        "id": "dropDown_451",
                                        "validationButtonType": "",
                                        "classname": "",
                                        "required": false,
                                        "apiEndPoints": [],
                                        "options": [],
                                        "multiple": false,
                                        "isClearable": false,
                                        "isStatus": false,
                                        "search": false,
                                        "singleSelect": true,
                                        "showLabel": "",
                                        "errorMessage": {
                                            "noData": "This fields is required"
                                        },
                                        "value": "",
                                        "imageUrl": "",
                                        "disabled": false,
                                        "wrapperClassName": "",
                                        "connectedArrays": [],
                                        "showBlankInPlaceholder": false,
                                        "blankDataList": [],
                                        "commonPathOfImage": "",
                                        "__schema_id": "",
                                        "showInputLabel": true,
                                        "labelContent": "dropdown_348",
                                        "labelClass": "form-label",
                                        "isLabelAfter": false,
                                        "showAdditionalInputContent": false,
                                        "additionalInputContent": "",
                                        "isAdditonalInputAfter": true,
                                        "draggable": false
                                    }
                                ],
                                "type": "",
                                "name": "antibody_phage_titration_details",
                                "classname": "table antibody-phage-titration-details-table",
                                "wrapperType": "TABLE",
                                "id": "wrapper_122",
                                "fields": [
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_250",
                                        "classname": "row",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_287",
                                        "fields": [
                                            {
                                                "control": [],
                                                "type": "",
                                                "name": "wrapper_652",
                                                "classname": "col-6",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_533",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            },
                                            {
                                                "control": [],
                                                "type": "",
                                                "name": "wrapper_730",
                                                "classname": "col-6",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_452",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            }
                                        ],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_291",
                                        "classname": "row d-flex",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_504",
                                        "fields": [
                                            {
                                                "control": [
                                                    {
                                                        "type": "text",
                                                        "name": "textBox_327",
                                                        "id": "textBox_337",
                                                        "value": "",
                                                        "placeholder": "",
                                                        "multiline": false,
                                                        "classname": "form-control ",
                                                        "regularExpression": "",
                                                        "disabled": false,
                                                        "required": false,
                                                        "errorMessage": {
                                                            "noData": "This fields is required",
                                                            "regexFail": ""
                                                        },
                                                        "validationButtonType": "",
                                                        "rows": 0,
                                                        "columns": 0,
                                                        "onDemandError": false,
                                                        "customValidation": [],
                                                        "multiError": false,
                                                        "applicationError": "",
                                                        "wrapperClassName": "",
                                                        "requiredErrorMessage": "This fields is required",
                                                        "__schema_id": "",
                                                        "showInputLabel": true,
                                                        "labelContent": "text_884",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "type": "",
                                                "name": "wrapper_724",
                                                "classname": "col-6",
                                                "wrapperType": "ITERATE",
                                                "id": "wrapper_718",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": "",
                                                "ButtonPosition": "top",
                                                "addButton": {
                                                    "classname": "btn",
                                                    "name": "",
                                                    "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                                },
                                                "removeButton": {
                                                    "classname": "btn",
                                                    "name": "",
                                                    "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                                },
                                                "controlButtonClass": "d-none",
                                                "hideSingleItemDelete": false,
                                                "addBtnPostion": null
                                            },
                                            {
                                                "control": [
                                                    {
                                                        "type": "dropdown",
                                                        "name": "dropDown_459",
                                                        "id": "dropDown_82",
                                                        "validationButtonType": "",
                                                        "classname": "",
                                                        "required": false,
                                                        "apiEndPoints": [],
                                                        "options": [],
                                                        "multiple": false,
                                                        "isClearable": false,
                                                        "isStatus": false,
                                                        "search": false,
                                                        "singleSelect": true,
                                                        "showLabel": "",
                                                        "errorMessage": {
                                                            "noData": "This fields is required"
                                                        },
                                                        "value": "",
                                                        "imageUrl": "",
                                                        "disabled": false,
                                                        "wrapperClassName": "",
                                                        "connectedArrays": [],
                                                        "showBlankInPlaceholder": false,
                                                        "blankDataList": [],
                                                        "commonPathOfImage": "",
                                                        "__schema_id": "",
                                                        "showInputLabel": true,
                                                        "labelContent": "dropdown_702",
                                                        "labelClass": "form-label",
                                                        "isLabelAfter": false,
                                                        "showAdditionalInputContent": false,
                                                        "additionalInputContent": "",
                                                        "isAdditonalInputAfter": true,
                                                        "draggable": false
                                                    }
                                                ],
                                                "type": "",
                                                "name": "wrapper_105",
                                                "classname": "col-6",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_820",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            }
                                        ],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    },
                                    {
                                        "control": [],
                                        "type": "",
                                        "name": "wrapper_357",
                                        "classname": "row",
                                        "wrapperType": "BOX",
                                        "id": "wrapper_915",
                                        "fields": [
                                            {
                                                "control": [],
                                                "type": "",
                                                "name": "wrapper_856",
                                                "classname": "col-6",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_727",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            },
                                            {
                                                "control": [],
                                                "type": "",
                                                "name": "wrapper_367",
                                                "classname": "col-6",
                                                "wrapperType": "BOX",
                                                "id": "wrapper_279",
                                                "fields": [],
                                                "head": "",
                                                "tabHeadClass": ""
                                            }
                                        ],
                                        "head": "",
                                        "tabHeadClass": "",
                                        "draggable": false
                                    }
                                ],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false,
                                "hideSingleItemDelete": false,
                                "tableFields": {
                                    "showHead": true,
                                    "isVertical": false,
                                    "isIteratable": false,
                                    "addButton": {
                                        "classname": "btn btn-primary",
                                        "name": "",
                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                    },
                                    "removeButton": {
                                        "classname": "btn btn-danger",
                                        "name": "",
                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                    },
                                    "tableHead": {
                                        "heading": [
                                            {
                                                "value": "Antigen",
                                                "classname": ""
                                            },
                                            {
                                                "value": "No.Of Antigen Conc",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Antigen Conc",
                                                "classname": ""
                                            },
                                            {
                                                "value": "No.Of Wells Coated",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Blocking Solution",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Select The Phage Dilution",
                                                "classname": ""
                                            }
                                        ],
                                        "classname": ""
                                    },
                                    "initRecordCount": 1,
                                    "showRecordCount": false,
                                    "recordCounterConfig": {
                                        "classname": "",
                                        "adjValue": "",
                                        "isAdjValueAfter": false
                                    },
                                    "tableRow": {
                                        "name": "",
                                        "classname": "trclass",
                                        "tableCols": [
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "antigen",
                                                            "id": "textBox_651",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": true,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "antigen",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_838",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "head": "",
                                                    "name": "box_205",
                                                    "id": "box_72",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "dropdown",
                                                            "name": "no_of_antigen",
                                                            "id": "dropDown_92",
                                                            "validationButtonType": "",
                                                            "classname": "",
                                                            "required": false,
                                                            "apiEndPoints": [],
                                                            "options": [
                                                                1,
                                                                2,
                                                                3,
                                                                4,
                                                                5,
                                                                6,
                                                                7,
                                                                8,
                                                                9,
                                                                10
                                                            ],
                                                            "multiple": true,
                                                            "isClearable": false,
                                                            "isStatus": false,
                                                            "search": false,
                                                            "singleSelect": true,
                                                            "showLabel": "",
                                                            "errorMessage": {
                                                                "noData": "This fields is required"
                                                            },
                                                            "value": "",
                                                            "imageUrl": "",
                                                            "disabled": false,
                                                            "wrapperClassName": "",
                                                            "connectedArrays": [],
                                                            "showBlankInPlaceholder": false,
                                                            "blankDataList": [],
                                                            "commonPathOfImage": "",
                                                            "__schema_id": "no_of_antigen",
                                                            "showInputLabel": false,
                                                            "labelContent": "dropdown_332",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_720",
                                                    "id": "box_116",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [],
                                                    "name": "antigen_conc",
                                                    "id": "box_377",
                                                    "wrapperType": "BOX",
                                                    "fields": [
                                                        {
                                                            "control": [],
                                                            "type": "",
                                                            "name": "wrapper_63",
                                                            "classname": "me-3",
                                                            "wrapperType": "BOX",
                                                            "id": "wrapper_422",
                                                            "fields": [
                                                                {
                                                                    "control": [
                                                                        {
                                                                            "type": "text",
                                                                            "name": "antigen_conc",
                                                                            "id": "textBox_255",
                                                                            "value": "",
                                                                            "placeholder": "",
                                                                            "multiline": false,
                                                                            "classname": "form-control",
                                                                            "regularExpression": "",
                                                                            "disabled": false,
                                                                            "required": false,
                                                                            "errorMessage": {
                                                                                "noData": "This fields is required",
                                                                                "regexFail": ""
                                                                            },
                                                                            "validationButtonType": "",
                                                                            "rows": 0,
                                                                            "columns": 0,
                                                                            "onDemandError": false,
                                                                            "customValidation": [],
                                                                            "multiError": false,
                                                                            "applicationError": "",
                                                                            "wrapperClassName": "",
                                                                            "requiredErrorMessage": "This fields is required",
                                                                            "__schema_id": "antigen_conc",
                                                                            "showInputLabel": false,
                                                                            "labelContent": "text_382",
                                                                            "labelClass": "form-label",
                                                                            "isLabelAfter": false,
                                                                            "showAdditionalInputContent": false,
                                                                            "additionalInputContent": "",
                                                                            "isAdditonalInputAfter": true,
                                                                            "draggable": false
                                                                        }
                                                                    ],
                                                                    "type": "",
                                                                    "name": "antigen_conc",
                                                                    "classname": "col-12",
                                                                    "wrapperType": "ITERATE",
                                                                    "id": "wrapper_345",
                                                                    "fields": [],
                                                                    "head": "",
                                                                    "tabHeadClass": "",
                                                                    "ButtonPosition": "top",
                                                                    "addButton": {
                                                                        "classname": "btn",
                                                                        "name": "",
                                                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                                                    },
                                                                    "removeButton": {
                                                                        "classname": "btn",
                                                                        "name": "",
                                                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                                                    },
                                                                    "controlButtonClass": "d-none",
                                                                    "hideSingleItemDelete": false,
                                                                    "addBtnPostion": null,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "head": "",
                                                            "tabHeadClass": "",
                                                            "draggable": false
                                                        },
                                                        {
                                                            "control": [
                                                                {
                                                                    "type": "dropdown",
                                                                    "name": "antigen_conc_unit",
                                                                    "id": "dropDown_973",
                                                                    "validationButtonType": "",
                                                                    "classname": "",
                                                                    "required": false,
                                                                    "apiEndPoints": [],
                                                                    "options": ["µg/ml", "nM"],
                                                                    "multiple": false,
                                                                    "isClearable": false,
                                                                    "isStatus": false,
                                                                    "search": false,
                                                                    "singleSelect": true,
                                                                    "showLabel": "",
                                                                    "errorMessage": {
                                                                        "noData": "This fields is required"
                                                                    },
                                                                    "value": "",
                                                                    "imageUrl": "",
                                                                    "disabled": false,
                                                                    "wrapperClassName": "",
                                                                    "connectedArrays": [],
                                                                    "showBlankInPlaceholder": false,
                                                                    "blankDataList": [],
                                                                    "commonPathOfImage": "",
                                                                    "__schema_id": "antigen_conc_unit",
                                                                    "showInputLabel": false,
                                                                    "labelContent": "dropdown_860",
                                                                    "labelClass": "form-label",
                                                                    "isLabelAfter": false,
                                                                    "showAdditionalInputContent": false,
                                                                    "additionalInputContent": "",
                                                                    "isAdditonalInputAfter": true,
                                                                    "draggable": false
                                                                }
                                                            ],
                                                            "type": "",
                                                            "name": "wrapper_394",
                                                            "classname": "",
                                                            "wrapperType": "BOX",
                                                            "id": "wrapper_798",
                                                            "fields": [],
                                                            "head": "",
                                                            "tabHeadClass": "",
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "type": "",
                                                    "classname": "action-wrap",
                                                    "wrapperClassName": "",
                                                    "addBtnPostion": null
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "no_of_wells",
                                                            "id": "textBox_663",
                                                            "value": "2 + 2",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": true,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "no_of_wells",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_604",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_261",
                                                    "id": "box_34",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "dropdown",
                                                            "name": "blocking_solution",
                                                            "id": "dropDown_609",
                                                            "validationButtonType": "",
                                                            "classname": "",
                                                            "required": false,
                                                            "apiEndPoints": [
                                                                {
                                                                    "type": "init",
                                                                    "method": "post",
                                                                    "url": "inventory_item",
                                                                    "payload": {
                                                                        "user_input": "",
                                                                        "perma": "lab-reagents"
                                                                    },
                                                                    "Mapto": [
                                                                        {
                                                                            "id": "wrapper_844.wrapper_122.0.box_120.dropDown_609"
                                                                        }
                                                                    ]
                                                                }
                                                            ],
                                                            "options": [],
                                                            "multiple": false,
                                                            "isClearable": false,
                                                            "isStatus": false,
                                                            "search": false,
                                                            "singleSelect": true,
                                                            "showLabel": "value",
                                                            "errorMessage": {
                                                                "noData": "This fields is required"
                                                            },
                                                            "value": "",
                                                            "imageUrl": "",
                                                            "disabled": false,
                                                            "wrapperClassName": "",
                                                            "connectedArrays": [],
                                                            "showBlankInPlaceholder": false,
                                                            "blankDataList": [],
                                                            "commonPathOfImage": "",
                                                            "__schema_id": "blocking_solution",
                                                            "showInputLabel": false,
                                                            "labelContent": "dropdown_604",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_17",
                                                    "id": "box_120",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "dropdown",
                                                            "name": "phage_dilution",
                                                            "id": "dropDown_260",
                                                            "validationButtonType": "",
                                                            "classname": "",
                                                            "required": false,
                                                            "apiEndPoints": [],
                                                            "options": [
                                                                1,
                                                                2.5,
                                                                5,
                                                                10
                                                            ],
                                                            "multiple": true,
                                                            "isClearable": false,
                                                            "isStatus": false,
                                                            "search": false,
                                                            "singleSelect": true,
                                                            "showLabel": "",
                                                            "errorMessage": {
                                                                "noData": "This fields is required"
                                                            },
                                                            "value": "",
                                                            "imageUrl": "",
                                                            "disabled": false,
                                                            "wrapperClassName": "",
                                                            "connectedArrays": [],
                                                            "showBlankInPlaceholder": false,
                                                            "blankDataList": [],
                                                            "commonPathOfImage": "",
                                                            "__schema_id": "phage_dilution",
                                                            "showInputLabel": false,
                                                            "labelContent": "dropdown_229",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_705",
                                                    "id": "box_616",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            }
                                        ]
                                    }
                                }
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "",
                            "headerBtnClassName": "",
                            "header": "<span>Antibody Phage Titration Details</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "",
                            "collapseOthers": true
                        },
                        "showClass": true
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_161",
                        "classname": "col-12",
                        "wrapperType": "BOX",
                        "id": "wrapper_243",
                        "fields": [
                            {
                                "control": [],
                                "type": "",
                                "name": "plate_development",
                                "classname": "table plate-development-table",
                                "wrapperType": "TABLE",
                                "id": "wrapper_979",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false,
                                "hideSingleItemDelete": false,
                                "tableFields": {
                                    "showHead": true,
                                    "isVertical": false,
                                    "isIteratable": false,
                                    "addButton": {
                                        "classname": "btn btn-primary",
                                        "name": "",
                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                    },
                                    "removeButton": {
                                        "classname": "btn btn-danger",
                                        "name": "",
                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                    },
                                    "tableHead": {
                                        "heading": [
                                            {
                                                "value": "Plate type",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Plate ID",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Barcode ID",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Antigen Antibody Solution Vol (µl)",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Reaction Stop Solution",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Stop Solution Vol (µl)",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Absorbance (nm)",
                                                "classname": ""
                                            }
                                        ],
                                        "classname": ""
                                    },
                                    "initRecordCount": 1,
                                    "showRecordCount": false,
                                    "recordCounterConfig": {
                                        "classname": "",
                                        "adjValue": "",
                                        "isAdjValueAfter": false
                                    },
                                    "tableRow": {
                                        "name": "",
                                        "classname": "trclass",
                                        "tableCols": [
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "dropdown",
                                                            "name": "plate_type",
                                                            "id": "dropDown_144",
                                                            "validationButtonType": "",
                                                            "classname": "",
                                                            "required": false,
                                                            "apiEndPoints": [],
                                                            "options": [],
                                                            "multiple": false,
                                                            "isClearable": false,
                                                            "isStatus": false,
                                                            "search": false,
                                                            "singleSelect": true,
                                                            "showLabel": "",
                                                            "errorMessage": {
                                                                "noData": "This fields is required"
                                                            },
                                                            "value": "384 well Maxisorp immunoplate",
                                                            "imageUrl": "",
                                                            "disabled": false,
                                                            "wrapperClassName": "",
                                                            "connectedArrays": [],
                                                            "showBlankInPlaceholder": false,
                                                            "blankDataList": [],
                                                            "commonPathOfImage": "",
                                                            "__schema_id": "plate_type",
                                                            "showInputLabel": false,
                                                            "labelContent": "dropdown_111",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "head": "",
                                                    "name": "box_419",
                                                    "id": "box_774",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "plate_id",
                                                            "id": "textBox_326",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "plate_id",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_864",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_398",
                                                    "id": "box_68",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "barcode_id",
                                                            "id": "textBox_600",
                                                            "value": "",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "barcode_id",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_270",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_750",
                                                    "id": "box_872",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "antibody_solution",
                                                            "id": "textBox_240",
                                                            "value": "30",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "antibody_solution",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_777",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_44",
                                                    "id": "box_496",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "reaction_stop_solution",
                                                            "id": "textBox_877",
                                                            "value": "H3PO4",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "reaction_stop_solution",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_897",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_287",
                                                    "id": "box_77",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "stop_solution",
                                                            "id": "textBox_332",
                                                            "value": "30",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "stop_solution",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_395",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_429",
                                                    "id": "box_20",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "absorbance",
                                                            "id": "textBox_837",
                                                            "value": "30",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "absorbance",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_685",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_404",
                                                    "id": "box_565",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            }
                                        ]
                                    }
                                }
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "",
                            "headerBtnClassName": "",
                            "header": "<span>Plate Development and the plate map section, plate setup</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "",
                            "collapseOthers": true
                        }
                    },
                    {
                        "control": [],
                        "type": "",
                        "name": "wrapper_553",
                        "classname": "col-12",
                        "wrapperType": "BOX",
                        "id": "wrapper_855",
                        "fields": [
                            {
                                "control": [],
                                "type": "",
                                "name": "antibody_conjugate",
                                "classname": "table antibody-conjugate-table",
                                "wrapperType": "TABLE",
                                "id": "wrapper_930",
                                "fields": [],
                                "head": "",
                                "tabHeadClass": "",
                                "draggable": false,
                                "hideSingleItemDelete": false,
                                "tableFields": {
                                    "showHead": true,
                                    "isVertical": false,
                                    "isIteratable": false,
                                    "addButton": {
                                        "classname": "btn btn-primary",
                                        "name": "",
                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
                                    },
                                    "removeButton": {
                                        "classname": "btn btn-danger",
                                        "name": "",
                                        "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
                                    },
                                    "tableHead": {
                                        "heading": [
                                            {
                                                "value": "Antibody Conjugate",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Antibody conjugate Vol (µl)",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Dilution factor",
                                                "classname": ""
                                            },
                                            {
                                                "value": "dilution buffer",
                                                "classname": ""
                                            },
                                            {
                                                "value": "substrate",
                                                "classname": ""
                                            },
                                            {
                                                "value": "substrate Vol (µl)",
                                                "classname": ""
                                            },
                                            {
                                                "value": "Wash Buffer",
                                                "classname": ""
                                            }
                                        ],
                                        "classname": ""
                                    },
                                    "initRecordCount": 1,
                                    "showRecordCount": false,
                                    "recordCounterConfig": {
                                        "classname": "",
                                        "adjValue": "",
                                        "isAdjValueAfter": false
                                    },
                                    "tableRow": {
                                        "name": "",
                                        "classname": "trclass",
                                        "tableCols": [
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "antibody_conjugate",
                                                            "id": "textBox_465",
                                                            "value": "Horseradish Peroxidase/anti M13",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "antibody_conjugate",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_915",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "head": "",
                                                    "name": "box_182",
                                                    "id": "box_199",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "antibody_conjugate_vol",
                                                            "id": "textBox_59",
                                                            "value": "300",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "antibody_conjugate_vol",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_63",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_607",
                                                    "id": "box_761",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "dilution_factor",
                                                            "id": "textBox_353",
                                                            "value": "1:3000",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "dilution_factor",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_937",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_854",
                                                    "id": "box_780",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            // {
                                            //     "fields": {
                                            //         "control": [
                                            //             {
                                            //                 "type": "text",
                                            //                 "name": "dilution_buffer",
                                            //                 "id": "textBox_716",
                                            //                 "value": "",
                                            //                 "placeholder": "",
                                            //                 "multiline": false,
                                            //                 "classname": "form-control ",
                                            //                 "regularExpression": "",
                                            //                 "disabled": false,
                                            //                 "required": false,
                                            //                 "errorMessage": {
                                            //                     "noData": "This fields is required",
                                            //                     "regexFail": ""
                                            //                 },
                                            //                 "validationButtonType": "",
                                            //                 "rows": 0,
                                            //                 "columns": 0,
                                            //                 "onDemandError": false,
                                            //                 "customValidation": [],
                                            //                 "multiError": false,
                                            //                 "applicationError": "",
                                            //                 "wrapperClassName": "",
                                            //                 "requiredErrorMessage": "This fields is required",
                                            //                 "__schema_id": "dilution_buffer",
                                            //                 "showInputLabel": false,
                                            //                 "labelContent": "text_616",
                                            //                 "labelClass": "form-label",
                                            //                 "isLabelAfter": false,
                                            //                 "showAdditionalInputContent": false,
                                            //                 "additionalInputContent": "",
                                            //                 "isAdditonalInputAfter": true,
                                            //                 "draggable": false
                                            //             }
                                            //         ],
                                            //         "name": "box_558",
                                            //         "id": "box_925",
                                            //         "wrapperType": "BOX",
                                            //         "fields": [],
                                            //         "type": "",
                                            //         "classname": "",
                                            //         "addButton": {
                                            //             "classname": "",
                                            //             "value": ""
                                            //         },
                                            //         "removeButton": {
                                            //             "classname": "",
                                            //             "value": ""
                                            //         },
                                            //         "wrapperClassName": ""
                                            //     },
                                            //     "classname": ""
                                            // },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "dropdown",
                                                            "name": "dilution_buffer",
                                                            "id": "dropDown_517",
                                                            "validationButtonType": "",
                                                            "classname": "",
                                                            "required": false,
                                                            "apiEndPoints": [
                                                                {
                                                                    type: 'init',
                                                                    method: 'post',
                                                                    url: 'inventory_item',
                                                                    payload: {
                                                                      user_input: '',
                                                                      perma: 'buffers',
                                                                    },
                                                                },
                                                            ],
                                                            "options": [],
                                                            "multiple": false,
                                                            "isClearable": false,
                                                            "isStatus": false,
                                                            "search": false,
                                                            "singleSelect": true,
                                                            "showLabel": "value",
                                                            "errorMessage": {
                                                                "noData": "This fields is required"
                                                            },
                                                            "value": "",
                                                            "imageUrl": "",
                                                            "disabled": false,
                                                            "wrapperClassName": "",
                                                            "connectedArrays": [],
                                                            "showBlankInPlaceholder": false,
                                                            "blankDataList": [],
                                                            "commonPathOfImage": "",
                                                            "__schema_id": "dilution_buffer",
                                                            "showInputLabel": false,
                                                            "labelContent": "dropdown_532",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_652",
                                                    "id": "box_684",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "substrate",
                                                            "id": "textBox_970",
                                                            "value": "TMB",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "substrate",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_557",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_830",
                                                    "id": "box_630",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "text",
                                                            "name": "substrate_vol",
                                                            "id": "textBox_509",
                                                            "value": "300",
                                                            "placeholder": "",
                                                            "multiline": false,
                                                            "classname": "form-control ",
                                                            "regularExpression": "",
                                                            "disabled": false,
                                                            "required": false,
                                                            "errorMessage": {
                                                                "noData": "This fields is required",
                                                                "regexFail": ""
                                                            },
                                                            "validationButtonType": "",
                                                            "rows": 0,
                                                            "columns": 0,
                                                            "onDemandError": false,
                                                            "customValidation": [],
                                                            "multiError": false,
                                                            "applicationError": "",
                                                            "wrapperClassName": "",
                                                            "requiredErrorMessage": "This fields is required",
                                                            "__schema_id": "substrate_vol",
                                                            "showInputLabel": false,
                                                            "labelContent": "text_239",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_733",
                                                    "id": "box_159",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            },
                                            {
                                                "fields": {
                                                    "control": [
                                                        {
                                                            "type": "dropdown",
                                                            "name": "wash_buffer",
                                                            "id": "dropDown_517",
                                                            "validationButtonType": "",
                                                            "classname": "",
                                                            "required": false,
                                                            "apiEndPoints": [
                                                                {
                                                                    type: 'init',
                                                                    method: 'post',
                                                                    url: 'inventory_item',
                                                                    payload: {
                                                                      user_input: '',
                                                                      perma: 'buffers',
                                                                    },
                                                                },
                                                            ],
                                                            "options": [],
                                                            "multiple": false,
                                                            "isClearable": false,
                                                            "isStatus": false,
                                                            "search": false,
                                                            "singleSelect": true,
                                                            "showLabel": "value",
                                                            "errorMessage": {
                                                                "noData": "This fields is required"
                                                            },
                                                            "value": "",
                                                            "imageUrl": "",
                                                            "disabled": false,
                                                            "wrapperClassName": "",
                                                            "connectedArrays": [],
                                                            "showBlankInPlaceholder": false,
                                                            "blankDataList": [],
                                                            "commonPathOfImage": "",
                                                            "__schema_id": "wash_buffer",
                                                            "showInputLabel": false,
                                                            "labelContent": "dropdown_532",
                                                            "labelClass": "form-label",
                                                            "isLabelAfter": false,
                                                            "showAdditionalInputContent": false,
                                                            "additionalInputContent": "",
                                                            "isAdditonalInputAfter": true,
                                                            "draggable": false
                                                        }
                                                    ],
                                                    "name": "box_652",
                                                    "id": "box_684",
                                                    "wrapperType": "BOX",
                                                    "fields": [],
                                                    "type": "",
                                                    "classname": "",
                                                    "addButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "removeButton": {
                                                        "classname": "",
                                                        "value": ""
                                                    },
                                                    "wrapperClassName": ""
                                                },
                                                "classname": ""
                                            }
                                        ]
                                    }
                                }
                            }
                        ],
                        "head": "",
                        "tabHeadClass": "",
                        "draggable": false,
                        "isAccordion": true,
                        "accordionConfig": {
                            "headerClassName": "",
                            "headerBtnClassName": "",
                            "header": "<span>Antibody Conjugate & Substrate Details</span>",
                            "collapseDivClassName": "",
                            "bodyClassName": "",
                            "collapseOthers": true
                        }
                    }
                ],
                "head": "",
                "tabHeadClass": "",
                "draggable": false,
                "isAccordion": true,
                "accordionConfig": {
                    "headerClassName": "",
                    "headerBtnClassName": "",
                    "header": "",
                    "collapseDivClassName": "",
                    "bodyClassName": "",
                    "collapseOthers": true
                }
            }
        ],
        "type": "",
        "classname": "",
        "showClass": true,
        "payload_schema": {
            "elisa_assay": {
                "antibody_conjugate": [
                    {
                        "wash_buffer": "wash_buffer",
                        "substrate_vol": "substrate_vol",
                        "substrate": "substrate",
                        "dilution_buffer": "dilution_buffer",
                        "dilution_factor": "dilution_factor",
                        "antibody_conjugate_vol": "antibody_conjugate_vol",
                        "antibody_conjugate": "antibody_conjugate"
                    }
                ],
                "plate_development": [
                    {
                        "absorbance": "absorbance",
                        "stop_solution": "stop_solution",
                        "reaction_stop_solution": "reaction_stop_solution",
                        "antibody_solution": "antibody_solution",
                        "barcode_id": "barcode_id",
                        "plate_id": "plate_id",
                        "plate_type": "plate_type"
                    }
                ],
                "antibody_phage_titration_details": [
                    {
                        "phage_dilution": "phage_dilution",
                        "blocking_solution": "blocking_solution",
                        "no_of_wells": "no_of_wells",
                        "antigen_conc": "antigen_conc",
                        "antigen_conc_unit": "antigen_conc_unit",
                        "no_of_antigen": "no_of_antigen",
                        "antigen": "antigen"
                    }
                ],
                "clone_antigen_info": [
                    {
                        "phage_dilution": "phage_dilution",
                        "clones_picked": "clones_picked",
                        "clonal_phage_plate_id": "clonal_phage_plate_id"
                    }
                ],
                "protocol_id": "protocol_id",
                "protocol_file_id": "protocol_file_id"
            }
        }
    },
     // upload file
     {
        jsonId: 164,
        control: [],
        head: '',
        name: 'main',
        id: 'main',
        wrapperType: 'REGULAR',
        fields: [
            {
                control: [
                    {
                        type: 'dropdown',
                        showBlankInPlaceholder: true,
                        blankDataList: ['""', 'undefined', 'null'
                        ],
                        name: 'file_type',
                        id: 'file_type',
                        validationButtonType: 'upload',
                        classname: '',
                        required: false,
                        apiEndPoints: [],
                        options: [
                            {
                                key: 'plate_reading_raw_data_file',
                                value: 'Plate reading raw data file',
                            },
                            {
                                key: 'conc_graph',
                                value: 'Conc Graph',
                            }
                        ],
                        multiple: false,
                        isClearable: false,
                        isStatus: false,
                        search: true,
                        singleSelect: true,
                        showLabel: 'value',
                        errorMessage: { noData: 'This field is required'
                        },
                        value: "",
                        imageUrl: '',
                        disabled: false,
                        wrapperClassName: '',
                        showInputLabel: true,
                        labelContent: 'Select File Type',
                        labelClass: 'form-label',
                        isLabelAfter: false,
                        showAdditionalInputContent: false,
                        additionalInputContent: '',
                        isAdditonalInputAfter: true,
                        draggable: false,
                    },
                ],
                type: '',
                name: 'wrapper_801',
                classname: 'col-12 mb-3',
                wrapperType: 'BOX',
                id: 'wrapper_766',
                fields: [],
                head: '',
                tabHeadClass: '',
                draggable: false,
            },
            {
                control: [
                    {
                        type: 'file',
                        name: 'file',
                        id: 'file',
                        value: 'upload',
                        classname: 'btn btn-file',
                        fileNameShow: true,
                        size: 10,
                        url: '',
                        functionName: '',
                        wrapperClassName: '',
                        fileCount: 1,
                        sizeKB: '50000',
                        allowMultiple: false,
                        required: false,
                        validationButtonType: 'upload',
                        formDataType: 'blob',
                        errorMsg: { invalidFormat: 'Invalid File Format', invalidSize: 'File Size exceeded'
                        },
                        fileTypes: ['all'],
                        // fileTypes: ['pdf', 'doc', 'ab1', 'txt', 'xlsx', 'xlsm', 'xlsb', 'xltx', 'xltm', 'xls', 'xlt', 'xml', 'xla', 'xlam', 'xlw', 'xlr', 'jpeg', 'png'],
                        listClass: '',
                        mainClass: '',
                        btnDivClass: '',
                        showInputLabel: true,
                        labelContent: 'Drag and drop files here or browse files  ',
                        labelClass: 'form-label',
                        requiredErrorMessage: 'This field is required',
                        isLabelAfter: false,
                        showAdditionalInputContent: false,
                        additionalInputContent: '',
                        isAdditonalInputAfter: true,
                        draggable: false,
                        showViewIcon: false,
                        showDownloadIcon: false,
                    },
                ],
                type: '',
                name: 'wrapper_379',
                classname: 'col-12 mb-3 drag-and-drop',
                wrapperType: 'BOX',
                id: 'wrapper_461',
                fields: [],
                head: '',
                tabHeadClass: '',
                draggable: false,
            },
        ],
        type: '',
        classname: '',
    },
]